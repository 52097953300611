import { findObjectByKeys } from "utils/object";
import { resolveAbsoluteUrlInParagraph } from "utils/route";
import { I18nContextData } from "i18n/context/LanguageContext";

enum CS_CALCULATOR_TYPE {
  RANGE = "range",
  SELECT = "select",
}

enum FORM_CALCULATOR_TYPE {
  RANGE = "stepper",
  SELECT = "dropdown-list",
}

export function getCalculatorForm(
  enquiryRef: any,
  publicConfigurations: any,
  i18nContext: I18nContextData
) {
  const modularBlocks = enquiryRef?.modular_blocks;

  return {
    startButtonText: enquiryRef?.start_button_text,
    nextButtonText: enquiryRef?.next_button_text,

    introductionBlock: {
      heading: findObjectByKeys(modularBlocks, "introduction_block")?.heading,
      description: resolveAbsoluteUrlInParagraph(
        findObjectByKeys(modularBlocks, "introduction_block")?.description,
        i18nContext
      ),
    },
    selectCategoryBlock: {
      prefix: findObjectByKeys(modularBlocks, "select_category")?.prefix,
      nameValue: findObjectByKeys(
        modularBlocks,
        "select_category"
      )?.name_value?.value?.map(({ key, value }: any) => {
        return {
          id: `61ec8b99-b467-4ac2-b37e-aa5ea63ddded-${key}`,
          name: key,
          displayName: key,
          parentId: "fddc7ff5-e84d-4f73-9828-03d25871a2a2",
          fields: {
            value: {
              value: value,
            },
            key: {
              value: key,
            },
          },
          value: false,
        };
      }),
      questionHint: findObjectByKeys(modularBlocks, "select_category")
        ?.question_hint,
      questionText: findObjectByKeys(modularBlocks, "select_category")
        ?.question_text,
    },
    dobBlock: {
      title: findObjectByKeys(modularBlocks, "dob_block")?.title,
      dayText: findObjectByKeys(modularBlocks, "dob_block")?.day?.default_text,
      monthText: findObjectByKeys(modularBlocks, "dob_block")?.month
        ?.default_text,
      yearText: findObjectByKeys(modularBlocks, "dob_block")?.year
        ?.default_text,
      minYear: findObjectByKeys(modularBlocks, "dob_block")?.premium_coverage
        ?.min,
      maxYear: findObjectByKeys(modularBlocks, "dob_block")?.premium_coverage
        ?.max,
      fromYear: findObjectByKeys(modularBlocks, "dob_block")?.year
        ?.minimum_year,
      toYear: findObjectByKeys(modularBlocks, "dob_block")?.year?.maximum_year,

      questionHint: findObjectByKeys(modularBlocks, "dob_block")?.question_hint,
      questionText: findObjectByKeys(modularBlocks, "dob_block")?.question_text,
      helpText: findObjectByKeys(modularBlocks, "dob_block")?.help_text,
      validationMessage: findObjectByKeys(modularBlocks, "dob_block")
        ?.validation_message,
      premiumError:
        findObjectByKeys(modularBlocks, "dob_block")?.premium_coverage
          ?.premium_error ?? "",

      longDateFormat: findObjectByKeys(modularBlocks, "dob_block")
        ?.long_date_format,
    },
    sumAssured: {
      prefix: findObjectByKeys(modularBlocks, "sum_assured")?.prefix,
      type:
        findObjectByKeys(modularBlocks, "sum_assured")?.type ===
        CS_CALCULATOR_TYPE.RANGE
          ? FORM_CALCULATOR_TYPE.RANGE
          : findObjectByKeys(modularBlocks, "sum_assured")?.type ===
            CS_CALCULATOR_TYPE.SELECT
          ? FORM_CALCULATOR_TYPE.SELECT
          : FORM_CALCULATOR_TYPE.RANGE,
      currency: findObjectByKeys(modularBlocks, "sum_assured")?.currency,
      currencyPosition: findObjectByKeys(modularBlocks, "sum_assured")
        ?.currency_position,
      amount: findObjectByKeys(modularBlocks, "sum_assured")?.amount,
      limitAmount: findObjectByKeys(modularBlocks, "sum_assured")?.limit_amount,
      limitAge: findObjectByKeys(modularBlocks, "sum_assured")?.limit_age,
      rangeDefaultValue: findObjectByKeys(modularBlocks, "sum_assured")
        ?.range_default_value,
      options: findObjectByKeys(modularBlocks, "sum_assured")
        ?.list_sum_assured_options,
      questionHint: findObjectByKeys(modularBlocks, "sum_assured")
        ?.question_hint,
      questionText: findObjectByKeys(modularBlocks, "sum_assured")
        ?.question_text,
      helpTextSelect: findObjectByKeys(modularBlocks, "sum_assured")
        ?.help_text_select,
      helpTextSelectSeparator: findObjectByKeys(modularBlocks, "sum_assured")
        ?.help_text_select_separator,
      helpTextRange: findObjectByKeys(modularBlocks, "sum_assured")
        ?.help_text_range,
      validationMessage: findObjectByKeys(modularBlocks, "sum_assured")
        ?.validation_message,
      dotNumber: findObjectByKeys(modularBlocks, "sum_assured")?.dot_number,
      dotNumberInConfirmPage: findObjectByKeys(modularBlocks, "sum_assured")
        ?.dot_number_in_confirm_page,
    },
    paymentOptions: {
      prefix: findObjectByKeys(modularBlocks, "dropdown")?.label,
      options: findObjectByKeys(modularBlocks, "dropdown")?.items,
      helpTextSelect: findObjectByKeys(modularBlocks, "dropdown"),
      questionText: findObjectByKeys(modularBlocks, "dropdown")?.question_text,
      questionHint: findObjectByKeys(modularBlocks, "dropdown")?.question_hint,
    },

    submitButtonText: enquiryRef?.submit_button_text,
    submitButtonHint: enquiryRef?.submit_button_hint,
    calculatingText: enquiryRef?.sending,
    submissionEndpoint: `${publicConfigurations?.publicRootUrl}/${enquiryRef?.submission_endpoint}`,
    authenticationKey: `Basic ${publicConfigurations?.apiAuthBase64}`,
    services: enquiryRef?.services || [],
    emailTemplate: enquiryRef?.email_template?.[0]?.template,

    mapping: {
      gender: findObjectByKeys(modularBlocks, "select_category")?.name_options
        ?.value,
      paymentFrequency: findObjectByKeys(modularBlocks, "dropdown")
        ?.items?.map((item: any) => item?.sub_items?.[0])
        .filter((item: any) => !!item),
    },
  };
}
