import { generateUniqSerial } from "utils/uid";

export function getEnquiryFieldProps(
  dataFields: any,
  type: string,
  parentId?: string,
) {
  return {
    id: generateUniqSerial(),
    name: type,
    displayName: type,
    parentId: parentId,
    fields: {
      toolTipText: {
        value: dataFields?.question_hint,
      },
      label: {
        value: dataFields?.question_text,
      },
      "Css Class": {
        value: "tool-tip",
      },
      isHidden: {
        value: false,
      },
      HelpText: {
        value: "",
      },
      GeneralError: {
        value: "",
      },
      SpecificError: {
        value: "",
      },
      apiName: null,
      "Is Tracking Enabled": {
        value: true,
      },
      "Field Type": {
        id: "fff6a7f6-db43-49f4-80aa-519af8ede3c5",
        fieldType: "Droptree",
        fieldName: "Field Type",
        url:
          "/sitecore/system/settings/forms/field-types/basic/tooltip",
        fields: {
          BackgroundColor: {
            value: "Sky",
          },
          Icon: {
            value: "Office/32x32/question.png",
          },
          IsDisabled: {
            value: false,
          },
          SpritePosition: {
            value: "",
          },
          "Field Template": {
            id: generateUniqSerial(),
            fieldType: "Droptree",
            fieldName: "Field Template",
            url:
              "/sitecore/templates/system/forms/fields/tooltip",
            fields: {},
          },
          "Allowed Validations": [],
          "Model Type": {
            value:
              "FWD.Foundation.Forms.CustomToolTipViewModel,FWD.Foundation.Forms",
          },
          "Property Editor": null,
          "View Path": {
            value: "FieldTemplates/ToolTip",
          },
        },
      },
      Conditions: {
        value: '{"fieldConditions":[]}',
      },
      "Field Key": {
        value: generateUniqSerial(),
      },
      "Collapsed by Default": {
        value: false,
      },
    },
    items: [],
  }
}