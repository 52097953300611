export function getEnquiryServices(services: any, categoryName?: string) {
  if (services?.address_to?.length > 0) {
    return services?.address_to;
  } else if (services?.filter_by_categories?.length > 0 && !!categoryName) {
    let selectedService = services?.filter_by_categories?.find((c: any) => {
      return c?.category_name?.toLowerCase() === categoryName?.toLowerCase();
    });
    return selectedService?.address_to || [];
  }
  return [];
}
