import { resolveAbsoluteUrl } from "utils/route";
import { I18nContextData } from "i18n/context/LanguageContext";

export function getCallbackEnquiryFormProps(
  generalFormEnquiry: any,
  productName: string,
  i18nContext: I18nContextData,
  isConfirmed: boolean = false
): any {
  const language = i18nContext?.language || "en";
  return {
    sitecoreContext: {
      route: {
        name: "Products",
        displayName: "Products",
        fields: {
          contentType: null,
          enableProductName: {
            value: true,
          },
          expectedProductName: {
            value: generalFormEnquiry?.expectedProductName || productName || "",
          },
          pagedescription: {
            value: "",
          },
          pagetitle: {
            value: "",
          },
          disableHTMLCache: {
            value: true,
          },
          globalMetaTextSetting: null,
          pageType: {
            id: "4fded742-177c-4850-9853-fa31cc47d63e",
            fieldType: "Droplink",
            fieldName: "pageType",
            url: "/components/pagetype/individual",
            fields: {
              value: {
                value: "Individual",
              },
              key: {
                value: "individual",
              },
            },
          },
          localizedPages: {
            value: {},
          },
          metadataTitle: {
            value: "FWD Insurance Products",
          },
          metadataDescription: {
            value:
              "At FWD, we design insurance products that’s inspired by our customers. Explore our range of insurance products to find what best suits you, your lifestyle and your needs.",
          },
          metadataKeywords: {
            value: "",
          },
          accrediationValue: null,
          accreditationMode: null,
          accreditationDescription: {
            value: "",
          },
          disableCompliance: {
            value: false,
          },
          openGraphType: {
            value: "",
          },
          openGraphDescription: {
            value: "",
          },
          openGraphURL: {
            value: {},
          },
          openGraphImage: {
            value: {},
          },
          openGraphTitle: {
            value: "",
          },
          canonicalUrl: {
            value: "",
          },
          isPageSearchable: {
            value: true,
          },
          hideInSitemap: {
            value: false,
          },
          hideLocalizedVersion: {
            value: false,
          },
        },
        databaseName: "web",
        deviceId: "fe5d7fdf-89c0-4d99-9aa3-b5fbd009c9f3",
        itemId: "db3ff0bc-2e10-4735-8baa-76a87a906821",
        itemLanguage: language?.languageCode,
        itemVersion: 2,
        layoutId: "e60605a5-f4f9-479a-b992-741bce767b1d",
        templateId: "52873596-fd74-488b-9e6e-6382b1cc2eb2",
        templateName: "Product Listing Page",
        placeholders: {
          "jss-metadata": [],
          "jss-header": [],
          "jss-footer": [],
        },
        sitecoreRoute: "en/products",
      },
      itemId: "db3ff0bc-2e10-4735-8baa-76a87a906821",
      pageEditing: false,
      site: {
        name: "fwd-th",
      },
      countryCode: i18nContext?.countryCode,
      pageState: "normal",
      language: language?.languageCode || "en",
      languageCode: language?.languageCode || "en",
      defaultLanguage: "en",
      siteSettings: {
        productListPage: "/products/",
        splashPage: "",
        globalMetaText: "FWD Hong Kong",
        cookieMessage:
          '<p>We use cookies to give you the best possible experience on our website. By continuing to browse this site, you give consent for cookies to be used. </p>\n<p>For more details please read our <a href="~/link.aspx?_id=DCD61657FA884252B0580B7EBE7F06F1&amp;_z=z">Cookie Policy</a>.</p>',
        cookieTimeStamp: "10",
        currencyPlacement: "BeforeNumericals",
        currencyWhitespace: false,
        currencySeparator: {
          value: {},
        },
        currencyLabel: {
          value: {
            USD: "US$",
            EUR: "EUR",
            HKD: "HKD",
          },
        },
        splashPageUpdatedDuration: "10",
        splashPageUpdatedDate: "",
        faviconImageUrl:
          "/-/media/global/images/icons/favicon.ico?rev=51cce1bfcfb347b3b2fc7d304180401d",
        productErrorPictogramUrl:
          "/-/media/pictograms/no-result-48px2x.png?rev=92f0c2a020e145e7a186408b6f8a8c09",
        dateFormat: "D MMMM YYYY",
        enablePlanCardSharePostCalc: "",
        sessionTimeout: "20",
        loaderTimeout: "20",
        gAID: "GTM-N6B3G67",
        gTMID: "GTM-N6B3G67",
        gtmParamters: [],
        hostName: resolveAbsoluteUrl("", i18nContext),
        enableBackToTopButton: 1,
        dialogSessionTimeout: "20",
        productFallbackCTA: {
          value: {
            href: "/forms-list/product-enquiry-form",
            text: "Enquire",
            anchor: "Enquire",
            linkType: "form",
            title: "Enquire",
            queryString: "enquiry",
            id: "{F2728281-FA5C-41CB-B168-773E0C78197F}",
            gaLabel: "Enquiry",
          },
        },
        brightcoveAccountID: "",
        languageIcon: {
          key: "language-globe",
          value: "language-globe",
        },
        enableMultipleNotifications: true,
        hidePrimaryLanguage: true,
        currencyGALabelName: "",
      },
      share: {
        socialApps: [],
      },
      siteNotifications: {},
      dialogModals: {
        modalList: [],
      },
      localizedPages: [
        {
          "en-hk": "/en/products",
        },
        {
          "zh-Hant-hk": "/products",
        },
      ],
      isRequestFromExternalSource: true,
      availableLanguages: ["id", "en"],
      languageBroken: false,
      svgAssets: null,
      i18nContext: i18nContext
    },
    productDetails: {
      initializer: "enquiry",
      enquiryInitializer: "SUPPORT",
      productName: "",
      minAge: "",
      maxAge: "",
      minSumAssured: "",
      maxSumAssured: "",
      calculationOptions: [],
      sumAssuredSelectionType: "",
      premiumSelectionType: "",
      minPremiumYearly: "",
      maxPremiumYearly: "",
      sumAssuredRange: "",
      sumAssuredPaymentMode: [],
      purchaseMethod: [],
      premiumRange: "",
      premiumStepperInterval: "",
      sumAssuredStepperInterval: "",
      plans: [],
      ridersAvailable: null,
      enableEnquiryForRiders: null,
      associatedItems: [],
      filteredAssociatedItems: [],
      allPlanCards: [],
      productType: "",
      talkToAgentLink: null,
      talkToAgentTitle: "",
      expectedInsuranceType: "",
      parameters: null,
      expectedInsuranceTypeProductCard: "",
      parametersProductCard: "",
    },
    formLinks: {
      calculateFormLink: "",
      recommenderFormLink: "",
      enquiryFormLink: "",
      callbackFormLink:
        "/sitecore/api/layout/render/jss?item=/forms-list/product-enquiry-form&sc_lang=en&sc_apikey={D7333054-1DCE-4B0C-A9A5-AC7372A796AB}",
      emailTemplates: generalFormEnquiry?.emailTemplates,
    },
    finalizer: "",
    apiStatus: {
      loading: false,
      apiStatus: null,
      errorCode: "",
      failCounter: 0,
    },
    form: {
      sitecore: {
        context: {
          pageEditing: false,
          site: {
            name: "fwd-hk",
          },
          pageState: "normal",
          language: "en",
          languageCode: "en",
          defaultLanguage: "th-TH",
          siteSettings: {
            productListPage: "/products/",
            splashPage: "",
            globalMetaText: "FWD Hong Kong",
            cookieMessage:
              '<p>We use cookies to give you the best possible experience on our website. By continuing to browse this site, you give consent for cookies to be used. </p>\n<p>For more details please read our <a href="~/link.aspx?_id=DCD61657FA884252B0580B7EBE7F06F1&amp;_z=z">Cookie Policy</a>.</p>',
            cookieTimeStamp: "10",
            currencyPlacement: "BeforeNumericals",
            currencyWhitespace: false,
            currencySeparator: {
              value: {},
            },
            currencyLabel: {
              value: {
                USD: "US$",
                EUR: "EUR",
                HKD: "HKD",
              },
            },
            splashPageUpdatedDuration: "10",
            splashPageUpdatedDate: "",
            faviconImageUrl:
              "https://www.fwd.com.hk/-/media/global/images/icons/favicon.ico?rev=51cce1bfcfb347b3b2fc7d304180401d",
            productErrorPictogramUrl:
              "https://www.fwd.com.hk/-/media/pictograms/no-result-48px2x.png?rev=92f0c2a020e145e7a186408b6f8a8c09",
            dateFormat: "D MMMM YYYY",
            enablePlanCardSharePostCalc: "",
            sessionTimeout: "20",
            loaderTimeout: "20",
            gAID: "GTM-N6B3G67",
            gTMID: "GTM-N6B3G67",
            gtmParamters: [],
            hostName: "https://www.fwd.com.hk",
            enableBackToTopButton: 1,
            dialogSessionTimeout: "20",
            productFallbackCTA: {
              value: {
                href: "/forms-list/product-enquiry-form",
                text: "Enquire",
                anchor: "Enquire",
                linkType: "form",
                title: "Enquire",
                queryString: "enquiry",
                id: "{F2728281-FA5C-41CB-B168-773E0C78197F}",
                gaLabel: "Enquiry",
              },
            },
            brightcoveAccountID: "",
            languageIcon: {
              key: "language-globe",
              value: "language-globe",
            },
            enableMultipleNotifications: true,
            hidePrimaryLanguage: true,
            currencyGALabelName: "",
          },
          share: {
            socialApps: [],
          },
          siteNotifications: {
            notifications: [],
          },
          dialogModals: {
            modalList: [],
          },
          disclosurePopups: {
            popupList: [],
          },
          localizedPages: [
            {
              "en-hk": "/en/forms-list/general-enquiry-form",
            },
            {
              "zh-Hant-hk": "/forms-list/general-enquiry-form",
            },
          ],
          isRequestFromExternalSource: true,
          availableLanguages: ["en", "tc"],
          services: generalFormEnquiry?.services || [],
        },
        route: {
          name: "Product Enquiry Form",
          displayName: "Product Enquiry Form",
          fields: {
            apiList: [
              {
                id: "d58e3660-0ab7-4811-ad6f-d007e52af349",
                fields: {
                  apiName: {
                    value: "SaveLead",
                  },
                  value: {
                    value: generalFormEnquiry?.submissionEndpoint,
                  },
                  key: {
                    value: generalFormEnquiry?.authenticationKey,
                  },
                },
              },
              {
                id: "2334a4bf-d431-461f-b313-1dd10100c27e",
                fields: {
                  apiName: {
                    value: "Email",
                  },
                  value: {
                    value: generalFormEnquiry?.sendEmailEndpoint,
                  },
                  key: {
                    value: generalFormEnquiry?.authenticationKey,
                  },
                },
              },
            ],
            formEmailTemplate: {
              value: {
                text: "",
                linktype: "form",
                querystring: "",
                target: "",
                id: "{604FEEB8-7B79-4966-A3D8-FEA5BCD7D98D}",
                href: "/forms-list/product-enquiry-form/product-enquiry-form-email-template",
              },
            },
            enableProductName: {
              value: true,
            },
          },
          databaseName: "web",
          deviceId: "fe5d7fdf-89c0-4d99-9aa3-b5fbd009c9f3",
          itemId: "3f7afe34-5245-4587-adec-6a0239c32ff3",
          itemLanguage: "en",
          itemVersion: 1,
          layoutId: "e60605a5-f4f9-479a-b992-741bce767b1d",
          templateId: "d48ab251-45a3-498a-a8c1-629e33a6b9b4",
          templateName: "Form",
          placeholders: {
            "jss-metadata": [],
            "jss-header": [],
            "jss-main": [
              {
                uid: "9e687e41-fa14-4810-a780-5b42b5322ae8",
                componentName: "Forms",
                dataSource: "{57EC00DF-F75A-4645-A4F1-A830BE98DE45}",
                params: {
                  formType: "conversational",
                  loaderBackgroundColor: generalFormEnquiry?.loadingDarkMode
                    ? "orange"
                    : "white",
                },
                fields: {
                  items: generalFormEnquiry?.enquirySteps,
                },
              },
            ],
            "jss-footer": [],
          },
        },
      },
    },
  };
}
