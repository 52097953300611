import { findObjectByKeys } from "utils/object";
import { resolveAbsoluteUrlInParagraph } from "utils/route";
import { generateUniqSerial } from "utils/uid";
import { getEnquiryFieldProps } from "./getEnquiryFieldProps";
import { I18nContextData } from "i18n/context/LanguageContext";
import { extractValueFromTag } from "utils/string";

export function getEnquiryFormProps(
  enquiryRef: any,
  publicConfigurations: any,
  i18nContext: I18nContextData
) {
  let enquirySteps: any = [];
  let modularBlocks = enquiryRef?.modular_blocks;
  if (!modularBlocks) return null;
  const consentBlock = findObjectByKeys(modularBlocks, "consent_block");
  const consentBlockBgColor = !!consentBlock?.no_background ? "white" : "grey";
  let countryCode: string = enquiryRef?.country;
  if (!countryCode) {
    countryCode = publicConfigurations?.publicCountryCode;
  }

  let submissionUrl = enquiryRef?.submission_endpoint;
  if (!/^(http:\/\/|https:\/\/|mailto:|tel:).*/.test(submissionUrl)) {
    submissionUrl = `${publicConfigurations?.publicRootUrl}${submissionUrl}`;
  }
  let enf: any = {
    startButtonText: enquiryRef?.start_button_text,
    nextButtonText: enquiryRef?.next_button_text,
    submissionEndpoint: submissionUrl,
    sendEmailEndpoint: submissionUrl,
    authenticationKey:
      publicConfigurations?.apiAuthBase64 || enquiryRef?.authentication_key,
    emailTemplates: !!enquiryRef?.email_template
      ? Object.fromEntries(
          enquiryRef?.email_template?.map((e: any) => {
            return [e.template, e.uid];
          })
        )
      : {},
    services: enquiryRef?.services || [],
    expectedProductName: enquiryRef?.expected_product_name || "",
  };
  let constants: any = {
    introduction_block: {
      id: "fcc3e054-7b8b-498b-9e57-d3bfbb8b867a",
      fieldKey: "3F83046E6287400389197ECF35DAA74D",
    },
    select_category: {
      id: "f34fb0d2-3fc0-4a9a-8eb1-88717eb6ddce",
      fieldKey: "04B6B0BE40774539912EB6E4268008B9",
      valueKey: "93BF0439473B468BBC93BC561328CA9D",
    },
    select_salutation: {
      id: "f34fb0d3-3fc0-4a9a-8eb1-88717eb6ddce",
      fieldKey: "05B6B0BE40774539912EB6E4268008B9",
      valueKey: "94BF0439473B468BBC93BC561328CA9D",
    },
    full_name_block: {
      id: "f4f6c886-df71-4d8e-b2cd-29e0c8b3ee95",
      fieldKey: "F2B07BE6685F41968F59E8E76ABBC8AD",
    },
    dob_block: {
      id: "3cb1e880-b628-4ef4-89f8-80e7bcbddc7d",
      fieldKey: "83F59508E70E42938C9C8BC6626295FC",
    },
    email_block: {
      id: "ccff71cf-07e1-4d4a-8593-65242a6e464e",
      fieldKey: "9D55C171FC094F128842ACE2B3DDA4A9",
      valueKey: "CF485B1F33E5435281D931A3DD5B328B",
    },
    phone_number_block: {
      id: "13ff3a7b-7607-4878-b7ad-5a86579bddab",
      fieldKey: "A822D936CE184893B07F20E4B97E9B22",
    },
    select_province_block: {
      id: "05843f30-abb3-427b-a9c4-ccec95f1e45d",
      fieldKey: "F46A6A39786642D2A743E145F488453A",
    },
    appointment_day_block: {
      id: "ce204667-409d-401f-9585-c28ee8446b9e",
      fieldKey: "9E94EEE6AE21451DBE91C3B2B376849A",
    },
    appointment_time_block: {
      id: "2420a361-ff7f-48f4-aea8-860bcbd642ec",
      fieldKey: "F46A6A39786642D2A743E145F488033A",
    },
    preferred_contact_method: {
      id: "3fc9e2df-ed81-4104-909d-ecf0ffad216f",
      fieldKey: "4DF8F5E941DF48CC846122E6426D5C47",
    },
    existing_customer: {
      id: "3fc9e2df-ed81-4104-909d-ecf0ffad311f",
      fieldKey: "4DF8F5E941DF48CC846122E6426D5D47",
    },
    insurance_policy: {
      id: "3fc9e2df-ed81-4104-909d-ecf0ffad3132",
      fieldKey: "4DF8F5E941DF48CC846122E6426D5117",
    },
    dropdown_gender: {
      id: "3fc9e2df-ed81-4104-909d-ecf0ffad1132",
      fieldKey: "94BF0439473B468BBC93BC561328CMKD",
    },
    dropdown_workIn: {
      id: "3fc9e2df-ed81-4104-909d-ecf0ffad3193",
      fieldKey: "94BF0439473B468BBC93BC561328WORKIN",
    },
    dropdown_companyIndustry: {
      id: "4fc9e2df-ed81-4104-909d-ecf0ffad3284",
      fieldKey: "94BF0439473B468BBC93BC561328CPNID",
    },
    dropdown_companyEmployees: {
      id: "4fc9e2df-ed81-4104-909d-company-employees",
      fieldKey: "94BF0439473B468BBC93COMPANYEMPLOYEES",
    },
    text_field_workAt: {
      id: "13ff3a7b-7607-4878-b7ad-5a86579bmddf",
      fieldKey: "A822D936CE184893B07F20E4B97E313",
    },
    text_field_specialisingIn: {
      id: "13ff3a7b-7607-4878-b7ad-specialising-in",
      fieldKey: "A822D936CE184893B07F20SPECIALISINGIN",
    },
    text_field_referralCodeAgentCodeValue: {
      id: "13ff3a7b-7607-4878-b7ad-referral-code-agent-code-value",
      fieldKey: "A822D936CE184893B07F20REFERRALCODEAGENTCODEVALUE",
    },
    text_field_age: {
      id: "13ff3a7b-7607-4878-b7ad-5a86579bdeeb",
      fieldKey: "A822D936CE184893B07F20E4B97E9B11",
    },
    text_field_otherManufacturingOtherField2: {
      id: "13ff3a7b-7607-4878-b7ad-5a84449bdeeb",
      fieldKey: "A822D936CE184893B07F20E4BOE39B11",
    },
    radios_group_referralCode: {
      id: "3fc9e2df-ed81-4104-909d-ecf0ffad311g",
      fieldKey: "A822D936CE184893B07F2REFERRALCODE",
    },
    leave_us_a_message: {
      id: "2344a106-ff60-4cbf-ac9c-c3f0994b0e83",
      fieldKey: "124E675A556E49098DB1F1CA757BA6BA",
    },
    multi_select_block_homeProvince: {
      id: "1344a106-ff60-4cbf-ac9c-c3f0994b0b93",
      fieldKey: "824E675A556E49098DB1F1CA757BA8DE",
    },
    multi_select_block_sbcArea: {
      id: "1344a106-ff60-4cbf-ac9c-c3f0994b21c4",
      fieldKey: "824E675A556E49098DB1F1CA757BA9MA",
    },
    multi_select_block_recruitmentTalk: {
      id: "1344a106-ff60-4cbf-ac9c-c3f0994b3123",
      fieldKey: "824E675A556E49098DB1F1CA757DOE93",
    },
    multi_select_block_recruitmentOpenDay: {
      id: "1344a106-ff60-4cbf-ac9c-c3f00932383011",
      fieldKey: "824E675A556E49098DB1F1CA75OED94",
    },
  };
  let categoryEqConditions: any = null;
  let categoryNeqConditions: any = null;
  let preferredContactNameValues: any = null;
  const defaultConditions = { fieldConditions: [] };

  const submitBtnId = generateUniqSerial();

  const consentStep = [
    {
      id: "8e8add7a-6528-42e9-9deb-9e6c2f2fbc1f",
      name: "PrivacyPolicySection",
      displayName: "PrivacyPolicySection",
      parentId: "2344a106-ff60-4cbf-ac9c-c3f0994b0e83",
      fields: {
        backgroundColor: {
          id: "cf8cf9a4-0f70-4db2-b298-90e820aca94b",
          fieldType: "Droplink",
          fieldName: "backgroundColor",
          url: `/sitecore/content/fwd/global/settings/list/colors/${consentBlockBgColor}white`,
          fields: {
            value: {
              value: `${consentBlockBgColor}-white`,
            },
            key: {
              value: `${consentBlockBgColor}-white`,
            },
          },
        },
        "Css Class": {
          value: "consentSection",
        },
        isHidden: {
          value: false,
        },
        HelpText: {
          value: "",
        },
        GeneralError: {
          value: "",
        },
        SpecificError: {
          value: "",
        },
        apiName: null,
        "Field Type": {
          id: "447aa745-6d29-4b65-a5a3-8173aa8af548",
          fieldType: "Droptree",
          fieldName: "Field Type",
          url: "/sitecore/system/settings/forms/field-types/structure/section",
          fields: {
            BackgroundColor: {
              value: "Eggplant",
            },
            Icon: {
              value: "OfficeWhite/32x32/document_header.png",
            },
            IsDisabled: {
              value: false,
            },
            SpritePosition: {
              value: "",
            },
            "Field Template": {
              id: "8cddb194-f456-4a75-89b7-346f8f39f95c",
              fieldType: "Droptree",
              fieldName: "Field Template",
              url: "/sitecore/templates/system/forms/fields/section",
              fields: {},
            },
            "Allowed Validations": [],
            "Model Type": {
              value:
                "Sitecore.ExperienceForms.Mvc.Models.Fields.FieldViewModel,Sitecore.ExperienceForms.Mvc",
            },
            "Property Editor": null,
            "View Path": {
              value: "FieldTemplates/Section",
            },
          },
        },
        Conditions: {
          value: '{"fieldConditions":[]}',
        },
        "Field Key": {
          value: "618B4717BC7C4EE3A52406098224765D",
        },
        "Collapsed by Default": {
          value: false,
        },
      },
      items: [
        {
          id: "65f6aef0-e025-4012-848b-4b220f070767",
          name: "privacyPolicy",
          displayName: "privacyPolicy",
          parentId: "8e8add7a-6528-42e9-9deb-9e6c2f2fbc1f",
          fields: {
            Datasource: {
              id: "cb493c1e-b699-414f-af4a-91f3c01c76e2",
              fieldType: "Droptree",
              fieldName: "Datasource",
              url: "/content/forms/privacypolicy",
              fields: {},
            },
            "Default Selection": {
              value: "",
            },
            "Display Field Name": {
              value: "value",
            },
            "Is Dynamic": {
              value: true,
            },
            "Value Field Name": {
              value: "value",
            },
            "Css Class": {
              value: "block privacyPolicyCheckbox",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: consentBlock?.validation_message,
            },
            SpecificError: {
              value: "",
            },
            apiName: {
              id: "2b066ac3-4cbd-41c7-8e19-c26ab07eccc1",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/consentcheckbox",
              fields: {
                value: {
                  value: "consentCheckBox",
                },
                key: {
                  value: "consentCheckBox",
                },
              },
            },
            "Label Css Class": {
              value: "",
            },
            Title: {
              value: "",
            },
            Required: {
              value: true,
            },
            Validations: [],
            "Is Tracking Enabled": {
              value: true,
            },
            "Allow Save": {
              value: true,
            },
            "Value Provider Parameters": {
              value: "",
            },
            "Value Provider": null,
            "Field Type": {
              id: "d86a361a-d4ff-46b2-9e97-a37fc5b1fe1a",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/lists/checkbox-list",
              fields: {
                BackgroundColor: {
                  value: "Grass",
                },
                Icon: {
                  value: "OfficeWhite/32x32/checkbox_group.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "5b672865-55d2-413e-b699-fdfc7e732ccf",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/list",
                  fields: {},
                },
                "Allowed Validations": [],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomCheckBoxListViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/CheckBoxList",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: "C470CC60AF974C7AADFF19FFAF9BF37D",
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: [
            {
              id: "5728cfb1-3ddb-4eb6-8ae1-c125c26aae08",
              name: "privacy-policy",
              displayName: "Privacy Policy",
              parentId: "cb493c1e-b699-414f-af4a-91f3c01c76e2",
              fields: {
                value: {
                  value: resolveAbsoluteUrlInParagraph(
                    consentBlock?.content,
                    i18nContext
                  ),
                },
              },
            },
          ],
        },
      ],
    },
    {
      id: "07365611-d72c-45cd-b8ed-fc07b80cdb88",
      name: "commentsSubmitClick",
      displayName: "Comment Submit",
      parentId: "2344a106-ff60-4cbf-ac9c-c3f0994b0e83",
      fields: {
        "Navigation Step": {
          value: "0",
        },
        emailTemplateLink: {
          value: "",
        },
        "Css Class": {
          value: "submitButton",
        },
        isHidden: {
          value: false,
        },
        HelpText: {
          value: "",
        },
        GeneralError: {
          value: "",
        },
        SpecificError: {
          value: "",
        },
        apiName: {
          id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
          fieldType: "Droplink",
          fieldName: "apiName",
          url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
          fields: {
            value: {
              value: "",
            },
            key: {
              value: "",
            },
          },
        },
        "Label Css Class": {
          value: "",
        },
        Title: {
          value: consentBlock?.submit_button_text,
        },
        "Field Type": {
          id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
          fieldType: "Droptree",
          fieldName: "Field Type",
          url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
          fields: {
            BackgroundColor: {
              value: "Eggplant",
            },
            Icon: {
              value: "OfficeWhite/32x32/button.png",
            },
            IsDisabled: {
              value: false,
            },
            SpritePosition: {
              value: "",
            },
            "Field Template": {
              id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
              fieldType: "Droptree",
              fieldName: "Field Template",
              url: "/sitecore/templates/system/forms/fields/button",
              fields: {},
            },
            "Allowed Validations": [],
            "Model Type": {
              value:
                "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
            },
            "Property Editor": null,
            "View Path": {
              value: "FieldTemplates/Button",
            },
          },
        },
        Conditions: {
          value: '{"fieldConditions":[]}',
        },
        "Field Key": {
          value: "1BCD51427EDA4A908AD768C2D9CA67CF",
        },
        "Collapsed by Default": {
          value: false,
        },
      },
      items: [
        {
          id: "f8f3882f-8829-40ff-acab-c690e0060c94",
          name: "Send Email",
          displayName: "Send Email",
          parentId: "07365611-d72c-45cd-b8ed-fc07b80cdb88",
          fields: {
            Description: {
              value: "",
            },
            Parameters: {
              value: "",
            },
            "Submit Action": {
              id: "2f66e378-be9d-4d9d-b43b-fc6ddc0e3db5",
              fieldType: "Droptree",
              fieldName: "Submit Action",
              url: "/sitecore/system/settings/forms/submit-actions/custom-action",
              fields: {
                Editor: null,
                "Error Message": {
                  value: "Saving form data failed!",
                },
                "Model Type": {
                  value: "Sitecore.ExperienceForms.Processing.Actions.SaveData",
                },
              },
            },
          },
          items: [],
        },
        {
          id: "9390c91e-92a4-4875-a859-9d4a753d96f5",
          name: "Redirect to Page",
          displayName: "Redirect to Page",
          parentId: "07365611-d72c-45cd-b8ed-fc07b80cdb88",
          fields: {
            Description: {
              value: "Thank You - Phone",
            },
            Parameters: {
              value: '{"referenceId":"{3627D278-2067-4AA3-831E-496E0AF1BD30}"}',
            },
            "Submit Action": {
              id: "3f3e2093-9dea-4199-86ca-44fc69ef624d",
              fieldType: "Droptree",
              fieldName: "Submit Action",
              url: "/sitecore/system/settings/forms/submit-actions/redirect-to-page",
              fields: {
                Editor: null,
                "Error Message": {
                  value: "Redirect to page failed!",
                },
                "Model Type": {
                  value:
                    "Sitecore.ExperienceForms.Processing.Actions.RedirectToPage",
                },
              },
            },
          },
          items: [],
        },
      ],
    },
  ];
  modularBlocks.forEach((block: any, index: number) => {
    let nextStepInfos: any = {};
    const nextPosition = index + 1;
    if (nextPosition < modularBlocks.length) {
      const nextStep = Object.keys(modularBlocks[nextPosition]);
      let nextKeyName = "";
      switch (nextStep[0]) {
        case "multi_select_block":
          nextKeyName = `multi_select_block_${modularBlocks[nextPosition]?.multi_select_block?.field_name}`;
          break;
        case "dropdown":
          nextKeyName = `dropdown_${modularBlocks[nextPosition]?.dropdown?.field_name}`;
          break;
        case "text_field":
          nextKeyName = `text_field_${modularBlocks[nextPosition]?.text_field?.field_name}`;
          break;
        case "radios_group":
          nextKeyName = `radios_group_${modularBlocks[nextPosition]?.radios_group?.field_name}`;
          break;
        default:
          nextKeyName = nextStep[0];
          break;
      }
      nextStepInfos = constants[nextKeyName];
    }

    if (block?.introduction_block) {
      let blockItems: any = [];
      const descriptionItem = {
        id: "4ef4127e-b7f0-4eaa-a49f-5e3081db18f4",
        name: "Text",
        displayName: "Text",
        parentId: "fcc3e054-7b8b-498b-9e57-d3bfbb8b867a",
        fields: {
          "Html Tag": {
            value: "p",
          },
          Text: {
            value: block?.introduction_block?.description,
          },
          "Css Class": {
            value: "splashScreenDisclaimer",
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: {
            id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
            fieldType: "Droplink",
            fieldName: "apiName",
            url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
            fields: {
              value: {
                value: "",
              },
              key: {
                value: "",
              },
            },
          },
          "Field Type": {
            id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/basic/text",
            fields: {
              BackgroundColor: {
                value: "Sky",
              },
              Icon: {
                value: "OfficeWhite/32x32/document_text.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/text",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Text",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: "89B5A6C3C7D84BCEAB2F22A622F968AE",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [],
      };
      const nextItem = {
        id: "404e7cce-6c5e-4d2d-b2d9-d71fe61ef1c1",
        name: "splashScreenClick",
        displayName: "splashScreenClick",
        parentId: "fcc3e054-7b8b-498b-9e57-d3bfbb8b867a",
        fields: {
          "Navigation Step": {
            value: "1",
          },
          emailTemplateLink: {
            value: "",
          },
          "Css Class": {
            value: "",
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: {
            id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
            fieldType: "Droplink",
            fieldName: "apiName",
            url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
            fields: {
              value: {
                value: "",
              },
              key: {
                value: "",
              },
            },
          },
          "Label Css Class": {
            value: "",
          },
          Title: {
            value: enf?.startButtonText,
          },
          "Field Type": {
            id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/button.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/button",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Button",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: "12A1320BA02240D6A3DAA22D4CD38950",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [],
      };
      if (block?.introduction_block?.description_at_bottom) {
        blockItems.push(nextItem, descriptionItem);
      } else {
        blockItems.push(descriptionItem, nextItem);
      }
      const introductionBlock = {
        id: constants?.introduction_block.id,
        name: "splashScreen",
        displayName: "splashScreen",
        parentId: "57ec00df-f75a-4645-a4f1-a830be98de45",
        fields: {
          gaLabel: {
            value: "",
          },
          backgroundColor: {
            id: "b47928d6-98fc-4b29-8d0f-586ab477286e",
            fieldType: "Droplink",
            fieldName: "backgroundColor",
            url: "/sitecore/content/fwd/global/settings/list/colors/orangewhite",
            fields: {
              value: {
                value: "orange-white",
              },
              key: {
                value: "OrangeWhite",
              },
            },
          },
          "Css Class": {
            value: "splashScreenPage darkMode",
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: null,
          "Field Type": {
            id: "d819b43e-c136-4392-9393-8be7fce32f5e",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/page",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/window_split_ver.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "cfee7b51-8505-45ce-b843-9358f827df87",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/page",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomPageViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Page",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: constants?.introduction_block.fieldKey,
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [
          {
            id: "0d83cfc8-883b-4777-924f-bfbea02901e0",
            name: "introText",
            displayName: "introText",
            parentId: "fcc3e054-7b8b-498b-9e57-d3bfbb8b867a",
            fields: {
              "Html Tag": {
                value: "p",
              },
              Text: {
                value: block?.introduction_block?.heading,
              },
              "Css Class": {
                value: "intro",
              },
              isHidden: {
                value: false,
              },
              HelpText: {
                value: "",
              },
              GeneralError: {
                value: "",
              },
              SpecificError: {
                value: "",
              },
              apiName: {
                id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
                fieldType: "Droplink",
                fieldName: "apiName",
                url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
                fields: {
                  value: {
                    value: "",
                  },
                  key: {
                    value: "",
                  },
                },
              },
              "Field Type": {
                id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
                fieldType: "Droptree",
                fieldName: "Field Type",
                url: "/sitecore/system/settings/forms/field-types/basic/text",
                fields: {
                  BackgroundColor: {
                    value: "Sky",
                  },
                  Icon: {
                    value: "OfficeWhite/32x32/document_text.png",
                  },
                  IsDisabled: {
                    value: false,
                  },
                  SpritePosition: {
                    value: "",
                  },
                  "Field Template": {
                    id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                    fieldType: "Droptree",
                    fieldName: "Field Template",
                    url: "/sitecore/templates/system/forms/fields/text",
                    fields: {},
                  },
                  "Allowed Validations": [],
                  "Model Type": {
                    value:
                      "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
                  },
                  "Property Editor": null,
                  "View Path": {
                    value: "FieldTemplates/Text",
                  },
                },
              },
              Conditions: {
                value: '{"fieldConditions":[]}',
              },
              "Field Key": {
                value: "F0B323C89D9942BFA7DA017E69B864AB",
              },
              "Collapsed by Default": {
                value: false,
              },
            },
            items: [],
          },
          ...blockItems,
        ],
      };
      enquirySteps.push(introductionBlock);
    }

    if (block?.select_category) {
      const nameOptions = block?.select_category?.name_options?.value?.map(
        ({ key, value }: any) => {
          return {
            id: `61ec8b99-b467-4ac2-b37e-aa5ea63ddded-${key}`,
            name: key,
            displayName: key,
            parentId: "fddc7ff5-e84d-4f73-9828-03d25871a2a2",
            fields: {
              value: {
                value: key,
              },
              key: {
                value: key,
              },
            },
            value: !!value,
          };
        }
      );
      const nameHasOptions = nameOptions?.filter((v: any) => {
        return !!v.value;
      });
      categoryNeqConditions =
        nameHasOptions?.map((v: any) => {
          return {
            fieldId: "93BF0439473B468BBC93BC561328CA9D",
            operatorId: "{49F47E77-E8C5-46F9-BF39-78D6B0D40B48}",
            value: v?.fields?.key?.value,
          };
        }) || [];
      categoryEqConditions =
        nameHasOptions?.map((v: any) => {
          return {
            fieldId: "93BF0439473B468BBC93BC561328CA9D",
            operatorId: "{1D38B217-A2EE-4E7B-B6ED-13E751462FEB}",
            value: v?.fields?.key?.value,
          };
        }) || [];

      const valueTaskOptionsConditions = {
        fieldConditions: [
          {
            matchTypeId: "{365C94DA-C1CD-4783-A91D-0D17A16C7117}",
            conditions: categoryNeqConditions,
            actions: [
              {
                fieldId: "0B059E90D26E41B2B35BE476FB35A168",
                actionTypeId: "{AAE07A52-46A4-49EF-98B0-C2595BAC2382}",
                value: null,
              },
            ],
          },
          {
            matchTypeId: "{365C94DA-C1CD-4783-A91D-0D17A16C7117}",
            conditions: categoryEqConditions,
            actions: [
              {
                fieldId: "70CF13F315E14103A03450E07FEA39C1",
                actionTypeId: "{AAE07A52-46A4-49EF-98B0-C2595BAC2382}",
                value: null,
              },
            ],
          },
        ],
      };

      const optionsValue = block?.select_category?.options_value?.value?.filter(
        (item: any) => item.key
      );

      const taskOptionsConditions =
        categoryEqConditions?.length > 0 && optionsValue?.length > 0
          ? valueTaskOptionsConditions
          : defaultConditions;
      const subOptions: any =
        optionsValue?.length > 0
          ? optionsValue?.map(({ key, value }: any) => {
              return {
                id: `80d6930e-5926-4948-8dbc-7f965a66d25a-${key}`,
                name: key,
                displayName: key,
                parentId: "93426df0-656f-4a3a-9def-358693f14b0c",
                fields: {
                  value: {
                    value: value,
                  },
                  key: {
                    value: value,
                  },
                },
                value: false,
              };
            })
          : [];
      let blockItems = [
        {
          id: "263046a7-a1a9-456e-99fe-a5713db6491c",
          name: "Text",
          displayName: "Text",
          parentId: "f34fb0d2-3fc0-4a9a-8eb1-88717eb6ddce",
          fields: {
            "Html Tag": {
              value: "p",
            },
            Text: {
              value: block?.select_category?.prefix,
            },
            "Css Class": {
              value: "block",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: "",
            },
            SpecificError: {
              value: "",
            },
            apiName: {
              id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
              fields: {
                value: {
                  value: "",
                },
                key: {
                  value: "",
                },
              },
            },
            "Field Type": {
              id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/basic/text",
              fields: {
                BackgroundColor: {
                  value: "Sky",
                },
                Icon: {
                  value: "OfficeWhite/32x32/document_text.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/text",
                  fields: {},
                },
                "Allowed Validations": [],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/Text",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: "B5C635B295AC460DB7A7644CD8A4B416",
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: [],
        },
        {
          id: "06bfc978-15c0-4cb0-9d7c-068f75061df3",
          name: "taskOptionsList",
          displayName: "Call Back Reasons",
          parentId: "f34fb0d2-3fc0-4a9a-8eb1-88717eb6ddce",
          fields: {
            Datasource: {
              id: "73285402-c0d0-42ec-828b-259642fa1510",
              fieldType: "Droptree",
              fieldName: "Datasource",
              url: "/content/forms/tasks",
              fields: {},
            },
            "Default Selection": {
              value: "",
            },
            "Display Field Name": {
              value: "__Display name",
            },
            "Is Dynamic": {
              value: true,
            },
            "Value Field Name": {
              value: "value",
            },
            "Css Class": {
              value: "block",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: "",
            },
            SpecificError: {
              value: "",
            },
            apiName: {
              id: "02e6f52d-421f-4179-b9f6-9cc0863cc4da",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/queryreason",
              fields: {
                value: {
                  value: "queryReason",
                },
                key: {
                  value: "queryReason",
                },
              },
            },
            "Label Css Class": {
              value: "",
            },
            Title: {
              value: "",
            },
            Required: {
              value: true,
            },
            Validations: [],
            "Is Tracking Enabled": {
              value: true,
            },
            "Allow Save": {
              value: true,
            },
            "Value Provider Parameters": {
              value: "",
            },
            "Value Provider": null,
            "Field Type": {
              id: "edbd38a8-1ae9-42ec-8ccd-f5b0e2998b4f",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/lists/radio-button-list",
              fields: {
                BackgroundColor: {
                  value: "Grass",
                },
                Icon: {
                  value: "OfficeWhite/32x32/radio_button_group.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "5b672865-55d2-413e-b699-fdfc7e732ccf",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/list",
                  fields: {},
                },
                "Allowed Validations": [],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomRadioButtonListViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/RadioButtonList",
                },
              },
            },
            Conditions: {
              value: JSON.stringify(taskOptionsConditions),
            },
            "Field Key": {
              value: "93BF0439473B468BBC93BC561328CA9D",
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: nameOptions,
        },
        getEnquiryFieldProps(
          block?.select_category,
          "Tooltip",
          "f34fb0d2-3fc0-4a9a-8eb1-88717eb6ddce"
        ),
        {
          id: "33530b5c-455f-4432-a7e6-f6255c35b210",
          name: "tasksNextButtonClick",
          displayName: "Next Page",
          parentId: "f34fb0d2-3fc0-4a9a-8eb1-88717eb6ddce",
          fields: {
            "Navigation Step": {
              value: "1",
            },
            emailTemplateLink: {
              value: "",
            },
            "Css Class": {
              value: "next-button",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: "",
            },
            SpecificError: {
              value: "",
            },
            apiName: {
              id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
              fields: {
                value: {
                  value: "",
                },
                key: {
                  value: "",
                },
              },
            },
            "Label Css Class": {
              value: "",
            },
            Title: {
              value: enf?.nextButtonText,
            },
            "Field Type": {
              id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
              fields: {
                BackgroundColor: {
                  value: "Eggplant",
                },
                Icon: {
                  value: "OfficeWhite/32x32/button.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/button",
                  fields: {},
                },
                "Allowed Validations": [],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/Button",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: "0B059E90D26E41B2B35BE476FB35A168",
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: [
            {
              id: "e9f1cad0-f49d-4ce0-a152-63ea6378ec62",
              name: "Redirect to Page",
              displayName: "Redirect to Page",
              parentId: "33530b5c-455f-4432-a7e6-f6255c35b210",
              fields: {
                Description: {
                  value: "subTaskPage",
                },
                Parameters: {
                  value: `{"referenceId":"{${nextStepInfos?.id?.toUpperCase()}}"}`,
                },
                "Submit Action": {
                  id: "3f3e2093-9dea-4199-86ca-44fc69ef624d",
                  fieldType: "Droptree",
                  fieldName: "Submit Action",
                  url: "/sitecore/system/settings/forms/submit-actions/redirect-to-page",
                  fields: {
                    Editor: null,
                    "Error Message": {
                      value: "Redirect to page failed!",
                    },
                    "Model Type": {
                      value:
                        "Sitecore.ExperienceForms.Processing.Actions.RedirectToPage",
                    },
                  },
                },
              },
              items: [],
            },
          ],
        },
      ];
      const selectCategoryBlock = {
        id: "f34fb0d2-3fc0-4a9a-8eb1-88717eb6ddce",
        name: "taskPage",
        displayName: "taskPage",
        parentId: "57ec00df-f75a-4645-a4f1-a830be98de45",
        fields: {
          gaLabel: {
            value: "",
          },
          backgroundColor: {
            id: "cbdc6265-d09f-4982-981a-bcc8f118f36f",
            fieldType: "Droplink",
            fieldName: "backgroundColor",
            url: "/sitecore/content/fwd/global/settings/list/colors/whiteorange",
            fields: {
              value: {
                value: "white-orange",
              },
              key: {
                value: "WhiteOrange",
              },
            },
          },
          "Css Class": {
            value: "interestedPage",
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: null,
          "Field Type": {
            id: "d819b43e-c136-4392-9393-8be7fce32f5e",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/page",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/window_split_ver.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "cfee7b51-8505-45ce-b843-9358f827df87",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/page",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomPageViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Page",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: "04B6B0BE40774539912EB6E4268008B9",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: blockItems,
      };
      if (subOptions?.length > 0) {
        blockItems.push({
          id: "422c12cf-3bfc-417a-88e2-0055490f88f7",
          name: "otherOptionNextClick",
          displayName: "otherOptionNextClick",
          parentId: "f34fb0d2-3fc0-4a9a-8eb1-88717eb6ddce",
          fields: {
            "Navigation Step": {
              value: "1",
            },
            emailTemplateLink: {
              value: "",
            },
            "Css Class": {
              value: "next-button",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: "",
            },
            SpecificError: {
              value: "",
            },
            apiName: {
              id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
              fields: {
                value: {
                  value: "",
                },
                key: {
                  value: "",
                },
              },
            },
            "Label Css Class": {
              value: "",
            },
            Title: {
              value: enf?.nextButtonText,
            },
            "Field Type": {
              id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
              fields: {
                BackgroundColor: {
                  value: "Eggplant",
                },
                Icon: {
                  value: "OfficeWhite/32x32/button.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/button",
                  fields: {},
                },
                "Allowed Validations": [],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/Button",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: "70CF13F315E14103A03450E07FEA39C1",
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: [
            {
              id: "dc0b68b8-097c-424a-bd32-61691ad23039",
              name: "Redirect to Page",
              displayName: "Redirect to Page",
              parentId: "422c12cf-3bfc-417a-88e2-0055490f88f7",
              fields: {
                Description: {
                  value: "Name",
                },
                Parameters: {
                  value:
                    '{"referenceId":"{FA9A0DDD-F620-436E-B1A4-976868988C50}"}',
                },
                "Submit Action": {
                  id: "3f3e2093-9dea-4199-86ca-44fc69ef624d",
                  fieldType: "Droptree",
                  fieldName: "Submit Action",
                  url: "/sitecore/system/settings/forms/submit-actions/redirect-to-page",
                  fields: {
                    Editor: null,
                    "Error Message": {
                      value: "Redirect to page failed!",
                    },
                    "Model Type": {
                      value:
                        "Sitecore.ExperienceForms.Processing.Actions.RedirectToPage",
                    },
                  },
                },
              },
              items: [],
            },
          ],
        });
      }
      enquirySteps.push(selectCategoryBlock);

      if (subOptions?.length > 0) {
        const subCategoryBlock = {
          id: "fa9a0ddd-f620-436e-b1a4-976868988c50",
          name: "subTaskPage",
          displayName: "subTaskPage",
          parentId: "57ec00df-f75a-4645-a4f1-a830be98de45",
          fields: {
            gaLabel: {
              value: "",
            },
            backgroundColor: {
              id: "cbdc6265-d09f-4982-981a-bcc8f118f36f",
              fieldType: "Droplink",
              fieldName: "backgroundColor",
              url: "/sitecore/content/fwd/global/settings/list/colors/whiteorange",
              fields: {
                value: {
                  value: "white-orange",
                },
                key: {
                  value: "WhiteOrange",
                },
              },
            },
            "Css Class": {
              value: "subTaskPage conditionPage",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: "",
            },
            SpecificError: {
              value: "",
            },
            apiName: null,
            "Field Type": {
              id: "d819b43e-c136-4392-9393-8be7fce32f5e",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/structure/page",
              fields: {
                BackgroundColor: {
                  value: "Eggplant",
                },
                Icon: {
                  value: "OfficeWhite/32x32/window_split_ver.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "cfee7b51-8505-45ce-b843-9358f827df87",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/page",
                  fields: {},
                },
                "Allowed Validations": [],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomPageViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/Page",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: "83F59508E70E42938C9C8BC6626295FC",
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: [
            {
              id: "a9f419b8-a9a6-491f-aa19-d3ab3ceba2b0",
              name: "Text",
              displayName: "Text",
              parentId: "fa9a0ddd-f620-436e-b1a4-976868988c50",
              fields: {
                "Html Tag": {
                  value: "p",
                },
                Text: {
                  value: block?.select_category?.prefix_options,
                },
                "Css Class": {
                  value: "block",
                },
                isHidden: {
                  value: false,
                },
                HelpText: {
                  value: "",
                },
                GeneralError: {
                  value: "",
                },
                SpecificError: {
                  value: "",
                },
                apiName: null,
                "Field Type": {
                  id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
                  fieldType: "Droptree",
                  fieldName: "Field Type",
                  url: "/sitecore/system/settings/forms/field-types/basic/text",
                  fields: {
                    BackgroundColor: {
                      value: "Sky",
                    },
                    Icon: {
                      value: "OfficeWhite/32x32/document_text.png",
                    },
                    IsDisabled: {
                      value: false,
                    },
                    SpritePosition: {
                      value: "",
                    },
                    "Field Template": {
                      id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                      fieldType: "Droptree",
                      fieldName: "Field Template",
                      url: "/sitecore/templates/system/forms/fields/text",
                      fields: {},
                    },
                    "Allowed Validations": [],
                    "Model Type": {
                      value:
                        "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
                    },
                    "Property Editor": null,
                    "View Path": {
                      value: "FieldTemplates/Text",
                    },
                  },
                },
                Conditions: {
                  value: '{"fieldConditions":[]}',
                },
                "Field Key": {
                  value: "9572D218437248E980F66B54BF9FAEA5",
                },
                "Collapsed by Default": {
                  value: false,
                },
              },
              items: [],
            },
            {
              id: "3b4ccb8f-165a-458f-9a17-006e228e2a78",
              name: "subTasksOptionsList",
              displayName: "subTasksOptionsList",
              parentId: "fa9a0ddd-f620-436e-b1a4-976868988c50",
              fields: {
                Datasource: {
                  id: "93426df0-656f-4a3a-9def-358693f14b0c",
                  fieldType: "Droptree",
                  fieldName: "Datasource",
                  url: "/content/forms/insurancetypes",
                  fields: {},
                },
                "Default Selection": {
                  value: "",
                },
                "Display Field Name": {
                  value: "__Display name",
                },
                "Is Dynamic": {
                  value: true,
                },
                "Value Field Name": {
                  value: "value",
                },
                "Css Class": {
                  value: "block",
                },
                isHidden: {
                  value: false,
                },
                HelpText: {
                  value: "",
                },
                GeneralError: {
                  value: "",
                },
                SpecificError: {
                  value: "",
                },
                apiName: {
                  id: "f2b244d0-fa3e-4b3e-ad38-ed69bbbbcd89",
                  fieldType: "Droplink",
                  fieldName: "apiName",
                  url: "/sitecore/content/fwd/global/settings/list/form-api-fields/expectedinsurancetype",
                  fields: {
                    value: {
                      value: "expectedInsuranceType",
                    },
                    key: {
                      value: "expectedInsuranceType",
                    },
                  },
                },
                "Label Css Class": {
                  value: "",
                },
                Title: {
                  value: "",
                },
                Required: {
                  value: true,
                },
                Validations: [],
                "Is Tracking Enabled": {
                  value: true,
                },
                "Allow Save": {
                  value: true,
                },
                "Value Provider Parameters": {
                  value: "",
                },
                "Value Provider": null,
                "Field Type": {
                  id: "edbd38a8-1ae9-42ec-8ccd-f5b0e2998b4f",
                  fieldType: "Droptree",
                  fieldName: "Field Type",
                  url: "/sitecore/system/settings/forms/field-types/lists/radio-button-list",
                  fields: {
                    BackgroundColor: {
                      value: "Grass",
                    },
                    Icon: {
                      value: "OfficeWhite/32x32/radio_button_group.png",
                    },
                    IsDisabled: {
                      value: false,
                    },
                    SpritePosition: {
                      value: "",
                    },
                    "Field Template": {
                      id: "5b672865-55d2-413e-b699-fdfc7e732ccf",
                      fieldType: "Droptree",
                      fieldName: "Field Template",
                      url: "/sitecore/templates/system/forms/fields/list",
                      fields: {},
                    },
                    "Allowed Validations": [],
                    "Model Type": {
                      value:
                        "FWD.Foundation.Forms.CustomRadioButtonListViewModel,FWD.Foundation.Forms",
                    },
                    "Property Editor": null,
                    "View Path": {
                      value: "FieldTemplates/RadioButtonList",
                    },
                  },
                },
                Conditions: {
                  value: '{"fieldConditions":[]}',
                },
                "Field Key": {
                  value: "D63E2549B3914223B72033F1F1A3EA30",
                },
                "Collapsed by Default": {
                  value: false,
                },
              },
              items: subOptions || [],
            },
            getEnquiryFieldProps(
              block?.select_category,
              "Tooltip",
              "a9a0ddd-f620-436e-b1a4-976868988c50"
            ),
            {
              id: "c1f16a5c-5083-4418-a39f-61f20efdb459",
              name: "subTaskButtonNextClick",
              displayName: "subTaskButtonNextClick",
              parentId: "fa9a0ddd-f620-436e-b1a4-976868988c50",
              fields: {
                "Navigation Step": {
                  value: "1",
                },
                emailTemplateLink: {
                  value: "",
                },
                "Css Class": {
                  value: "next-button",
                },
                isHidden: {
                  value: false,
                },
                HelpText: {
                  value: "",
                },
                GeneralError: {
                  value: "",
                },
                SpecificError: {
                  value: "",
                },
                apiName: {
                  id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
                  fieldType: "Droplink",
                  fieldName: "apiName",
                  url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
                  fields: {
                    value: {
                      value: "",
                    },
                    key: {
                      value: "",
                    },
                  },
                },
                "Label Css Class": {
                  value: "",
                },
                Title: {
                  value: enf?.nextButtonText,
                },
                "Field Type": {
                  id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
                  fieldType: "Droptree",
                  fieldName: "Field Type",
                  url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
                  fields: {
                    BackgroundColor: {
                      value: "Eggplant",
                    },
                    Icon: {
                      value: "OfficeWhite/32x32/button.png",
                    },
                    IsDisabled: {
                      value: false,
                    },
                    SpritePosition: {
                      value: "",
                    },
                    "Field Template": {
                      id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
                      fieldType: "Droptree",
                      fieldName: "Field Template",
                      url: "/sitecore/templates/system/forms/fields/button",
                      fields: {},
                    },
                    "Allowed Validations": [],
                    "Model Type": {
                      value:
                        "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
                    },
                    "Property Editor": null,
                    "View Path": {
                      value: "FieldTemplates/Button",
                    },
                  },
                },
                Conditions: {
                  value: '{"fieldConditions":[]}',
                },
                "Field Key": {
                  value: "63FEB8F928EA4CAFB690CB395F0667A5",
                },
                "Collapsed by Default": {
                  value: false,
                },
              },
              items: [
                {
                  id: "e81e0d31-715a-48a9-9ac9-4d64ac42f4d7",
                  name: "Redirect to Page",
                  displayName: "Redirect to Page",
                  parentId: "c1f16a5c-5083-4418-a39f-61f20efdb459",
                  fields: {
                    Description: {
                      value: "Name",
                    },
                    Parameters: {
                      value:
                        '{"referenceId":"{F4F6C886-DF71-4D8E-B2CD-29E0C8B3EE95}"}',
                    },
                    "Submit Action": {
                      id: "3f3e2093-9dea-4199-86ca-44fc69ef624d",
                      fieldType: "Droptree",
                      fieldName: "Submit Action",
                      url: "/sitecore/system/settings/forms/submit-actions/redirect-to-page",
                      fields: {
                        Editor: null,
                        "Error Message": {
                          value: "Redirect to page failed!",
                        },
                        "Model Type": {
                          value:
                            "Sitecore.ExperienceForms.Processing.Actions.RedirectToPage",
                        },
                      },
                    },
                  },
                  items: [],
                },
              ],
            },
          ],
        };
        enquirySteps.push(subCategoryBlock);
      }
    }

    if (block?.select_salutation) {
      const items = block?.select_salutation?.items?.map(
        ({ item_key, item_value }: any) => {
          return {
            id: `bddda039-7c80-466c-ab59-f5322e3c8191-${item_key}`,
            name: item_value,
            displayName: item_value,
            parentId: "7221f6f3-19f5-42fc-9419-2e060f0adae3",
            fields: {
              value: {
                value: item_value,
              },
              key: {
                value: item_key,
              },
            },
          };
        }
      );

      const selectSalutationBlock = {
        id: constants?.select_salutation?.id,
        name: "availabilityTimePage",
        displayName: "Select Salutation Page",
        parentId: "57ec00df-f75a-4645-a4f1-a830be98de45",
        fields: {
          gaLabel: {
            value: "",
          },
          backgroundColor: {
            id: "cbdc6265-d09f-4982-981a-bcc8f118f36f",
            fieldType: "Droplink",
            fieldName: "backgroundColor",
            url: "/sitecore/content/fwd/global/settings/list/colors/whiteorange",
            fields: {
              value: {
                value: "white-orange",
              },
              key: {
                value: "WhiteOrange",
              },
            },
          },
          "Css Class": {
            value: `availabilityTimePage ${
              !block?.select_salutation?.fill_icon ? "conditionPage" : ""
            }`,
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: null,
          "Field Type": {
            id: "d819b43e-c136-4392-9393-8be7fce32f5e",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/page",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/window_split_ver.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "cfee7b51-8505-45ce-b843-9358f827df87",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/page",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomPageViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Page",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: "05B6B0BE40774539912EB6E4268008B9",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [
          {
            id: "79c5fa97-edab-419f-86f4-7a1844af969a",
            name: "Text",
            displayName: "Text",
            parentId: "05843f30-abb3-427b-a9c4-ccec95f1e45d",
            fields: {
              "Html Tag": {
                value: "p",
              },
              Text: {
                value: block?.select_salutation?.prefix,
              },
              "Css Class": {
                value: "inline",
              },
              isHidden: {
                value: false,
              },
              HelpText: {
                value: "",
              },
              GeneralError: {
                value: "",
              },
              SpecificError: {
                value: "",
              },
              apiName: null,
              "Field Type": {
                id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
                fieldType: "Droptree",
                fieldName: "Field Type",
                url: "/sitecore/system/settings/forms/field-types/basic/text",
                fields: {
                  BackgroundColor: {
                    value: "Sky",
                  },
                  Icon: {
                    value: "OfficeWhite/32x32/document_text.png",
                  },
                  IsDisabled: {
                    value: false,
                  },
                  SpritePosition: {
                    value: "",
                  },
                  "Field Template": {
                    id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                    fieldType: "Droptree",
                    fieldName: "Field Template",
                    url: "/sitecore/templates/system/forms/fields/text",
                    fields: {},
                  },
                  "Allowed Validations": [],
                  "Model Type": {
                    value:
                      "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
                  },
                  "Property Editor": null,
                  "View Path": {
                    value: "FieldTemplates/Text",
                  },
                },
              },
              Conditions: {
                value: '{"fieldConditions":[]}',
              },
              "Field Key": {
                value: "3FE63FA45349456B88D9EFA8F776D9FE",
              },
              "Collapsed by Default": {
                value: false,
              },
            },
            items: [],
          },
          {
            id: "dbda29bc-721f-4ba3-b2ff-8a7533b86935",
            name: "provinceDropdown",
            displayName: "Salutation",
            parentId: "05843f30-abb3-427b-a9c4-ccec95f1e45d",
            fields: {
              "Show Empty Item": {
                value: true,
              },
              isAutoSuggest: {
                value: block?.select_salutation?.is_autocomplete,
              },
              "Empty Text": {
                value: block?.select_salutation?.no_options_text,
              },
              Datasource: {
                id: "58f59e4e-2486-42c4-8ed7-0663f6709b55",
                fieldType: "Droptree",
                fieldName: "Datasource",
                url: "/content/forms/contacttimelist",
                fields: {},
              },
              "Default Selection": {
                value: "",
              },
              "Display Field Name": {
                value: "value",
              },
              "Is Dynamic": {
                value: true,
              },
              "Value Field Name": {
                value: "key",
              },
              "Css Class": {
                value: "inline",
              },
              isHidden: {
                value: false,
              },
              HelpText: {
                value: "",
              },
              GeneralError: {
                value: "",
              },
              SpecificError: {
                value: "",
              },
              apiName: {
                id: "1e985703-c4e7-402d-a310-c17b2d90d381",
                fieldType: "Droplink",
                fieldName: "apiName",
                url: "/sitecore/content/fwd/global/settings/list/form-api-fields/salutation",
                fields: {
                  value: {
                    value: "salutation",
                  },
                  key: {
                    value: "salutation",
                  },
                },
              },
              "Label Css Class": {
                value: "",
              },
              Title: {
                value: block?.select_salutation?.placeholder,
              },
              Required: {
                value: true,
              },
              Validations: [],
              "Is Tracking Enabled": {
                value: true,
              },
              "Allow Save": {
                value: true,
              },
              "Value Provider Parameters": {
                value: "",
              },
              "Value Provider": null,
              "Collapsed by Default": {
                value: false,
              },
              "Field Type": {
                id: "e0cfadee-1ac0-471d-a820-2e70d1547b4b",
                fieldType: "Droptree",
                fieldName: "Field Type",
                url: "/sitecore/system/settings/forms/field-types/lists/dropdown-list",
                fields: {
                  BackgroundColor: {
                    value: "Grass",
                  },
                  Icon: {
                    value: "OfficeWhite/32x32/drop_down_list.png",
                  },
                  IsDisabled: {
                    value: false,
                  },
                  SpritePosition: {
                    value: "",
                  },
                  "Field Template": {
                    id: "9121d435-48b8-4649-9d13-03d680474fad",
                    fieldType: "Droptree",
                    fieldName: "Field Template",
                    url: "/sitecore/templates/system/forms/fields/dropdownlist",
                    fields: {},
                  },
                  "Allowed Validations": [],
                  "Model Type": {
                    value:
                      "FWD.Foundation.Forms.CustomDropDownListViewModel,FWD.Foundation.Forms",
                  },
                  "Property Editor": null,
                  "View Path": {
                    value: "FieldTemplates/DropdownList",
                  },
                },
              },
              Conditions: {
                value: '{"fieldConditions":[]}',
              },
              "Field Key": {
                value: "94BF0439473B468BBC93BC561328CA9D",
              },
            },
            items: items,
          },
          getEnquiryFieldProps(
            block?.select_salutation,
            "Tooltip",
            "05843f30-abb3-427b-a9c4-ccec95f1e45d"
          ),
          {
            id: "6bdeb7a7-f163-11ec-8ea0-0242ac120002",
            name: "provinceButtonNextClick",
            displayName: "provinceButtonNextClick",
            parentId: "05843f30-abb3-427b-a9c4-ccec95f1e45d",
            fields: {
              "Navigation Step": {
                value: "1",
              },
              emailTemplateLink: {
                value: "",
              },
              "Css Class": {
                value: "next-button",
              },
              isHidden: {
                value: false,
              },
              HelpText: {
                value: "",
              },
              GeneralError: {
                value: "",
              },
              SpecificError: {
                value: "",
              },
              apiName: {
                id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
                fieldType: "Droplink",
                fieldName: "apiName",
                url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
                fields: {
                  value: {
                    value: "",
                  },
                  key: {
                    value: "",
                  },
                },
              },
              "Label Css Class": {
                value: "",
              },
              Title: {
                value: enf?.nextButtonText,
              },
              "Field Type": {
                id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
                fieldType: "Droptree",
                fieldName: "Field Type",
                url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
                fields: {
                  BackgroundColor: {
                    value: "Eggplant",
                  },
                  Icon: {
                    value: "OfficeWhite/32x32/button.png",
                  },
                  IsDisabled: {
                    value: false,
                  },
                  SpritePosition: {
                    value: "",
                  },
                  "Field Template": {
                    id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
                    fieldType: "Droptree",
                    fieldName: "Field Template",
                    url: "/sitecore/templates/system/forms/fields/button",
                    fields: {},
                  },
                  "Allowed Validations": [],
                  "Model Type": {
                    value:
                      "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
                  },
                  "Property Editor": null,
                  "View Path": {
                    value: "FieldTemplates/Button",
                  },
                },
              },
              Conditions: {
                value: '{"fieldConditions":[]}',
              },
              "Field Key": {
                value: "B544A093CB984BB8AF1E0C9494DE48EF",
              },
              "Collapsed by Default": {
                value: false,
              },
            },
            items: [
              {
                id: "a1ccd500-f163-11ec-8ea0-0242ac120002",
                name: "Redirect to Page",
                displayName: "Redirect to Page",
                parentId: "6bdeb7a6-f163-11ec-8ea0-0242ac120002",
                fields: {
                  Description: {
                    value: "Phone Details",
                  },
                  Parameters: {
                    value: `{"referenceId":"{${nextStepInfos?.id?.toUpperCase()}}"}`,
                  },
                  "Submit Action": {
                    id: "3f3e2093-9dea-4199-86ca-44fc69ef624d",
                    fieldType: "Droptree",
                    fieldName: "Submit Action",
                    url: "/sitecore/system/settings/forms/submit-actions/redirect-to-page",
                    fields: {
                      Editor: null,
                      "Error Message": {
                        value: "Redirect to page failed!",
                      },
                      "Model Type": {
                        value:
                          "Sitecore.ExperienceForms.Processing.Actions.RedirectToPage",
                      },
                    },
                  },
                },
                items: [],
              },
            ],
          },
        ],
      };
      enquirySteps.push(selectSalutationBlock);
    }

    if (block?.full_name_block) {
      let conditions: any = { fieldConditions: [] };
      let titleFullNameBlock = {};
      const titleFullNameDropdown = modularBlocks.find((b: any) =>{
        return b?.dropdown && b?.dropdown?.field_name === "titleFullName";
      });
      if (!!titleFullNameDropdown) {
        const titleFullNames = titleFullNameDropdown.dropdown?.items.map(
          ({ item_key }: any) => {
            return {
              id: generateUniqSerial(),
              name: item_key,
              displayName: item_key,
              parentId: "6947b485-f82f-456e-af03-d8779ef44eba",
              fields: {
                value: {
                  value: item_key,
                },
              },
              items: [],
            };
          }
        );
        titleFullNameBlock = {
          id: "a8a831c4-ce45-4545-adb2-ed75a15239c8",
          name: "titleFullNameDropdown",
          displayName: "Title Full Name",
          parentId: "f4f6c886-df71-4d8e-b2cd-29e0c8b3ee95",
          fields: {
            "Show Empty Item": {
              value: true,
            },
            isAutoSuggest: {
              value: false,
            },
            Datasource: {
              id: "18de3e80-78b9-48b3-a1f1-aedf03565306",
              fieldType: "Droptree",
              fieldName: "Datasource",
              url: "/content/forms/titlefullnamelist-general-enquiry",
              fields: {},
            },
            "Default Selection": {
              value: "",
            },
            "Display Field Name": {
              value: "value",
            },
            "Is Dynamic": {
              value: true,
            },
            "Value Field Name": {
              value: "key",
            },
            "Css Class": {
              value: "block",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: "",
            },
            SpecificError: {
              value: "",
            },
            apiName: {
              id: "58b136a7-764d-48c7-a721-a53d8fa01074",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/titleFullName",
              fields: {
                value: {
                  value: "titleFullName",
                },
                key: {
                  value: "titleFullName",
                },
              },
            },
            "Label Css Class": {
              value: "",
            },
            Title: {
              value: titleFullNameDropdown.dropdown?.label,
            },
            Required: {
              value: true,
            },
            Validations: [],
            "Is Tracking Enabled": {
              value: true,
            },
            "Allow Save": {
              value: true,
            },
            "Value Provider Parameters": {
              value: "",
            },
            "Value Provider": null,
            "Collapsed by Default": {
              value: false,
            },
            "Field Type": {
              id: "e0cfadee-1ac0-471d-a820-2e70d1547b4b",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/lists/dropdown-list",
              fields: {
                BackgroundColor: {
                  value: "Grass",
                },
                Icon: {
                  value: "OfficeWhite/32x32/drop_down_list.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "9121d435-48b8-4649-9d13-03d680474fad",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/dropdownlist",
                  fields: {},
                },
                "Allowed Validations": [],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomDropDownListViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/DropdownList",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: "F388ECF875174844B62D66E2E2642B15B",
            },
          },
          items: titleFullNames || [],
        };
      }

      let blockItems = [
        {
          id: "7093b79f-1254-4730-b2b7-fc2923277340",
          name: "Text",
          displayName: "Text",
          parentId: "f4f6c886-df71-4d8e-b2cd-29e0c8b3ee95",
          fields: {
            "Html Tag": {
              value: "h3",
            },
            Text: {
              value: block?.full_name_block?.prefix,
            },
            "Css Class": {
              value: "block",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: "",
            },
            SpecificError: {
              value: "",
            },
            apiName: null,
            "Field Type": {
              id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/basic/text",
              fields: {
                BackgroundColor: {
                  value: "Sky",
                },
                Icon: {
                  value: "OfficeWhite/32x32/document_text.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/text",
                  fields: {},
                },
                "Allowed Validations": [],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/Text",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: "04919D66DA774E6D896DB65E36D0E8D8",
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: [],
        },
        titleFullNameDropdown ? titleFullNameBlock : null,
        {
          id: "695cad3f-8c05-42ce-8995-1cc735169dc8",
          name: "first-name",
          displayName: "First Name",
          parentId: "f4f6c886-df71-4d8e-b2cd-29e0c8b3ee95",
          fields: {
            "Default Value": {
              value: "",
            },
            "Max Length": {
              value: "0",
            },
            "Min Length": {
              value: "0",
            },
            "Placeholder Text": {
              value: block?.full_name_block?.first_name?.placeholder,
            },
            dialInCodeLength: null,
            "Css Class": {
              value: "inline",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: block?.full_name_block?.first_name?.validation_message,
            },
            SpecificError: {
              value: "",
            },
            apiName: {
              id: "f20d5651-f7b9-49a8-9e57-0a3c7e0b5cb8",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/firstname",
              fields: {
                value: {
                  value: "firstName",
                },
                key: {
                  value: "firstName",
                },
              },
            },
            "Label Css Class": {
              value: "",
            },
            Title: {
              value: "",
            },
            Required: {
              value: true,
            },
            Validations: [],
            "Is Tracking Enabled": {
              value: true,
            },
            "Allow Save": {
              value: true,
            },
            "Value Provider Parameters": {
              value: "",
            },
            "Value Provider": null,
            "Field Type": {
              id: "4ee89ea7-cefe-4c8e-8532-467ef64591fc",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/basic/single-line-text",
              fields: {
                BackgroundColor: {
                  value: "Sky",
                },
                Icon: {
                  value: "OfficeWhite/32x32/text_field.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "0908030b-4564-42ea-a6fa-c7a5a2d921a8",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/input",
                  fields: {},
                },
                "Allowed Validations": [
                  {
                    id: "83e96d09-11c8-4132-8a8a-76d8db8208d6",
                    fields: {
                      Message: {
                        value:
                          "{0} must have at least {1} and no more than {2} characters.",
                      },
                      Parameters: {
                        value: "",
                      },
                      Type: {
                        value:
                          "Sitecore.ExperienceForms.Mvc.Models.Validation.StringLengthValidation,Sitecore.ExperienceForms.Mvc",
                      },
                    },
                  },
                ],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomSingleLineViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/SingleLineText",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: "57CE7E7216534F4F9033E231AD69AFE0",
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: [],
        },
        {
          id: "de2c0f67-47ac-4bba-b33b-cffca6bee6f7",
          name: "last-name",
          displayName: "Last Name",
          parentId: "f4f6c886-df71-4d8e-b2cd-29e0c8b3ee95",
          fields: {
            "Default Value": {
              value: "",
            },
            "Max Length": {
              value: "0",
            },
            "Min Length": {
              value: "0",
            },
            "Placeholder Text": {
              value: block?.full_name_block?.last_name?.placeholder,
            },
            dialInCodeLength: null,
            "Css Class": {
              value: "inline",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: block?.full_name_block?.last_name?.validation_message,
            },
            SpecificError: {
              value: "",
            },
            apiName: {
              id: "ceb2b470-c127-4eda-b777-0d5943f0c90a",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/lastname",
              fields: {
                value: {
                  value: "lastName",
                },
                key: {
                  value: "lastName",
                },
              },
            },
            "Label Css Class": {
              value: "",
            },
            Title: {
              value: "",
            },
            Required: {
              value: true,
            },
            Validations: [],
            "Is Tracking Enabled": {
              value: true,
            },
            "Allow Save": {
              value: true,
            },
            "Value Provider Parameters": {
              value: "",
            },
            "Value Provider": null,
            "Field Type": {
              id: "4ee89ea7-cefe-4c8e-8532-467ef64591fc",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/basic/single-line-text",
              fields: {
                BackgroundColor: {
                  value: "Sky",
                },
                Icon: {
                  value: "OfficeWhite/32x32/text_field.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "0908030b-4564-42ea-a6fa-c7a5a2d921a8",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/input",
                  fields: {},
                },
                "Allowed Validations": [
                  {
                    id: "83e96d09-11c8-4132-8a8a-76d8db8208d6",
                    fields: {
                      Message: {
                        value:
                          "{0} must have at least {1} and no more than {2} characters.",
                      },
                      Parameters: {
                        value: "",
                      },
                      Type: {
                        value:
                          "Sitecore.ExperienceForms.Mvc.Models.Validation.StringLengthValidation,Sitecore.ExperienceForms.Mvc",
                      },
                    },
                  },
                ],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomSingleLineViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/SingleLineText",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: "EF0F512D39CE4F759715B4680BF02EF5",
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: [],
        },
        getEnquiryFieldProps(
          block?.full_name_block,
          "Tooltip",
          "f4f6c886-df71-4d8e-b2cd-29e0c8b3ee95"
        ),
      ];
      blockItems = blockItems.filter(b => !!b);

      const defaultNextClick: any = {
        id: "c1f16a5c-5083-4418-a39f-61f20efdb459",
        name: "fullNameDefaultClick",
        displayName: "fullNameDefaultClick",
        parentId: "fa9a0ddd-f620-436e-b1a4-976868988c50",
        fields: {
          "Navigation Step": {
            value: "1",
          },
          emailTemplateLink: {
            value: "",
          },
          "Css Class": {
            value: "next-button",
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: {
            id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
            fieldType: "Droplink",
            fieldName: "apiName",
            url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
            fields: {
              value: {
                value: "",
              },
              key: {
                value: "",
              },
            },
          },
          "Label Css Class": {
            value: "",
          },
          Title: {
            value: enf?.nextButtonText,
          },
          "Field Type": {
            id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/button.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/button",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Button",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: "84FEB8F928EA4CAFB690CB395F0667B6",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [
          {
            id: "a1ccd500-f163-11ec-8ea0-0242ac121102",
            name: "Redirect to Page",
            displayName: "Redirect to Page",
            parentId: "6bdeb7a6-f163-11ec-8ea0-0242ac120002",
            fields: {
              Description: {
                value: "Phone Details",
              },
              Parameters: {
                value: `{"referenceId":"{${nextStepInfos?.id?.toUpperCase()}}"}`,
              },
              "Submit Action": {
                id: "3f3e2093-9dea-4199-86ca-44fc69ef624d",
                fieldType: "Droptree",
                fieldName: "Submit Action",
                url: "/sitecore/system/settings/forms/submit-actions/redirect-to-page",
                fields: {
                  Editor: null,
                  "Error Message": {
                    value: "Redirect to page failed!",
                  },
                  "Model Type": {
                    value:
                      "Sitecore.ExperienceForms.Processing.Actions.RedirectToPage",
                  },
                },
              },
            },
            items: [],
          },
        ],
      };
      const provinceNextClick: any = {
        id: "c1f16a5c-5083-4418-a39f-61f20efdb460",
        name: "fullNameProvinceClick",
        displayName: "fullNameProvinceClick",
        parentId: "fa9a0ddd-f620-436e-b1a4-976868988c50",
        fields: {
          "Navigation Step": {
            value: "1",
          },
          emailTemplateLink: {
            value: "",
          },
          "Css Class": {
            value: "next-button",
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: {
            id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
            fieldType: "Droplink",
            fieldName: "apiName",
            url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
            fields: {
              value: {
                value: "",
              },
              key: {
                value: "",
              },
            },
          },
          "Label Css Class": {
            value: "",
          },
          Title: {
            value: enf?.nextButtonText,
          },
          "Field Type": {
            id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/button.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/button",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Button",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: "84FEB8F928EA4CAFB690CB395F0669D1",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [
          {
            id: "a1ccd500-f163-11ec-8ea0-0242ac121103",
            name: "Redirect to Page",
            displayName: "Redirect to Page",
            parentId: "6bdeb7a6-f163-11ec-8ea0-0242ac120002",
            fields: {
              Description: {
                value: "Province Next Click",
              },
              Parameters: {
                value: `{"referenceId":"{05843F30-ABB3-427B-A9C4-CCEC95F1E45D}"}`,
              },
              "Submit Action": {
                id: "3f3e2093-9dea-4199-86ca-44fc69ef624d",
                fieldType: "Droptree",
                fieldName: "Submit Action",
                url: "/sitecore/system/settings/forms/submit-actions/redirect-to-page",
                fields: {
                  Editor: null,
                  "Error Message": {
                    value: "Redirect to page failed!",
                  },
                  "Model Type": {
                    value:
                      "Sitecore.ExperienceForms.Processing.Actions.RedirectToPage",
                  },
                },
              },
            },
            items: [],
          },
        ],
      };
      const emailNextClick: any = {
        id: "2456029e-8769-40d9-a718-1f5fc136e95b",
        name: "customEmailNextClickScreen",
        displayName: "customEmailNextClickScreen",
        parentId: "f4f6c886-df71-4d8e-b2cd-29e0c8b3ee95",
        fields: {
          "Navigation Step": {
            value: "1",
          },
          emailTemplateLink: {
            value: "",
          },
          "Css Class": {
            value: "next-button",
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: {
            id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
            fieldType: "Droplink",
            fieldName: "apiName",
            url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
            fields: {
              value: {
                value: "",
              },
              key: {
                value: "",
              },
            },
          },
          "Label Css Class": {
            value: "",
          },
          Title: {
            value: enf?.nextButtonText,
          },
          "Field Type": {
            id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/button.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/button",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Button",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: "D9B947C796E4429A9649870470347AE9",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [
          {
            id: "e533e5ae-b98a-4e7f-8305-88963c6d5eca",
            name: "Redirect to Page",
            displayName: "Redirect to Page",
            parentId: "2456029e-8769-40d9-a718-1f5fc136e95b",
            fields: {
              Description: {
                value: "Email",
              },
              Parameters: {
                value: `{"referenceId":"{${constants.email_block.id.toUpperCase()}}"}`,
              },
              "Submit Action": {
                id: "3f3e2093-9dea-4199-86ca-44fc69ef624d",
                fieldType: "Droptree",
                fieldName: "Submit Action",
                url: "/sitecore/system/settings/forms/submit-actions/redirect-to-page",
                fields: {
                  Editor: null,
                  "Error Message": {
                    value: "Redirect to page failed!",
                  },
                  "Model Type": {
                    value:
                      "Sitecore.ExperienceForms.Processing.Actions.RedirectToPage",
                  },
                },
              },
            },
            items: [],
          },
        ],
      };
      blockItems.push(defaultNextClick);
      if (
        ["th"].indexOf(countryCode) >= 0 &&
        enquiryRef?.category === "product-plan"
      ) {
        blockItems.push(provinceNextClick);
      }

      if (
        ["th", "hk", "mo"].indexOf(countryCode) >= 0 &&
        categoryNeqConditions?.length > 0
      ) {
        blockItems.push(emailNextClick);
        conditions = {
          fieldConditions: [
            {
              matchTypeId: "{365C94DA-C1CD-4783-A91D-0D17A16C7117}",
              conditions: categoryNeqConditions, // If category not equal "Book time with an agent"
              actions: [
                {
                  fieldId: "84FEB8F928EA4CAFB690CB395F0667B6",
                  actionTypeId: "{7F58C8DD-D7C0-4FB7-BB44-8EC6B5E1C3D9}",
                  value: null,
                },
                {
                  fieldId: "D9B947C796E4429A9649870470347AE9",
                  actionTypeId: "{AAE07A52-46A4-49EF-98B0-C2595BAC2382}",
                  value: null,
                },
              ],
            },
          ],
        };
      }
      const fullNameBlock = {
        id: "f4f6c886-df71-4d8e-b2cd-29e0c8b3ee95",
        name: "namePage",
        displayName: "Name",
        parentId: "57ec00df-f75a-4645-a4f1-a830be98de45",
        fields: {
          gaLabel: {
            value: "",
          },
          backgroundColor: {
            id: "cbdc6265-d09f-4982-981a-bcc8f118f36f",
            fieldType: "Droplink",
            fieldName: "backgroundColor",
            url: "/sitecore/content/fwd/global/settings/list/colors/whiteorange",
            fields: {
              value: {
                value: "white-orange",
              },
              key: {
                value: "WhiteOrange",
              },
            },
          },
          "Css Class": {
            value: `namePage ${
              !block?.full_name_block?.fill_icon ? "conditionPage" : ""
            }`,
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: null,
          "Field Type": {
            id: "d819b43e-c136-4392-9393-8be7fce32f5e",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/page",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/window_split_ver.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "cfee7b51-8505-45ce-b843-9358f827df87",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/page",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomPageViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Page",
              },
            },
          },
          Conditions: {
            value: JSON.stringify(conditions),
          },
          "Field Key": {
            value: "F2B07BE6685F41968F59E8E76ABBC8AD",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: blockItems,
      };
      enquirySteps.push(fullNameBlock);
    }

    if (block?.select_province_block) {
      let provinces =
        block?.select_province_block?.provinces?.map(
          ({ title, filter_key }: any) => {
            return {
              id: generateUniqSerial(),
              name: title,
              displayName: title,
              parentId: constants?.select_province_block?.id,
              fields: {
                value: {
                  value: title,
                },
                key: {
                  value: filter_key,
                },
              },
            };
          }
        ) || [];
      if (
        !!block?.select_province_block?.additional_provinces &&
        block?.select_province_block?.additional_provinces.length > 0
      ) {
        block?.select_province_block?.additional_provinces.forEach(
          ({ name, filter_key }: any) => {
            provinces.push({
              id: generateUniqSerial(),
              name: name || filter_key,
              displayName: name || filter_key,
              parentId: constants?.select_province_block?.id,
              fields: {
                value: {
                  value: name || filter_key,
                },
                key: {
                  value: filter_key,
                },
              },
            });
          }
        );
      }

      const selectProvinceBlock = {
        id: constants?.select_province_block?.id,
        name: "provincePage",
        displayName: "Province Details",
        parentId: "57ec00df-f75a-4645-a4f1-a830be98de45",
        fields: {
          gaLabel: {
            value: "",
          },
          backgroundColor: {
            id: "cbdc6265-d09f-4982-981a-bcc8f118f36f",
            fieldType: "Droplink",
            fieldName: "backgroundColor",
            url: "/sitecore/content/fwd/global/settings/list/colors/whiteorange",
            fields: {
              value: {
                value: "white-orange",
              },
              key: {
                value: "WhiteOrange",
              },
            },
          },
          "Css Class": {
            value: `provincePage ${
              !block?.select_province_block?.fill_icon ? "conditionPage" : ""
            }`,
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: null,
          "Field Type": {
            id: "d819b43e-c136-4392-9393-8be7fce32f5e",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/page",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/window_split_ver.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "cfee7b51-8505-45ce-b843-9358f827df87",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/page",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomPageViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Page",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: "F46A6A39786642D2A743E145F488453A",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [
          {
            id: "79c5fa97-edab-419f-86f4-7a1844af969a",
            name: "Text",
            displayName: "Text",
            parentId: "05843f30-abb3-427b-a9c4-ccec95f1e45d",
            fields: {
              "Html Tag": {
                value: "p",
              },
              Text: {
                value: block?.select_province_block?.prefix,
              },
              "Css Class": {
                value: "inline",
              },
              isHidden: {
                value: false,
              },
              HelpText: {
                value: "",
              },
              GeneralError: {
                value: "",
              },
              SpecificError: {
                value: "",
              },
              apiName: null,
              "Field Type": {
                id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
                fieldType: "Droptree",
                fieldName: "Field Type",
                url: "/sitecore/system/settings/forms/field-types/basic/text",
                fields: {
                  BackgroundColor: {
                    value: "Sky",
                  },
                  Icon: {
                    value: "OfficeWhite/32x32/document_text.png",
                  },
                  IsDisabled: {
                    value: false,
                  },
                  SpritePosition: {
                    value: "",
                  },
                  "Field Template": {
                    id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                    fieldType: "Droptree",
                    fieldName: "Field Template",
                    url: "/sitecore/templates/system/forms/fields/text",
                    fields: {},
                  },
                  "Allowed Validations": [],
                  "Model Type": {
                    value:
                      "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
                  },
                  "Property Editor": null,
                  "View Path": {
                    value: "FieldTemplates/Text",
                  },
                },
              },
              Conditions: {
                value: '{"fieldConditions":[]}',
              },
              "Field Key": {
                value: "3FE63FA45349456B87D9EFA8F776D9FE",
              },
              "Collapsed by Default": {
                value: false,
              },
            },
            items: [],
          },
          {
            id: "dbda29bc-721f-4ba3-b2ff-8a7533b86935",
            name: "provinceDropdown",
            displayName: "Province",
            parentId: "05843f30-abb3-427b-a9c4-ccec95f1e45d",
            fields: {
              "Show Empty Item": {
                value: true,
              },
              isAutoSuggest: {
                value: block?.select_province_block?.is_autocomplete,
              },
              "Empty Text": {
                value: block?.select_province_block?.no_options_text,
              },
              Datasource: {
                id: "58f59e4e-2486-42c4-8ed7-0663f6709b55",
                fieldType: "Droptree",
                fieldName: "Datasource",
                url: "/content/forms/contacttimelist",
                fields: {},
              },
              "Default Selection": {
                value: "",
              },
              "Display Field Name": {
                value: "value",
              },
              "Is Dynamic": {
                value: true,
              },
              "Value Field Name": {
                value: "key",
              },
              "Css Class": {
                value: "inline",
              },
              isHidden: {
                value: false,
              },
              HelpText: {
                value: "",
              },
              GeneralError: {
                value: "",
              },
              SpecificError: {
                value: "",
              },
              apiName: {
                id: "1e985703-c4e7-402d-a310-c17b2d90d381",
                fieldType: "Droplink",
                fieldName: "apiName",
                url: "/sitecore/content/fwd/global/settings/list/form-api-fields/homeProvince",
                fields: {
                  value: {
                    value: "homeProvince",
                  },
                  key: {
                    value: "homeProvince",
                  },
                },
              },
              "Label Css Class": {
                value: "",
              },
              Title: {
                value: block?.select_province_block?.placeholder,
              },
              Required: {
                value: true,
              },
              Validations: [],
              "Is Tracking Enabled": {
                value: true,
              },
              "Allow Save": {
                value: true,
              },
              "Value Provider Parameters": {
                value: "",
              },
              "Value Provider": null,
              "Collapsed by Default": {
                value: false,
              },
              "Field Type": {
                id: "e0cfadee-1ac0-471d-a820-2e70d1547b4b",
                fieldType: "Droptree",
                fieldName: "Field Type",
                url: "/sitecore/system/settings/forms/field-types/lists/dropdown-list",
                fields: {
                  BackgroundColor: {
                    value: "Grass",
                  },
                  Icon: {
                    value: "OfficeWhite/32x32/drop_down_list.png",
                  },
                  IsDisabled: {
                    value: false,
                  },
                  SpritePosition: {
                    value: "",
                  },
                  "Field Template": {
                    id: "9121d435-48b8-4649-9d13-03d680474fad",
                    fieldType: "Droptree",
                    fieldName: "Field Template",
                    url: "/sitecore/templates/system/forms/fields/dropdownlist",
                    fields: {},
                  },
                  "Allowed Validations": [],
                  "Model Type": {
                    value:
                      "FWD.Foundation.Forms.CustomDropDownListViewModel,FWD.Foundation.Forms",
                  },
                  "Property Editor": null,
                  "View Path": {
                    value: "FieldTemplates/DropdownList",
                  },
                },
              },
              Conditions: {
                value: '{"fieldConditions":[]}',
              },
              "Field Key": {
                value: "3769A8E2F8484912B8012DBDCE9A47CA",
              },
            },
            items: provinces,
          },
          getEnquiryFieldProps(
            block?.select_province_block,
            "Tooltip",
            "05843f30-abb3-427b-a9c4-ccec95f1e45d"
          ),
          {
            id: "6bdeb7a7-f163-11ec-8ea0-0242ac120002",
            name: "provinceButtonNextClick",
            displayName: "provinceButtonNextClick",
            parentId: "05843f30-abb3-427b-a9c4-ccec95f1e45d",
            fields: {
              "Navigation Step": {
                value: "1",
              },
              emailTemplateLink: {
                value: "",
              },
              "Css Class": {
                value: "next-button",
              },
              isHidden: {
                value: false,
              },
              HelpText: {
                value: "",
              },
              GeneralError: {
                value: "",
              },
              SpecificError: {
                value: "",
              },
              apiName: {
                id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
                fieldType: "Droplink",
                fieldName: "apiName",
                url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
                fields: {
                  value: {
                    value: "",
                  },
                  key: {
                    value: "",
                  },
                },
              },
              "Label Css Class": {
                value: "",
              },
              Title: {
                value: enf?.nextButtonText,
              },
              "Field Type": {
                id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
                fieldType: "Droptree",
                fieldName: "Field Type",
                url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
                fields: {
                  BackgroundColor: {
                    value: "Eggplant",
                  },
                  Icon: {
                    value: "OfficeWhite/32x32/button.png",
                  },
                  IsDisabled: {
                    value: false,
                  },
                  SpritePosition: {
                    value: "",
                  },
                  "Field Template": {
                    id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
                    fieldType: "Droptree",
                    fieldName: "Field Template",
                    url: "/sitecore/templates/system/forms/fields/button",
                    fields: {},
                  },
                  "Allowed Validations": [],
                  "Model Type": {
                    value:
                      "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
                  },
                  "Property Editor": null,
                  "View Path": {
                    value: "FieldTemplates/Button",
                  },
                },
              },
              Conditions: {
                value: '{"fieldConditions":[]}',
              },
              "Field Key": {
                value: "B544A093CB984BB8AF1E0C9494DE48EF",
              },
              "Collapsed by Default": {
                value: false,
              },
            },
            items: [
              {
                id: "a1ccd500-f163-11ec-8ea0-0242ac120002",
                name: "Redirect to Page",
                displayName: "Redirect to Page",
                parentId: "6bdeb7a6-f163-11ec-8ea0-0242ac120002",
                fields: {
                  Description: {
                    value: "Phone Details",
                  },
                  Parameters: {
                    value: `{"referenceId":"{${nextStepInfos?.id?.toUpperCase()}}"}`,
                  },
                  "Submit Action": {
                    id: "3f3e2093-9dea-4199-86ca-44fc69ef624d",
                    fieldType: "Droptree",
                    fieldName: "Submit Action",
                    url: "/sitecore/system/settings/forms/submit-actions/redirect-to-page",
                    fields: {
                      Editor: null,
                      "Error Message": {
                        value: "Redirect to page failed!",
                      },
                      "Model Type": {
                        value:
                          "Sitecore.ExperienceForms.Processing.Actions.RedirectToPage",
                      },
                    },
                  },
                },
                items: [],
              },
            ],
          },
        ],
      };
      enquirySteps.push(selectProvinceBlock);
    }

    if (block?.email_block) {
      let blockItems: any = [
        {
          id: "9e3a4783-02c7-4cc9-bf07-243adb6bd633",
          name: "Text",
          displayName: "Text",
          parentId: constants.email_block.id,
          fields: {
            "Html Tag": {
              value: "h5",
            },
            Text: {
              value: block?.email_block?.prefix,
            },
            "Css Class": {
              value: "inline",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: "",
            },
            SpecificError: {
              value: "",
            },
            apiName: {
              id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
              fields: {
                value: {
                  value: "",
                },
                key: {
                  value: "",
                },
              },
            },
            "Field Type": {
              id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/basic/text",
              fields: {
                BackgroundColor: {
                  value: "Sky",
                },
                Icon: {
                  value: "OfficeWhite/32x32/document_text.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/text",
                  fields: {},
                },
                "Allowed Validations": [],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/Text",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: "97A7E3EA18774D5EBD50D8D73064CD99",
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: [],
        },
        {
          id: "0b325fca-50ea-4873-a036-cbf0455eb045",
          name: "Email",
          displayName: "Email",
          parentId: constants.email_block.id,
          fields: {
            "Default Value": {
              value: "",
            },
            "Max Length": {
              value: "255",
            },
            "Min Length": {
              value: "0",
            },
            "Placeholder Text": {
              value: block?.email_block?.placeholder,
            },
            dialInCodeLength: {
              id: "924aee12-9c96-42e0-8548-8378e251a8c7",
              fieldType: "Droplink",
              fieldName: "dialInCodeLength",
              url: "/sitecore/content/fwd/global/settings/list/formdiallingcodelength/zero",
              fields: {
                value: {
                  value: "0",
                },
                key: {
                  value: "zero",
                },
              },
            },
            "Css Class": {
              value: "inline",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: block?.email_block?.validation_message,
            },
            SpecificError: {
              value: "",
            },
            serverValidationErrorText: {
              value: "Email is invalid",
            },
            serverValidationLoadingText: {
              value: "",
            },
            enableServerValidation: {
              value: !!(enquiryRef?.server_side_validation),
            },
            serverValidationAPI:
              !!(enquiryRef?.server_side_validation)
                ? {
                    fields: {
                      value: {
                        value: `${publicConfigurations?.publicApiUrl}/api/enquiry/validate`,
                      },
                      key: {
                        value: "",
                      },
                    },
                  }
                : null,
            apiName: {
              id: "fa36b97a-b013-41b6-b33a-b8c3e2843137",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/email",
              fields: {
                value: {
                  value: "email",
                },
                key: {
                  value: "email",
                },
              },
            },
            "Label Css Class": {
              value: "",
            },
            Title: {
              value: "",
            },
            Required: {
              value: !!block?.email_block?.validation_message,
            },
            Validations: [
              {
                id: "9bae3e9a-d89f-4f93-9577-68b5e9d44a38",
                fields: {
                  Message: {
                    value: block?.email_block?.validation_rules?.message,
                  },
                  Parameters: {
                    value: block?.email_block?.validation_rules?.regex,
                  },
                  Type: {
                    value:
                      "FWD.Foundation.Forms.Validators.CustomRegularExpressionValidation,FWD.Foundation.Forms",
                  },
                },
              },
            ],
            "Is Tracking Enabled": {
              value: true,
            },
            "Allow Save": {
              value: true,
            },
            "Value Provider Parameters": {
              value: "",
            },
            "Value Provider": null,
            "Field Type": {
              id: "04c39cac-8976-4910-be0d-879ed3368429",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/basic/email",
              fields: {
                BackgroundColor: {
                  value: "Sky",
                },
                Icon: {
                  value: "OfficeWhite/32x32/mail.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "886adec1-abf8-40e1-9926-d9189c4e8e1b",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/email",
                  fields: {},
                },
                "Allowed Validations": [
                  {
                    id: "9bae3e9a-d89f-4f93-9577-68b5e9d44a38",
                    fields: {
                      Message: {
                        value: block?.email_block?.validation_rules?.message,
                      },
                      Parameters: {
                        value: block?.email_block?.validation_rules?.regex,
                      },
                      Type: {
                        value:
                          "FWD.Foundation.Forms.Validators.CustomRegularExpressionValidation,FWD.Foundation.Forms",
                      },
                    },
                  },
                ],
                "Model Type": {
                  value:
                    " FWD.Foundation.Forms.CustomSingleLineViewModel, FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/Email",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: "CF485B1F33E5435281D931A3DD5B328B",
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: [],
        },
        getEnquiryFieldProps(
          block?.email_block,
          "Tooltip",
          constants.email_block.id
        ),
      ];
      if (block?.email_block && ["kh"].indexOf(countryCode) >= 0) {
        const privacyPolicy: any = {
          id: "8e8add7a-6528-42e9-9deb-9e6c2f2fbc2g",
          name: "PrivacyPolicySection",
          displayName: "PrivacyPolicySection",
          parentId: "57ec00df-f75a-4645-a4f1-a830be98de45",
          fields: {
            backgroundColor: {
              id: "cf8cf9a4-0f70-4db2-b298-90e820aca94b",
              fieldType: "Droplink",
              fieldName: "backgroundColor",
              url: `/sitecore/content/fwd/global/settings/list/colors/${consentBlockBgColor}white`,
              fields: {
                value: {
                  value: `${consentBlockBgColor}-white`,
                },
                key: {
                  value: `${consentBlockBgColor}-white`,
                },
              },
            },
            "Css Class": {
              value: "consentSection",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: "",
            },
            SpecificError: {
              value: "",
            },
            apiName: null,
            "Field Type": {
              id: "447aa745-6d29-4b65-a5a3-8173aa8af548",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/structure/section",
              fields: {
                BackgroundColor: {
                  value: "Eggplant",
                },
                Icon: {
                  value: "OfficeWhite/32x32/document_header.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "8cddb194-f456-4a75-89b7-346f8f39f95c",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/section",
                  fields: {},
                },
                "Allowed Validations": [],
                "Model Type": {
                  value:
                    "Sitecore.ExperienceForms.Mvc.Models.Fields.FieldViewModel,Sitecore.ExperienceForms.Mvc",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/Section",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: "618B4717BC7C4EE3A524060982247E6F",
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: [
            {
              id: "65f6aef0-e025-4012-848b-4b220f070767",
              name: "privacyPolicy",
              displayName: "privacyPolicy",
              parentId: "8e8add7a-6528-42e9-9deb-9e6c2f2fbc2g",
              fields: {
                Datasource: {
                  id: "cb493c1e-b699-414f-af4a-91f3c01c76e2",
                  fieldType: "Droptree",
                  fieldName: "Datasource",
                  url: "/content/forms/privacypolicy",
                  fields: {},
                },
                "Default Selection": {
                  value: "",
                },
                "Display Field Name": {
                  value: "value",
                },
                "Is Dynamic": {
                  value: true,
                },
                "Value Field Name": {
                  value: "value",
                },
                "Css Class": {
                  value: "block privacyPolicyCheckbox",
                },
                isHidden: {
                  value: false,
                },
                HelpText: {
                  value: "",
                },
                GeneralError: {
                  value: consentBlock?.validation_message,
                },
                SpecificError: {
                  value: "",
                },
                apiName: {
                  id: "2b066ac3-4cbd-41c7-8e19-c26ab07eccc1",
                  fieldType: "Droplink",
                  fieldName: "apiName",
                  url: "/sitecore/content/fwd/global/settings/list/form-api-fields/consentcheckbox",
                  fields: {
                    value: {
                      value: "consentCheckBox",
                    },
                    key: {
                      value: "consentCheckBox",
                    },
                  },
                },
                "Label Css Class": {
                  value: "",
                },
                Title: {
                  value: "",
                },
                Required: {
                  value: true,
                },
                Validations: [],
                "Is Tracking Enabled": {
                  value: true,
                },
                "Allow Save": {
                  value: true,
                },
                "Value Provider Parameters": {
                  value: "",
                },
                "Value Provider": null,
                "Field Type": {
                  id: "d86a361a-d4ff-46b2-9e97-a37fc5b1fe1a",
                  fieldType: "Droptree",
                  fieldName: "Field Type",
                  url: "/sitecore/system/settings/forms/field-types/lists/checkbox-list",
                  fields: {
                    BackgroundColor: {
                      value: "Grass",
                    },
                    Icon: {
                      value: "OfficeWhite/32x32/checkbox_group.png",
                    },
                    IsDisabled: {
                      value: false,
                    },
                    SpritePosition: {
                      value: "",
                    },
                    "Field Template": {
                      id: "5b672865-55d2-413e-b699-fdfc7e732ccf",
                      fieldType: "Droptree",
                      fieldName: "Field Template",
                      url: "/sitecore/templates/system/forms/fields/list",
                      fields: {},
                    },
                    "Allowed Validations": [],
                    "Model Type": {
                      value:
                        "FWD.Foundation.Forms.CustomCheckBoxListViewModel,FWD.Foundation.Forms",
                    },
                    "Property Editor": null,
                    "View Path": {
                      value: "FieldTemplates/CheckBoxList",
                    },
                  },
                },
                Conditions: {
                  value: '{"fieldConditions":[]}',
                },
                "Field Key": {
                  value: "C470CC60AF974C7AADFF19FFAF9BF37D",
                },
                "Collapsed by Default": {
                  value: false,
                },
              },
              items: [
                {
                  id: "5728cfb1-3ddb-4eb6-8ae1-c125c26aae08",
                  name: "privacy-policy",
                  displayName: "Privacy Policy",
                  parentId: "cb493c1e-b699-414f-af4a-91f3c01c76e2",
                  fields: {
                    value: {
                      value: resolveAbsoluteUrlInParagraph(
                        consentBlock?.content,
                        i18nContext
                      ),
                    },
                  },
                },
              ],
            },
          ],
        };
        const submitButton: any = {
          id: "00999fba-c905-4f4d-a69a-dc166f6a939d",
          name: "submitClick",
          displayName: "submitClick",
          parentId: "2344a106-ff60-4cbf-ac9c-c3f0994b0e83",
          fields: {
            "Navigation Step": {
              value: "0",
            },
            emailTemplateLink: {
              value: "",
            },
            "Css Class": {
              value: "submitButton",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: "",
            },
            SpecificError: {
              value: "",
            },
            apiName: {
              id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
              fields: {
                value: {
                  value: "",
                },
                key: {
                  value: "",
                },
              },
            },
            "Label Css Class": {
              value: "",
            },
            Title: {
              value: consentBlock?.submit_button_text,
            },
            "Field Type": {
              id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
              fields: {
                BackgroundColor: {
                  value: "Eggplant",
                },
                Icon: {
                  value: "OfficeWhite/32x32/button.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/button",
                  fields: {},
                },
                "Allowed Validations": [],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/Button",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: "4268BDA3502A42B5ABFB35917297517D",
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: [
            {
              id: "20907e35-2797-495f-aa4a-3164b7afeedb",
              name: "Send Email",
              displayName: "Send Email",
              parentId: submitBtnId,
              fields: {
                Description: {
                  value: "",
                },
                Parameters: {
                  value: "",
                },
                "Submit Action": {
                  id: "2f66e378-be9d-4d9d-b43b-fc6ddc0e3db5",
                  fieldType: "Droptree",
                  fieldName: "Submit Action",
                  url: "/sitecore/system/settings/forms/submit-actions/custom-action",
                  fields: {
                    Editor: null,
                    "Error Message": {
                      value: "Saving form data failed!",
                    },
                    "Model Type": {
                      value:
                        "Sitecore.ExperienceForms.Processing.Actions.SaveData",
                    },
                  },
                },
              },
              items: [],
            },
            {
              id: "e4a2a602-5e65-4597-8c0e-d6c2a59f8e76",
              name: "Redirect to Page",
              displayName: "Redirect to Page",
              parentId: "57321773-013d-4531-98ce-6521b01a7fe6",
              fields: {
                Description: {
                  value: "Phone Number",
                },
                Parameters: {
                  value: `{"referenceId":"{3627D278-2067-4AA3-831E-496E0AF1BD30}"}`,
                },
                "Submit Action": {
                  id: "3f3e2093-9dea-4199-86ca-44fc69ef624d",
                  fieldType: "Droptree",
                  fieldName: "Submit Action",
                  url: "/sitecore/system/settings/forms/submit-actions/redirect-to-page",
                  fields: {
                    Editor: null,
                    "Error Message": {
                      value: "Redirect to page failed!",
                    },
                    "Model Type": {
                      value:
                        "Sitecore.ExperienceForms.Processing.Actions.RedirectToPage",
                    },
                  },
                },
              },
              items: [],
            },
          ],
        };
        blockItems.push(privacyPolicy, submitButton);
      } else {
        const nextButton = {
          id: "57321773-013d-4531-98ce-6521b01a7fe6",
          name: "emailNextClick",
          displayName: "Next Page",
          parentId: constants.email_block.id,
          fields: {
            "Navigation Step": {
              value: "1",
            },
            emailTemplateLink: {
              value: "",
            },
            "Css Class": {
              value: "next-button",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: "",
            },
            SpecificError: {
              value: "",
            },
            apiName: {
              id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
              fields: {
                value: {
                  value: "",
                },
                key: {
                  value: "",
                },
              },
            },
            "Label Css Class": {
              value: "",
            },
            Title: {
              value: enf?.nextButtonText,
            },
            "Field Type": {
              id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
              fields: {
                BackgroundColor: {
                  value: "Eggplant",
                },
                Icon: {
                  value: "OfficeWhite/32x32/button.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/button",
                  fields: {},
                },
                "Allowed Validations": [],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/Button",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: "3AD1E5279740470796028C7203617EB0",
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: [
            {
              id: "e4a2a602-5e65-4597-8c0e-d6c2a59f8e76",
              name: "Redirect to Page",
              displayName: "Redirect to Page",
              parentId: "57321773-013d-4531-98ce-6521b01a7fe6",
              fields: {
                Description: {
                  value: "Phone Number",
                },
                Parameters: {
                  value: `{"referenceId":"{${nextStepInfos?.id?.toUpperCase()}}"}`,
                },
                "Submit Action": {
                  id: "3f3e2093-9dea-4199-86ca-44fc69ef624d",
                  fieldType: "Droptree",
                  fieldName: "Submit Action",
                  url: "/sitecore/system/settings/forms/submit-actions/redirect-to-page",
                  fields: {
                    Editor: null,
                    "Error Message": {
                      value: "Redirect to page failed!",
                    },
                    "Model Type": {
                      value:
                        "Sitecore.ExperienceForms.Processing.Actions.RedirectToPage",
                    },
                  },
                },
              },
              items: [],
            },
          ],
        };
        blockItems.push(nextButton);
      }
      const emailBlock = {
        id: constants.email_block.id,
        name: "emailPage",
        displayName: "Email",
        parentId: "57ec00df-f75a-4645-a4f1-a830be98de45",
        fields: {
          gaLabel: {
            value: "",
          },
          backgroundColor: {
            id: "cbdc6265-d09f-4982-981a-bcc8f118f36f",
            fieldType: "Droplink",
            fieldName: "backgroundColor",
            url: "/sitecore/content/fwd/global/settings/list/colors/whiteorange",
            fields: {
              value: {
                value: "white-orange",
              },
              key: {
                value: "WhiteOrange",
              },
            },
          },
          "Css Class": {
            value: `emailPage ${
              !block?.email_block?.fill_icon ? "conditionPage" : ""
            }`,
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: null,
          "Field Type": {
            id: "d819b43e-c136-4392-9393-8be7fce32f5e",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/page",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/window_split_ver.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "cfee7b51-8505-45ce-b843-9358f827df87",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/page",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomPageViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Page",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: "9D55C171FC094F128842ACE2B3DDA4A9",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: blockItems,
      };
      enquirySteps.push(emailBlock);
    }

    if (block?.phone_number_block) {
      let conditions: any = { fieldConditions: [] };
      let appointmentDayNextButton = {
        id: "97230db2-f52d-467e-9964-b48aaa250194",
        name: "phoneNextButton",
        displayName: "Phone Click with Preferred Contact",
        parentId: "57ec00df-f75a-4645-a4f1-a830be98de45",
        fields: {
          "Navigation Step": {
            value: "1",
          },
          emailTemplateLink: {
            value: "",
          },
          "Css Class": {
            value: "next-button",
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: {
            id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
            fieldType: "Droplink",
            fieldName: "apiName",
            url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
            fields: {
              value: {
                value: "",
              },
              key: {
                value: "",
              },
            },
          },
          "Label Css Class": {
            value: "",
          },
          Title: {
            value: enf?.nextButtonText,
          },
          "Field Type": {
            id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/button.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/button",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Button",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: "D9G917C796E4429A9649870470347ABE",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [
          {
            id: "e4a2a602-5e65-4597-8c0e-d6c2a59f8e76",
            name: "Redirect to Page",
            displayName: "Redirect to Page",
            parentId: "57321773-013d-4531-98ce-6521b01a7fe6",
            fields: {
              Description: {
                value: "Phone Number",
              },
              Parameters: {
                value:
                  '{"referenceId":"{CE204667-409D-401F-9585-C28EE8446B9E}"}',
              },
              "Submit Action": {
                id: "3f3e2093-9dea-4199-86ca-44fc69ef624d",
                fieldType: "Droptree",
                fieldName: "Submit Action",
                url: "/sitecore/system/settings/forms/submit-actions/redirect-to-page",
                fields: {
                  Editor: null,
                  "Error Message": {
                    value: "Redirect to page failed!",
                  },
                  "Model Type": {
                    value:
                      "Sitecore.ExperienceForms.Processing.Actions.RedirectToPage",
                  },
                },
              },
            },
            items: [],
          },
        ],
      };

      if (
        ["th"].indexOf(countryCode) >= 0 &&
        categoryNeqConditions?.length > 0
      ) {
        conditions = {
          fieldConditions: [
            {
              matchTypeId: "{365C94DA-C1CD-4783-A91D-0D17A16C7117}",
              conditions: categoryNeqConditions,
              actions: [
                {
                  fieldId: "D9G917C796E4429A9649870470347ABE",
                  actionTypeId: "{7F58C8DD-D7C0-4FB7-BB44-8EC6B5E1C3D9}",
                  value: null,
                },
              ],
            },
            {
              matchTypeId: "{365C94DA-C1CD-4783-A91D-0D17A16C7117}",
              conditions: categoryEqConditions,
              actions: [
                {
                  fieldId: "7BB73BAD1EEC4F309AB7046C2FFF2E12",
                  actionTypeId: "{7F58C8DD-D7C0-4FB7-BB44-8EC6B5E1C3D9}",
                  value: null,
                },
              ],
            },
          ],
        };
      }

      let blockItems = [
        {
          id: "4f3881e9-622b-4d4d-bbb9-e176d3db9884",
          name: "Text",
          displayName: "Text",
          parentId: "13ff3a7b-7607-4878-b7ad-5a86579bddab",
          fields: {
            "Html Tag": {
              value: "h5",
            },
            Text: {
              value: block?.phone_number_block?.prefix,
            },
            "Css Class": {
              value: "inline",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: "",
            },
            SpecificError: {
              value: "",
            },
            apiName: {
              id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
              fields: {
                value: {
                  value: "",
                },
                key: {
                  value: "",
                },
              },
            },
            "Field Type": {
              id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/basic/text",
              fields: {
                BackgroundColor: {
                  value: "Sky",
                },
                Icon: {
                  value: "OfficeWhite/32x32/document_text.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/text",
                  fields: {},
                },
                "Allowed Validations": [],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/Text",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: "68525ADA341A44E4A149A8B8DFECA243",
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: [],
        },
        {
          id: "28cbb30c-ea50-4853-a49c-d11bc7303e24",
          name: "country_code",
          displayName: "country_code",
          parentId: "13ff3a7b-7607-4878-b7ad-5a86579bddab",
          fields: {
            "Default Value": {
              value: block?.phone_number_block?.country_code || "+62",
            },
            "Max Length": {
              value: "255",
            },
            "Min Length": {
              value: "0",
            },
            "Placeholder Text": {
              value: "",
            },
            dialInCodeLength: {
              id: "924aee12-9c96-42e0-8548-8378e251a8c7",
              fieldType: "Droplink",
              fieldName: "dialInCodeLength",
              url: "/sitecore/content/fwd/global/settings/list/formdiallingcodelength/zero",
              fields: {
                value: {
                  value: "No",
                },
                key: {
                  value: "zero",
                },
              },
            },
            "Css Class": {
              value: "inline disabled countryCodeInput",
            },
            isHidden: {
              value: !block?.phone_number_block?.country_code,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: "",
            },
            SpecificError: {
              value: "",
            },
            apiName: {
              id: "01b8756b-4bc7-4534-b990-516943821261",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/countrycode",
              fields: {
                value: {
                  value: "countryCode",
                },
                key: {
                  value: "countryCode",
                },
              },
            },
            serverValidationErrorText: {
              value: "",
            },
            serverValidationLoadingText: {
              value: "",
            },
            enableServerValidation: {
              value: false,
            },
            serverValidationAPI: null,
            "Label Css Class": {
              value: "",
            },
            Title: {
              value: "",
            },
            Required: {
              value: !!block?.phone_number_block?.country_code,
            },
            Validations: [],
            "Is Tracking Enabled": {
              value: true,
            },
            "Allow Save": {
              value: true,
            },
            "Value Provider Parameters": {
              value: "",
            },
            "Value Provider": null,
            "Field Type": {
              id: "4ee89ea7-cefe-4c8e-8532-467ef64591fc",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/basic/single-line-text",
              fields: {
                BackgroundColor: {
                  value: "Sky",
                },
                Icon: {
                  value: "OfficeWhite/32x32/text_field.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "0908030b-4564-42ea-a6fa-c7a5a2d921a8",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/input",
                  fields: {},
                },
                "Allowed Validations": [
                  {
                    id: "83e96d09-11c8-4132-8a8a-76d8db8208d6",
                    fields: {
                      Message: {
                        value: "",
                      },
                      Parameters: {
                        value: "",
                      },
                      Type: {
                        value:
                          "Sitecore.ExperienceForms.Mvc.Models.Validation.StringLengthValidation,Sitecore.ExperienceForms.Mvc",
                      },
                    },
                  },
                ],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomSingleLineViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/SingleLineText",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: "8964ED2ED7E64233BBB0708A7872ABD8",
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: [],
        },
        {
          id: "aedc3ff6-7b8a-40f7-9e24-205b977df0fa",
          name: "Telephone",
          displayName: "Telephone",
          parentId: "13ff3a7b-7607-4878-b7ad-5a86579bddab",
          fields: {
            "Default Value": {
              value: "",
            },
            "Max Length": {
              value: block?.phone_number_block?.max_length,
            },
            "Min Length": {
              value: block?.phone_number_block?.min_length,
            },
            "Placeholder Text": {
              value: block?.phone_number_block?.placeholder,
            },
            "Country Code": {
              value: block?.phone_number_block?.country_code,
            },
            dialInCodeLength: {
              id: "924aee12-9c96-42e0-8548-8378e251a8c7",
              fieldType: "Droplink",
              fieldName: "dialInCodeLength",
              url: "/sitecore/content/fwd/global/settings/list/formdiallingcodelength/zero",
              fields: {
                value: {
                  value: "0",
                },
                key: {
                  value: "zero",
                },
              },
            },
            "Css Class": {
              value: "inline telephoneInput",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: block?.phone_number_block?.validation_message,
            },
            SpecificError: {
              value: "Please input an 8-digit phone number",
            },
            serverValidationErrorText: {
              value: "Phone is invalid",
            },
            serverValidationLoadingText: {
              value: "",
            },
            enableServerValidation: {
              value: !!(enquiryRef?.server_side_validation),
            },
            serverValidationAPI:
              !!(enquiryRef?.server_side_validation)
                ? {
                    fields: {
                      value: {
                        value: `${publicConfigurations?.publicApiUrl}/api/enquiry/validate`,
                      },
                      key: {
                        value: "",
                      },
                    },
                  }
                : null,
            apiName: {
              id: "22259656-b6e9-4f74-993e-4578dbcf0741",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/cellphone",
              fields: {
                value: {
                  value: "cellPhone",
                },
                key: {
                  value: "cellPhone",
                },
              },
            },
            "Label Css Class": {
              value: "",
            },
            Title: {
              value: "",
            },
            Required: {
              value: true,
            },
            Validations: [
              {
                id: "e61ea5ab-77bb-4278-9b13-eac1c30e8e0e",
                fields: {
                  Message: {
                    value: block?.phone_number_block?.validation_rules?.message,
                  },
                  Parameters: {
                    value: block?.phone_number_block?.validation_rules?.regex,
                  },
                  Type: {
                    value:
                      "FWD.Foundation.Forms.Validators.CustomRegularExpressionValidation,FWD.Foundation.Forms",
                  },
                },
              },
            ],
            "Is Tracking Enabled": {
              value: true,
            },
            "Allow Save": {
              value: true,
            },
            "Value Provider Parameters": {
              value: "",
            },
            "Value Provider": null,
            "Field Type": {
              id: "df74f55b-47e6-4d1c-92f8-b0d46a7b2704",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/basic/telephone",
              fields: {
                BackgroundColor: {
                  value: "Sky",
                },
                Icon: {
                  value: "OfficeWhite/32x32/mobile_phone.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "0908030b-4564-42ea-a6fa-c7a5a2d921a8",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/input",
                  fields: {},
                },
                "Allowed Validations": [
                  {
                    id: "1aa74652-2177-48c9-88f5-1214445fea34",
                    fields: {
                      Message: {
                        value: "Please input 9 to 10 digits phone number.",
                      },
                      Parameters: {
                        value: "//^d +$//",
                      },
                      Type: {
                        value:
                          "FWD.Foundation.Forms.Validators.CustomRegularExpressionValidation,FWD.Foundation.Forms",
                      },
                    },
                  },
                  {
                    id: "83e96d09-11c8-4132-8a8a-76d8db8208d6",
                    fields: {
                      Message: {
                        value:
                          "{0} must have at least {1} and no more than {2} characters.",
                      },
                      Parameters: {
                        value: "",
                      },
                      Type: {
                        value:
                          "Sitecore.ExperienceForms.Mvc.Models.Validation.StringLengthValidation,Sitecore.ExperienceForms.Mvc",
                      },
                    },
                  },
                ],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomSingleLineViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/Telephone",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: "4799D38182DD4823B332E218187C23B0",
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: [],
        },
        getEnquiryFieldProps(
          block?.phone_number_block,
          "Tooltip",
          "13ff3a7b-7607-4878-b7ad-5a86579bddab"
        ),
        {
          id: "7803d6ed-0383-4199-9b8c-bb7ed47e3d25",
          name: "defaultPhoneNextButton",
          displayName: "Next Button Default",
          parentId: "13ff3a7b-7607-4878-b7ad-5a86579bddab",
          fields: {
            "Navigation Step": {
              value: "1",
            },
            emailTemplateLink: {
              value: "",
            },
            "Css Class": {
              value: "next-button",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: "",
            },
            SpecificError: {
              value: "",
            },
            apiName: {
              id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
              fields: {
                value: {
                  value: "",
                },
                key: {
                  value: "",
                },
              },
            },
            "Label Css Class": {
              value: "",
            },
            Title: {
              value: enf?.nextButtonText,
            },
            "Field Type": {
              id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
              fields: {
                BackgroundColor: {
                  value: "Eggplant",
                },
                Icon: {
                  value: "OfficeWhite/32x32/button.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/button",
                  fields: {},
                },
                "Allowed Validations": [],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/Button",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: "7BB73BAD1EEC4F309AB7046C2FFF2E12",
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: [
            {
              id: "784ca9a9-9fb1-48a9-a396-a86487b1ffd3",
              name: "Redirect to Page",
              displayName: "Redirect to Page",
              parentId: "7803d6ed-0383-4199-9b8c-bb7ed47e3d25",
              fields: {
                Description: {
                  value: "Preferred Contact Mode",
                },
                Parameters: {
                  value: `{"referenceId":"{${nextStepInfos?.id?.toUpperCase()}}"}`,
                },
                "Submit Action": {
                  id: "3f3e2093-9dea-4199-86ca-44fc69ef624d",
                  fieldType: "Droptree",
                  fieldName: "Submit Action",
                  url: "/sitecore/system/settings/forms/submit-actions/redirect-to-page",
                  fields: {
                    Editor: null,
                    "Error Message": {
                      value: "Redirect to page failed!",
                    },
                    "Model Type": {
                      value:
                        "Sitecore.ExperienceForms.Processing.Actions.RedirectToPage",
                    },
                  },
                },
              },
              items: [],
            },
          ],
        },
      ];
      if (
        ["th"].indexOf(countryCode) >= 0 &&
        categoryNeqConditions?.length > 0
      ) {
        blockItems.push(appointmentDayNextButton);
      }
      const phoneBlock = {
        id: "13ff3a7b-7607-4878-b7ad-5a86579bddab",
        name: "phonePage",
        displayName: "Phone Number",
        parentId: "57ec00df-f75a-4645-a4f1-a830be98de45",
        fields: {
          gaLabel: {
            value: "",
          },
          backgroundColor: {
            id: "cbdc6265-d09f-4982-981a-bcc8f118f36f",
            fieldType: "Droplink",
            fieldName: "backgroundColor",
            url: "/sitecore/content/fwd/global/settings/list/colors/whiteorange",
            fields: {
              value: {
                value: "white-orange",
              },
              key: {
                value: "WhiteOrange",
              },
            },
          },
          "Css Class": {
            value: `phonePage ${
              !block?.phone_number_block?.fill_icon ? "conditionPage" : ""
            }`,
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: null,
          "Field Type": {
            id: "d819b43e-c136-4392-9393-8be7fce32f5e",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/page",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/window_split_ver.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "cfee7b51-8505-45ce-b843-9358f827df87",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/page",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomPageViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Page",
              },
            },
          },
          Conditions: {
            value: JSON.stringify(conditions),
          },
          "Field Key": {
            value: "A822D936CE184893B07F20E4B97E9B22",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: blockItems,
      };
      enquirySteps.push(phoneBlock);
    }

    if (block?.dob_block) {
      let conditions: any = { fieldConditions: [] };
      let blockItems: any = [
        {
          id: "093e9da5-c485-4491-8c6e-86b89bac974e",
          name: "Text",
          displayName: "Text",
          parentId: "3cb1e880-b628-4ef4-89f8-80e7bcbddc7d",
          fields: {
            "Html Tag": {
              value: "p",
            },
            Text: {
              value: block?.dob_block?.title,
            },
            "Css Class": {
              value: "block",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: "",
            },
            SpecificError: {
              value: "",
            },
            apiName: null,
            "Field Type": {
              id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/basic/text",
              fields: {
                BackgroundColor: {
                  value: "Sky",
                },
                Icon: {
                  value: "OfficeWhite/32x32/document_text.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/text",
                  fields: {},
                },
                "Allowed Validations": [],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/Text",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: "9572D218437248E980F66B54BF9FAEA6",
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: [],
        },
        {
          id: "c7a9a0a1-9f24-47aa-b654-277e8f72debf",
          name: "dob",
          displayName: {
            day: block?.dob_block?.day?.default_text,
            month: block?.dob_block?.month?.default_text,
            year: block?.dob_block?.year?.default_text,
          },
          parentId: "3cb1e880-b628-4ef4-89f8-80e7bcbddc7d",
          fields: {
            "Show Empty Item": {
              value: true,
            },
            isAutoSuggest: {
              value: false,
            },
            Datasource: {
              id: "18de3e80-78b9-48b3-a1f1-aedf03565306",
              fieldType: "Droptree",
              fieldName: "Datasource",
              url: "/content/forms/contactdaylist-general-enquiry",
              fields: {},
            },
            Text: {
              value: "Demo 1",
            },
            "Default Selection": {
              value: "1",
            },
            "Display Field Name": {
              value: "value",
            },
            "Is Dynamic": {
              value: true,
            },
            "Value Field Name": {
              value: "key",
            },
            "Css Class": {
              value: "inline",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: block?.dob_block?.validation_message,
            },
            SpecificError: {
              value: "",
            },
            apiName: {
              id: "58b136a7-764d-48c7-a721-a53d8fa01074",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/dob",
              fields: {
                value: {
                  value: "dob",
                },
                key: {
                  value: "dob",
                },
              },
            },
            minimumYear: {
              value: block?.dob_block?.year?.minimum_year,
            },
            maximumYear: {
              value: block?.dob_block?.year?.max_year,
            },
            "Label Css Class": {
              value: "",
            },
            Title: {
              value: "",
            },
            Required: {
              value: true,
            },
            Validations: [],
            "Is Tracking Enabled": {
              value: true,
            },
            "Allow Save": {
              value: true,
            },
            "Value Provider Parameters": {
              value: "",
            },
            "Value Provider": null,
            "Collapsed by Default": {
              value: false,
            },
            "Field Type": {
              id: "e0cfadee-1ac0-471d-a820-2e70d1547b4b",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/dob",
              fields: {
                BackgroundColor: {
                  value: "Grass",
                },
                Icon: {
                  value: "OfficeWhite/32x32/drop_down_list.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "9121d435-48b8-4649-9d13-03d680474fad",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/dropdownlist",
                  fields: {},
                },
                "Allowed Validations": [],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomDropDownListViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/DropdownList",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: "dob",
            },
          },
          items: [],
        },
        getEnquiryFieldProps(
          block?.dob_block,
          "Tooltip",
          "fa9a0ddd-f620-436e-b1a4-976868988c50"
        ),
      ];
      const dobButtonNextClick: any = {
        id: "c1f16a5c-5083-4418-a39f-61f20efdb459",
        name: "dobButtonNextClick",
        displayName: "dobButtonNextClick",
        parentId: "3cb1e880-b628-4ef4-89f8-80e7bcbddc7d",
        fields: {
          "Navigation Step": {
            value: "1",
          },
          emailTemplateLink: {
            value: "",
          },
          "Css Class": {
            value: "next-button",
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: {
            id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
            fieldType: "Droplink",
            fieldName: "apiName",
            url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
            fields: {
              value: {
                value: "",
              },
              key: {
                value: "",
              },
            },
          },
          "Label Css Class": {
            value: "",
          },
          Title: {
            value: enf?.nextButtonText,
          },
          "Field Type": {
            id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/button.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/button",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Button",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: "63FEB8F928EA4CAFB690CB395F0667A5",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [
          {
            id: "e4a2a602-5e65-4597-8c0e-d6c2a59f8e76",
            name: "Redirect to Page",
            displayName: "Redirect to Page",
            parentId: "57321773-013d-4531-98ce-6521b01a7fe6",
            fields: {
              Description: {
                value: "Phone Number",
              },
              Parameters: {
                value: `{"referenceId":"{${nextStepInfos?.id?.toUpperCase()}}"}`,
              },
              "Submit Action": {
                id: "3f3e2093-9dea-4199-86ca-44fc69ef624d",
                fieldType: "Droptree",
                fieldName: "Submit Action",
                url: "/sitecore/system/settings/forms/submit-actions/redirect-to-page",
                fields: {
                  Editor: null,
                  "Error Message": {
                    value: "Redirect to page failed!",
                  },
                  "Model Type": {
                    value:
                      "Sitecore.ExperienceForms.Processing.Actions.RedirectToPage",
                  },
                },
              },
            },
            items: [],
          },
        ],
      };
      blockItems.push(dobButtonNextClick);

      const dobBlock = {
        id: "3cb1e880-b628-4ef4-89f8-80e7bcbddc7d",
        name: "dobPage",
        displayName: "dobPage",
        parentId: "57ec00df-f75a-4645-a4f1-a830be98de45",
        fields: {
          gaLabel: {
            value: "",
          },
          backgroundColor: {
            id: "cbdc6265-d09f-4982-981a-bcc8f118f36f",
            fieldType: "Droplink",
            fieldName: "backgroundColor",
            url: "/sitecore/content/fwd/global/settings/list/colors/whiteorange",
            fields: {
              value: {
                value: "white-orange",
              },
              key: {
                value: "WhiteOrange",
              },
            },
          },
          "Css Class": {
            value: `dobPage ${
              !block?.dob_block?.fill_icon ? "conditionPage" : ""
            }`,
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: null,
          "Field Type": {
            id: "d819b43e-c136-4392-9393-8be7fce32f5e",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/page",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/window_split_ver.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "cfee7b51-8505-45ce-b843-9358f827df87",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/page",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomPageViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Page",
              },
            },
          },
          Conditions: {
            value: JSON.stringify(conditions),
          },
          "Field Key": {
            value: "83F59508E70E42938C9C8BC6626295FC",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: blockItems,
      };
      enquirySteps.push(dobBlock);
    }

    if (block?.preferred_contact_method) {
      preferredContactNameValues =
        block?.preferred_contact_method?.name_value?.value.map(
          ({ key, value }: any) => {
            return {
              id: `51cdfc1b-ca14-4cd1-92c1-05497f00c3ed-${key}`,
              name: key,
              displayName: key,
              parentId: "6947b485-f82f-456e-af03-d8779ef44eba",
              fields: {
                value: {
                  value: key,
                },
              },
              items: [],
            };
          }
        );
      let conditions: any = { fieldConditions: [] };
      const phoneNextClick: any = {
        id: "24d892a6-ba06-4c0f-8389-6bb5ba0fc781",
        name: "phoneOptionNextClick",
        displayName: "phoneOptionNextClick",
        parentId: "3fc9e2df-ed81-4104-909d-ecf0ffad216f",
        fields: {
          "Navigation Step": {
            value: "1",
          },
          emailTemplateLink: {
            value: "",
          },
          "Css Class": {
            value: "next-button",
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: {
            id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
            fieldType: "Droplink",
            fieldName: "apiName",
            url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
            fields: {
              value: {
                value: "",
              },
              key: {
                value: "",
              },
            },
          },
          "Label Css Class": {
            value: "",
          },
          Title: {
            value: enf?.nextButtonText,
          },
          "Field Type": {
            id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/button.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/button",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Button",
              },
            },
          },
          Conditions: {
            value: `{
                "fieldConditions": [
                ]
              }`,
          },
          "Field Key": {
            value: "DFA430F198F44C0DA33CD16E1DB2299E",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [
          {
            id: "e4a2a602-5e65-4597-8c0e-d6c2a59f8e76",
            name: "Redirect to Page",
            displayName: "Redirect to Page",
            parentId: "57321773-013d-4531-98ce-6521b01a7fe6",
            fields: {
              Description: {
                value: "Phone Number",
              },
              Parameters: {
                value: `{"referenceId":"{${nextStepInfos?.id?.toUpperCase()}}"}`,
              },
              "Submit Action": {
                id: "3f3e2093-9dea-4199-86ca-44fc69ef624d",
                fieldType: "Droptree",
                fieldName: "Submit Action",
                url: "/sitecore/system/settings/forms/submit-actions/redirect-to-page",
                fields: {
                  Editor: null,
                  "Error Message": {
                    value: "Redirect to page failed!",
                  },
                  "Model Type": {
                    value:
                      "Sitecore.ExperienceForms.Processing.Actions.RedirectToPage",
                  },
                },
              },
            },
            items: [],
          },
        ],
      };
      const emailNextClick: any = {
        id: "24d892a6-ba06-4c0f-8389-6bb5ba0fc782",
        name: `emailNextClick`,
        displayName: `emailNextClick`,
        parentId: "3fc9e2df-ed81-4104-909d-ecf0ffad216f",
        fields: {
          "Navigation Step": {
            value: "1",
          },
          emailTemplateLink: {
            value: "",
          },
          "Css Class": {
            value: "next-button",
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: {
            id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
            fieldType: "Droplink",
            fieldName: "apiName",
            url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
            fields: {
              value: {
                value: "",
              },
              key: {
                value: "",
              },
            },
          },
          "Label Css Class": {
            value: "",
          },
          Title: {
            value: enf?.nextButtonText,
          },
          "Field Type": {
            id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/button.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/button",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Button",
              },
            },
          },
          Conditions: {
            value: `{
              "fieldConditions": [
              ]
            }`,
          },
          "Field Key": {
            value: "DFA430F198F44C0DA33CD16E1DB2299F",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [
          {
            id: generateUniqSerial(),
            name: "Redirect to Page",
            displayName: "Redirect to Page",
            parentId: "24d892a6-ba06-4c0f-8389-6bb5ba0fc782",
            fields: {
              Description: {
                value: "Phone Number",
              },
              Parameters: {
                value: `{"referenceId":"{${constants.leave_us_a_message.id.toUpperCase()}}"}`,
              },
              "Submit Action": {
                id: "3f3e2093-9dea-4199-86ca-44fc69ef624d",
                fieldType: "Droptree",
                fieldName: "Submit Action",
                url: "/sitecore/system/settings/forms/submit-actions/redirect-to-page",
                fields: {
                  Editor: null,
                  "Error Message": {
                    value: "Redirect to page failed!",
                  },
                  "Model Type": {
                    value:
                      "Sitecore.ExperienceForms.Processing.Actions.RedirectToPage",
                  },
                },
              },
            },
            items: [],
          },
        ],
      };

      let blockItems: any = [
        {
          id: "2aaa89b7-a326-46a0-b73f-d028383ef493",
          name: "Text",
          displayName: "Text",
          parentId: "3fc9e2df-ed81-4104-909d-ecf0ffad216f",
          fields: {
            "Html Tag": {
              value: "p",
            },
            Text: {
              value: block?.preferred_contact_method?.prefix,
            },
            "Css Class": {
              value: "block",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: "",
            },
            SpecificError: {
              value: "",
            },
            apiName: {
              id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
              fields: {
                value: {
                  value: "",
                },
                key: {
                  value: "",
                },
              },
            },
            "Field Type": {
              id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/basic/text",
              fields: {
                BackgroundColor: {
                  value: "Sky",
                },
                Icon: {
                  value: "OfficeWhite/32x32/document_text.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/text",
                  fields: {},
                },
                "Allowed Validations": [],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/Text",
                },
              },
            },
            Conditions: {
              value: `{"fieldConditions": []}`,
            },
            "Field Key": {
              value: "2CEFA97A507747A183E4DB2D202E0B10",
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: [],
        },
        {
          id: "6947b485-f82f-456e-af03-d8779ef44eba",
          name: "ContactMode",
          displayName: "ContactMode",
          parentId: "3fc9e2df-ed81-4104-909d-ecf0ffad216f",
          fields: {
            Datasource: null,
            "Default Selection": {
              value: "",
            },
            "Display Field Name": {
              value: "__Display name",
            },
            "Is Dynamic": {
              value: false,
            },
            "Value Field Name": {
              value: "Value",
            },
            "Css Class": {
              value: "block",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: "",
            },
            SpecificError: {
              value: "",
            },
            apiName: {
              id: "50d505ca-38f7-420f-8f73-f221ef889e74",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/preferredcontactmethod",
              fields: {
                value: {
                  value: "preferredContactMethod",
                },
                key: {
                  value: "preferredContactMethod",
                },
              },
            },
            "Label Css Class": {
              value: "",
            },
            Title: {
              value: "",
            },
            Required: {
              value: true,
            },
            Validations: [],
            "Is Tracking Enabled": {
              value: true,
            },
            "Allow Save": {
              value: true,
            },
            "Value Provider Parameters": {
              value: "",
            },
            "Value Provider": null,
            "Field Type": {
              id: "edbd38a8-1ae9-42ec-8ccd-f5b0e2998b4f",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/lists/radio-button-list",
              fields: {
                BackgroundColor: {
                  value: "Grass",
                },
                Icon: {
                  value: "OfficeWhite/32x32/radio_button_group.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "5b672865-55d2-413e-b699-fdfc7e732ccf",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/list",
                  fields: {},
                },
                "Allowed Validations": [],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomRadioButtonListViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/RadioButtonList",
                },
              },
            },
            Conditions: {
              value: JSON.stringify(conditions),
            },
            "Field Key": {
              value: "049474A552CA48A58B82690D22292B31",
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: preferredContactNameValues || [],
        },
        getEnquiryFieldProps(
          block?.preferred_contact_method,
          "Tooltip",
          "3fc9e2df-ed81-4104-909d-ecf0ffad216f"
        ),
      ];

      if (["th", "id", "hk", "mo"].indexOf(countryCode) >= 0) {
        blockItems.push(phoneNextClick, emailNextClick);
        conditions = {
          fieldConditions: [
            {
              matchTypeId: "{365C94DA-C1CD-4783-A91D-0D17A16C7117}",
              conditions: [
                {
                  fieldId: "049474A552CA48A58B82690D22292B31",
                  operatorId: "{1D38B217-A2EE-4E7B-B6ED-13E751462FEB}",
                  value: `${preferredContactNameValues?.[0]?.name}`,
                },
              ],
              actions: [
                {
                  fieldId: "DFA430F198F44C0DA33CD16E1DB2299E", // Preferred contact Phone
                  actionTypeId: "{AAE07A52-46A4-49EF-98B0-C2595BAC2382}",
                  value: null,
                },
                {
                  fieldId: "915D7E632E374A2AA094ABE3D95213BE",
                  actionTypeId: "{AAE07A52-46A4-49EF-98B0-C2595BAC2382}",
                  value: null,
                },
              ],
            },
            {
              matchTypeId: "{365C94DA-C1CD-4783-A91D-0D17A16C7117}",
              conditions: [
                {
                  fieldId: "049474A552CA48A58B82690D22292B31",
                  operatorId: "{1D38B217-A2EE-4E7B-B6ED-13E751462FEB}",
                  value: `${preferredContactNameValues?.[1]?.name}`,
                },
              ],
              actions: [
                {
                  fieldId: "DFA430F198F44C0DA33CD16E1DB2299F",
                  actionTypeId: "{AAE07A52-46A4-49EF-98B0-C2595BAC2382}",
                  value: null,
                },
                {
                  fieldId: "98FB8E4051374455BD89F04F5AAA2D77",
                  actionTypeId: "{AAE07A52-46A4-49EF-98B0-C2595BAC2382}",
                  value: null,
                },
              ],
            },
          ],
        };
      }

      if (["my"].indexOf(countryCode) >= 0) {
        blockItems.push(phoneNextClick);
      }

      const preferredContactMethod = {
        id: "3fc9e2df-ed81-4104-909d-ecf0ffad216f",
        name: "preferredContactModePage",
        displayName: "Preferred Contact Mode",
        parentId: "57ec00df-f75a-4645-a4f1-a830be98de45",
        fields: {
          gaLabel: {
            value: "",
          },
          backgroundColor: {
            id: "cbdc6265-d09f-4982-981a-bcc8f118f36f",
            fieldType: "Droplink",
            fieldName: "backgroundColor",
            url: "/sitecore/content/fwd/global/settings/list/colors/whiteorange",
            fields: {
              value: {
                value: "white-orange",
              },
              key: {
                value: "WhiteOrange",
              },
            },
          },
          "Css Class": {
            value: `preferredContactModePage ${
              !block?.preferred_contact_method?.fill_icon ? "conditionPage" : ""
            }`,
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: null,
          "Field Type": {
            id: "d819b43e-c136-4392-9393-8be7fce32f5e",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/page",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/window_split_ver.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "cfee7b51-8505-45ce-b843-9358f827df87",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/page",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomPageViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Page",
              },
            },
          },
          Conditions: {
            value: JSON.stringify(conditions),
          },
          "Field Key": {
            value: "4DF8F5E941DF48CC846122E6426D5C47",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: blockItems,
      };
      enquirySteps.push(preferredContactMethod);
    }

    if (block?.appointment_day_block) {
      const appointmentDays =
        block?.appointment_day_block?.appointment_day
          ?.filter((v: any) => {
            return !!v.title;
          })
          .map(({ title, filter_key }: any) => {
            return {
              id: generateUniqSerial(),
              name: title,
              displayName: title,
              parentId: constants?.appointment_day_block?.id,
              fields: {
                value: {
                  value: title,
                },
                key: {
                  value: filter_key,
                },
              },
            };
          }) || [];
      const appointmentDayBlock = {
        id: "ce204667-409d-401f-9585-c28ee8446b9e",
        name: "dayPage",
        displayName: "Day Details",
        parentId: "57ec00df-f75a-4645-a4f1-a830be98de45",
        fields: {
          gaLabel: {
            value: "",
          },
          backgroundColor: {
            id: "cbdc6265-d09f-4982-981a-bcc8f118f36f",
            fieldType: "Droplink",
            fieldName: "backgroundColor",
            url: "/sitecore/content/fwd/global/settings/list/colors/whiteorange",
            fields: {
              value: {
                value: "white-orange",
              },
              key: {
                value: "WhiteOrange",
              },
            },
          },
          "Css Class": {
            value: `dayPage ${
              !block?.appointment_day_block?.fill_icon ? "conditionPage" : ""
            }`,
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: null,
          "Field Type": {
            id: "d819b43e-c136-4392-9393-8be7fce32f5e",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/page",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/window_split_ver.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "cfee7b51-8505-45ce-b843-9358f827df87",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/page",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomPageViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Page",
              },
            },
          },
          Conditions: {
            value: `{
              "fieldConditions": [
              ]
            }`,
          },
          "Field Key": {
            value: "9E94EEE6AE21451DBE91C3B2B376849A",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [
          {
            id: "1789f54d-33c3-4045-baf6-bf2f857712ae",
            name: "Text",
            displayName: "Text",
            parentId: "ce204667-409d-401f-9585-c28ee8446b9e",
            fields: {
              "Html Tag": {
                value: "p",
              },
              Text: {
                value: block?.appointment_day_block?.prefix,
              },
              "Css Class": {
                value: "inline",
              },
              isHidden: {
                value: false,
              },
              HelpText: {
                value: "",
              },
              GeneralError: {
                value: "",
              },
              SpecificError: {
                value: "",
              },
              apiName: {
                id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
                fieldType: "Droplink",
                fieldName: "apiName",
                url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
                fields: {
                  value: {
                    value: "",
                  },
                  key: {
                    value: "",
                  },
                },
              },
              "Field Type": {
                id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
                fieldType: "Droptree",
                fieldName: "Field Type",
                url: "/sitecore/system/settings/forms/field-types/basic/text",
                fields: {
                  BackgroundColor: {
                    value: "Sky",
                  },
                  Icon: {
                    value: "OfficeWhite/32x32/document_text.png",
                  },
                  IsDisabled: {
                    value: false,
                  },
                  SpritePosition: {
                    value: "",
                  },
                  "Field Template": {
                    id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                    fieldType: "Droptree",
                    fieldName: "Field Template",
                    url: "/sitecore/templates/system/forms/fields/text",
                    fields: {},
                  },
                  "Allowed Validations": [],
                  "Model Type": {
                    value:
                      "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
                  },
                  "Property Editor": null,
                  "View Path": {
                    value: "FieldTemplates/Text",
                  },
                },
              },
              Conditions: {
                value: '{"fieldConditions":[]}',
              },
              "Field Key": {
                value: "04BB8D346D4D4667B6D98295CE78AB93",
              },
              "Collapsed by Default": {
                value: false,
              },
            },
            items: [],
          },
          {
            id: "a8a831c4-ce45-4545-adb2-ed75a15688c8",
            name: "dayDropdown",
            displayName: "Day",
            parentId: "ce204667-409d-401f-9585-c28ee8446b9e",
            fields: {
              "Show Empty Item": {
                value: true,
              },
              isAutoSuggest: {
                value: false,
              },
              Datasource: {
                id: "18de3e80-78b9-48b3-a1f1-aedf03565306",
                fieldType: "Droptree",
                fieldName: "Datasource",
                url: "/content/forms/contactdaylist-general-enquiry",
                fields: {},
              },
              "Default Selection": {
                value: "",
              },
              "Display Field Name": {
                value: "value",
              },
              "Is Dynamic": {
                value: true,
              },
              "Value Field Name": {
                value: "key",
              },
              "Css Class": {
                value: "inline",
              },
              isHidden: {
                value: false,
              },
              HelpText: {
                value: "",
              },
              GeneralError: {
                value: "",
              },
              SpecificError: {
                value: "",
              },
              apiName: {
                id: "58b136a7-764d-48c7-a721-a53d8fa01074",
                fieldType: "Droplink",
                fieldName: "apiName",
                url: "/sitecore/content/fwd/global/settings/list/form-api-fields/preferredday",
                fields: {
                  value: {
                    value: "preferredDay",
                  },
                  key: {
                    value: "preferredDay",
                  },
                },
              },
              "Label Css Class": {
                value: "",
              },
              Title: {
                value: block?.appointment_day_block?.placeholder,
              },
              Required: {
                value: true,
              },
              Validations: [],
              "Is Tracking Enabled": {
                value: true,
              },
              "Allow Save": {
                value: true,
              },
              "Value Provider Parameters": {
                value: "",
              },
              "Value Provider": null,
              "Collapsed by Default": {
                value: false,
              },
              "Field Type": {
                id: "e0cfadee-1ac0-471d-a820-2e70d1547b4b",
                fieldType: "Droptree",
                fieldName: "Field Type",
                url: "/sitecore/system/settings/forms/field-types/lists/dropdown-list",
                fields: {
                  BackgroundColor: {
                    value: "Grass",
                  },
                  Icon: {
                    value: "OfficeWhite/32x32/drop_down_list.png",
                  },
                  IsDisabled: {
                    value: false,
                  },
                  SpritePosition: {
                    value: "",
                  },
                  "Field Template": {
                    id: "9121d435-48b8-4649-9d13-03d680474fad",
                    fieldType: "Droptree",
                    fieldName: "Field Template",
                    url: "/sitecore/templates/system/forms/fields/dropdownlist",
                    fields: {},
                  },
                  "Allowed Validations": [],
                  "Model Type": {
                    value:
                      "FWD.Foundation.Forms.CustomDropDownListViewModel,FWD.Foundation.Forms",
                  },
                  "Property Editor": null,
                  "View Path": {
                    value: "FieldTemplates/DropdownList",
                  },
                },
              },
              Conditions: {
                value: '{"fieldConditions":[]}',
              },
              "Field Key": {
                value: "F388ECF875174833A4D66E2E2642B15B",
              },
            },
            items: appointmentDays || [],
          },
          getEnquiryFieldProps(
            block?.appointment_day_block,
            "Tooltip",
            "ce204667-409d-401f-9585-c28ee8446b9e"
          ),
          {
            id: "c8952a8d-5a09-49f9-ae50-97628fc459ae",
            name: "dayButtonNextClick",
            displayName: "dayButtonNextClick",
            parentId: "ce204667-409d-401f-9585-c28ee8446b9e",
            fields: {
              "Navigation Step": {
                value: "1",
              },
              emailTemplateLink: {
                value: "",
              },
              "Css Class": {
                value: "next-button",
              },
              isHidden: {
                value: false,
              },
              HelpText: {
                value: "",
              },
              GeneralError: {
                value: "",
              },
              SpecificError: {
                value: "",
              },
              apiName: {
                id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
                fieldType: "Droplink",
                fieldName: "apiName",
                url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
                fields: {
                  value: {
                    value: "",
                  },
                  key: {
                    value: "",
                  },
                },
              },
              "Label Css Class": {
                value: "",
              },
              Title: {
                value: enf?.nextButtonText,
              },
              "Field Type": {
                id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
                fieldType: "Droptree",
                fieldName: "Field Type",
                url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
                fields: {
                  BackgroundColor: {
                    value: "Eggplant",
                  },
                  Icon: {
                    value: "OfficeWhite/32x32/button.png",
                  },
                  IsDisabled: {
                    value: false,
                  },
                  SpritePosition: {
                    value: "",
                  },
                  "Field Template": {
                    id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
                    fieldType: "Droptree",
                    fieldName: "Field Template",
                    url: "/sitecore/templates/system/forms/fields/button",
                    fields: {},
                  },
                  "Allowed Validations": [],
                  "Model Type": {
                    value:
                      "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
                  },
                  "Property Editor": null,
                  "View Path": {
                    value: "FieldTemplates/Button",
                  },
                },
              },
              Conditions: {
                value: '{"fieldConditions":[]}',
              },
              "Field Key": {
                value: "B544A093CB784BB8AF1E0C9494DE48EE",
              },
              "Collapsed by Default": {
                value: false,
              },
            },
            items: [
              {
                id: "e4a2a602-5e65-4597-8c0e-d6c2a59f8e76",
                name: "Redirect to Page",
                displayName: "Redirect to Page",
                parentId: "57321773-013d-4531-98ce-6521b01a7fe6",
                fields: {
                  Description: {
                    value: "Phone Number",
                  },
                  Parameters: {
                    value: `{"referenceId":"{${nextStepInfos?.id?.toUpperCase()}}"}`,
                  },
                  "Submit Action": {
                    id: "3f3e2093-9dea-4199-86ca-44fc69ef624d",
                    fieldType: "Droptree",
                    fieldName: "Submit Action",
                    url: "/sitecore/system/settings/forms/submit-actions/redirect-to-page",
                    fields: {
                      Editor: null,
                      "Error Message": {
                        value: "Redirect to page failed!",
                      },
                      "Model Type": {
                        value:
                          "Sitecore.ExperienceForms.Processing.Actions.RedirectToPage",
                      },
                    },
                  },
                },
                items: [],
              },
            ],
          },
        ],
      };
      enquirySteps.push(appointmentDayBlock);
    }

    if (block?.appointment_time_block) {
      let conditions: any = { fieldConditions: [] };
      const appointmentTimes =
        block?.appointment_time_block?.appointment_time
          ?.filter((v: any) => {
            return !!v.title;
          })
          .map(({ title, filter_key }: any) => {
            return {
              id: generateUniqSerial(),
              name: title,
              displayName: title,
              parentId: constants?.appointment_time_block?.id,
              fields: {
                value: {
                  value: title,
                },
                key: {
                  value: filter_key,
                },
              },
            };
          }) || [];

      let blockItems: any = [
        {
          id: "bb9588b2-985c-4a23-8de0-e2b6a0adc066",
          name: "Text",
          displayName: "Text",
          parentId: "2420a361-ff7f-48f4-aea8-860bcbd642ec",
          fields: {
            "Html Tag": {
              value: "p",
            },
            Text: {
              value: block?.appointment_time_block?.prefix,
            },
            "Css Class": {
              value: "inline",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: "",
            },
            SpecificError: {
              value: "",
            },
            apiName: null,
            "Field Type": {
              id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/basic/text",
              fields: {
                BackgroundColor: {
                  value: "Sky",
                },
                Icon: {
                  value: "OfficeWhite/32x32/document_text.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/text",
                  fields: {},
                },
                "Allowed Validations": [],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/Text",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: "3FE63FA45349456B87D9EFA8F775D9FE",
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: [],
        },
        {
          id: "dbda29bc-721f-4ba3-b2ff-8a7533b86935",
          name: "timeDropdown",
          displayName: "Time",
          parentId: "2420a361-ff7f-48f4-aea8-860bcbd642ec",
          fields: {
            "Show Empty Item": {
              value: true,
            },
            isAutoSuggest: {
              value: false,
            },
            Datasource: {
              id: "58f59e4e-2486-42c4-8ed7-0663f6709b55",
              fieldType: "Droptree",
              fieldName: "Datasource",
              url: "/content/forms/contacttimelist",
              fields: {},
            },
            "Default Selection": {
              value: "",
            },
            "Display Field Name": {
              value: "value",
            },
            "Is Dynamic": {
              value: true,
            },
            "Value Field Name": {
              value: "key",
            },
            "Css Class": {
              value: "inline",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: "",
            },
            SpecificError: {
              value: "",
            },
            apiName: {
              id: "1e985703-c4e7-402d-a310-c17b2d90d381",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/preferredtime",
              fields: {
                value: {
                  value: "preferredTime",
                },
                key: {
                  value: "preferredTime",
                },
              },
            },
            "Label Css Class": {
              value: "",
            },
            Title: {
              value: block?.appointment_time_block?.placeholder,
            },
            Required: {
              value: true,
            },
            Validations: [],
            "Is Tracking Enabled": {
              value: true,
            },
            "Allow Save": {
              value: true,
            },
            "Value Provider Parameters": {
              value: "",
            },
            "Value Provider": null,
            "Collapsed by Default": {
              value: false,
            },
            "Field Type": {
              id: "e0cfadee-1ac0-471d-a820-2e70d1547b4b",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/lists/dropdown-list",
              fields: {
                BackgroundColor: {
                  value: "Grass",
                },
                Icon: {
                  value: "OfficeWhite/32x32/drop_down_list.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "9121d435-48b8-4649-9d13-03d680474fad",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/dropdownlist",
                  fields: {},
                },
                "Allowed Validations": [],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomDropDownListViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/DropdownList",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: "3769A8E2F8484912B8012DBDCD9A47CA",
            },
          },
          items: appointmentTimes || [],
        },
        getEnquiryFieldProps(
          block?.appointment_time_block,
          "Tooltip",
          "2420a361-ff7f-48f4-aea8-860bcbd642ec"
        ),
      ];
      const nextButton: any = {
        id: "c8952a8d-5a09-49f9-ae50-97628fc459ae",
        name: "timeButtonNextClick",
        displayName: "timeButtonNextClick",
        parentId: "ce204667-409d-401f-9585-c28ee8446b9e",
        fields: {
          "Navigation Step": {
            value: "1",
          },
          emailTemplateLink: {
            value: "",
          },
          "Css Class": {
            value: "next-button",
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: {
            id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
            fieldType: "Droplink",
            fieldName: "apiName",
            url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
            fields: {
              value: {
                value: "",
              },
              key: {
                value: "",
              },
            },
          },
          "Label Css Class": {
            value: "",
          },
          Title: {
            value: enf?.nextButtonText,
          },
          "Field Type": {
            id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/button.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/button",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Button",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: "B544A093CB784BB8AF1E0C9494DE48BE",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [
          {
            id: "e4a2a602-5e65-4597-8c0e-d6c2a59f8e76",
            name: "Redirect to Page",
            displayName: "Redirect to Page",
            parentId: "57321773-013d-4531-98ce-6521b01a7fe6",
            fields: {
              Description: {
                value: "Phone Number",
              },
              Parameters: {
                value: `{"referenceId":"{${nextStepInfos?.id?.toUpperCase()}}"}`,
              },
              "Submit Action": {
                id: "3f3e2093-9dea-4199-86ca-44fc69ef624d",
                fieldType: "Droptree",
                fieldName: "Submit Action",
                url: "/sitecore/system/settings/forms/submit-actions/redirect-to-page",
                fields: {
                  Editor: null,
                  "Error Message": {
                    value: "Redirect to page failed!",
                  },
                  "Model Type": {
                    value:
                      "Sitecore.ExperienceForms.Processing.Actions.RedirectToPage",
                  },
                },
              },
            },
            items: [],
          },
        ],
      };
      const privacyPolicy: any = {
        id: "8e8add7a-6528-42e9-9deb-9e6c2f2fbc2g",
        name: "PrivacyPolicySection",
        displayName: "PrivacyPolicySection",
        parentId: "57ec00df-f75a-4645-a4f1-a830be98de45",
        fields: {
          backgroundColor: {
            id: "cf8cf9a4-0f70-4db2-b298-90e820aca94b",
            fieldType: "Droplink",
            fieldName: "backgroundColor",
            url: `/sitecore/content/fwd/global/settings/list/colors/${consentBlockBgColor}white`,
            fields: {
              value: {
                value: `${consentBlockBgColor}-white`,
              },
              key: {
                value: `${consentBlockBgColor}-white`,
              },
            },
          },
          "Css Class": {
            value: "consentSection",
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: null,
          "Field Type": {
            id: "447aa745-6d29-4b65-a5a3-8173aa8af548",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/section",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/document_header.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "8cddb194-f456-4a75-89b7-346f8f39f95c",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/section",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "Sitecore.ExperienceForms.Mvc.Models.Fields.FieldViewModel,Sitecore.ExperienceForms.Mvc",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Section",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: "618B4717BC7C4EE3A524060982247E5D",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [
          {
            id: "65f6aef0-e025-4012-848b-4b220f070767",
            name: "privacyPolicy",
            displayName: "privacyPolicy",
            parentId: "8e8add7a-6528-42e9-9deb-9e6c2f2fbc2g",
            fields: {
              Datasource: {
                id: "cb493c1e-b699-414f-af4a-91f3c01c76e2",
                fieldType: "Droptree",
                fieldName: "Datasource",
                url: "/content/forms/privacypolicy",
                fields: {},
              },
              "Default Selection": {
                value: "",
              },
              "Display Field Name": {
                value: "value",
              },
              "Is Dynamic": {
                value: true,
              },
              "Value Field Name": {
                value: "value",
              },
              "Css Class": {
                value: "block privacyPolicyCheckbox",
              },
              isHidden: {
                value: false,
              },
              HelpText: {
                value: "",
              },
              GeneralError: {
                value: consentBlock?.validation_message,
              },
              SpecificError: {
                value: "",
              },
              apiName: {
                id: "2b066ac3-4cbd-41c7-8e19-c26ab07eccc1",
                fieldType: "Droplink",
                fieldName: "apiName",
                url: "/sitecore/content/fwd/global/settings/list/form-api-fields/consentcheckbox",
                fields: {
                  value: {
                    value: "consentCheckBox",
                  },
                  key: {
                    value: "consentCheckBox",
                  },
                },
              },
              "Label Css Class": {
                value: "",
              },
              Title: {
                value: "",
              },
              Required: {
                value: true,
              },
              Validations: [],
              "Is Tracking Enabled": {
                value: true,
              },
              "Allow Save": {
                value: true,
              },
              "Value Provider Parameters": {
                value: "",
              },
              "Value Provider": null,
              "Field Type": {
                id: "d86a361a-d4ff-46b2-9e97-a37fc5b1fe1a",
                fieldType: "Droptree",
                fieldName: "Field Type",
                url: "/sitecore/system/settings/forms/field-types/lists/checkbox-list",
                fields: {
                  BackgroundColor: {
                    value: "Grass",
                  },
                  Icon: {
                    value: "OfficeWhite/32x32/checkbox_group.png",
                  },
                  IsDisabled: {
                    value: false,
                  },
                  SpritePosition: {
                    value: "",
                  },
                  "Field Template": {
                    id: "5b672865-55d2-413e-b699-fdfc7e732ccf",
                    fieldType: "Droptree",
                    fieldName: "Field Template",
                    url: "/sitecore/templates/system/forms/fields/list",
                    fields: {},
                  },
                  "Allowed Validations": [],
                  "Model Type": {
                    value:
                      "FWD.Foundation.Forms.CustomCheckBoxListViewModel,FWD.Foundation.Forms",
                  },
                  "Property Editor": null,
                  "View Path": {
                    value: "FieldTemplates/CheckBoxList",
                  },
                },
              },
              Conditions: {
                value: '{"fieldConditions":[]}',
              },
              "Field Key": {
                value: "C470CC60AF974C7AADFF19FFAF9BF37D",
              },
              "Collapsed by Default": {
                value: false,
              },
            },
            items: [
              {
                id: "5728cfb1-3ddb-4eb6-8ae1-c125c26aae08",
                name: "privacy-policy",
                displayName: "Privacy Policy",
                parentId: "cb493c1e-b699-414f-af4a-91f3c01c76e2",
                fields: {
                  value: {
                    value: resolveAbsoluteUrlInParagraph(
                      consentBlock?.content,
                      i18nContext
                    ),
                  },
                },
              },
            ],
          },
        ],
      };
      const submitButton: any = {
        id: submitBtnId,
        name: "phoneOptionSubmitClick",
        displayName: "phoneOptionSubmitClick",
        parentId: "2344a106-ff60-4cbf-ac9c-c3f0994b0e83",
        fields: {
          "Navigation Step": {
            value: "0",
          },
          emailTemplateLink: {
            value: "",
          },
          "Css Class": {
            value: "submitButton",
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: {
            id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
            fieldType: "Droplink",
            fieldName: "apiName",
            url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
            fields: {
              value: {
                value: "",
              },
              key: {
                value: "",
              },
            },
          },
          "Label Css Class": {
            value: "",
          },
          Title: {
            value: consentBlock?.submit_button_text,
          },
          "Field Type": {
            id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/button.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/button",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Button",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: "4268BDA3502A42B5ABFB35917297516C",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [
          {
            id: "20907e35-2797-495f-aa4a-3164b7afeedb",
            name: "Send Email",
            displayName: "Send Email",
            parentId: submitBtnId,
            fields: {
              Description: {
                value: "",
              },
              Parameters: {
                value: "",
              },
              "Submit Action": {
                id: "2f66e378-be9d-4d9d-b43b-fc6ddc0e3db5",
                fieldType: "Droptree",
                fieldName: "Submit Action",
                url: "/sitecore/system/settings/forms/submit-actions/custom-action",
                fields: {
                  Editor: null,
                  "Error Message": {
                    value: "Saving form data failed!",
                  },
                  "Model Type": {
                    value:
                      "Sitecore.ExperienceForms.Processing.Actions.SaveData",
                  },
                },
              },
            },
            items: [],
          },
          {
            id: "e4a2a602-5e65-4597-8c0e-d6c2a59f8e76",
            name: "Redirect to Page",
            displayName: "Redirect to Page",
            parentId: "57321773-013d-4531-98ce-6521b01a7fe6",
            fields: {
              Description: {
                value: "Phone Number",
              },
              Parameters: {
                value: `{"referenceId":"{3627D278-2067-4AA3-831E-496E0AF1BD30}"}`,
              },
              "Submit Action": {
                id: "3f3e2093-9dea-4199-86ca-44fc69ef624d",
                fieldType: "Droptree",
                fieldName: "Submit Action",
                url: "/sitecore/system/settings/forms/submit-actions/redirect-to-page",
                fields: {
                  Editor: null,
                  "Error Message": {
                    value: "Redirect to page failed!",
                  },
                  "Model Type": {
                    value:
                      "Sitecore.ExperienceForms.Processing.Actions.RedirectToPage",
                  },
                },
              },
            },
            items: [],
          },
        ],
      };

      blockItems.push(privacyPolicy);
      blockItems.push(submitButton);

      if (
        ["th"].indexOf(countryCode) >= 0 &&
        categoryNeqConditions?.length > 0
      ) {
        blockItems.push(nextButton);
        conditions = {
          fieldConditions: [
            {
              matchTypeId: "{365C94DA-C1CD-4783-A91D-0D17A16C7117}",
              conditions: categoryNeqConditions, // If category not equal "Book time with an agent", show next button => leave message
              actions: [
                {
                  fieldId: "B544A093CB784BB8AF1E0C9494DE48BE",
                  actionTypeId: "{AAE07A52-46A4-49EF-98B0-C2595BAC2382}",
                  value: null,
                },
                {
                  fieldId: "915D7E632E374A2AA094ABE3D95214DF",
                  actionTypeId: "{7F58C8DD-D7C0-4FB7-BB44-8EC6B5E1C3D9}",
                  value: null,
                },
              ],
            },
            {
              matchTypeId: "{365C94DA-C1CD-4783-A91D-0D17A16C7117}",
              conditions: categoryEqConditions,
              actions: [
                {
                  fieldId: "618B4717BC7C4EE3A524060982247E5D", // equal => show privacy & submitbutton
                  actionTypeId: "{AAE07A52-46A4-49EF-98B0-C2595BAC2382}",
                  value: null,
                },
                {
                  fieldId: "4268BDA3502A42B5ABFB35917297516C", // equal => show privacy & submitbutton
                  actionTypeId: "{AAE07A52-46A4-49EF-98B0-C2595BAC2382}",
                  value: null,
                },
              ],
            },
          ],
        };
      }

      if (
        ["id", "hk", "mo"].indexOf(countryCode) >= 0 &&
        block?.appointment_time_block?.next_button
      ) {
        blockItems.push(nextButton);
        blockItems = blockItems.filter((item: any) => {
          return (
            ["PrivacyPolicySection", "phoneOptionSubmitClick"].indexOf(
              item.name
            ) < 0
          );
        });
      }

      const appointmentTimeBlock = {
        id: "2420a361-ff7f-48f4-aea8-860bcbd642ec",
        name: "timePage",
        displayName: "Phone Details",
        parentId: "57ec00df-f75a-4645-a4f1-a830be98de45",
        fields: {
          gaLabel: {
            value: "",
          },
          backgroundColor: {
            id: "cbdc6265-d09f-4982-981a-bcc8f118f36f",
            fieldType: "Droplink",
            fieldName: "backgroundColor",
            url: "/sitecore/content/fwd/global/settings/list/colors/whiteorange",
            fields: {
              value: {
                value: "white-orange",
              },
              key: {
                value: "WhiteOrange",
              },
            },
          },
          "Css Class": {
            value: `timePage ${
              !block?.appointment_time_block?.fill_icon ? "conditionPage" : ""
            }`,
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: null,
          "Field Type": {
            id: "d819b43e-c136-4392-9393-8be7fce32f5e",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/page",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/window_split_ver.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "cfee7b51-8505-45ce-b843-9358f827df87",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/page",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomPageViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Page",
              },
            },
          },
          Conditions: {
            value: JSON.stringify(conditions),
          },
          "Field Key": {
            value: "F46A6A39786642D2A743E145F488033A",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: blockItems,
      };
      enquirySteps.push(appointmentTimeBlock);
    }

    if (block?.leave_us_a_message) {
      const leaveUsAMessageBlock = {
        id: "2344a106-ff60-4cbf-ac9c-c3f0994b0e83",
        name: "commentsPage",
        displayName: "Comments Page",
        parentId: "57ec00df-f75a-4645-a4f1-a830be98de45",
        fields: {
          gaLabel: {
            value: "",
          },
          backgroundColor: {
            id: "cbdc6265-d09f-4982-981a-bcc8f118f36f",
            fieldType: "Droplink",
            fieldName: "backgroundColor",
            url: "/sitecore/content/fwd/global/settings/list/colors/whiteorange",
            fields: {
              value: {
                value: "white-orange",
              },
              key: {
                value: "WhiteOrange",
              },
            },
          },
          "Css Class": {
            value: `commentsPage ${
              !block?.leave_us_a_message?.fill_icon ? "conditionPage" : ""
            }`,
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: null,
          "Field Type": {
            id: "d819b43e-c136-4392-9393-8be7fce32f5e",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/page",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/window_split_ver.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "cfee7b51-8505-45ce-b843-9358f827df87",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/page",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomPageViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Page",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: "124E675A556E49098DB1F1CA757BA6BA",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [
          {
            id: "e424f13b-a2ac-4778-ac58-f99d53d65f74",
            name: "Text",
            displayName: "Text",
            parentId: "2344a106-ff60-4cbf-ac9c-c3f0994b0e83",
            fields: {
              "Html Tag": {
                value: "p",
              },
              Text: {
                value: block?.leave_us_a_message?.prefix,
              },
              "Css Class": {
                value: "block",
              },
              isHidden: {
                value: false,
              },
              HelpText: {
                value: "",
              },
              GeneralError: {
                value: "",
              },
              SpecificError: {
                value: "",
              },
              apiName: {
                id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
                fieldType: "Droplink",
                fieldName: "apiName",
                url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
                fields: {
                  value: {
                    value: "",
                  },
                  key: {
                    value: "",
                  },
                },
              },
              "Field Type": {
                id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
                fieldType: "Droptree",
                fieldName: "Field Type",
                url: "/sitecore/system/settings/forms/field-types/basic/text",
                fields: {
                  BackgroundColor: {
                    value: "Sky",
                  },
                  Icon: {
                    value: "OfficeWhite/32x32/document_text.png",
                  },
                  IsDisabled: {
                    value: false,
                  },
                  SpritePosition: {
                    value: "",
                  },
                  "Field Template": {
                    id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                    fieldType: "Droptree",
                    fieldName: "Field Template",
                    url: "/sitecore/templates/system/forms/fields/text",
                    fields: {},
                  },
                  "Allowed Validations": [],
                  "Model Type": {
                    value:
                      "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
                  },
                  "Property Editor": null,
                  "View Path": {
                    value: "FieldTemplates/Text",
                  },
                },
              },
              Conditions: {
                value: '{"fieldConditions":[]}',
              },
              "Field Key": {
                value: "A75064A325DD4F9886C59B90D94623CD",
              },
              "Collapsed by Default": {
                value: false,
              },
            },
            items: [],
          },
          {
            id: "43fe4bed-cefd-43ee-91d2-4c02bf5d83ae",
            name: "AddAComment",
            displayName: "AddAComment",
            parentId: "2344a106-ff60-4cbf-ac9c-c3f0994b0e83",
            fields: {
              Rows: {
                value: "4",
              },
              "Default Value": {
                value: "",
              },
              "Max Length": {
                value: block?.leave_us_a_message?.max_length || 256,
                validationMessage:
                  block?.leave_us_a_message?.validation_max_length_message ||
                  "",
              },
              "Min Length": {
                value: "0",
              },
              "Placeholder Text": {
                value: block?.leave_us_a_message?.placeholder,
              },
              dialInCodeLength: null,
              "Css Class": {
                value: "block",
              },
              isHidden: {
                value: false,
              },
              HelpText: {
                value: "",
              },
              GeneralError: {
                value: block?.leave_us_a_message?.validation_message,
              },
              SpecificError: {
                value:
                  "To submit your question(s) or to comment, please leave a message.",
              },
              apiName: {
                id: "87827a1f-bbbd-42b2-b966-5fa2a695f102",
                fieldType: "Droplink",
                fieldName: "apiName",
                url: "/sitecore/content/fwd/global/settings/list/form-api-fields/comment",
                fields: {
                  value: {
                    value: "comment",
                  },
                  key: {
                    value: "comment",
                  },
                },
              },
              "Label Css Class": {
                value: "",
              },
              Title: {
                value: "",
              },
              Required: {
                value: true,
              },
              Validations: [],
              "Is Tracking Enabled": {
                value: true,
              },
              "Allow Save": {
                value: true,
              },
              "Value Provider Parameters": {
                value: "",
              },
              "Value Provider": null,
              "Field Type": {
                id: "a296a1c1-0da0-4493-a92e-b8191f43aec6",
                fieldType: "Droptree",
                fieldName: "Field Type",
                url: "/sitecore/system/settings/forms/field-types/basic/multiple-line-text",
                fields: {
                  BackgroundColor: {
                    value: "Sky",
                  },
                  Icon: {
                    value: "OfficeWhite/32x32/text.png",
                  },
                  IsDisabled: {
                    value: false,
                  },
                  SpritePosition: {
                    value: "",
                  },
                  "Field Template": {
                    id: "d8386d04-c1e3-4cd3-9227-9e9f86ef3c88",
                    fieldType: "Droptree",
                    fieldName: "Field Template",
                    url: "/sitecore/templates/system/forms/fields/multiple-line-text",
                    fields: {},
                  },
                  "Allowed Validations": [
                    {
                      id: "83e96d09-11c8-4132-8a8a-76d8db8208d6",
                      fields: {
                        Message: {
                          value:
                            "{0} must have at least {1} and no more than {2} characters.",
                        },
                        Parameters: {
                          value: "",
                        },
                        Type: {
                          value:
                            "Sitecore.ExperienceForms.Mvc.Models.Validation.StringLengthValidation,Sitecore.ExperienceForms.Mvc",
                        },
                      },
                    },
                  ],
                  "Model Type": {
                    value:
                      "FWD.Foundation.Forms.CustomMultipleLineTextViewModel,FWD.Foundation.Forms",
                  },
                  "Property Editor": null,
                  "View Path": {
                    value: "FieldTemplates/MultipleLineText",
                  },
                },
              },
              Conditions: {
                value: '{"fieldConditions":[]}',
              },
              "Field Key": {
                value: "C6739D2F83B749A3A7F79BB681CC6DAD",
              },
              "Collapsed by Default": {
                value: false,
              },
            },
            items: [],
          },
          getEnquiryFieldProps(
            block?.leave_us_a_message,
            "Tooltip",
            "2344a106-ff60-4cbf-ac9c-c3f0994b0e83"
          ),
          ...consentStep,
        ],
      };
      enquirySteps.push(leaveUsAMessageBlock);
    }

    if (block?.existing_customer) {
      const nameValues = block?.existing_customer?.items?.map(
        ({ item_key }: any) => {
          return {
            id: generateUniqSerial(),
            name: item_key,
            displayName: item_key,
            parentId: "6947b485-f82f-456e-af03-d8779ef44eba",
            fields: {
              value: {
                value: item_key,
              },
            },
            items: [],
          };
        }
      );
      let nextSteps: any = [];
      let fieldConditions: any = [];
      let conditions: any = { fieldConditions: [] };
      if (block?.existing_customer?.consent) {
        nextSteps = [...consentStep];
      } else {
        nextSteps.push({
          id: "24d892a6-ba06-4c0f-8389-6bb5ba0fc771",
          name: "phoneOptionNextClick",
          displayName: "phoneOptionNextClick",
          parentId: "3fc9e2df-ed81-4104-909d-ecf0ffad216f",
          fields: {
            "Navigation Step": {
              value: "1",
            },
            emailTemplateLink: {
              value: "",
            },
            "Css Class": {
              value: "next-button",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: "",
            },
            SpecificError: {
              value: "",
            },
            apiName: {
              id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
              fields: {
                value: {
                  value: "",
                },
                key: {
                  value: "",
                },
              },
            },
            "Label Css Class": {
              value: "",
            },
            Title: {
              value: enf?.nextButtonText,
            },
            "Field Type": {
              id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
              fields: {
                BackgroundColor: {
                  value: "Eggplant",
                },
                Icon: {
                  value: "OfficeWhite/32x32/button.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/button",
                  fields: {},
                },
                "Allowed Validations": [],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/Button",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: "DFA430F198F44C0DA33CD16E1DB2232E",
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: [
            {
              id: "e4a2a602-5e65-4597-8c0e-d6c2a59f8e76",
              name: "Redirect to Page",
              displayName: "Redirect to Page",
              parentId: "57321773-013d-4531-98ce-6521b01a7fe6",
              fields: {
                Description: {
                  value: "Phone Number",
                },
                Parameters: {
                  value: `{"referenceId":"{${nextStepInfos?.id?.toUpperCase()}}"}`,
                },
                "Submit Action": {
                  id: "3f3e2093-9dea-4199-86ca-44fc69ef624d",
                  fieldType: "Droptree",
                  fieldName: "Submit Action",
                  url: "/sitecore/system/settings/forms/submit-actions/redirect-to-page",
                  fields: {
                    Editor: null,
                    "Error Message": {
                      value: "Redirect to page failed!",
                    },
                    "Model Type": {
                      value:
                        "Sitecore.ExperienceForms.Processing.Actions.RedirectToPage",
                    },
                  },
                },
              },
              items: [],
            },
          ],
        });
      }

      if (["ph"].indexOf(countryCode) >= 0) {
        conditions = {
          fieldConditions: [
            {
              matchTypeId: "{365C94DA-C1CD-4783-A91D-0D17A16C7117}",
              conditions: [
                {
                  fieldId: "049474A552CA48A58B82690D12292C93",
                  operatorId: "{1D38B217-A2EE-4E7B-B6ED-13E751462FEB}",
                  value: `no`,
                },
              ],
              actions: [
                {
                  fieldId: "DFA430F198F44C0DA33CD16E1DB2233DE",
                  actionTypeId: "{AAE07A52-46A4-49EF-98B0-C2595BAC2382}",
                  value: null,
                },
              ],
            },
            {
              matchTypeId: "{365C94DA-C1CD-4783-A91D-0D17A16C7117}",
              conditions: [
                {
                  fieldId: "049474A552CA48A58B82690D12292C93",
                  operatorId: "{1D38B217-A2EE-4E7B-B6ED-13E751462FEB}",
                  value: `yes`,
                },
              ],
              actions: [
                {
                  fieldId: "DFA430F198F44C0DA33CD16E1DB2232E",
                  actionTypeId: "{AAE07A52-46A4-49EF-98B0-C2595BAC2382}",
                  value: null,
                },
              ],
            },
          ],
        };
        nextSteps.push({
          id: "24d892a6-ba06-4c0f-8389-6bb5ba0fc771",
          name: "phoneOptionNextClick",
          displayName: "phoneOptionNextClick",
          parentId: "3fc9e2df-ed81-4104-909d-ecf0ffad216f",
          fields: {
            "Navigation Step": {
              value: "1",
            },
            emailTemplateLink: {
              value: "",
            },
            "Css Class": {
              value: "next-button",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: "",
            },
            SpecificError: {
              value: "",
            },
            apiName: {
              id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
              fields: {
                value: {
                  value: "",
                },
                key: {
                  value: "",
                },
              },
            },
            "Label Css Class": {
              value: "",
            },
            Title: {
              value: enf?.nextButtonText,
            },
            "Field Type": {
              id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
              fields: {
                BackgroundColor: {
                  value: "Eggplant",
                },
                Icon: {
                  value: "OfficeWhite/32x32/button.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/button",
                  fields: {},
                },
                "Allowed Validations": [],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/Button",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: "DFA430F198F44C0DA33CD16E1DB2233DE",
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: [
            {
              id: "e4a2a602-5e65-4597-8c0e-d6c2a59f8e76",
              name: "Redirect to Page",
              displayName: "Redirect to Page",
              parentId: "57321773-013d-4531-98ce-6521b01a7fe6",
              fields: {
                Description: {
                  value: "Phone Number",
                },
                Parameters: {
                  value: `{"referenceId":"{CE204667-409D-401F-9585-C28EE8446B9E}"}`,
                },
                "Submit Action": {
                  id: "3f3e2093-9dea-4199-86ca-44fc69ef624d",
                  fieldType: "Droptree",
                  fieldName: "Submit Action",
                  url: "/sitecore/system/settings/forms/submit-actions/redirect-to-page",
                  fields: {
                    Editor: null,
                    "Error Message": {
                      value: "Redirect to page failed!",
                    },
                    "Model Type": {
                      value:
                        "Sitecore.ExperienceForms.Processing.Actions.RedirectToPage",
                    },
                  },
                },
              },
              items: [],
            },
          ],
        });
      }

      const step = {
        id: "3fc9e2df-ed81-4104-909d-ecf0ffad311f",
        name: "existingCustomerPage",
        displayName: "existingCustomerPage",
        parentId: "57ec00df-f75a-4645-a4f1-a830be98de45",
        fields: {
          gaLabel: {
            value: "",
          },
          backgroundColor: {
            id: "cbdc6265-d09f-4982-981a-bcc8f118f36f",
            fieldType: "Droplink",
            fieldName: "backgroundColor",
            url: "/sitecore/content/fwd/global/settings/list/colors/whiteorange",
            fields: {
              value: {
                value: "white-orange",
              },
              key: {
                value: "WhiteOrange",
              },
            },
          },
          "Css Class": {
            value: `preferredContactModePage ${
              !block?.existing_customer?.fill_icon ? "conditionPage" : ""
            }`,
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: null,
          "Field Type": {
            id: "d819b43e-c136-4392-9393-8be7fce32f5e",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/page",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/window_split_ver.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "cfee7b51-8505-45ce-b843-9358f827df87",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/page",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomPageViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Page",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: "4DF8F5E941DF48CC846122E6426D5147",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [
          {
            id: "2aaa89b7-a326-46a0-b73f-d028383ef493",
            name: "Text",
            displayName: "Text",
            parentId: "3fc9e2df-ed81-4104-909d-ecf0ffad216f",
            fields: {
              "Html Tag": {
                value: "p",
              },
              Text: {
                value: block?.existing_customer?.prefix,
              },
              "Css Class": {
                value: "block",
              },
              isHidden: {
                value: false,
              },
              HelpText: {
                value: "",
              },
              GeneralError: {
                value: "",
              },
              SpecificError: {
                value: "",
              },
              apiName: {
                id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
                fieldType: "Droplink",
                fieldName: "apiName",
                url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
                fields: {
                  value: {
                    value: "",
                  },
                  key: {
                    value: "",
                  },
                },
              },
              "Field Type": {
                id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
                fieldType: "Droptree",
                fieldName: "Field Type",
                url: "/sitecore/system/settings/forms/field-types/basic/text",
                fields: {
                  BackgroundColor: {
                    value: "Sky",
                  },
                  Icon: {
                    value: "OfficeWhite/32x32/document_text.png",
                  },
                  IsDisabled: {
                    value: false,
                  },
                  SpritePosition: {
                    value: "",
                  },
                  "Field Template": {
                    id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                    fieldType: "Droptree",
                    fieldName: "Field Template",
                    url: "/sitecore/templates/system/forms/fields/text",
                    fields: {},
                  },
                  "Allowed Validations": [],
                  "Model Type": {
                    value:
                      "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
                  },
                  "Property Editor": null,
                  "View Path": {
                    value: "FieldTemplates/Text",
                  },
                },
              },
              Conditions: {
                value: JSON.stringify(conditions),
              },
              "Field Key": {
                value: "2CEFA97A507747A183E4DB2D202E0B11",
              },
              "Collapsed by Default": {
                value: false,
              },
            },
            items: [],
          },
          {
            id: "6947b485-f82f-456e-af03-d8779ef44eba",
            name: "Existing Mode",
            displayName: "Existing Mode",
            parentId: "3fc9e2df-ed81-4104-909d-ecf0ffad216f",
            fields: {
              Datasource: null,
              "Default Selection": {
                value: "",
              },
              "Display Field Name": {
                value: "__Display name",
              },
              "Is Dynamic": {
                value: false,
              },
              "Value Field Name": {
                value: "Value",
              },
              "Css Class": {
                value: "block",
              },
              isHidden: {
                value: false,
              },
              HelpText: {
                value: "",
              },
              GeneralError: {
                value: "",
              },
              SpecificError: {
                value: "",
              },
              apiName: {
                id: "50d505ca-38f7-420f-8f73-f221ef889e74",
                fieldType: "Droplink",
                fieldName: "apiName",
                url: "/sitecore/content/fwd/global/settings/list/form-api-fields/sbcCustomer",
                fields: {
                  value: {
                    value: "sbcCustomer",
                  },
                  key: {
                    value: "sbcCustomer",
                  },
                },
              },
              "Label Css Class": {
                value: "",
              },
              Title: {
                value: "",
              },
              Required: {
                value: true,
              },
              Validations: [],
              "Is Tracking Enabled": {
                value: true,
              },
              "Allow Save": {
                value: true,
              },
              "Value Provider Parameters": {
                value: "",
              },
              "Value Provider": null,
              "Field Type": {
                id: "edbd38a8-1ae9-42ec-8ccd-f5b0e2998b4f",
                fieldType: "Droptree",
                fieldName: "Field Type",
                url: "/sitecore/system/settings/forms/field-types/lists/radio-button-list",
                fields: {
                  BackgroundColor: {
                    value: "Grass",
                  },
                  Icon: {
                    value: "OfficeWhite/32x32/radio_button_group.png",
                  },
                  IsDisabled: {
                    value: false,
                  },
                  SpritePosition: {
                    value: "",
                  },
                  "Field Template": {
                    id: "5b672865-55d2-413e-b699-fdfc7e732ccf",
                    fieldType: "Droptree",
                    fieldName: "Field Template",
                    url: "/sitecore/templates/system/forms/fields/list",
                    fields: {},
                  },
                  "Allowed Validations": [],
                  "Model Type": {
                    value:
                      "FWD.Foundation.Forms.CustomRadioButtonListViewModel,FWD.Foundation.Forms",
                  },
                  "Property Editor": null,
                  "View Path": {
                    value: "FieldTemplates/RadioButtonList",
                  },
                },
              },
              Conditions: {
                value: `{"fieldConditions": ${[
                  JSON.stringify(fieldConditions),
                ]}}`,
              },
              "Field Key": {
                value: "049474A552CA48A58B82690D12292C93",
              },
              "Collapsed by Default": {
                value: false,
              },
            },
            items: nameValues || [],
          },
          getEnquiryFieldProps(
            block?.existing_customer,
            "Tooltip",
            "3fc9e2df-ed81-4104-909d-ecf0ffad216f"
          ),
          ...nextSteps,
        ],
      };
      enquirySteps.push(step);
    }

    if (block?.insurance_policy) {
      const nameValues = block?.insurance_policy?.items?.map(
        ({ item_key }: any) => {
          return {
            id: generateUniqSerial(),
            name: item_key,
            displayName: item_key,
            parentId: "3fc9e2df-ed81-4104-909d-ecf0ffad3132",
            fields: {
              value: {
                value: item_key,
              },
            },
            items: [],
          };
        }
      );
      let conditions: any = { fieldConditions: [] };

      const nextButton: any = {
        id: "24d892a6-ba06-4c0f-8389-6bb5ba0fc771",
        name: "insurancePolicyNextClick",
        displayName: "insurancePolicyNextClick",
        parentId: "3fc9e2df-ed81-4104-909d-ecf0ffad216f",
        fields: {
          "Navigation Step": {
            value: "1",
          },
          emailTemplateLink: {
            value: "",
          },
          "Css Class": {
            value: "next-button",
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: {
            id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
            fieldType: "Droplink",
            fieldName: "apiName",
            url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
            fields: {
              value: {
                value: "",
              },
              key: {
                value: "",
              },
            },
          },
          "Label Css Class": {
            value: "",
          },
          Title: {
            value: enf?.nextButtonText,
          },
          "Field Type": {
            id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/button.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/button",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Button",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: "DFA430F198F44C0DA33CD16E1DB2233G",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [
          {
            id: "e4a2a602-5e65-4597-8c0e-d6c2a59f8e76",
            name: "Redirect to Page",
            displayName: "Redirect to Page",
            parentId: "57321773-013d-4531-98ce-6521b01a7fe6",
            fields: {
              Description: {
                value: "Phone Number",
              },
              Parameters: {
                value: `{"referenceId":"{${nextStepInfos?.id?.toUpperCase()}}"}`,
              },
              "Submit Action": {
                id: "3f3e2093-9dea-4199-86ca-44fc69ef624d",
                fieldType: "Droptree",
                fieldName: "Submit Action",
                url: "/sitecore/system/settings/forms/submit-actions/redirect-to-page",
                fields: {
                  Editor: null,
                  "Error Message": {
                    value: "Redirect to page failed!",
                  },
                  "Model Type": {
                    value:
                      "Sitecore.ExperienceForms.Processing.Actions.RedirectToPage",
                  },
                },
              },
            },
            items: [],
          },
        ],
      };
      const privacyPolicy: any = {
        id: "8e8add7a-6528-42e9-9deb-9e6c2f2fbc2g",
        name: "PrivacyPolicySection",
        displayName: "PrivacyPolicySection",
        parentId: "57ec00df-f75a-4645-a4f1-a830be98de45",
        fields: {
          backgroundColor: {
            id: "cf8cf9a4-0f70-4db2-b298-90e820aca94b",
            fieldType: "Droplink",
            fieldName: "backgroundColor",
            url: `/sitecore/content/fwd/global/settings/list/colors/${consentBlockBgColor}white`,
            fields: {
              value: {
                value: `${consentBlockBgColor}-white`,
              },
              key: {
                value: `${consentBlockBgColor}-white`,
              },
            },
          },
          "Css Class": {
            value: "consentSection",
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: null,
          "Field Type": {
            id: "447aa745-6d29-4b65-a5a3-8173aa8af548",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/section",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/document_header.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "8cddb194-f456-4a75-89b7-346f8f39f95c",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/section",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "Sitecore.ExperienceForms.Mvc.Models.Fields.FieldViewModel,Sitecore.ExperienceForms.Mvc",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Section",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: "618B4717BC7C4EE3A524060982247E6F",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [
          {
            id: "65f6aef0-e025-4012-848b-4b220f070767",
            name: "privacyPolicy",
            displayName: "privacyPolicy",
            parentId: "8e8add7a-6528-42e9-9deb-9e6c2f2fbc2g",
            fields: {
              Datasource: {
                id: "cb493c1e-b699-414f-af4a-91f3c01c76e2",
                fieldType: "Droptree",
                fieldName: "Datasource",
                url: "/content/forms/privacypolicy",
                fields: {},
              },
              "Default Selection": {
                value: "",
              },
              "Display Field Name": {
                value: "value",
              },
              "Is Dynamic": {
                value: true,
              },
              "Value Field Name": {
                value: "value",
              },
              "Css Class": {
                value: "block privacyPolicyCheckbox",
              },
              isHidden: {
                value: false,
              },
              HelpText: {
                value: "",
              },
              GeneralError: {
                value: consentBlock?.validation_message,
              },
              SpecificError: {
                value: "",
              },
              apiName: {
                id: "2b066ac3-4cbd-41c7-8e19-c26ab07eccc1",
                fieldType: "Droplink",
                fieldName: "apiName",
                url: "/sitecore/content/fwd/global/settings/list/form-api-fields/consentcheckbox",
                fields: {
                  value: {
                    value: "consentCheckBox",
                  },
                  key: {
                    value: "consentCheckBox",
                  },
                },
              },
              "Label Css Class": {
                value: "",
              },
              Title: {
                value: "",
              },
              Required: {
                value: true,
              },
              Validations: [],
              "Is Tracking Enabled": {
                value: true,
              },
              "Allow Save": {
                value: true,
              },
              "Value Provider Parameters": {
                value: "",
              },
              "Value Provider": null,
              "Field Type": {
                id: "d86a361a-d4ff-46b2-9e97-a37fc5b1fe1a",
                fieldType: "Droptree",
                fieldName: "Field Type",
                url: "/sitecore/system/settings/forms/field-types/lists/checkbox-list",
                fields: {
                  BackgroundColor: {
                    value: "Grass",
                  },
                  Icon: {
                    value: "OfficeWhite/32x32/checkbox_group.png",
                  },
                  IsDisabled: {
                    value: false,
                  },
                  SpritePosition: {
                    value: "",
                  },
                  "Field Template": {
                    id: "5b672865-55d2-413e-b699-fdfc7e732ccf",
                    fieldType: "Droptree",
                    fieldName: "Field Template",
                    url: "/sitecore/templates/system/forms/fields/list",
                    fields: {},
                  },
                  "Allowed Validations": [],
                  "Model Type": {
                    value:
                      "FWD.Foundation.Forms.CustomCheckBoxListViewModel,FWD.Foundation.Forms",
                  },
                  "Property Editor": null,
                  "View Path": {
                    value: "FieldTemplates/CheckBoxList",
                  },
                },
              },
              Conditions: {
                value: '{"fieldConditions":[]}',
              },
              "Field Key": {
                value: "C470CC60AF974C7AADFF19FFAF9BF37D",
              },
              "Collapsed by Default": {
                value: false,
              },
            },
            items: [
              {
                id: "5728cfb1-3ddb-4eb6-8ae1-c125c26aae08",
                name: "privacy-policy",
                displayName: "Privacy Policy",
                parentId: "cb493c1e-b699-414f-af4a-91f3c01c76e2",
                fields: {
                  value: {
                    value: resolveAbsoluteUrlInParagraph(
                      consentBlock?.content,
                      i18nContext
                    ),
                  },
                },
              },
            ],
          },
        ],
      };
      const submitButton: any = {
        id: "00999fba-c905-4f4d-a69a-dc166f6a939d",
        name: "submitClick",
        displayName: "submitClick",
        parentId: "2344a106-ff60-4cbf-ac9c-c3f0994b0e83",
        fields: {
          "Navigation Step": {
            value: "0",
          },
          emailTemplateLink: {
            value: "",
          },
          "Css Class": {
            value: "submitButton",
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: {
            id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
            fieldType: "Droplink",
            fieldName: "apiName",
            url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
            fields: {
              value: {
                value: "",
              },
              key: {
                value: "",
              },
            },
          },
          "Label Css Class": {
            value: "",
          },
          Title: {
            value: consentBlock?.submit_button_text,
          },
          "Field Type": {
            id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/button.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/button",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Button",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: "4268BDA3502A42B5ABFB35917297517D",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [
          {
            id: "20907e35-2797-495f-aa4a-3164b7afeedb",
            name: "Send Email",
            displayName: "Send Email",
            parentId: submitBtnId,
            fields: {
              Description: {
                value: "",
              },
              Parameters: {
                value: "",
              },
              "Submit Action": {
                id: "2f66e378-be9d-4d9d-b43b-fc6ddc0e3db5",
                fieldType: "Droptree",
                fieldName: "Submit Action",
                url: "/sitecore/system/settings/forms/submit-actions/custom-action",
                fields: {
                  Editor: null,
                  "Error Message": {
                    value: "Saving form data failed!",
                  },
                  "Model Type": {
                    value:
                      "Sitecore.ExperienceForms.Processing.Actions.SaveData",
                  },
                },
              },
            },
            items: [],
          },
          {
            id: "e4a2a602-5e65-4597-8c0e-d6c2a59f8e76",
            name: "Redirect to Page",
            displayName: "Redirect to Page",
            parentId: "57321773-013d-4531-98ce-6521b01a7fe6",
            fields: {
              Description: {
                value: "Phone Number",
              },
              Parameters: {
                value: `{"referenceId":"{3627D278-2067-4AA3-831E-496E0AF1BD30}"}`,
              },
              "Submit Action": {
                id: "3f3e2093-9dea-4199-86ca-44fc69ef624d",
                fieldType: "Droptree",
                fieldName: "Submit Action",
                url: "/sitecore/system/settings/forms/submit-actions/redirect-to-page",
                fields: {
                  Editor: null,
                  "Error Message": {
                    value: "Redirect to page failed!",
                  },
                  "Model Type": {
                    value:
                      "Sitecore.ExperienceForms.Processing.Actions.RedirectToPage",
                  },
                },
              },
            },
            items: [],
          },
        ],
      };
      let blockItems: any = [
        {
          id: "2aaa89b7-a326-46a0-b73f-d028383ef493",
          name: "Text",
          displayName: "Text",
          parentId: "3fc9e2df-ed81-4104-909d-ecf0ffad216f",
          fields: {
            "Html Tag": {
              value: "p",
            },
            Text: {
              value: block?.insurance_policy?.prefix,
            },
            "Css Class": {
              value: "block",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: "",
            },
            SpecificError: {
              value: "",
            },
            apiName: {
              id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
              fields: {
                value: {
                  value: "",
                },
                key: {
                  value: "",
                },
              },
            },
            "Field Type": {
              id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/basic/text",
              fields: {
                BackgroundColor: {
                  value: "Sky",
                },
                Icon: {
                  value: "OfficeWhite/32x32/document_text.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/text",
                  fields: {},
                },
                "Allowed Validations": [],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/Text",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: "2CEFA97A507747A183E4DB2D202E0B12",
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: [],
        },
        {
          id: "6947b485-f82f-456e-af03-d8779ef44eba",
          name: "Existing Mode",
          displayName: "Existing Mode",
          parentId: "3fc9e2df-ed81-4104-909d-ecf0ffad216f",
          fields: {
            Datasource: null,
            "Default Selection": {
              value: "",
            },
            "Display Field Name": {
              value: "__Display name",
            },
            "Is Dynamic": {
              value: false,
            },
            "Value Field Name": {
              value: "Value",
            },
            "Css Class": {
              value: "block",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: "",
            },
            SpecificError: {
              value: "",
            },
            apiName: {
              id: "50d505ca-38f7-420f-8f73-f221ef889e74",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/existingCustomer",
              fields: {
                value: {
                  value: "existingCustomer",
                },
                key: {
                  value: "existingCustomer",
                },
              },
            },
            "Label Css Class": {
              value: "",
            },
            Title: {
              value: "",
            },
            Required: {
              value: true,
            },
            Validations: [],
            "Is Tracking Enabled": {
              value: true,
            },
            "Allow Save": {
              value: true,
            },
            "Value Provider Parameters": {
              value: "",
            },
            "Value Provider": null,
            "Field Type": {
              id: "edbd38a8-1ae9-42ec-8ccd-f5b0e2998b4f",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/lists/radio-button-list",
              fields: {
                BackgroundColor: {
                  value: "Grass",
                },
                Icon: {
                  value: "OfficeWhite/32x32/radio_button_group.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "5b672865-55d2-413e-b699-fdfc7e732ccf",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/list",
                  fields: {},
                },
                "Allowed Validations": [],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomRadioButtonListViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/RadioButtonList",
                },
              },
            },
            Conditions: {
              value: JSON.stringify(conditions),
            },
            "Field Key": {
              value: "049474A552CA48A58B82690D22292C93",
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: nameValues || [],
        },
        getEnquiryFieldProps(
          block?.insurance_policy,
          "Tooltip",
          "3fc9e2df-ed81-4104-909d-ecf0ffad3132"
        ),
      ];
      if (!!block?.insurance_policy?.next_button) {
        blockItems.push(nextButton);
      }
      if (countryCode == "vn" && categoryEqConditions?.length > 0) {
        blockItems.push(privacyPolicy);
        blockItems.push(submitButton);
        conditions = {
          fieldConditions: [
            {
              matchTypeId: "{365C94DA-C1CD-4783-A91D-0D17A16C7117}",
              conditions: categoryEqConditions,
              actions: [
                {
                  fieldId: "DFA430F198F44C0DA33CD16E1DB2233G",
                  actionTypeId: "{AAE07A52-46A4-49EF-98B0-C2595BAC2382}",
                  value: null,
                },
              ],
            },
            {
              matchTypeId: "{365C94DA-C1CD-4783-A91D-0D17A16C7117}",
              conditions: categoryNeqConditions,
              actions: [
                {
                  fieldId: "618B4717BC7C4EE3A524060982247E6F",
                  actionTypeId: "{AAE07A52-46A4-49EF-98B0-C2595BAC2382}",
                  value: null,
                },
                {
                  fieldId: "4268BDA3502A42B5ABFB35917297517D",
                  actionTypeId: "{AAE07A52-46A4-49EF-98B0-C2595BAC2382}",
                  value: null,
                },
              ],
            },
          ],
        };
      } else if (countryCode === "vn") {
        blockItems.push(privacyPolicy);
        blockItems.push(submitButton);
      }
      const insurancePolicy = {
        id: "3fc9e2df-ed81-4104-909d-ecf0ffad3132",
        name: "insurancePolicyPage",
        displayName: "insurancePolicyPage",
        parentId: "57ec00df-f75a-4645-a4f1-a830be98de45",
        fields: {
          gaLabel: {
            value: "",
          },
          backgroundColor: {
            id: "cbdc6265-d09f-4982-981a-bcc8f118f36f",
            fieldType: "Droplink",
            fieldName: "backgroundColor",
            url: "/sitecore/content/fwd/global/settings/list/colors/whiteorange",
            fields: {
              value: {
                value: "white-orange",
              },
              key: {
                value: "WhiteOrange",
              },
            },
          },
          "Css Class": {
            value: `preferredContactModePage ${
              !block?.insurance_policy?.fill_icon ? "conditionPage" : ""
            }`,
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: null,
          "Field Type": {
            id: "d819b43e-c136-4392-9393-8be7fce32f5e",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/page",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/window_split_ver.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "cfee7b51-8505-45ce-b843-9358f827df87",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/page",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomPageViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Page",
              },
            },
          },
          Conditions: {
            value: JSON.stringify(conditions),
          },
          "Field Key": {
            value: "4DF8F5E941DF48CC846122E6426D5117",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: blockItems,
      };
      enquirySteps.push(insurancePolicy);
    }

    if (
      block?.multi_select_block &&
      block?.multi_select_block?.field_name === "homeProvince"
    ) {
      const items =
        block?.multi_select_block?.items?.map(({ name, item_value }: any) => {
          return {
            id: generateUniqSerial(),
            name: name,
            displayName: name,
            parentId: constants?.multi_select_block_homeProvince?.id,
            fields: {
              value: {
                value: name,
              },
              key: {
                value: item_value,
              },
            },
          };
        }) || [];
      let blockItems: any = [
        {
          id: "79c5fa97-edab-419f-86f4-7a1844af969a",
          name: "Text",
          displayName: "Text",
          parentId: "05843f30-abb3-427b-a9c4-ccec95f1e45d",
          fields: {
            "Html Tag": {
              value: "p",
            },
            Text: {
              value: block?.multi_select_block?.prefix,
            },
            "Css Class": {
              value: "inline",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: "",
            },
            SpecificError: {
              value: "",
            },
            apiName: null,
            "Field Type": {
              id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/basic/text",
              fields: {
                BackgroundColor: {
                  value: "Sky",
                },
                Icon: {
                  value: "OfficeWhite/32x32/document_text.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/text",
                  fields: {},
                },
                "Allowed Validations": [],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/Text",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: "3FE63FA45349456B87D9EFA8F776D9FE",
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: [],
        },
        {
          id: "dbda29bc-721f-4ba3-b2ff-8a7533b86935",
          name: "provinceDropdown",
          displayName: "Province",
          parentId: "05843f30-abb3-427b-a9c4-ccec95f1e45d",
          fields: {
            "Show Empty Item": {
              value: true,
            },
            isAutoSuggest: {
              value: block?.multi_select_block?.is_autocomplete,
            },
            "Empty Text": {
              value: block?.multi_select_block?.no_options_text,
            },
            Datasource: {
              id: "58f59e4e-2486-42c4-8ed7-0663f6709b55",
              fieldType: "Droptree",
              fieldName: "Datasource",
              url: "/content/forms/contacttimelist",
              fields: {},
            },
            "Default Selection": {
              value: "",
            },
            "Display Field Name": {
              value: "value",
            },
            "Is Dynamic": {
              value: true,
            },
            "Value Field Name": {
              value: "key",
            },
            "Css Class": {
              value: "block",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: "",
            },
            SpecificError: {
              value: "",
            },
            apiName: {
              id: "1e985703-c4e7-402d-a310-c17b2d90d381",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/homeProvince",
              fields: {
                value: {
                  value: "homeProvince",
                },
                key: {
                  value: "homeProvince",
                },
              },
            },
            "Label Css Class": {
              value: "",
            },
            Title: {
              value: block?.multi_select_block?.placeholder,
            },
            Required: {
              value: true,
            },
            Validations: [],
            "Is Tracking Enabled": {
              value: true,
            },
            "Allow Save": {
              value: true,
            },
            "Value Provider Parameters": {
              value: "",
            },
            "Value Provider": null,
            "Collapsed by Default": {
              value: false,
            },
            "Field Type": {
              id: "e0cfadee-1ac0-471d-a820-2e70d1547b4b",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/lists/dropdown-list",
              fields: {
                BackgroundColor: {
                  value: "Grass",
                },
                Icon: {
                  value: "OfficeWhite/32x32/drop_down_list.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "9121d435-48b8-4649-9d13-03d680474fad",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/dropdownlist",
                  fields: {},
                },
                "Allowed Validations": [],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomDropDownListViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/DropdownList",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: "3769A8E2F8484912B8012DBDCE9A47CA",
            },
          },
          items: items,
        },
      ];
      const firstChildConditions = {
        fieldConditions: [
          {
            matchTypeId: "{365C94DA-C1CD-4783-A91D-0D17A16C7118}",
            conditions: [
              {
                fieldId: "3769A8E2F8484912B8012DBDCE9A47CA",
                operatorId: "{1D38B217-A2EE-4E7B-B6ED-13E751462FEB}",
                value: "",
              },
            ],
            actions: [
              {
                fieldId: `REGIONFIRST`,
                actionTypeId: "{AAE07A52-46A4-49EF-98B0-C2595BAC2382}",
                value: null,
              },
            ],
          },
        ],
      };
      const firstChildDropdown = {
        id: "dbda29bc-721f-4ba3-b2ff-8a7533b86936",
        name: "provinceDropdown1",
        displayName: "Province1",
        parentId: "05843f30-abb3-427b-a9c4-ccec95f1e45d",
        fields: {
          "Show Empty Item": {
            value: true,
          },
          isAutoSuggest: {
            value: block?.multi_select_block?.is_autocomplete,
          },
          "Empty Text": {
            value: block?.multi_select_block?.no_options_text,
          },
          Datasource: {
            id: "58f59e4e-2486-42c4-8ed7-0663f6709b55",
            fieldType: "Droptree",
            fieldName: "Datasource",
            url: "/content/forms/contacttimelist",
            fields: {},
          },
          "Default Selection": {
            value: "",
          },
          "Display Field Name": {
            value: "value",
          },
          "Is Dynamic": {
            value: true,
          },
          "Value Field Name": {
            value: "key",
          },
          "Css Class": {
            value: "block",
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: {
            id: "1e985703-c4e7-402d-a310-c17b2d90d381",
            fieldType: "Droplink",
            fieldName: "apiName",
            url: "/sitecore/content/fwd/global/settings/list/form-api-fields/municipality",
            fields: {
              value: {
                value: "municipality",
              },
              key: {
                value: "municipality",
              },
            },
          },
          "Label Css Class": {
            value: "",
          },
          Title: {
            value: block?.multi_select_block?.sub_placeholder,
          },
          Required: {
            value: false,
          },
          Validations: [],
          "Is Tracking Enabled": {
            value: true,
          },
          "Allow Save": {
            value: true,
          },
          "Value Provider Parameters": {
            value: "",
          },
          "Value Provider": null,
          "Collapsed by Default": {
            value: false,
          },
          "Field Type": {
            id: "e0cfadee-1ac0-471d-a820-2e70d1547b4b",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/lists/dropdown-list",
            fields: {
              BackgroundColor: {
                value: "Grass",
              },
              Icon: {
                value: "OfficeWhite/32x32/drop_down_list.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "9121d435-48b8-4649-9d13-03d680474fad",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/dropdownlist",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomDropDownListViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/DropdownList",
              },
            },
          },
          Conditions: {
            value: JSON.stringify(firstChildConditions),
          },
          "Field Key": {
            value: `REGIONFIRST`,
          },
        },
        items: [],
      };
      blockItems.push(firstChildDropdown);

      let childItems: any = [];
      block?.multi_select_block?.items?.forEach((item: any) => {
        const conditions = {
          fieldConditions: [
            {
              matchTypeId: "{365C94DA-C1CD-4783-A91D-0D17A16C7118}",
              conditions: [
                {
                  fieldId: "3769A8E2F8484912B8012DBDCE9A47CA",
                  operatorId: "{1D38B217-A2EE-4E7B-B6ED-13E751462FEB}",
                  value: item?.item_value,
                },
              ],
              actions: [
                {
                  fieldId: `REGION${item?.item_value.replace(/\s/g, "")}`,
                  actionTypeId: "{AAE07A52-46A4-49EF-98B0-C2595BAC2382}",
                  value: null,
                },
              ],
            },
          ],
        };
        // let conditions: any = { fieldConditions: [] };
        childItems = item?.sub_items?.map(({ item_key, item_value }: any) => {
          return {
            id: `276d2cc4-8bde-4e8d-9b88-55da1298bb14-${item_key}`,
            name: item_value,
            displayName: item_value,
            parentId: "bb339335-5ea1-4dac-a9da-35a04b0a0c46",
            fields: {
              value: {
                value: item_value,
              },
              key: {
                value: item_key,
              },
            },
          };
        });
        const childDropdown = {
          id: "dbda29bc-721f-4ba3-b2ff-8a7533b86936",
          name: "provinceDropdown1",
          displayName: "Province1",
          parentId: "05843f30-abb3-427b-a9c4-ccec95f1e45d",
          fields: {
            "Show Empty Item": {
              value: true,
            },
            isAutoSuggest: {
              value: block?.multi_select_block?.is_autocomplete,
            },
            "Empty Text": {
              value: block?.multi_select_block?.no_options_text,
            },
            Datasource: {
              id: "58f59e4e-2486-42c4-8ed7-0663f6709b55",
              fieldType: "Droptree",
              fieldName: "Datasource",
              url: "/content/forms/contacttimelist",
              fields: {},
            },
            "Default Selection": {
              value: "",
            },
            "Display Field Name": {
              value: "value",
            },
            "Is Dynamic": {
              value: true,
            },
            "Value Field Name": {
              value: "key",
            },
            "Css Class": {
              value: "block",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: "",
            },
            SpecificError: {
              value: "",
            },
            apiName: {
              id: "1e985703-c4e7-402d-a310-c17b2d90d381",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/municipality",
              fields: {
                value: {
                  value: "municipality",
                },
                key: {
                  value: "municipality",
                },
              },
            },
            "Label Css Class": {
              value: "",
            },
            Title: {
              value: block?.multi_select_block?.sub_placeholder,
            },
            Required: {
              value: false,
            },
            Validations: [],
            "Is Tracking Enabled": {
              value: true,
            },
            "Allow Save": {
              value: true,
            },
            "Value Provider Parameters": {
              value: "",
            },
            "Value Provider": null,
            "Collapsed by Default": {
              value: false,
            },
            "Field Type": {
              id: "e0cfadee-1ac0-471d-a820-2e70d1547b4b",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/lists/dropdown-list",
              fields: {
                BackgroundColor: {
                  value: "Grass",
                },
                Icon: {
                  value: "OfficeWhite/32x32/drop_down_list.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "9121d435-48b8-4649-9d13-03d680474fad",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/dropdownlist",
                  fields: {},
                },
                "Allowed Validations": [],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomDropDownListViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/DropdownList",
                },
              },
            },
            Conditions: {
              value: JSON.stringify(conditions),
            },
            "Field Key": {
              value: `REGION${item?.item_value.replace(/\s/g, "")}`,
            },
          },
          items: childItems || [],
        };
        blockItems.push(childDropdown);
      });

      blockItems.push(
        getEnquiryFieldProps(
          block?.multi_select_block,
          "Tooltip",
          "05843f30-abb3-427b-a9c4-ccec95f1e45d"
        ),
        {
          id: "6bdeb7a7-f163-11ec-8ea0-0242ac120002",
          name: "provinceButtonNextClick1",
          displayName: "provinceButtonNextClick1",
          parentId: "05843f30-abb3-427b-a9c4-ccec95f1e45d",
          fields: {
            "Navigation Step": {
              value: "1",
            },
            emailTemplateLink: {
              value: "",
            },
            "Css Class": {
              value: "next-button",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: "",
            },
            SpecificError: {
              value: "",
            },
            apiName: {
              id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
              fields: {
                value: {
                  value: "",
                },
                key: {
                  value: "",
                },
              },
            },
            "Label Css Class": {
              value: "",
            },
            Title: {
              value: enf?.nextButtonText,
            },
            "Field Type": {
              id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
              fields: {
                BackgroundColor: {
                  value: "Eggplant",
                },
                Icon: {
                  value: "OfficeWhite/32x32/button.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/button",
                  fields: {},
                },
                "Allowed Validations": [],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/Button",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: "B544A093CB984BB8AF1E0C9494DE48EF",
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: [
            {
              id: "a1ccd500-f163-11ec-8ea0-0242ac120002",
              name: "Redirect to Page",
              displayName: "Redirect to Page",
              parentId: "6bdeb7a6-f163-11ec-8ea0-0242ac120002",
              fields: {
                Description: {
                  value: "Phone Details",
                },
                Parameters: {
                  value: `{"referenceId":"{${nextStepInfos?.id?.toUpperCase()}}"}`,
                },
                "Submit Action": {
                  id: "3f3e2093-9dea-4199-86ca-44fc69ef624d",
                  fieldType: "Droptree",
                  fieldName: "Submit Action",
                  url: "/sitecore/system/settings/forms/submit-actions/redirect-to-page",
                  fields: {
                    Editor: null,
                    "Error Message": {
                      value: "Redirect to page failed!",
                    },
                    "Model Type": {
                      value:
                        "Sitecore.ExperienceForms.Processing.Actions.RedirectToPage",
                    },
                  },
                },
              },
              items: [],
            },
          ],
        }
      );

      const step = {
        id: constants?.multi_select_block_homeProvince?.id,
        name: "multiSelectPage",
        displayName: "MultiSelect Details",
        parentId: "57ec00df-f75a-4645-a4f1-a830be98de45",
        fields: {
          gaLabel: {
            value: "",
          },
          backgroundColor: {
            id: "cbdc6265-d09f-4982-981a-bcc8f118f36f",
            fieldType: "Droplink",
            fieldName: "backgroundColor",
            url: "/sitecore/content/fwd/global/settings/list/colors/whiteorange",
            fields: {
              value: {
                value: "white-orange",
              },
              key: {
                value: "WhiteOrange",
              },
            },
          },
          "Css Class": {
            value: `provincePage2 ${
              !block?.select_province_block?.fill_icon ? "conditionPage" : ""
            }`,
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: null,
          "Field Type": {
            id: "d819b43e-c136-4392-9393-8be7fce32f5e",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/page",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/window_split_ver.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "cfee7b51-8505-45ce-b843-9358f827df87",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/page",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomPageViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Page",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: "F46A6A39786642D2A743E145F488453A",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: blockItems || [],
      };
      enquirySteps.push(step);
    }

    if (
      block?.multi_select_block &&
      block?.multi_select_block?.field_name === "sbcArea"
    ) {
      const items =
        block?.multi_select_block?.items?.map(({ name, item_value }: any) => {
          return {
            id: generateUniqSerial(),
            name: name,
            displayName: name,
            parentId: constants?.multi_select_block_sbcArea?.id,
            fields: {
              value: {
                value: name,
              },
              key: {
                value: item_value,
              },
            },
          };
        }) || [];
      let blockItems: any = [
        {
          id: "79c5fa97-edab-419f-86f4-7a1844af969a",
          name: "Text",
          displayName: "Text",
          parentId: "05843f30-abb3-427b-a9c4-ccec95f1e45d",
          fields: {
            "Html Tag": {
              value: "p",
            },
            Text: {
              value: block?.multi_select_block?.prefix,
            },
            "Css Class": {
              value: "inline",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: "",
            },
            SpecificError: {
              value: "",
            },
            apiName: null,
            "Field Type": {
              id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/basic/text",
              fields: {
                BackgroundColor: {
                  value: "Sky",
                },
                Icon: {
                  value: "OfficeWhite/32x32/document_text.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/text",
                  fields: {},
                },
                "Allowed Validations": [],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/Text",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: "3FE63FA45349456B87D9EFA8F776D9FE",
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: [],
        },
        {
          id: "dbda29bc-721f-4ba3-b2ff-8a7533b86935",
          name: "provinceDropdown",
          displayName: "Province",
          parentId: "05843f30-abb3-427b-a9c4-ccec95f1e45d",
          fields: {
            "Show Empty Item": {
              value: true,
            },
            isAutoSuggest: {
              value: block?.multi_select_block?.is_autocomplete,
            },
            "Empty Text": {
              value: block?.multi_select_block?.no_options_text,
            },
            Datasource: {
              id: "58f59e4e-2486-42c4-8ed7-0663f6709b55",
              fieldType: "Droptree",
              fieldName: "Datasource",
              url: "/content/forms/contacttimelist",
              fields: {},
            },
            "Default Selection": {
              value: "",
            },
            "Display Field Name": {
              value: "value",
            },
            "Is Dynamic": {
              value: true,
            },
            "Value Field Name": {
              value: "key",
            },
            "Css Class": {
              value: "block",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: "",
            },
            SpecificError: {
              value: "",
            },
            apiName: {
              id: "1e985703-c4e7-402d-a310-c17b2d90d381",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/sbcArea",
              fields: {
                value: {
                  value: "sbcArea",
                },
                key: {
                  value: "sbcArea",
                },
              },
            },
            "Label Css Class": {
              value: "",
            },
            Title: {
              value: block?.multi_select_block?.placeholder,
            },
            Required: {
              value: true,
            },
            Validations: [],
            "Is Tracking Enabled": {
              value: true,
            },
            "Allow Save": {
              value: true,
            },
            "Value Provider Parameters": {
              value: "",
            },
            "Value Provider": null,
            "Collapsed by Default": {
              value: false,
            },
            "Field Type": {
              id: "e0cfadee-1ac0-471d-a820-2e70d1547b4b",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/lists/dropdown-list",
              fields: {
                BackgroundColor: {
                  value: "Grass",
                },
                Icon: {
                  value: "OfficeWhite/32x32/drop_down_list.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "9121d435-48b8-4649-9d13-03d680474fad",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/dropdownlist",
                  fields: {},
                },
                "Allowed Validations": [],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomDropDownListViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/DropdownList",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: `${constants?.multi_select_block_sbcArea?.fieldKey}`,
            },
          },
          items: items,
        },
      ];

      const firstChildConditions = {
        fieldConditions: [
          {
            matchTypeId: "{365C94DA-C1CD-4783-A91D-0D17A16C7118}",
            conditions: [
              {
                fieldId: `${constants?.multi_select_block_sbcArea?.fieldKey}`,
                operatorId: "{1D38B217-A2EE-4E7B-B6ED-13E751462FEB}",
                value: "",
              },
            ],
            actions: [
              {
                fieldId: `SCAREAFIRST`,
                actionTypeId: "{AAE07A52-46A4-49EF-98B0-C2595BAC2382}",
                value: null,
              },
            ],
          },
        ],
      };
      const firstChildDropdown = {
        id: "dbda29bc-721f-4ba3-b2ff-8a7533b86936",
        name: "provinceDropdown1",
        displayName: "Province1",
        parentId: "05843f30-abb3-427b-a9c4-ccec95f1e45d",
        fields: {
          "Show Empty Item": {
            value: true,
          },
          isAutoSuggest: {
            value: block?.multi_select_block?.is_autocomplete,
          },
          "Empty Text": {
            value: block?.multi_select_block?.no_options_text,
          },
          Datasource: {
            id: "58f59e4e-2486-42c4-8ed7-0663f6709b55",
            fieldType: "Droptree",
            fieldName: "Datasource",
            url: "/content/forms/contacttimelist",
            fields: {},
          },
          "Default Selection": {
            value: "",
          },
          "Display Field Name": {
            value: "value",
          },
          "Is Dynamic": {
            value: true,
          },
          "Value Field Name": {
            value: "key",
          },
          "Css Class": {
            value: "block",
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: {
            id: "1e985703-c4e7-402d-a310-c17b2d90d381",
            fieldType: "Droplink",
            fieldName: "apiName",
            url: "/sitecore/content/fwd/global/settings/list/form-api-fields/sbcBranch",
            fields: {
              value: {
                value: "sbcBranch",
              },
              key: {
                value: "sbcBranch",
              },
            },
          },
          "Label Css Class": {
            value: "",
          },
          Title: {
            value: block?.multi_select_block?.sub_placeholder,
          },
          Required: {
            value: false,
          },
          Validations: [],
          "Is Tracking Enabled": {
            value: true,
          },
          "Allow Save": {
            value: true,
          },
          "Value Provider Parameters": {
            value: "",
          },
          "Value Provider": null,
          "Collapsed by Default": {
            value: false,
          },
          "Field Type": {
            id: "e0cfadee-1ac0-471d-a820-2e70d1547b4b",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/lists/dropdown-list",
            fields: {
              BackgroundColor: {
                value: "Grass",
              },
              Icon: {
                value: "OfficeWhite/32x32/drop_down_list.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "9121d435-48b8-4649-9d13-03d680474fad",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/dropdownlist",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomDropDownListViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/DropdownList",
              },
            },
          },
          Conditions: {
            value: JSON.stringify(firstChildConditions),
          },
          "Field Key": {
            value: `SCAREAFIRST`,
          },
        },
        items: [],
      };
      blockItems.push(firstChildDropdown);

      let childItems: any = [];
      block?.multi_select_block?.items?.forEach((item: any) => {
        const conditions = {
          fieldConditions: [
            {
              matchTypeId: "{365C94DA-C1CD-4783-A91D-0D17A16C7119}",
              conditions: [
                {
                  fieldId: `${constants?.multi_select_block_sbcArea?.fieldKey}`,
                  operatorId: "{1D38B217-A2EE-4E7B-B6ED-13E751462FEB}",
                  value: item?.item_value,
                },
              ],
              actions: [
                {
                  fieldId: `SBCAREA${item?.item_value.replace(/\s/g, "")}`,
                  actionTypeId: "{AAE07A52-46A4-49EF-98B0-C2595BAC2382}",
                  value: null,
                },
              ],
            },
          ],
        };
        // let conditions: any = { fieldConditions: [] };
        childItems = item?.sub_items?.map(({ item_key, item_value }: any) => {
          return {
            id: `276d2cc4-8bde-4e8d-9b88-55da1298bb14-${item_key}`,
            name: item_value,
            displayName: item_value,
            parentId: "bb339335-5ea1-4dac-a9da-35a04b0a0c46",
            fields: {
              value: {
                value: item_value,
              },
              key: {
                value: item_key,
              },
            },
          };
        });
        const childDropdown = {
          id: "dbda29bc-721f-4ba3-b2ff-8a7533b86936",
          name: "provinceDropdown1",
          displayName: "Province1",
          parentId: "05843f30-abb3-427b-a9c4-ccec95f1e45d",
          fields: {
            "Show Empty Item": {
              value: true,
            },
            isAutoSuggest: {
              value: block?.multi_select_block?.is_autocomplete,
            },
            "Empty Text": {
              value: block?.multi_select_block?.no_options_text,
            },
            Datasource: {
              id: "58f59e4e-2486-42c4-8ed7-0663f6709b55",
              fieldType: "Droptree",
              fieldName: "Datasource",
              url: "/content/forms/contacttimelist",
              fields: {},
            },
            "Default Selection": {
              value: "",
            },
            "Display Field Name": {
              value: "value",
            },
            "Is Dynamic": {
              value: true,
            },
            "Value Field Name": {
              value: "key",
            },
            "Css Class": {
              value: "block",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: "",
            },
            SpecificError: {
              value: "",
            },
            apiName: {
              id: "1e985703-c4e7-402d-a310-c17b2d90d381",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/sbcBranch",
              fields: {
                value: {
                  value: "sbcBranch",
                },
                key: {
                  value: "sbcBranch",
                },
              },
            },
            "Label Css Class": {
              value: "",
            },
            Title: {
              value: block?.multi_select_block?.sub_placeholder,
            },
            Required: {
              value: false,
            },
            Validations: [],
            "Is Tracking Enabled": {
              value: true,
            },
            "Allow Save": {
              value: true,
            },
            "Value Provider Parameters": {
              value: "",
            },
            "Value Provider": null,
            "Collapsed by Default": {
              value: false,
            },
            "Field Type": {
              id: "e0cfadee-1ac0-471d-a820-2e70d1547b4b",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/lists/dropdown-list",
              fields: {
                BackgroundColor: {
                  value: "Grass",
                },
                Icon: {
                  value: "OfficeWhite/32x32/drop_down_list.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "9121d435-48b8-4649-9d13-03d680474fad",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/dropdownlist",
                  fields: {},
                },
                "Allowed Validations": [],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomDropDownListViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/DropdownList",
                },
              },
            },
            Conditions: {
              value: JSON.stringify(conditions),
            },
            "Field Key": {
              value: `SBCAREA${item?.item_value.replace(/\s/g, "")}`,
            },
          },
          items: childItems || [],
        };
        blockItems.push(childDropdown);
      });

      blockItems.push(
        getEnquiryFieldProps(
          block?.multi_select_block,
          "Tooltip",
          "05843f30-abb3-427b-a9c4-ccec95f1e45d"
        ),
        {
          id: "6bdeb7a7-f163-11ec-8ea0-0242ac120002",
          name: "provinceButtonNextClick1",
          displayName: "provinceButtonNextClick1",
          parentId: "05843f30-abb3-427b-a9c4-ccec95f1e45d",
          fields: {
            "Navigation Step": {
              value: "1",
            },
            emailTemplateLink: {
              value: "",
            },
            "Css Class": {
              value: "next-button",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: "",
            },
            SpecificError: {
              value: "",
            },
            apiName: {
              id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
              fields: {
                value: {
                  value: "",
                },
                key: {
                  value: "",
                },
              },
            },
            "Label Css Class": {
              value: "",
            },
            Title: {
              value: enf?.nextButtonText,
            },
            "Field Type": {
              id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
              fields: {
                BackgroundColor: {
                  value: "Eggplant",
                },
                Icon: {
                  value: "OfficeWhite/32x32/button.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/button",
                  fields: {},
                },
                "Allowed Validations": [],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/Button",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: "B544A093CB984BB8AF1E0C9494DE48EF",
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: [
            {
              id: "a1ccd500-f163-11ec-8ea0-0242ac120002",
              name: "Redirect to Page",
              displayName: "Redirect to Page",
              parentId: "6bdeb7a6-f163-11ec-8ea0-0242ac120002",
              fields: {
                Description: {
                  value: "Phone Details",
                },
                Parameters: {
                  value: `{"referenceId":"{${nextStepInfos?.id?.toUpperCase()}}"}`,
                },
                "Submit Action": {
                  id: "3f3e2093-9dea-4199-86ca-44fc69ef624d",
                  fieldType: "Droptree",
                  fieldName: "Submit Action",
                  url: "/sitecore/system/settings/forms/submit-actions/redirect-to-page",
                  fields: {
                    Editor: null,
                    "Error Message": {
                      value: "Redirect to page failed!",
                    },
                    "Model Type": {
                      value:
                        "Sitecore.ExperienceForms.Processing.Actions.RedirectToPage",
                    },
                  },
                },
              },
              items: [],
            },
          ],
        }
      );

      const step = {
        id: constants?.multi_select_block_sbcArea?.id,
        name: "multiSelectPage",
        displayName: "MultiSelect Details",
        parentId: "57ec00df-f75a-4645-a4f1-a830be98de45",
        fields: {
          gaLabel: {
            value: "",
          },
          backgroundColor: {
            id: "cbdc6265-d09f-4982-981a-bcc8f118f36f",
            fieldType: "Droplink",
            fieldName: "backgroundColor",
            url: "/sitecore/content/fwd/global/settings/list/colors/whiteorange",
            fields: {
              value: {
                value: "white-orange",
              },
              key: {
                value: "WhiteOrange",
              },
            },
          },
          "Css Class": {
            value: `provincePage2 ${
              !block?.select_province_block?.fill_icon ? "conditionPage" : ""
            }`,
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: null,
          "Field Type": {
            id: "d819b43e-c136-4392-9393-8be7fce32f5e",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/page",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/window_split_ver.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "cfee7b51-8505-45ce-b843-9358f827df87",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/page",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomPageViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Page",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: `9021ADBSA1ADASAKSJASIEAEHAKEHAAE`,
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: blockItems || [],
      };
      enquirySteps.push(step);
    }

    if (
      block?.multi_select_block &&
      ["recruitmentTalk", "recruitmentOpenDay"].indexOf(block?.multi_select_block?.field_name) >= 0
    ) {
      const nameValues = block?.multi_select_block?.items?.map(
        ({ item_value }: any) => {
          return {
            id: generateUniqSerial(),
            name: item_value,
            displayName: item_value,
            parentId: constants?.[`multi_select_block_${block?.multi_select_block?.field_name}`]?.id,
            fields: {
              value: {
                value: item_value,
              },
            },
            items: [],
          };
        }
      );
      let nextSteps: any = [];
      let conditions: any = { fieldConditions: [] };
      nextSteps.push({
        id: `24d892a6-ba06-4c0f-8389-6bb5ba0fc771${block?.multi_select_block?.field_name}`,
        name: `${block?.multi_select_block?.field_name}NextClick`,
        displayName: `${block?.multi_select_block?.field_name}NextClick`,
        parentId: constants?.[`multi_select_block_${block?.multi_select_block?.field_name}`]?.id,
        fields: {
          "Navigation Step": {
            value: "1",
          },
          emailTemplateLink: {
            value: "",
          },
          "Css Class": {
            value: "next-button",
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: {
            id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
            fieldType: "Droplink",
            fieldName: "apiName",
            url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
            fields: {
              value: {
                value: "",
              },
              key: {
                value: "",
              },
            },
          },
          "Label Css Class": {
            value: "",
          },
          Title: {
            value: enf?.nextButtonText,
          },
          "Field Type": {
            id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/button.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/button",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Button",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: "DFA430F198F44C0DA33CD16E1DB2232E",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [
          {
            id: "e4a2a602-5e65-4597-8c0e-d6c2a59f8e76",
            name: "Redirect to Page",
            displayName: "Redirect to Page",
            parentId: "57321773-013d-4531-98ce-6521b01a7fe6",
            fields: {
              Description: {
                value: "Phone Number",
              },
              Parameters: {
                value: `{"referenceId":"{${nextStepInfos?.id?.toUpperCase()}}"}`,
              },
              "Submit Action": {
                id: "3f3e2093-9dea-4199-86ca-44fc69ef624d",
                fieldType: "Droptree",
                fieldName: "Submit Action",
                url: "/sitecore/system/settings/forms/submit-actions/redirect-to-page",
                fields: {
                  Editor: null,
                  "Error Message": {
                    value: "Redirect to page failed!",
                  },
                  "Model Type": {
                    value:
                      "Sitecore.ExperienceForms.Processing.Actions.RedirectToPage",
                  },
                },
              },
            },
            items: [],
          },
        ],
      });

      const step = {
        id: constants?.[`multi_select_block_${block?.multi_select_block?.field_name}`]?.id,
        name: block?.multi_select_block?.field_name,
        displayName: `${block?.multi_select_block?.field_name}Page`,
        parentId: "05843f30-abb3-427b-a9c4-ccec95f1e45d",
        fields: {
          gaLabel: {
            value: "",
          },
          backgroundColor: {
            id: "cbdc6265-d09f-4982-981a-bcc8f118f36f",
            fieldType: "Droplink",
            fieldName: "backgroundColor",
            url: "/sitecore/content/fwd/global/settings/list/colors/whiteorange",
            fields: {
              value: {
                value: "white-orange",
              },
              key: {
                value: "WhiteOrange",
              },
            },
          },
          "Css Class": {
            value: `preferredContactModePage ${
              !block?.multi_select_block?.fill_icon ? "conditionPage" : ""
            }`,
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: null,
          "Field Type": {
            id: "d819b43e-c136-4392-9393-8be7fce32f5e",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/page",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/window_split_ver.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "cfee7b51-8505-45ce-b843-9358f827df87",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/page",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomPageViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Page",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: `4DF8F5E941DF48CC846122E6426D5147${block?.multi_select_block?.field_name}`,
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [
          {
            id: `2aaa89b7-a326-46a0-b73f-d028383ef493${block?.multi_select_block?.field_name}`,
            name: "Text",
            displayName: "Text",
            parentId: constants?.[`multi_select_block_${block?.multi_select_block?.field_name}`]?.id,
            fields: {
              "Html Tag": {
                value: "p",
              },
              Text: {
                value: block?.multi_select_block?.prefix,
              },
              "Css Class": {
                value: "block",
              },
              isHidden: {
                value: false,
              },
              HelpText: {
                value: "",
              },
              GeneralError: {
                value: "",
              },
              SpecificError: {
                value: "",
              },
              apiName: {
                id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
                fieldType: "Droplink",
                fieldName: "apiName",
                url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
                fields: {
                  value: {
                    value: "",
                  },
                  key: {
                    value: "",
                  },
                },
              },
              "Field Type": {
                id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
                fieldType: "Droptree",
                fieldName: "Field Type",
                url: "/sitecore/system/settings/forms/field-types/basic/text",
                fields: {
                  BackgroundColor: {
                    value: "Sky",
                  },
                  Icon: {
                    value: "OfficeWhite/32x32/document_text.png",
                  },
                  IsDisabled: {
                    value: false,
                  },
                  SpritePosition: {
                    value: "",
                  },
                  "Field Template": {
                    id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                    fieldType: "Droptree",
                    fieldName: "Field Template",
                    url: "/sitecore/templates/system/forms/fields/text",
                    fields: {},
                  },
                  "Allowed Validations": [],
                  "Model Type": {
                    value:
                      "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
                  },
                  "Property Editor": null,
                  "View Path": {
                    value: "FieldTemplates/Text",
                  },
                },
              },
              Conditions: {
                value: JSON.stringify(conditions),
              },
              "Field Key": {
                value: `2CEFA97A507747A183E4DB2D202E0B11${block?.multi_select_block?.field_name}`,
              },
              "Collapsed by Default": {
                value: false,
              },
            },
            items: [],
          },
          {
            id: "6947b485-f82f-456e-af03-d8779ef44eba",
            name: `${block?.multi_select_block?.field_name} Mode`,
            displayName: `${block?.multi_select_block?.field_name} Mode`,
            parentId: constants?.[`multi_select_block_${block?.multi_select_block?.field_name}`]?.id,
            fields: {
              Datasource: null,
              "Default Selection": {
                value: "",
              },
              "Display Field Name": {
                value: "__Display name",
              },
              "Is Dynamic": {
                value: false,
              },
              "Value Field Name": {
                value: "Value",
              },
              "Css Class": {
                value: "block",
              },
              isHidden: {
                value: false,
              },
              HelpText: {
                value: "",
              },
              GeneralError: {
                value: "",
              },
              SpecificError: {
                value: "",
              },
              apiName: {
                id: "50d505ca-38f7-420f-8f73-f221ef889e74",
                fieldType: "Droplink",
                fieldName: "apiName",
                url: `/sitecore/content/fwd/global/settings/list/form-api-fields/${block?.multi_select_block?.field_name}`,
                fields: {
                  value: {
                    value: block?.multi_select_block?.field_name,
                  },
                  key: {
                    value: block?.multi_select_block?.field_name,
                  },
                },
              },
              "Label Css Class": {
                value: "",
              },
              Title: {
                value: "",
              },
              Required: {
                value: true,
              },
              Validations: [],
              "Is Tracking Enabled": {
                value: true,
              },
              "Allow Save": {
                value: true,
              },
              "Value Provider Parameters": {
                value: "",
              },
              "Value Provider": null,
              "Field Type": {
                id: "edbd38a8-1ae9-42ec-8ccd-f5b0e2998b4f",
                fieldType: "Droptree",
                fieldName: "Field Type",
                url: "/sitecore/system/settings/forms/field-types/lists/radio-button-list",
                fields: {
                  BackgroundColor: {
                    value: "Grass",
                  },
                  Icon: {
                    value: "OfficeWhite/32x32/radio_button_group.png",
                  },
                  IsDisabled: {
                    value: false,
                  },
                  SpritePosition: {
                    value: "",
                  },
                  "Field Template": {
                    id: "5b672865-55d2-413e-b699-fdfc7e732ccf",
                    fieldType: "Droptree",
                    fieldName: "Field Template",
                    url: "/sitecore/templates/system/forms/fields/list",
                    fields: {},
                  },
                  "Allowed Validations": [],
                  "Model Type": {
                    value:
                      "FWD.Foundation.Forms.CustomRadioButtonListViewModel,FWD.Foundation.Forms",
                  },
                  "Property Editor": null,
                  "View Path": {
                    value: "FieldTemplates/RadioButtonList",
                  },
                },
              },
              Conditions: {
                value: JSON.stringify(conditions),
              },
              "Field Key": {
                value: constants?.[`multi_select_block_${block?.multi_select_block?.field_name}`]?.fieldKey,
              },
              "Collapsed by Default": {
                value: false,
              },
            },
            items: nameValues || [],
          },
          getEnquiryFieldProps(
            block?.multi_select_block,
            "Tooltip",
            constants?.[`multi_select_block_${block?.multi_select_block?.field_name}`]?.id
          ),
          ...nextSteps,
        ],
      };
      enquirySteps.push(step);
    }

    if (block?.dropdown && block?.dropdown?.field_name === "gender") {
      const items = block?.dropdown?.items?.map(
        ({ item_key, item_value }: any) => {
          return {
            id: generateUniqSerial(),
            name: item_value,
            displayName: item_value,
            parentId: "7221f6f3-19f5-42fc-9419-2e060f0adae3",
            fields: {
              value: {
                value: item_value,
              },
              key: {
                value: item_key,
              },
            },
          };
        }
      );

      const genderBlock = {
        id: constants?.dropdown_gender?.id,
        name: "genderPage",
        displayName: "Select Gender Page",
        parentId: "57ec00df-f75a-4645-a4f1-a830be98de45",
        fields: {
          gaLabel: {
            value: "",
          },
          backgroundColor: {
            id: "cbdc6265-d09f-4982-981a-bcc8f118f36f",
            fieldType: "Droplink",
            fieldName: "backgroundColor",
            url: "/sitecore/content/fwd/global/settings/list/colors/whiteorange",
            fields: {
              value: {
                value: "white-orange",
              },
              key: {
                value: "WhiteOrange",
              },
            },
          },
          "Css Class": {
            value: `provincePage`,
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: null,
          "Field Type": {
            id: "d819b43e-c136-4392-9393-8be7fce32f5e",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/page",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/window_split_ver.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "cfee7b51-8505-45ce-b843-9358f827df87",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/page",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomPageViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Page",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: "05B6B0BE40774539912EB6E4268008J9",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [
          {
            id: "79c5fa97-edab-419f-86f4-7a1844af969a",
            name: "Text",
            displayName: "Text",
            parentId: "05843f30-abb3-427b-a9c4-ccec95f1e45d",
            fields: {
              "Html Tag": {
                value: "p",
              },
              Text: {
                value: block?.dropdown?.label,
              },
              "Css Class": {
                value: "inline",
              },
              isHidden: {
                value: false,
              },
              HelpText: {
                value: "",
              },
              GeneralError: {
                value: "",
              },
              SpecificError: {
                value: "",
              },
              apiName: null,
              "Field Type": {
                id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
                fieldType: "Droptree",
                fieldName: "Field Type",
                url: "/sitecore/system/settings/forms/field-types/basic/text",
                fields: {
                  BackgroundColor: {
                    value: "Sky",
                  },
                  Icon: {
                    value: "OfficeWhite/32x32/document_text.png",
                  },
                  IsDisabled: {
                    value: false,
                  },
                  SpritePosition: {
                    value: "",
                  },
                  "Field Template": {
                    id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                    fieldType: "Droptree",
                    fieldName: "Field Template",
                    url: "/sitecore/templates/system/forms/fields/text",
                    fields: {},
                  },
                  "Allowed Validations": [],
                  "Model Type": {
                    value:
                      "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
                  },
                  "Property Editor": null,
                  "View Path": {
                    value: "FieldTemplates/Text",
                  },
                },
              },
              Conditions: {
                value: '{"fieldConditions":[]}',
              },
              "Field Key": {
                value: "3FE63FA45349456B88D9EFA8F776D9FE",
              },
              "Collapsed by Default": {
                value: false,
              },
            },
            items: [],
          },
          {
            id: "dbda29bc-721f-4ba3-b2ff-8a7533b86935",
            name: "genderDropdown",
            displayName: "Gender",
            parentId: "05843f30-abb3-427b-a9c4-ccec95f1e45d",
            fields: {
              "Show Empty Item": {
                value: true,
              },
              isAutoSuggest: {
                value: true,
              },
              "Empty Text": {
                value: block?.dropdown?.no_options_text || "",
              },
              Datasource: {
                id: "58f59e4e-2486-42c4-8ed7-0663f6709b55",
                fieldType: "Droptree",
                fieldName: "Datasource",
                url: "/content/forms/contacttimelist",
                fields: {},
              },
              "Default Selection": {
                value: "",
              },
              "Display Field Name": {
                value: "value",
              },
              "Is Dynamic": {
                value: true,
              },
              "Value Field Name": {
                value: "key",
              },
              "Css Class": {
                value: "inline",
              },
              isHidden: {
                value: false,
              },
              HelpText: {
                value: "",
              },
              GeneralError: {
                value: "",
              },
              SpecificError: {
                value: "",
              },
              apiName: {
                id: "1e985703-c4e7-402d-a310-c17b2d90d381",
                fieldType: "Droplink",
                fieldName: "apiName",
                url: "/sitecore/content/fwd/global/settings/list/form-api-fields/gender",
                fields: {
                  value: {
                    value: "gender",
                  },
                  key: {
                    value: "gender",
                  },
                },
              },
              "Label Css Class": {
                value: "",
              },
              Title: {
                value: block?.dropdown?.placeholder || "",
              },
              Required: {
                value: ["kh"].indexOf(countryCode) < 0,
              },
              Validations: [],
              "Is Tracking Enabled": {
                value: true,
              },
              "Allow Save": {
                value: true,
              },
              "Value Provider Parameters": {
                value: "",
              },
              "Value Provider": null,
              "Collapsed by Default": {
                value: false,
              },
              "Field Type": {
                id: "e0cfadee-1ac0-471d-a820-2e70d1547b4b",
                fieldType: "Droptree",
                fieldName: "Field Type",
                url: "/sitecore/system/settings/forms/field-types/lists/dropdown-list",
                fields: {
                  BackgroundColor: {
                    value: "Grass",
                  },
                  Icon: {
                    value: "OfficeWhite/32x32/drop_down_list.png",
                  },
                  IsDisabled: {
                    value: false,
                  },
                  SpritePosition: {
                    value: "",
                  },
                  "Field Template": {
                    id: "9121d435-48b8-4649-9d13-03d680474fad",
                    fieldType: "Droptree",
                    fieldName: "Field Template",
                    url: "/sitecore/templates/system/forms/fields/dropdownlist",
                    fields: {},
                  },
                  "Allowed Validations": [],
                  "Model Type": {
                    value:
                      "FWD.Foundation.Forms.CustomDropDownListViewModel,FWD.Foundation.Forms",
                  },
                  "Property Editor": null,
                  "View Path": {
                    value: "FieldTemplates/DropdownList",
                  },
                },
              },
              Conditions: {
                value: '{"fieldConditions":[]}',
              },
              "Field Key": {
                value: constants?.dropdown_gender?.fieldKey,
              },
            },
            items: items,
          },
          getEnquiryFieldProps(
            block?.dropdown,
            "Tooltip",
            "05843f30-abb3-427b-a9c4-ccec95f1e45d"
          ),
          {
            id: "6bdeb7a7-f163-11ec-8ea0-0242ac120002",
            name: "genderButtonNextClick",
            displayName: "genderButtonNextClick",
            parentId: "05843f30-abb3-427b-a9c4-ccec95f1e45d",
            fields: {
              "Navigation Step": {
                value: "1",
              },
              emailTemplateLink: {
                value: "",
              },
              "Css Class": {
                value: "next-button",
              },
              isHidden: {
                value: false,
              },
              HelpText: {
                value: "",
              },
              GeneralError: {
                value: "",
              },
              SpecificError: {
                value: "",
              },
              apiName: {
                id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
                fieldType: "Droplink",
                fieldName: "apiName",
                url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
                fields: {
                  value: {
                    value: "",
                  },
                  key: {
                    value: "",
                  },
                },
              },
              "Label Css Class": {
                value: "",
              },
              Title: {
                value: enf?.nextButtonText,
              },
              "Field Type": {
                id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
                fieldType: "Droptree",
                fieldName: "Field Type",
                url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
                fields: {
                  BackgroundColor: {
                    value: "Eggplant",
                  },
                  Icon: {
                    value: "OfficeWhite/32x32/button.png",
                  },
                  IsDisabled: {
                    value: false,
                  },
                  SpritePosition: {
                    value: "",
                  },
                  "Field Template": {
                    id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
                    fieldType: "Droptree",
                    fieldName: "Field Template",
                    url: "/sitecore/templates/system/forms/fields/button",
                    fields: {},
                  },
                  "Allowed Validations": [],
                  "Model Type": {
                    value:
                      "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
                  },
                  "Property Editor": null,
                  "View Path": {
                    value: "FieldTemplates/Button",
                  },
                },
              },
              Conditions: {
                value: '{"fieldConditions":[]}',
              },
              "Field Key": {
                value: "B544A093CB984BB8AF1E0C9494DE48MF",
              },
              "Collapsed by Default": {
                value: false,
              },
            },
            items: [
              {
                id: "a1ccd500-f163-11ec-8ea0-0242ac120002",
                name: "Redirect to Page",
                displayName: "Redirect to Page",
                parentId: "6bdeb7a6-f163-11ec-8ea0-0242ac120002",
                fields: {
                  Description: {
                    value: "Phone Details",
                  },
                  Parameters: {
                    value: `{"referenceId":"{${nextStepInfos?.id?.toUpperCase()}}"}`,
                  },
                  "Submit Action": {
                    id: "3f3e2093-9dea-4199-86ca-44fc69ef624d",
                    fieldType: "Droptree",
                    fieldName: "Submit Action",
                    url: "/sitecore/system/settings/forms/submit-actions/redirect-to-page",
                    fields: {
                      Editor: null,
                      "Error Message": {
                        value: "Redirect to page failed!",
                      },
                      "Model Type": {
                        value:
                          "Sitecore.ExperienceForms.Processing.Actions.RedirectToPage",
                      },
                    },
                  },
                },
                items: [],
              },
            ],
          },
        ],
      };
      enquirySteps.push(genderBlock);
    }

    if (block?.dropdown && block?.dropdown?.field_name === "workIn") {
      const items = block?.dropdown?.items?.map(
        ({ item_key, item_value }: any) => {
          return {
            id: generateUniqSerial(),
            name: item_value,
            displayName: item_value,
            parentId: "7221f6f3-19f5-42fc-9419-2e060f0adae3",
            fields: {
              value: {
                value: item_value,
              },
              key: {
                value: item_key,
              },
            },
          };
        }
      );

      const workInBlock = {
        id: constants?.dropdown_workIn?.id,
        name: "workInPage",
        displayName: "Select WorkIn Page",
        parentId: "57ec00df-f75a-4645-a4f1-a830be98de45",
        fields: {
          gaLabel: {
            value: "",
          },
          backgroundColor: {
            id: "cbdc6265-d09f-4982-981a-bcc8f118f36f",
            fieldType: "Droplink",
            fieldName: "backgroundColor",
            url: "/sitecore/content/fwd/global/settings/list/colors/whiteorange",
            fields: {
              value: {
                value: "white-orange",
              },
              key: {
                value: "WhiteOrange",
              },
            },
          },
          "Css Class": {
            value: `provincePage`,
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: null,
          "Field Type": {
            id: "d819b43e-c136-4392-9393-8be7fce32f5e",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/page",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/window_split_ver.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "cfee7b51-8505-45ce-b843-9358f827df87",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/page",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomPageViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Page",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: "05B6B0BE40774539912EB6E4268008J9",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [
          {
            id: "79c5fa97-edab-419f-86f4-7a1844af969a",
            name: "Text",
            displayName: "Text",
            parentId: "05843f30-abb3-427b-a9c4-ccec95f1e45d",
            fields: {
              "Html Tag": {
                value: "p",
              },
              Text: {
                value: block?.dropdown?.label,
              },
              "Css Class": {
                value: "inline",
              },
              isHidden: {
                value: false,
              },
              HelpText: {
                value: "",
              },
              GeneralError: {
                value: "",
              },
              SpecificError: {
                value: "",
              },
              apiName: null,
              "Field Type": {
                id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
                fieldType: "Droptree",
                fieldName: "Field Type",
                url: "/sitecore/system/settings/forms/field-types/basic/text",
                fields: {
                  BackgroundColor: {
                    value: "Sky",
                  },
                  Icon: {
                    value: "OfficeWhite/32x32/document_text.png",
                  },
                  IsDisabled: {
                    value: false,
                  },
                  SpritePosition: {
                    value: "",
                  },
                  "Field Template": {
                    id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                    fieldType: "Droptree",
                    fieldName: "Field Template",
                    url: "/sitecore/templates/system/forms/fields/text",
                    fields: {},
                  },
                  "Allowed Validations": [],
                  "Model Type": {
                    value:
                      "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
                  },
                  "Property Editor": null,
                  "View Path": {
                    value: "FieldTemplates/Text",
                  },
                },
              },
              Conditions: {
                value: '{"fieldConditions":[]}',
              },
              "Field Key": {
                value: "3FE63FA45349456B88D9EFA8F776D9FE",
              },
              "Collapsed by Default": {
                value: false,
              },
            },
            items: [],
          },
          {
            id: "dbda29bc-721f-4ba3-b2ff-8a7533b86935",
            name: "workInDropdown",
            displayName: "WorkIn",
            parentId: "05843f30-abb3-427b-a9c4-ccec95f1e45d",
            fields: {
              "Show Empty Item": {
                value: true,
              },
              isAutoSuggest: {
                value: true,
              },
              "Empty Text": {
                value: block?.dropdown?.no_options_text || "",
              },
              Datasource: {
                id: "58f59e4e-2486-42c4-8ed7-0663f6709b55",
                fieldType: "Droptree",
                fieldName: "Datasource",
                url: "/content/forms/contacttimelist",
                fields: {},
              },
              "Default Selection": {
                value: "",
              },
              "Display Field Name": {
                value: "value",
              },
              "Is Dynamic": {
                value: true,
              },
              "Value Field Name": {
                value: "key",
              },
              "Css Class": {
                value: "inline",
              },
              isHidden: {
                value: false,
              },
              HelpText: {
                value: "",
              },
              GeneralError: {
                value: "",
              },
              SpecificError: {
                value: "",
              },
              apiName: {
                id: "1e985703-c4e7-402d-a310-c17b2d90d381",
                fieldType: "Droplink",
                fieldName: "apiName",
                url: "/sitecore/content/fwd/global/settings/list/form-api-fields/workIn",
                fields: {
                  value: {
                    value: "workIn",
                  },
                  key: {
                    value: "workIn",
                  },
                },
              },
              "Label Css Class": {
                value: "",
              },
              Title: {
                value: block?.dropdown?.placeholder || "",
              },
              Required: {
                value: false,
              },
              Validations: [],
              "Is Tracking Enabled": {
                value: true,
              },
              "Allow Save": {
                value: true,
              },
              "Value Provider Parameters": {
                value: "",
              },
              "Value Provider": null,
              "Collapsed by Default": {
                value: false,
              },
              "Field Type": {
                id: "e0cfadee-1ac0-471d-a820-2e70d1547b4b",
                fieldType: "Droptree",
                fieldName: "Field Type",
                url: "/sitecore/system/settings/forms/field-types/lists/dropdown-list",
                fields: {
                  BackgroundColor: {
                    value: "Grass",
                  },
                  Icon: {
                    value: "OfficeWhite/32x32/drop_down_list.png",
                  },
                  IsDisabled: {
                    value: false,
                  },
                  SpritePosition: {
                    value: "",
                  },
                  "Field Template": {
                    id: "9121d435-48b8-4649-9d13-03d680474fad",
                    fieldType: "Droptree",
                    fieldName: "Field Template",
                    url: "/sitecore/templates/system/forms/fields/dropdownlist",
                    fields: {},
                  },
                  "Allowed Validations": [],
                  "Model Type": {
                    value:
                      "FWD.Foundation.Forms.CustomDropDownListViewModel,FWD.Foundation.Forms",
                  },
                  "Property Editor": null,
                  "View Path": {
                    value: "FieldTemplates/DropdownList",
                  },
                },
              },
              Conditions: {
                value: '{"fieldConditions":[]}',
              },
              "Field Key": {
                value: constants?.dropdown_workIn?.fieldKey,
              },
            },
            items: items,
          },
          getEnquiryFieldProps(
            block?.dropdown,
            "Tooltip",
            "05843f30-abb3-427b-a9c4-ccec95f1e45d"
          ),
          {
            id: "6bdeb7a7-f163-11ec-8ea0-0242ac120002",
            name: "workInButtonNextClick",
            displayName: "workInButtonNextClick",
            parentId: "05843f30-abb3-427b-a9c4-ccec95f1e45d",
            fields: {
              "Navigation Step": {
                value: "1",
              },
              emailTemplateLink: {
                value: "",
              },
              "Css Class": {
                value: "next-button",
              },
              isHidden: {
                value: false,
              },
              HelpText: {
                value: "",
              },
              GeneralError: {
                value: "",
              },
              SpecificError: {
                value: "",
              },
              apiName: {
                id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
                fieldType: "Droplink",
                fieldName: "apiName",
                url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
                fields: {
                  value: {
                    value: "",
                  },
                  key: {
                    value: "",
                  },
                },
              },
              "Label Css Class": {
                value: "",
              },
              Title: {
                value: enf?.nextButtonText,
              },
              "Field Type": {
                id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
                fieldType: "Droptree",
                fieldName: "Field Type",
                url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
                fields: {
                  BackgroundColor: {
                    value: "Eggplant",
                  },
                  Icon: {
                    value: "OfficeWhite/32x32/button.png",
                  },
                  IsDisabled: {
                    value: false,
                  },
                  SpritePosition: {
                    value: "",
                  },
                  "Field Template": {
                    id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
                    fieldType: "Droptree",
                    fieldName: "Field Template",
                    url: "/sitecore/templates/system/forms/fields/button",
                    fields: {},
                  },
                  "Allowed Validations": [],
                  "Model Type": {
                    value:
                      "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
                  },
                  "Property Editor": null,
                  "View Path": {
                    value: "FieldTemplates/Button",
                  },
                },
              },
              Conditions: {
                value: '{"fieldConditions":[]}',
              },
              "Field Key": {
                value: "B544A093CB984BB8AF1E0C9494DE48MF",
              },
              "Collapsed by Default": {
                value: false,
              },
            },
            items: [
              {
                id: "a1ccd500-f163-11ec-8ea0-0242ac120002",
                name: "Redirect to Page",
                displayName: "Redirect to Page",
                parentId: "6bdeb7a6-f163-11ec-8ea0-0242ac120002",
                fields: {
                  Description: {
                    value: "Phone Details",
                  },
                  Parameters: {
                    value: `{"referenceId":"{${nextStepInfos?.id?.toUpperCase()}}"}`,
                  },
                  "Submit Action": {
                    id: "3f3e2093-9dea-4199-86ca-44fc69ef624d",
                    fieldType: "Droptree",
                    fieldName: "Submit Action",
                    url: "/sitecore/system/settings/forms/submit-actions/redirect-to-page",
                    fields: {
                      Editor: null,
                      "Error Message": {
                        value: "Redirect to page failed!",
                      },
                      "Model Type": {
                        value:
                          "Sitecore.ExperienceForms.Processing.Actions.RedirectToPage",
                      },
                    },
                  },
                },
                items: [],
              },
            ],
          },
        ],
      };
      enquirySteps.push(workInBlock);
    }

    if (block?.dropdown && block?.dropdown?.field_name === "companyIndustry") {
      const items = block?.dropdown?.items?.map(
        ({ item_key, item_value }: any) => {
          return {
            id: generateUniqSerial(),
            name: item_value,
            displayName: item_value,
            parentId: "7221f6f3-19f5-42fc-9419-2e060f0adae3",
            fields: {
              value: {
                value: item_value,
              },
              key: {
                value: item_key,
              },
            },
          };
        }
      );
      let conditions: any = {
        fieldConditions: [
          {
            matchTypeId: "{365C94DA-C1CD-4783-A91D-0D17A16C7117}",
            conditions: [
              {
                fieldId: constants.dropdown_companyIndustry.fieldKey,
                operatorId: "{1D38B217-A2EE-4E7B-B6ED-13E751462FEB}",
                value: `Others`,
              },
            ],
            actions: [
              {
                fieldId: "B544A093CB984BB8AF1E0C9494DEIDEF",
                actionTypeId: "{AAE07A52-46A4-49EF-98B0-C2595BAC2382}",
                value: null,
              },
            ],
          },
          {
            matchTypeId: "{365C94DA-C1CD-4783-A91D-0D17A16C7117}",
            conditions: [
              {
                fieldId: constants.dropdown_companyIndustry.fieldKey,
                operatorId: "{49F47E77-E8C5-46F9-BF39-78D6B0D40B48}",
                value: `Others`,
              },
            ],
            actions: [
              {
                fieldId: "B544A093CB984BB8AF1E0D8494DE47NG",
                actionTypeId: "{AAE07A52-46A4-49EF-98B0-C2595BAC2382}",
                value: null,
              }
            ],
          },
        ],
      };

      const { strWithoutTag, subStr } = extractValueFromTag(block.dropdown?.label, "[NextButton]");
      const NormalNextButton = {
        id: "6bdeb7a7-f163-11ec-8ea0-0242ac120102",
        name: "companyIndustryButtonNextClick",
        displayName: "companyIndustryButtonNextClick",
        parentId: "05843f30-abb3-427b-a9c4-ccec95f1e45d",
        fields: {
          "Navigation Step": {
            value: "1",
          },
          emailTemplateLink: {
            value: "",
          },
          "Css Class": {
            value: "next-button",
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: {
            id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
            fieldType: "Droplink",
            fieldName: "apiName",
            url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
            fields: {
              value: {
                value: "",
              },
              key: {
                value: "",
              },
            },
          },
          "Label Css Class": {
            value: "",
          },
          Title: {
            value: subStr || enf?.nextButtonText,
          },
          "Field Type": {
            id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/button.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/button",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Button",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: "B544A093CB984BB8AF1E0D8494DE47NG",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [
          {
            id: "a1ccd500-f163-11ec-8ea0-0242ac120002",
            name: "Redirect to Page",
            displayName: "Redirect to Page",
            parentId: "6bdeb7a6-f163-11ec-8ea0-0242ac120002",
            fields: {
              Description: {
                value: "Phone Details",
              },
              Parameters: {
                value: `{"referenceId":"{${constants.text_field_specialisingIn.id?.toUpperCase()}}"}`,
              },
              "Submit Action": {
                id: "3f3e2093-9dea-4199-86ca-44fc69ef624d",
                fieldType: "Droptree",
                fieldName: "Submit Action",
                url: "/sitecore/system/settings/forms/submit-actions/redirect-to-page",
                fields: {
                  Editor: null,
                  "Error Message": {
                    value: "Redirect to page failed!",
                  },
                  "Model Type": {
                    value:
                      "Sitecore.ExperienceForms.Processing.Actions.RedirectToPage",
                  },
                },
              },
            },
            items: [],
          },
        ],
      };
      const OtherNextButton = {
        id: "6bdeb7a7-f163-11ec-8ea0-0242ac120102",
        name: "companyIndustryButtonNextClick",
        displayName: "companyIndustryButtonNextClick",
        parentId: "05843f30-abb3-427b-a9c4-ccec95f1e45d",
        fields: {
          "Navigation Step": {
            value: "1",
          },
          emailTemplateLink: {
            value: "",
          },
          "Css Class": {
            value: "next-button",
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: {
            id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
            fieldType: "Droplink",
            fieldName: "apiName",
            url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
            fields: {
              value: {
                value: "",
              },
              key: {
                value: "",
              },
            },
          },
          "Label Css Class": {
            value: "",
          },
          Title: {
            value: subStr || enf?.nextButtonText,
          },
          "Field Type": {
            id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/button.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/button",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Button",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: "B544A093CB984BB8AF1E0C9494DEIDEF",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [
          {
            id: "a1ccd500-f163-11ec-8ea0-0242ac120002",
            name: "Redirect to Page",
            displayName: "Redirect to Page",
            parentId: "6bdeb7a6-f163-11ec-8ea0-0242ac120002",
            fields: {
              Description: {
                value: "Phone Details",
              },
              Parameters: {
                value: `{"referenceId":"{${nextStepInfos?.id?.toUpperCase()}}"}`,
              },
              "Submit Action": {
                id: "3f3e2093-9dea-4199-86ca-44fc69ef624d",
                fieldType: "Droptree",
                fieldName: "Submit Action",
                url: "/sitecore/system/settings/forms/submit-actions/redirect-to-page",
                fields: {
                  Editor: null,
                  "Error Message": {
                    value: "Redirect to page failed!",
                  },
                  "Model Type": {
                    value:
                      "Sitecore.ExperienceForms.Processing.Actions.RedirectToPage",
                  },
                },
              },
            },
            items: [],
          },
        ],
      };
      const nextItems = [NormalNextButton, OtherNextButton];
      const companyIndustryBlock = {
        id: constants.dropdown_companyIndustry.id,
        name: "companyIndustryPage",
        displayName: "companyIndustryPage",
        parentId: "57ec00df-f75a-4645-a4f1-a830be98de45",
        fields: {
          gaLabel: {
            value: "",
          },
          backgroundColor: {
            id: "cbdc6265-d09f-4982-981a-bcc8f118f36f",
            fieldType: "Droplink",
            fieldName: "backgroundColor",
            url: "/sitecore/content/fwd/global/settings/list/colors/whiteorange",
            fields: {
              value: {
                value: "white-orange",
              },
              key: {
                value: "WhiteOrange",
              },
            },
          },
          "Css Class": {
            value: `provincePage`,
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: null,
          "Field Type": {
            id: "d819b43e-c136-4392-9393-8be7fce32f5e",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/page",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/window_split_ver.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "cfee7b51-8505-45ce-b843-9358f827df87",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/page",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomPageViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Page",
              },
            },
          },
          Conditions: {
            value: JSON.stringify(conditions),
          },
          "Field Key": {
            value: "05B6B0BE40774539912EB6E4268008201",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [
          {
            id: "79c5fa97-edab-419f-86f4-7a1844af96124",
            name: "Text",
            displayName: "Text",
            parentId: "05843f30-abb3-427b-a9c4-ccec95f1e45d",
            fields: {
              "Html Tag": {
                value: "p",
              },
              Text: {
                value: strWithoutTag,
              },
              "Css Class": {
                value: "inline",
              },
              isHidden: {
                value: false,
              },
              HelpText: {
                value: "",
              },
              GeneralError: {
                value: "",
              },
              SpecificError: {
                value: "",
              },
              apiName: null,
              "Field Type": {
                id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
                fieldType: "Droptree",
                fieldName: "Field Type",
                url: "/sitecore/system/settings/forms/field-types/basic/text",
                fields: {
                  BackgroundColor: {
                    value: "Sky",
                  },
                  Icon: {
                    value: "OfficeWhite/32x32/document_text.png",
                  },
                  IsDisabled: {
                    value: false,
                  },
                  SpritePosition: {
                    value: "",
                  },
                  "Field Template": {
                    id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                    fieldType: "Droptree",
                    fieldName: "Field Template",
                    url: "/sitecore/templates/system/forms/fields/text",
                    fields: {},
                  },
                  "Allowed Validations": [],
                  "Model Type": {
                    value:
                      "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
                  },
                  "Property Editor": null,
                  "View Path": {
                    value: "FieldTemplates/Text",
                  },
                },
              },
              Conditions: {
                value: '{"fieldConditions":[]}',
              },
              "Field Key": {
                value: "3FE63FA45349456B88D9EFA8F7762ANFE",
              },
              "Collapsed by Default": {
                value: false,
              },
            },
            items: [],
          },
          {
            id: "dbda29bc-721f-4ba3-b2ff-8a7533b312925",
            name: "companyIndustryDropdown",
            displayName: "companyIndustry",
            parentId: "05843f30-abb3-427b-a9c4-ccec95f1e45d",
            fields: {
              "Show Empty Item": {
                value: true,
              },
              isAutoSuggest: {
                value: true,
              },
              "Empty Text": {
                value: block?.dropdown?.no_options_text || "",
              },
              Datasource: {
                id: "58f59e4e-2486-42c4-8ed7-0663f6709b55",
                fieldType: "Droptree",
                fieldName: "Datasource",
                url: "/content/forms/contacttimelist",
                fields: {},
              },
              "Default Selection": {
                value: "",
              },
              "Display Field Name": {
                value: "value",
              },
              "Is Dynamic": {
                value: true,
              },
              "Value Field Name": {
                value: "key",
              },
              "Css Class": {
                value: "inline",
              },
              isHidden: {
                value: false,
              },
              HelpText: {
                value: "",
              },
              GeneralError: {
                value: "",
              },
              SpecificError: {
                value: "",
              },
              apiName: {
                id: "1e985703-c4e7-402d-a310-c17b2d90d381",
                fieldType: "Droplink",
                fieldName: "apiName",
                url: "/sitecore/content/fwd/global/settings/list/form-api-fields/companyIndustry",
                fields: {
                  value: {
                    value: "companyIndustry",
                  },
                  key: {
                    value: "companyIndustry",
                  },
                },
              },
              "Label Css Class": {
                value: "",
              },
              Title: {
                value: block?.dropdown?.placeholder || "",
              },
              Required: {
                value: false,
              },
              Validations: [],
              "Is Tracking Enabled": {
                value: true,
              },
              "Allow Save": {
                value: true,
              },
              "Value Provider Parameters": {
                value: "",
              },
              "Value Provider": null,
              "Collapsed by Default": {
                value: false,
              },
              "Field Type": {
                id: "e0cfadee-1ac0-471d-a820-2e70d1547b4b",
                fieldType: "Droptree",
                fieldName: "Field Type",
                url: "/sitecore/system/settings/forms/field-types/lists/dropdown-list",
                fields: {
                  BackgroundColor: {
                    value: "Grass",
                  },
                  Icon: {
                    value: "OfficeWhite/32x32/drop_down_list.png",
                  },
                  IsDisabled: {
                    value: false,
                  },
                  SpritePosition: {
                    value: "",
                  },
                  "Field Template": {
                    id: "9121d435-48b8-4649-9d13-03d680474fad",
                    fieldType: "Droptree",
                    fieldName: "Field Template",
                    url: "/sitecore/templates/system/forms/fields/dropdownlist",
                    fields: {},
                  },
                  "Allowed Validations": [],
                  "Model Type": {
                    value:
                      "FWD.Foundation.Forms.CustomDropDownListViewModel,FWD.Foundation.Forms",
                  },
                  "Property Editor": null,
                  "View Path": {
                    value: "FieldTemplates/DropdownList",
                  },
                },
              },
              Conditions: {
                value: '{"fieldConditions":[]}',
              },
              "Field Key": {
                value: constants.dropdown_companyIndustry.fieldKey,
              },
            },
            items: items,
          },
          getEnquiryFieldProps(
            block?.dropdown,
            "Tooltip",
            "05843f30-abb3-427b-a9c4-ccec95f1e109d"
          ),
          ...nextItems
        ],
      };
      enquirySteps.push(companyIndustryBlock);
    }

    if (block?.dropdown && block?.dropdown?.field_name === "companyEmployees") {
      const items = block?.dropdown?.items?.map(
        ({ item_key, item_value }: any) => {
          return {
            id: generateUniqSerial(),
            name: item_value,
            displayName: item_value,
            parentId: "7221f6f3-19f5-42fc-9419-2e060f0adae3",
            fields: {
              value: {
                value: item_value,
              },
              key: {
                value: item_key,
              },
            },
          };
        }
      );
      const companyEmployeesBlock = {
        id: constants.dropdown_companyEmployees.id,
        name: "companyEmployeesPage",
        displayName: "companyEmployeesPage",
        parentId: "57ec00df-f75a-4645-a4f1-a830be98de45",
        fields: {
          gaLabel: {
            value: "",
          },
          backgroundColor: {
            id: "cbdc6265-d09f-4982-981a-bcc8f118f36f",
            fieldType: "Droplink",
            fieldName: "backgroundColor",
            url: "/sitecore/content/fwd/global/settings/list/colors/whiteorange",
            fields: {
              value: {
                value: "white-orange",
              },
              key: {
                value: "WhiteOrange",
              },
            },
          },
          "Css Class": {
            value: `provincePage`,
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: null,
          "Field Type": {
            id: "d819b43e-c136-4392-9393-8be7fce32f5e",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/page",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/window_split_ver.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "cfee7b51-8505-45ce-b843-9358f827df87",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/page",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomPageViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Page",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: "05B6B0BE40774539912EB6E4268008201",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [
          {
            id: "79c5fa97-edab-419f-86f4-7a1844af96124",
            name: "Text",
            displayName: "Text",
            parentId: "05843f30-abb3-427b-a9c4-ccec95f1e45d",
            fields: {
              "Html Tag": {
                value: "p",
              },
              Text: {
                value: block?.dropdown?.label,
              },
              "Css Class": {
                value: "inline",
              },
              isHidden: {
                value: false,
              },
              HelpText: {
                value: "",
              },
              GeneralError: {
                value: "",
              },
              SpecificError: {
                value: "",
              },
              apiName: null,
              "Field Type": {
                id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
                fieldType: "Droptree",
                fieldName: "Field Type",
                url: "/sitecore/system/settings/forms/field-types/basic/text",
                fields: {
                  BackgroundColor: {
                    value: "Sky",
                  },
                  Icon: {
                    value: "OfficeWhite/32x32/document_text.png",
                  },
                  IsDisabled: {
                    value: false,
                  },
                  SpritePosition: {
                    value: "",
                  },
                  "Field Template": {
                    id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                    fieldType: "Droptree",
                    fieldName: "Field Template",
                    url: "/sitecore/templates/system/forms/fields/text",
                    fields: {},
                  },
                  "Allowed Validations": [],
                  "Model Type": {
                    value:
                      "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
                  },
                  "Property Editor": null,
                  "View Path": {
                    value: "FieldTemplates/Text",
                  },
                },
              },
              Conditions: {
                value: '{"fieldConditions":[]}',
              },
              "Field Key": {
                value: "3FE63FA45349456B88D9EFA8F7762ANFE",
              },
              "Collapsed by Default": {
                value: false,
              },
            },
            items: [],
          },
          {
            id: "dbda29bc-721f-4ba3-b2ff-8a7533b312925",
            name: "companyEmployeesDropdown",
            displayName: "companyEmployees",
            parentId: "05843f30-abb3-427b-a9c4-ccec95f1e45d",
            fields: {
              "Show Empty Item": {
                value: true,
              },
              isAutoSuggest: {
                value: true,
              },
              "Empty Text": {
                value: block?.dropdown?.no_options_text || "",
              },
              Datasource: {
                id: "58f59e4e-2486-42c4-8ed7-0663f6709b55",
                fieldType: "Droptree",
                fieldName: "Datasource",
                url: "/content/forms/contacttimelist",
                fields: {},
              },
              "Default Selection": {
                value: "",
              },
              "Display Field Name": {
                value: "value",
              },
              "Is Dynamic": {
                value: true,
              },
              "Value Field Name": {
                value: "key",
              },
              "Css Class": {
                value: "inline",
              },
              isHidden: {
                value: false,
              },
              HelpText: {
                value: "",
              },
              GeneralError: {
                value: "",
              },
              SpecificError: {
                value: "",
              },
              apiName: {
                id: "1e985703-c4e7-402d-a310-c17b2d90d381",
                fieldType: "Droplink",
                fieldName: "apiName",
                url: "/sitecore/content/fwd/global/settings/list/form-api-fields/companyEmployees",
                fields: {
                  value: {
                    value: "companyEmployees",
                  },
                  key: {
                    value: "companyEmployees",
                  },
                },
              },
              "Label Css Class": {
                value: "",
              },
              Title: {
                value: block?.dropdown?.placeholder || "",
              },
              Required: {
                value: false,
              },
              Validations: [],
              "Is Tracking Enabled": {
                value: true,
              },
              "Allow Save": {
                value: true,
              },
              "Value Provider Parameters": {
                value: "",
              },
              "Value Provider": null,
              "Collapsed by Default": {
                value: false,
              },
              "Field Type": {
                id: "e0cfadee-1ac0-471d-a820-2e70d1547b4b",
                fieldType: "Droptree",
                fieldName: "Field Type",
                url: "/sitecore/system/settings/forms/field-types/lists/dropdown-list",
                fields: {
                  BackgroundColor: {
                    value: "Grass",
                  },
                  Icon: {
                    value: "OfficeWhite/32x32/drop_down_list.png",
                  },
                  IsDisabled: {
                    value: false,
                  },
                  SpritePosition: {
                    value: "",
                  },
                  "Field Template": {
                    id: "9121d435-48b8-4649-9d13-03d680474fad",
                    fieldType: "Droptree",
                    fieldName: "Field Template",
                    url: "/sitecore/templates/system/forms/fields/dropdownlist",
                    fields: {},
                  },
                  "Allowed Validations": [],
                  "Model Type": {
                    value:
                      "FWD.Foundation.Forms.CustomDropDownListViewModel,FWD.Foundation.Forms",
                  },
                  "Property Editor": null,
                  "View Path": {
                    value: "FieldTemplates/DropdownList",
                  },
                },
              },
              Conditions: {
                value: '{"fieldConditions":[]}',
              },
              "Field Key": {
                value: constants.dropdown_companyEmployees.fieldKey,
              },
            },
            items: items,
          },
          getEnquiryFieldProps(
            block?.dropdown,
            "Tooltip",
            "05843f30-abb3-427b-a9c4-ccec95f1e109d"
          ),
          {
            id: "6bdeb7a7-f163-11ec-8ea0-0242ac120102",
            name: "companyEmployeesButtonNextClick",
            displayName: "companyEmployeesButtonNextClick",
            parentId: "05843f30-abb3-427b-a9c4-ccec95f1e45d",
            fields: {
              "Navigation Step": {
                value: "1",
              },
              emailTemplateLink: {
                value: "",
              },
              "Css Class": {
                value: "next-button",
              },
              isHidden: {
                value: false,
              },
              HelpText: {
                value: "",
              },
              GeneralError: {
                value: "",
              },
              SpecificError: {
                value: "",
              },
              apiName: {
                id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
                fieldType: "Droplink",
                fieldName: "apiName",
                url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
                fields: {
                  value: {
                    value: "",
                  },
                  key: {
                    value: "",
                  },
                },
              },
              "Label Css Class": {
                value: "",
              },
              Title: {
                value: enf?.nextButtonText,
              },
              "Field Type": {
                id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
                fieldType: "Droptree",
                fieldName: "Field Type",
                url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
                fields: {
                  BackgroundColor: {
                    value: "Eggplant",
                  },
                  Icon: {
                    value: "OfficeWhite/32x32/button.png",
                  },
                  IsDisabled: {
                    value: false,
                  },
                  SpritePosition: {
                    value: "",
                  },
                  "Field Template": {
                    id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
                    fieldType: "Droptree",
                    fieldName: "Field Template",
                    url: "/sitecore/templates/system/forms/fields/button",
                    fields: {},
                  },
                  "Allowed Validations": [],
                  "Model Type": {
                    value:
                      "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
                  },
                  "Property Editor": null,
                  "View Path": {
                    value: "FieldTemplates/Button",
                  },
                },
              },
              Conditions: {
                value: '{"fieldConditions":[]}',
              },
              "Field Key": {
                value: "B544A093CB984BB8AF1E0C9494DE48MF",
              },
              "Collapsed by Default": {
                value: false,
              },
            },
            items: [
              {
                id: "a1ccd500-f163-11ec-8ea0-0242ac120002",
                name: "Redirect to Page",
                displayName: "Redirect to Page",
                parentId: "6bdeb7a6-f163-11ec-8ea0-0242ac120002",
                fields: {
                  Description: {
                    value: "Phone Details",
                  },
                  Parameters: {
                    value: `{"referenceId":"{${nextStepInfos?.id?.toUpperCase()}}"}`,
                  },
                  "Submit Action": {
                    id: "3f3e2093-9dea-4199-86ca-44fc69ef624d",
                    fieldType: "Droptree",
                    fieldName: "Submit Action",
                    url: "/sitecore/system/settings/forms/submit-actions/redirect-to-page",
                    fields: {
                      Editor: null,
                      "Error Message": {
                        value: "Redirect to page failed!",
                      },
                      "Model Type": {
                        value:
                          "Sitecore.ExperienceForms.Processing.Actions.RedirectToPage",
                      },
                    },
                  },
                },
                items: [],
              },
            ],
          },
        ],
      };
      enquirySteps.push(companyEmployeesBlock);
    }

    if (block?.text_field && block?.text_field?.field_name === "workAt") {
      let conditions: any = { fieldConditions: [] };

      let blockItems = [
        {
          id: "4f3881e9-622b-4d4d-bbb9-e176d3db9873",
          name: "Text",
          displayName: "Text",
          parentId: "13ff3a7b-7607-4878-b7ad-5a86579bddab",
          fields: {
            "Html Tag": {
              value: "h5",
            },
            Text: {
              value: block?.text_field?.label,
            },
            "Css Class": {
              value: "inline",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: "",
            },
            SpecificError: {
              value: "",
            },
            apiName: {
              id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
              fields: {
                value: {
                  value: "",
                },
                key: {
                  value: "",
                },
              },
            },
            "Field Type": {
              id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/basic/text",
              fields: {
                BackgroundColor: {
                  value: "Sky",
                },
                Icon: {
                  value: "OfficeWhite/32x32/document_text.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/text",
                  fields: {},
                },
                "Allowed Validations": [],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/Text",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: "68525ADA341A44E4A149A8B8DFECA243",
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: [],
        },
        {
          id: "aedc3ff6-7b8a-40f7-9e24-205b977df0fa",
          name: "WorkAt",
          displayName: "WorkAt",
          parentId: "13ff3a7b-7607-4878-b7ad-5a86579bddab",
          fields: {
            "Default Value": {
              value: "",
            },
            "Max Length": {
              value: block?.text_field?.max_length || "2",
            },
            "Min Length": {
              value: "",
            },
            "Placeholder Text": {
              value:
                block?.text_field?.placeholder || block?.text_field?.sub_label,
            },
            dialInCodeLength: {
              id: "924aee12-9c96-42e0-8548-8378e251a8c7",
              fieldType: "Droplink",
              fieldName: "dialInCodeLength",
              url: "/sitecore/content/fwd/global/settings/list/formdiallingcodelength/zero",
              fields: {
                value: {
                  value: "0",
                },
                key: {
                  value: "zero",
                },
              },
            },
            "Css Class": {
              value: "inline telephoneInput",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: block?.text_field?.validation_message,
            },
            SpecificError: {
              value: "Please input an 8-digit phone number",
            },
            apiName: {
              id: "22259656-b6e9-4f74-993e-4578dbcf0741",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/workAt",
              fields: {
                value: {
                  value: "workAt",
                },
                key: {
                  value: "workAt",
                },
              },
            },
            "Label Css Class": {
              value: "",
            },
            Title: {
              value: "",
            },
            Required: {
              value: false,
            },
            Validations: [
              {
                id: "e61ea5ab-77bb-4278-9b13-eac1c30e8e0e",
                fields: {
                  Message: {
                    value: block?.text_field?.validation_rules?.message,
                  },
                  Parameters: {
                    value: block?.text_field?.validation_rules?.regex,
                  },
                  Type: {
                    value:
                      "FWD.Foundation.Forms.Validators.CustomRegularExpressionValidation,FWD.Foundation.Forms",
                  },
                },
              },
            ],
            "Is Tracking Enabled": {
              value: true,
            },
            "Allow Save": {
              value: true,
            },
            "Value Provider Parameters": {
              value: "",
            },
            "Value Provider": null,
            "Field Type": {
              id: "4ee89ea7-cefe-4c8e-8532-467ef64591fc",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/basic/single-line-text",
              fields: {
                BackgroundColor: {
                  value: "Sky",
                },
                Icon: {
                  value: "OfficeWhite/32x32/text_field.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "0908030b-4564-42ea-a6fa-c7a5a2d921a8",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/input",
                  fields: {},
                },
                "Allowed Validations": [
                  {
                    id: "83e96d09-11c8-4132-8a8a-76d8db8208d6",
                    fields: {
                      Message: {
                        value:
                          "{0} must have at least {1} and no more than {2} characters.",
                      },
                      Parameters: {
                        value: "",
                      },
                      Type: {
                        value:
                          "Sitecore.ExperienceForms.Mvc.Models.Validation.StringLengthValidation,Sitecore.ExperienceForms.Mvc",
                      },
                    },
                  },
                ],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomSingleLineViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/SingleLineText",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: constants.text_field_workAt.fieldKey,
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: [],
        },
        getEnquiryFieldProps(
          block?.text_field,
          "Tooltip",
          "13ff3a7b-7607-4878-b7ad-5a86579bddab"
        ),
        {
          id: "7803d6ed-0383-4199-9b8c-bb7ed47e3d25",
          name: "defaultPhoneNextButton",
          displayName: "Next Button Default",
          parentId: "13ff3a7b-7607-4878-b7ad-5a86579bddab",
          fields: {
            "Navigation Step": {
              value: "1",
            },
            emailTemplateLink: {
              value: "",
            },
            "Css Class": {
              value: "next-button",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: "",
            },
            SpecificError: {
              value: "",
            },
            apiName: {
              id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
              fields: {
                value: {
                  value: "",
                },
                key: {
                  value: "",
                },
              },
            },
            "Label Css Class": {
              value: "",
            },
            Title: {
              value: enf?.nextButtonText,
            },
            "Field Type": {
              id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
              fields: {
                BackgroundColor: {
                  value: "Eggplant",
                },
                Icon: {
                  value: "OfficeWhite/32x32/button.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/button",
                  fields: {},
                },
                "Allowed Validations": [],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/Button",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: "7BB73BAD1EEC4F309AB7046C2FFF2ED2",
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: [
            {
              id: "784ca9a9-9fb1-48a9-a396-a86487b1ffd3",
              name: "Redirect to Page",
              displayName: "Redirect to Page",
              parentId: "7803d6ed-0383-4199-9b8c-bb7ed47e3d25",
              fields: {
                Description: {
                  value: "Preferred Contact Mode",
                },
                Parameters: {
                  value: `{"referenceId":"{${nextStepInfos?.id?.toUpperCase()}}"}`,
                },
                "Submit Action": {
                  id: "3f3e2093-9dea-4199-86ca-44fc69ef624d",
                  fieldType: "Droptree",
                  fieldName: "Submit Action",
                  url: "/sitecore/system/settings/forms/submit-actions/redirect-to-page",
                  fields: {
                    Editor: null,
                    "Error Message": {
                      value: "Redirect to page failed!",
                    },
                    "Model Type": {
                      value:
                        "Sitecore.ExperienceForms.Processing.Actions.RedirectToPage",
                    },
                  },
                },
              },
              items: [],
            },
          ],
        },
      ];
      const workAtBlock = {
        id: constants.text_field_workAt.id,
        name: "workAtPage",
        displayName: "workAtPage",
        parentId: "57ec00df-f75a-4645-a4f1-a830be98de45",
        fields: {
          gaLabel: {
            value: "",
          },
          backgroundColor: {
            id: "cbdc6265-d09f-4982-981a-bcc8f118f36f",
            fieldType: "Droplink",
            fieldName: "backgroundColor",
            url: "/sitecore/content/fwd/global/settings/list/colors/whiteorange",
            fields: {
              value: {
                value: "white-orange",
              },
              key: {
                value: "WhiteOrange",
              },
            },
          },
          "Css Class": {
            value: `phonePage`,
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: null,
          "Field Type": {
            id: "d819b43e-c136-4392-9393-8be7fce32f5e",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/page",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/window_split_ver.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "cfee7b51-8505-45ce-b843-9358f827df87",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/page",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomPageViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Page",
              },
            },
          },
          Conditions: {
            value: JSON.stringify(conditions),
          },
          "Field Key": {
            value: "A822D936CE184893B07F20E4B97E9BDEA",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: blockItems,
      };
      enquirySteps.push(workAtBlock);
    }

    if (block?.text_field && block?.text_field?.field_name === "specialisingIn") {
      let conditions: any = { fieldConditions: [] };

      let blockItems = [
        {
          id: "4f3881e9-622b-4d4d-bbb9-e176d3db9873",
          name: "Text",
          displayName: "Text",
          parentId: "13ff3a7b-7607-4878-b7ad-5a86579bddab",
          fields: {
            "Html Tag": {
              value: "h5",
            },
            Text: {
              value: block?.text_field?.label,
            },
            "Css Class": {
              value: "inline",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: "",
            },
            SpecificError: {
              value: "",
            },
            apiName: {
              id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
              fields: {
                value: {
                  value: "",
                },
                key: {
                  value: "",
                },
              },
            },
            "Field Type": {
              id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/basic/text",
              fields: {
                BackgroundColor: {
                  value: "Sky",
                },
                Icon: {
                  value: "OfficeWhite/32x32/document_text.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/text",
                  fields: {},
                },
                "Allowed Validations": [],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/Text",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: "68525ADA341A44E4A149A8B8DFECA243",
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: [],
        },
        {
          id: "aedc3ff6-7b8a-40f7-9e24-205b977df0fa",
          name: "SpecialisingIn",
          displayName: "SpecialisingIn",
          parentId: "13ff3a7b-7607-4878-b7ad-5a86579bddab",
          fields: {
            "Default Value": {
              value: "",
            },
            "Max Length": {
              value: block?.text_field?.max_length || "2",
            },
            "Min Length": {
              value: "",
            },
            "Placeholder Text": {
              value:
                block?.text_field?.placeholder || block?.text_field?.sub_label,
            },
            dialInCodeLength: {
              id: "924aee12-9c96-42e0-8548-8378e251a8c7",
              fieldType: "Droplink",
              fieldName: "dialInCodeLength",
              url: "/sitecore/content/fwd/global/settings/list/formdiallingcodelength/zero",
              fields: {
                value: {
                  value: "0",
                },
                key: {
                  value: "zero",
                },
              },
            },
            "Css Class": {
              value: "inline telephoneInput",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: block?.text_field?.validation_message,
            },
            SpecificError: {
              value: "Please input an 8-digit phone number",
            },
            apiName: {
              id: "22259656-b6e9-4f74-993e-4578dbcf0741",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/specialisingIn",
              fields: {
                value: {
                  value: "specialisingIn",
                },
                key: {
                  value: "specialisingIn",
                },
              },
            },
            "Label Css Class": {
              value: "",
            },
            Title: {
              value: "",
            },
            Required: {
              value: false,
            },
            Validations: [
              {
                id: "e61ea5ab-77bb-4278-9b13-eac1c30e8e0e",
                fields: {
                  Message: {
                    value: block?.text_field?.validation_rules?.message,
                  },
                  Parameters: {
                    value: block?.text_field?.validation_rules?.regex,
                  },
                  Type: {
                    value:
                      "FWD.Foundation.Forms.Validators.CustomRegularExpressionValidation,FWD.Foundation.Forms",
                  },
                },
              },
            ],
            "Is Tracking Enabled": {
              value: true,
            },
            "Allow Save": {
              value: true,
            },
            "Value Provider Parameters": {
              value: "",
            },
            "Value Provider": null,
            "Field Type": {
              id: "4ee89ea7-cefe-4c8e-8532-467ef64591fc",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/basic/single-line-text",
              fields: {
                BackgroundColor: {
                  value: "Sky",
                },
                Icon: {
                  value: "OfficeWhite/32x32/text_field.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "0908030b-4564-42ea-a6fa-c7a5a2d921a8",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/input",
                  fields: {},
                },
                "Allowed Validations": [
                  {
                    id: "83e96d09-11c8-4132-8a8a-76d8db8208d6",
                    fields: {
                      Message: {
                        value:
                          "{0} must have at least {1} and no more than {2} characters.",
                      },
                      Parameters: {
                        value: "",
                      },
                      Type: {
                        value:
                          "Sitecore.ExperienceForms.Mvc.Models.Validation.StringLengthValidation,Sitecore.ExperienceForms.Mvc",
                      },
                    },
                  },
                ],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomSingleLineViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/SingleLineText",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: constants.text_field_specialisingIn.fieldKey,
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: [],
        },
        getEnquiryFieldProps(
          block?.text_field,
          "Tooltip",
          "13ff3a7b-7607-4878-b7ad-5a86579bddab"
        ),
        {
          id: "7803d6ed-0383-4199-9b8c-bb7ed47e3d25",
          name: "defaultPhoneNextButton",
          displayName: "Next Button Default",
          parentId: "13ff3a7b-7607-4878-b7ad-5a86579bddab",
          fields: {
            "Navigation Step": {
              value: "1",
            },
            emailTemplateLink: {
              value: "",
            },
            "Css Class": {
              value: "next-button",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: "",
            },
            SpecificError: {
              value: "",
            },
            apiName: {
              id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
              fields: {
                value: {
                  value: "",
                },
                key: {
                  value: "",
                },
              },
            },
            "Label Css Class": {
              value: "",
            },
            Title: {
              value: enf?.nextButtonText,
            },
            "Field Type": {
              id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
              fields: {
                BackgroundColor: {
                  value: "Eggplant",
                },
                Icon: {
                  value: "OfficeWhite/32x32/button.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/button",
                  fields: {},
                },
                "Allowed Validations": [],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/Button",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: "7BB73BAD1EEC4F309AB7046C2FFF2ED2",
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: [
            {
              id: "784ca9a9-9fb1-48a9-a396-a86487b1ffd3",
              name: "Redirect to Page",
              displayName: "Redirect to Page",
              parentId: "7803d6ed-0383-4199-9b8c-bb7ed47e3d25",
              fields: {
                Description: {
                  value: "Preferred Contact Mode",
                },
                Parameters: {
                  value: `{"referenceId":"{${nextStepInfos?.id?.toUpperCase()}}"}`,
                },
                "Submit Action": {
                  id: "3f3e2093-9dea-4199-86ca-44fc69ef624d",
                  fieldType: "Droptree",
                  fieldName: "Submit Action",
                  url: "/sitecore/system/settings/forms/submit-actions/redirect-to-page",
                  fields: {
                    Editor: null,
                    "Error Message": {
                      value: "Redirect to page failed!",
                    },
                    "Model Type": {
                      value:
                        "Sitecore.ExperienceForms.Processing.Actions.RedirectToPage",
                    },
                  },
                },
              },
              items: [],
            },
          ],
        },
      ];

      const specialisingInBlock = {
        id: constants.text_field_specialisingIn.id,
        name: "specialisingInPage",
        displayName: "specialisingInPage",
        parentId: "57ec00df-f75a-4645-a4f1-a830be98de45",
        fields: {
          gaLabel: {
            value: "",
          },
          backgroundColor: {
            id: "cbdc6265-d09f-4982-981a-bcc8f118f36f",
            fieldType: "Droplink",
            fieldName: "backgroundColor",
            url: "/sitecore/content/fwd/global/settings/list/colors/whiteorange",
            fields: {
              value: {
                value: "white-orange",
              },
              key: {
                value: "WhiteOrange",
              },
            },
          },
          "Css Class": {
            value: `phonePage`,
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: null,
          "Field Type": {
            id: "d819b43e-c136-4392-9393-8be7fce32f5e",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/page",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/window_split_ver.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "cfee7b51-8505-45ce-b843-9358f827df87",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/page",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomPageViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Page",
              },
            },
          },
          Conditions: {
            value: JSON.stringify(conditions),
          },
          "Field Key": {
            value: "A822D936CE184893B07F20E4B97E9BDEA",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: blockItems,
      };
      enquirySteps.push(specialisingInBlock);
    }

    if (block?.text_field && block?.text_field?.field_name === "referralCodeAgentCodeValue") {
      let conditions: any = { fieldConditions: [] };
      let blockItems = [
        {
          id: "4f3881e9-622b-4d4d-bbb9-referralCodeAgentCodeValue",
          name: "Text",
          displayName: "Text",
          parentId: "13ff3a7b-7607-4878-b7ad-5a86579bddab",
          fields: {
            "Html Tag": {
              value: "h5",
            },
            Text: {
              value: block?.text_field?.label,
            },
            "Css Class": {
              value: "inline",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: "",
            },
            SpecificError: {
              value: "",
            },
            apiName: {
              id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
              fields: {
                value: {
                  value: "",
                },
                key: {
                  value: "",
                },
              },
            },
            "Field Type": {
              id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/basic/text",
              fields: {
                BackgroundColor: {
                  value: "Sky",
                },
                Icon: {
                  value: "OfficeWhite/32x32/document_text.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/text",
                  fields: {},
                },
                "Allowed Validations": [],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/Text",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: "68525ADA341A44E4A149A8B8DFECA243",
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: [],
        },
        {
          id: "aedc3ff6-7b8a-40f7-9e24-205b977df0fa",
          name: "WorkAt",
          displayName: "WorkAt",
          parentId: "13ff3a7b-7607-4878-b7ad-5a86579bddab",
          fields: {
            "Default Value": {
              value: "",
            },
            "Max Length": {
              value: block?.text_field?.max_length || "2",
            },
            "Min Length": {
              value: "",
            },
            "Placeholder Text": {
              value:
                block?.text_field?.placeholder || block?.text_field?.sub_label,
            },
            dialInCodeLength: {
              id: "924aee12-9c96-42e0-8548-8378e251a8c7",
              fieldType: "Droplink",
              fieldName: "dialInCodeLength",
              url: "/sitecore/content/fwd/global/settings/list/formdiallingcodelength/zero",
              fields: {
                value: {
                  value: "0",
                },
                key: {
                  value: "zero",
                },
              },
            },
            "Css Class": {
              value: "block",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: block?.text_field?.validation_message,
            },
            SpecificError: {
              value: "Please input an 8-digit phone number",
            },
            apiName: {
              id: "22259656-b6e9-4f74-993e-4578dbcf0741",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/referralCode",
              fields: {
                value: {
                  value: "referralCode",
                },
                key: {
                  value: "referralCode",
                },
              },
            },
            "Label Css Class": {
              value: "",
            },
            Title: {
              value: "",
            },
            Required: {
              value: false,
            },
            Validations: [
              {
                id: "e61ea5ab-77bb-4278-9b13-eac1c30e8e0e",
                fields: {
                  Message: {
                    value: block?.text_field?.validation_rules?.message,
                  },
                  Parameters: {
                    value: block?.text_field?.validation_rules?.regex,
                  },
                  Type: {
                    value:
                      "FWD.Foundation.Forms.Validators.CustomRegularExpressionValidation,FWD.Foundation.Forms",
                  },
                },
              },
            ],
            "Is Tracking Enabled": {
              value: true,
            },
            "Allow Save": {
              value: true,
            },
            "Value Provider Parameters": {
              value: "",
            },
            "Value Provider": null,
            "Field Type": {
              id: "4ee89ea7-cefe-4c8e-8532-467ef64591fc",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/basic/single-line-text",
              fields: {
                BackgroundColor: {
                  value: "Sky",
                },
                Icon: {
                  value: "OfficeWhite/32x32/text_field.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "0908030b-4564-42ea-a6fa-c7a5a2d921a8",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/input",
                  fields: {},
                },
                "Allowed Validations": [
                  {
                    id: "83e96d09-11c8-4132-8a8a-76d8db8208d6",
                    fields: {
                      Message: {
                        value:
                          "{0} must have at least {1} and no more than {2} characters.",
                      },
                      Parameters: {
                        value: "",
                      },
                      Type: {
                        value:
                          "Sitecore.ExperienceForms.Mvc.Models.Validation.StringLengthValidation,Sitecore.ExperienceForms.Mvc",
                      },
                    },
                  },
                ],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomSingleLineViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/SingleLineText",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: constants.text_field_referralCodeAgentCodeValue.fieldKey,
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: [],
        },
        getEnquiryFieldProps(
          block?.text_field,
          "Tooltip",
          "13ff3a7b-7607-4878-b7ad-5a86579bddab"
        ),
        {
          id: "7803d6ed-0383-4199-9b8c-bb7ed47e3d25",
          name: "defaultPhoneNextButton",
          displayName: "Next Button Default",
          parentId: "13ff3a7b-7607-4878-b7ad-5a86579bddab",
          fields: {
            "Navigation Step": {
              value: "1",
            },
            emailTemplateLink: {
              value: "",
            },
            "Css Class": {
              value: "next-button",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: "",
            },
            SpecificError: {
              value: "",
            },
            apiName: {
              id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
              fields: {
                value: {
                  value: "",
                },
                key: {
                  value: "",
                },
              },
            },
            "Label Css Class": {
              value: "",
            },
            Title: {
              value: enf?.nextButtonText,
            },
            "Field Type": {
              id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
              fields: {
                BackgroundColor: {
                  value: "Eggplant",
                },
                Icon: {
                  value: "OfficeWhite/32x32/button.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/button",
                  fields: {},
                },
                "Allowed Validations": [],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/Button",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: "7BB73BAD1EEC4F309AB7046C2FFF2ED2",
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: [
            {
              id: "784ca9a9-9fb1-48a9-a396-a86487b1ffd3",
              name: "Redirect to Page",
              displayName: "Redirect to Page",
              parentId: "7803d6ed-0383-4199-9b8c-bb7ed47e3d25",
              fields: {
                Description: {
                  value: "Preferred Contact Mode",
                },
                Parameters: {
                  value: `{"referenceId":"{2420A361-FF7F-48F4-AEA8-860BCBD6431EC}"}`,
                },
                "Submit Action": {
                  id: "3f3e2093-9dea-4199-86ca-44fc69ef624d",
                  fieldType: "Droptree",
                  fieldName: "Submit Action",
                  url: "/sitecore/system/settings/forms/submit-actions/redirect-to-page",
                  fields: {
                    Editor: null,
                    "Error Message": {
                      value: "Redirect to page failed!",
                    },
                    "Model Type": {
                      value:
                        "Sitecore.ExperienceForms.Processing.Actions.RedirectToPage",
                    },
                  },
                },
              },
              items: [],
            },
          ],
        },
      ];
      const referralCodeBlock = {
        id: constants.text_field_referralCodeAgentCodeValue.id,
        name: "referralCodeAgentCodeValuePage",
        displayName: "referralCodeAgentCodeValuePage",
        parentId: "57ec00df-f75a-4645-a4f1-a830be98de45",
        fields: {
          gaLabel: {
            value: "",
          },
          backgroundColor: {
            id: "cbdc6265-d09f-4982-981a-bcc8f118f36f",
            fieldType: "Droplink",
            fieldName: "backgroundColor",
            url: "/sitecore/content/fwd/global/settings/list/colors/whiteorange",
            fields: {
              value: {
                value: "white-orange",
              },
              key: {
                value: "WhiteOrange",
              },
            },
          },
          "Css Class": {
            value: `phonePage`,
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: null,
          "Field Type": {
            id: "d819b43e-c136-4392-9393-8be7fce32f5e",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/page",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/window_split_ver.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "cfee7b51-8505-45ce-b843-9358f827df87",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/page",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomPageViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Page",
              },
            },
          },
          Conditions: {
            value: JSON.stringify(conditions),
          },
          "Field Key": {
            value: "A822D936CE184894D07F20E4B97218DEA",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: blockItems,
      };
      enquirySteps.push(referralCodeBlock);

      const privacyPolicy: any = {
        id: "8e8add7a-6528-42e9-9deb-9e6c2f2fbc2g",
        name: "PrivacyPolicySection",
        displayName: "PrivacyPolicySection",
        parentId: "57ec00df-f75a-4645-a4f1-a830be98de45",
        fields: {
          backgroundColor: {
            id: "cf8cf9a4-0f70-4db2-b298-90e820aca94b",
            fieldType: "Droplink",
            fieldName: "backgroundColor",
            url: `/sitecore/content/fwd/global/settings/list/colors/${consentBlockBgColor}white`,
            fields: {
              value: {
                value: `${consentBlockBgColor}-white`,
              },
              key: {
                value: `${consentBlockBgColor}-white`,
              },
            },
          },
          "Css Class": {
            value: "consentSection consentSectionActive",
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: null,
          "Field Type": {
            id: "447aa745-6d29-4b65-a5a3-8173aa8af548",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/section",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/document_header.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "8cddb194-f456-4a75-89b7-346f8f39f95c",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/section",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "Sitecore.ExperienceForms.Mvc.Models.Fields.FieldViewModel,Sitecore.ExperienceForms.Mvc",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Section",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: "618B4717BC7C4EE3A524060982247E5D",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [
          {
            id: "65f6aef0-e025-4012-848b-4b220f070767",
            name: "privacyPolicy",
            displayName: "privacyPolicy",
            parentId: "8e8add7a-6528-42e9-9deb-9e6c2f2fbc2g",
            fields: {
              Datasource: {
                id: "cb493c1e-b699-414f-af4a-91f3c01c76e2",
                fieldType: "Droptree",
                fieldName: "Datasource",
                url: "/content/forms/privacypolicy",
                fields: {},
              },
              "Default Selection": {
                value: "",
              },
              "Display Field Name": {
                value: "value",
              },
              "Is Dynamic": {
                value: true,
              },
              "Value Field Name": {
                value: "value",
              },
              "Css Class": {
                value: "block privacyPolicyCheckbox activeCheckbox",
              },
              isHidden: {
                value: false,
              },
              HelpText: {
                value: "",
              },
              GeneralError: {
                value: consentBlock?.validation_message,
              },
              SpecificError: {
                value: "",
              },
              apiName: {
                id: "2b066ac3-4cbd-41c7-8e19-c26ab07eccc1",
                fieldType: "Droplink",
                fieldName: "apiName",
                url: "/sitecore/content/fwd/global/settings/list/form-api-fields/consentcheckbox",
                fields: {
                  value: {
                    value: "consentCheckBox",
                  },
                  key: {
                    value: "consentCheckBox",
                  },
                },
              },
              "Label Css Class": {
                value: "",
              },
              Title: {
                value: "",
              },
              Required: {
                value: true,
              },
              Validations: [],
              "Is Tracking Enabled": {
                value: true,
              },
              "Allow Save": {
                value: true,
              },
              "Value Provider Parameters": {
                value: "",
              },
              "Value Provider": null,
              "Field Type": {
                id: "d86a361a-d4ff-46b2-9e97-a37fc5b1fe1a",
                fieldType: "Droptree",
                fieldName: "Field Type",
                url: "/sitecore/system/settings/forms/field-types/lists/checkbox-list",
                fields: {
                  BackgroundColor: {
                    value: "Grass",
                  },
                  Icon: {
                    value: "OfficeWhite/32x32/checkbox_group.png",
                  },
                  IsDisabled: {
                    value: false,
                  },
                  SpritePosition: {
                    value: "",
                  },
                  "Field Template": {
                    id: "5b672865-55d2-413e-b699-fdfc7e732ccf",
                    fieldType: "Droptree",
                    fieldName: "Field Template",
                    url: "/sitecore/templates/system/forms/fields/list",
                    fields: {},
                  },
                  "Allowed Validations": [],
                  "Model Type": {
                    value:
                      "FWD.Foundation.Forms.CustomCheckBoxListViewModel,FWD.Foundation.Forms",
                  },
                  "Property Editor": null,
                  "View Path": {
                    value: "FieldTemplates/CheckBoxList",
                  },
                },
              },
              Conditions: {
                value: '{"fieldConditions":[]}',
              },
              "Field Key": {
                value: "C470CC60AF974C7AADFF19FFAF9BF37D",
              },
              "Collapsed by Default": {
                value: false,
              },
            },
            items: [
              {
                id: "5728cfb1-3ddb-4eb6-8ae1-c125c26aae08",
                name: "privacy-policy",
                displayName: "Privacy Policy",
                parentId: "cb493c1e-b699-414f-af4a-91f3c01c76e2",
                fields: {
                  value: {
                    value: resolveAbsoluteUrlInParagraph(
                      consentBlock?.content,
                      i18nContext
                    ),
                  },
                },
              },
            ],
          },
        ],
      };
      const submitButton: any = {
        id: submitBtnId,
        name: "phoneOptionSubmitClick",
        displayName: "phoneOptionSubmitClick",
        parentId: "2344a106-ff60-4cbf-ac9c-c3f0994b0e83",
        fields: {
          "Navigation Step": {
            value: "0",
          },
          emailTemplateLink: {
            value: "",
          },
          "Css Class": {
            value: "submitButton",
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: {
            id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
            fieldType: "Droplink",
            fieldName: "apiName",
            url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
            fields: {
              value: {
                value: "",
              },
              key: {
                value: "",
              },
            },
          },
          "Label Css Class": {
            value: "",
          },
          Title: {
            value: consentBlock?.submit_button_text,
          },
          "Field Type": {
            id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/button.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/button",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Button",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: "4268BDA3502A42B5ABFB35917297516C",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [
          {
            id: "20907e35-2797-495f-aa4a-3164b7afeedb",
            name: "Send Email",
            displayName: "Send Email",
            parentId: submitBtnId,
            fields: {
              Description: {
                value: "",
              },
              Parameters: {
                value: "",
              },
              "Submit Action": {
                id: "2f66e378-be9d-4d9d-b43b-fc6ddc0e3db5",
                fieldType: "Droptree",
                fieldName: "Submit Action",
                url: "/sitecore/system/settings/forms/submit-actions/custom-action",
                fields: {
                  Editor: null,
                  "Error Message": {
                    value: "Saving form data failed!",
                  },
                  "Model Type": {
                    value:
                      "Sitecore.ExperienceForms.Processing.Actions.SaveData",
                  },
                },
              },
            },
            items: [],
          },
          {
            id: "e4a2a602-5e65-4597-8c0e-d6c2a59f8e76",
            name: "Redirect to Page",
            displayName: "Redirect to Page",
            parentId: "57321773-013d-4531-98ce-6521b01a7fe6",
            fields: {
              Description: {
                value: "Phone Number",
              },
              Parameters: {
                value: `{"referenceId":"{3627D278-2067-4AA3-831E-496E0AF1BD30}"}`,
              },
              "Submit Action": {
                id: "3f3e2093-9dea-4199-86ca-44fc69ef624d",
                fieldType: "Droptree",
                fieldName: "Submit Action",
                url: "/sitecore/system/settings/forms/submit-actions/redirect-to-page",
                fields: {
                  Editor: null,
                  "Error Message": {
                    value: "Redirect to page failed!",
                  },
                  "Model Type": {
                    value:
                      "Sitecore.ExperienceForms.Processing.Actions.RedirectToPage",
                  },
                },
              },
            },
            items: [],
          },
        ],
      };

      const lastStep = {
        id: "2420a361-ff7f-48f4-aea8-860bcbd6431ec",
        name: "privacyPolicyPage",
        displayName: "privacyPolicyPage",
        parentId: "57ec00df-f75a-4645-a4f1-a830be98de45",
        fields: {
          gaLabel: {
            value: "",
          },
          backgroundColor: {
            id: "cbdc6265-d09f-4982-981a-bcc8f118f36f",
            fieldType: "Droplink",
            fieldName: "backgroundColor",
            url: "/sitecore/content/fwd/global/settings/list/colors/whiteorange",
            fields: {
              value: {
                value: "white-orange",
              },
              key: {
                value: "WhiteOrange",
              },
            },
          },
          "Css Class": {
            value: `defaultVisibleContent conditionPage`,
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: null,
          "Field Type": {
            id: "d819b43e-c136-4392-9393-8be7fce32f5e",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/page",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/window_split_ver.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "cfee7b51-8505-45ce-b843-9358f827df87",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/page",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomPageViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Page",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: "4DF8F5E941DF48CC8461572E6426D5147",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [privacyPolicy, submitButton],
      };
      enquirySteps.push(lastStep);
    }

    if (block?.text_field && block?.text_field?.field_name === "age") {
      let conditions: any = { fieldConditions: [] };

      let blockItems = [
        {
          id: "4f3881e9-622b-4d4d-bbb9-e176d3db9873",
          name: "Text",
          displayName: "Text",
          parentId: "13ff3a7b-7607-4878-b7ad-5a86579bddab",
          fields: {
            "Html Tag": {
              value: "h5",
            },
            Text: {
              value: block?.text_field?.label,
            },
            "Css Class": {
              value: "inline",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: "",
            },
            SpecificError: {
              value: "",
            },
            apiName: {
              id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
              fields: {
                value: {
                  value: "",
                },
                key: {
                  value: "",
                },
              },
            },
            "Field Type": {
              id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/basic/text",
              fields: {
                BackgroundColor: {
                  value: "Sky",
                },
                Icon: {
                  value: "OfficeWhite/32x32/document_text.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/text",
                  fields: {},
                },
                "Allowed Validations": [],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/Text",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: "68525ADA341A44E4A149A8B8DFECA243",
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: [],
        },
        {
          id: "aedc3ff6-7b8a-40f7-9e24-205b977df0fa",
          name: "Age",
          displayName: "Age",
          parentId: "13ff3a7b-7607-4878-b7ad-5a86579bddab",
          fields: {
            "Default Value": {
              value: "",
            },
            "Max Length": {
              value: block?.text_field?.max_length || "2",
            },
            "Min Length": {
              value: "",
            },
            "Placeholder Text": {
              value:
                block?.text_field?.placeholder || block?.text_field?.sub_label,
            },
            dialInCodeLength: {
              id: "924aee12-9c96-42e0-8548-8378e251a8c7",
              fieldType: "Droplink",
              fieldName: "dialInCodeLength",
              url: "/sitecore/content/fwd/global/settings/list/formdiallingcodelength/zero",
              fields: {
                value: {
                  value: "0",
                },
                key: {
                  value: "zero",
                },
              },
            },
            "Css Class": {
              value: "inline telephoneInput",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: block?.text_field?.validation_message,
            },
            SpecificError: {
              value: "Please input an 8-digit phone number",
            },
            apiName: {
              id: "22259656-b6e9-4f74-993e-4578dbcf0741",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/age",
              fields: {
                value: {
                  value: "age",
                },
                key: {
                  value: "age",
                },
              },
            },
            "Label Css Class": {
              value: "",
            },
            Title: {
              value: "",
            },
            Required: {
              value: true,
            },
            Validations: [
              {
                id: "e61ea5ab-77bb-4278-9b13-eac1c30e8e0e",
                fields: {
                  Message: {
                    value: block?.text_field?.validation_rules?.message,
                  },
                  Parameters: {
                    value: block?.text_field?.validation_rules?.regex,
                  },
                  Type: {
                    value:
                      "FWD.Foundation.Forms.Validators.CustomRegularExpressionValidation,FWD.Foundation.Forms",
                  },
                },
              },
            ],
            "Is Tracking Enabled": {
              value: true,
            },
            "Allow Save": {
              value: true,
            },
            "Value Provider Parameters": {
              value: "",
            },
            "Value Provider": null,
            "Field Type": {
              id: "df74f55b-47e6-4d1c-92f8-b0d46a7b2704",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/basic/telephone",
              fields: {
                BackgroundColor: {
                  value: "Sky",
                },
                Icon: {
                  value: "OfficeWhite/32x32/mobile_phone.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "0908030b-4564-42ea-a6fa-c7a5a2d921a8",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/input",
                  fields: {},
                },
                "Allowed Validations": [
                  {
                    id: "1aa74652-2177-48c9-88f5-1214445fea34",
                    fields: {
                      Message: {
                        value: "Please input 9 to 10 digits phone number.",
                      },
                      Parameters: {
                        value: "//^d +$//",
                      },
                      Type: {
                        value:
                          "FWD.Foundation.Forms.Validators.CustomRegularExpressionValidation,FWD.Foundation.Forms",
                      },
                    },
                  },
                  {
                    id: "83e96d09-11c8-4132-8a8a-76d8db8208d6",
                    fields: {
                      Message: {
                        value:
                          "{0} must have at least {1} and no more than {2} characters.",
                      },
                      Parameters: {
                        value: "",
                      },
                      Type: {
                        value:
                          "Sitecore.ExperienceForms.Mvc.Models.Validation.StringLengthValidation,Sitecore.ExperienceForms.Mvc",
                      },
                    },
                  },
                ],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomSingleLineViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/Telephone",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: "4799D38182DD4823B332E218187C23A1",
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: [],
        },
        getEnquiryFieldProps(
          block?.text_field,
          "Tooltip",
          "13ff3a7b-7607-4878-b7ad-5a86579bddab"
        ),
        {
          id: "7803d6ed-0383-4199-9b8c-bb7ed47e3d25",
          name: "defaultPhoneNextButton",
          displayName: "Next Button Default",
          parentId: "13ff3a7b-7607-4878-b7ad-5a86579bddab",
          fields: {
            "Navigation Step": {
              value: "1",
            },
            emailTemplateLink: {
              value: "",
            },
            "Css Class": {
              value: "next-button",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: "",
            },
            SpecificError: {
              value: "",
            },
            apiName: {
              id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
              fields: {
                value: {
                  value: "",
                },
                key: {
                  value: "",
                },
              },
            },
            "Label Css Class": {
              value: "",
            },
            Title: {
              value: enf?.nextButtonText,
            },
            "Field Type": {
              id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
              fields: {
                BackgroundColor: {
                  value: "Eggplant",
                },
                Icon: {
                  value: "OfficeWhite/32x32/button.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/button",
                  fields: {},
                },
                "Allowed Validations": [],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/Button",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: "7BB73BAD1EEC4F309AB7046C2FFF2ED2",
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: [
            {
              id: "784ca9a9-9fb1-48a9-a396-a86487b1ffd3",
              name: "Redirect to Page",
              displayName: "Redirect to Page",
              parentId: "7803d6ed-0383-4199-9b8c-bb7ed47e3d25",
              fields: {
                Description: {
                  value: "Preferred Contact Mode",
                },
                Parameters: {
                  value: `{"referenceId":"{${nextStepInfos?.id?.toUpperCase()}}"}`,
                },
                "Submit Action": {
                  id: "3f3e2093-9dea-4199-86ca-44fc69ef624d",
                  fieldType: "Droptree",
                  fieldName: "Submit Action",
                  url: "/sitecore/system/settings/forms/submit-actions/redirect-to-page",
                  fields: {
                    Editor: null,
                    "Error Message": {
                      value: "Redirect to page failed!",
                    },
                    "Model Type": {
                      value:
                        "Sitecore.ExperienceForms.Processing.Actions.RedirectToPage",
                    },
                  },
                },
              },
              items: [],
            },
          ],
        },
      ];
      const ageBlock = {
        id: "13ff3a7b-7607-4878-b7ad-5a86579bdeeb",
        name: "phonePage",
        displayName: "Phone Number",
        parentId: "57ec00df-f75a-4645-a4f1-a830be98de45",
        fields: {
          gaLabel: {
            value: "",
          },
          backgroundColor: {
            id: "cbdc6265-d09f-4982-981a-bcc8f118f36f",
            fieldType: "Droplink",
            fieldName: "backgroundColor",
            url: "/sitecore/content/fwd/global/settings/list/colors/whiteorange",
            fields: {
              value: {
                value: "white-orange",
              },
              key: {
                value: "WhiteOrange",
              },
            },
          },
          "Css Class": {
            value: `phonePage`,
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: null,
          "Field Type": {
            id: "d819b43e-c136-4392-9393-8be7fce32f5e",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/page",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/window_split_ver.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "cfee7b51-8505-45ce-b843-9358f827df87",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/page",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomPageViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Page",
              },
            },
          },
          Conditions: {
            value: JSON.stringify(conditions),
          },
          "Field Key": {
            value: "A822D936CE184893B07F20E4B97E9B11",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: blockItems,
      };
      enquirySteps.push(ageBlock);
    }

    if (block?.text_field && block?.text_field?.field_name === "otherManufacturingOtherField2") {
      let conditions: any = { fieldConditions: [] };
      let blockItems = [
        {
          id: "4f3881e9-622b-4d4d-bbb9-e176d3db9873",
          name: "Text",
          displayName: "Text",
          parentId: "13ff3a7b-7607-4878-b7ad-5a86579bddab",
          fields: {
            "Html Tag": {
              value: "h5",
            },
            Text: {
              value: block?.text_field?.label,
            },
            "Css Class": {
              value: "inline",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: "",
            },
            SpecificError: {
              value: "",
            },
            apiName: {
              id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
              fields: {
                value: {
                  value: "",
                },
                key: {
                  value: "",
                },
              },
            },
            "Field Type": {
              id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/basic/text",
              fields: {
                BackgroundColor: {
                  value: "Sky",
                },
                Icon: {
                  value: "OfficeWhite/32x32/document_text.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/text",
                  fields: {},
                },
                "Allowed Validations": [],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/Text",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: "68525ADA341A44E4A149A8B8DFECA243",
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: [],
        },
        {
          id: "aedc3ff6-7b8a-40f7-9e24-205b977df0fa",
          name: "otherManufacturingOtherField2Page",
          displayName: "otherManufacturingOtherField2Page",
          parentId: "13ff3a7b-7607-4878-b7ad-5a86579bddab",
          fields: {
            "Default Value": {
              value: "",
            },
            "Max Length": {
              value: block?.text_field?.max_length || "2",
            },
            "Min Length": {
              value: "",
            },
            "Placeholder Text": {
              value:
                block?.text_field?.placeholder || block?.text_field?.sub_label,
            },
            dialInCodeLength: {
              id: "924aee12-9c96-42e0-8548-8378e251a8c7",
              fieldType: "Droplink",
              fieldName: "dialInCodeLength",
              url: "/sitecore/content/fwd/global/settings/list/formdiallingcodelength/zero",
              fields: {
                value: {
                  value: "0",
                },
                key: {
                  value: "zero",
                },
              },
            },
            "Css Class": {
              value: "inline telephoneInput",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: block?.text_field?.validation_message,
            },
            SpecificError: {
              value: "Please input an 8-digit phone number",
            },
            apiName: {
              id: "22259656-b6e9-4f74-993e-4578dbcf0741",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/otherManufacturingOtherField2Page",
              fields: {
                value: {
                  value: "otherManufacturingOtherField2Page",
                },
                key: {
                  value: "otherManufacturingOtherField2Page",
                },
              },
            },
            "Label Css Class": {
              value: "",
            },
            Title: {
              value: "",
            },
            Required: {
              value: false,
            },
            Validations: [
              {
                id: "e61ea5ab-77bb-4278-9b13-eac1c30e8e0e",
                fields: {
                  Message: {
                    value: block?.text_field?.validation_rules?.message,
                  },
                  Parameters: {
                    value: block?.text_field?.validation_rules?.regex,
                  },
                  Type: {
                    value:
                      "FWD.Foundation.Forms.Validators.CustomRegularExpressionValidation,FWD.Foundation.Forms",
                  },
                },
              },
            ],
            "Is Tracking Enabled": {
              value: true,
            },
            "Allow Save": {
              value: true,
            },
            "Value Provider Parameters": {
              value: "",
            },
            "Value Provider": null,
            "Field Type": {
              id: "4ee89ea7-cefe-4c8e-8532-467ef64591fc",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/basic/single-line-text",
              fields: {
                BackgroundColor: {
                  value: "Sky",
                },
                Icon: {
                  value: "OfficeWhite/32x32/text_field.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "0908030b-4564-42ea-a6fa-c7a5a2d921a8",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/input",
                  fields: {},
                },
                "Allowed Validations": [
                  {
                    id: "83e96d09-11c8-4132-8a8a-76d8db8208d6",
                    fields: {
                      Message: {
                        value:
                          "{0} must have at least {1} and no more than {2} characters.",
                      },
                      Parameters: {
                        value: "",
                      },
                      Type: {
                        value:
                          "Sitecore.ExperienceForms.Mvc.Models.Validation.StringLengthValidation,Sitecore.ExperienceForms.Mvc",
                      },
                    },
                  },
                ],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomSingleLineViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/SingleLineText",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: constants.text_field_otherManufacturingOtherField2.fieldKey,
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: [],
        },
        getEnquiryFieldProps(
          block?.text_field,
          "Tooltip",
          "13ff3a7b-7607-4878-b7ad-5a86579bddab"
        ),
        {
          id: "7803d6ed-0383-4199-9b8c-bb7ed47e3d25",
          name: "defaultPhoneNextButton",
          displayName: "Next Button Default",
          parentId: "13ff3a7b-7607-4878-b7ad-5a86579bddab",
          fields: {
            "Navigation Step": {
              value: "1",
            },
            emailTemplateLink: {
              value: "",
            },
            "Css Class": {
              value: "next-button",
            },
            isHidden: {
              value: false,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: "",
            },
            SpecificError: {
              value: "",
            },
            apiName: {
              id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
              fields: {
                value: {
                  value: "",
                },
                key: {
                  value: "",
                },
              },
            },
            "Label Css Class": {
              value: "",
            },
            Title: {
              value: enf?.nextButtonText,
            },
            "Field Type": {
              id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
              fields: {
                BackgroundColor: {
                  value: "Eggplant",
                },
                Icon: {
                  value: "OfficeWhite/32x32/button.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/button",
                  fields: {},
                },
                "Allowed Validations": [],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/Button",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: "7BB73BAD1EEC4F309AB7046C2FFF2ED2",
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: [
            {
              id: "784ca9a9-9fb1-48a9-a396-a86487b1ffd3",
              name: "Redirect to Page",
              displayName: "Redirect to Page",
              parentId: "7803d6ed-0383-4199-9b8c-bb7ed47e3d25",
              fields: {
                Description: {
                  value: "Preferred Contact Mode",
                },
                Parameters: {
                  value: `{"referenceId":"{${nextStepInfos?.id?.toUpperCase()}}"}`,
                },
                "Submit Action": {
                  id: "3f3e2093-9dea-4199-86ca-44fc69ef624d",
                  fieldType: "Droptree",
                  fieldName: "Submit Action",
                  url: "/sitecore/system/settings/forms/submit-actions/redirect-to-page",
                  fields: {
                    Editor: null,
                    "Error Message": {
                      value: "Redirect to page failed!",
                    },
                    "Model Type": {
                      value:
                        "Sitecore.ExperienceForms.Processing.Actions.RedirectToPage",
                    },
                  },
                },
              },
              items: [],
            },
          ],
        },
      ];
      const otherManufacturingOtherField2PageBlock = {
        id: constants.text_field_otherManufacturingOtherField2.id,
        name: "otherManufacturingOtherField2PagePage",
        displayName: "otherManufacturingOtherField2PagePage",
        parentId: "57ec00df-f75a-4645-a4f1-a830be98de45",
        fields: {
          gaLabel: {
            value: "",
          },
          backgroundColor: {
            id: "cbdc6265-d09f-4982-981a-bcc8f118f36f",
            fieldType: "Droplink",
            fieldName: "backgroundColor",
            url: "/sitecore/content/fwd/global/settings/list/colors/whiteorange",
            fields: {
              value: {
                value: "white-orange",
              },
              key: {
                value: "WhiteOrange",
              },
            },
          },
          "Css Class": {
            value: `otherManufacturingOtherField2PagePage`,
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: null,
          "Field Type": {
            id: "d819b43e-c136-4392-9393-8be7fce32f5e",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/page",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/window_split_ver.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "cfee7b51-8505-45ce-b843-9358f827df87",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/page",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomPageViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Page",
              },
            },
          },
          Conditions: {
            value: JSON.stringify(conditions),
          },
          "Field Key": {
            value: "A822D936CE184893B07F20E4B9009BDEA",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: blockItems,
      };
      enquirySteps.push(otherManufacturingOtherField2PageBlock);
    }

    if (block?.radios_group && block?.radios_group?.field_name === "referralCode") {
      const nameValues = block?.radios_group?.items?.map(
        ({ item_key }: any) => {
          return {
            id: generateUniqSerial(),
            name: item_key,
            displayName: item_key,
            parentId: "6947b485-f82f-456e-af03-d8779ef44eba",
            fields: {
              value: {
                value: item_key,
              },
            },
            items: [],
          };
        }
      );
      const { strWithoutTag, subStr } = extractValueFromTag(block.radios_group?.label, "[NextButton]");

      let nextSteps: any = [];
      let conditions: any = { fieldConditions: [] };
      conditions = {
        fieldConditions: [
          {
            matchTypeId: "{365C94DA-C1CD-4783-A91D-0D17A16C7117}",
            conditions: [
              {
                fieldId: "049474A552CA48A58B82690D122142C93",
                operatorId: "{1D38B217-A2EE-4E7B-B6ED-13E751462FEB}",
                value: `No`,
              },
            ],
            actions: [
              {
                fieldId: "DFA430F198F44C0DA331CD16E1DB2232E",
                actionTypeId: "{AAE07A52-46A4-49EF-98B0-C2595BAC2382}",
                value: null,
              },
            ],
          },
          {
            matchTypeId: "{365C94DA-C1CD-4783-A91D-0D17A16C7117}",
            conditions: [
              {
                fieldId: "049474A552CA48A58B82690D122142C93",
                operatorId: "{1D38B217-A2EE-4E7B-B6ED-13E751462FEB}",
                value: `Yes`,
              },
            ],
            actions: [
              {
                fieldId: "DFA430F198F44C0DA331CD16E1DB2233D",
                actionTypeId: "{AAE07A52-46A4-49EF-98B0-C2595BAC2382}",
                value: null,
              }
            ],
          },
        ],
      };
      const referralCodeNoNextButton = {
        id: "24d892a6-ba06-4c0f-8389-6bb5ba0fc771",
        name: "referralCodeNoNextButton",
        displayName: "referralCodeNoNextButton",
        parentId: "3fc9e2df-ed81-4104-909d-ecf0ffad216f",
        fields: {
          "Navigation Step": {
            value: "1",
          },
          emailTemplateLink: {
            value: "",
          },
          "Css Class": {
            value: "next-button",
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: {
            id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
            fieldType: "Droplink",
            fieldName: "apiName",
            url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
            fields: {
              value: {
                value: "",
              },
              key: {
                value: "",
              },
            },
          },
          "Label Css Class": {
            value: "",
          },
          Title: {
            value: enf?.nextButtonText,
          },
          "Field Type": {
            id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/button.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/button",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Button",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: "DFA430F198F44C0DA331CD16E1DB2232E",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [
          {
            id: "e4a2a602-5e65-4597-8c0e-d6c2a59f8e76",
            name: "Redirect to Page",
            displayName: "Redirect to Page",
            parentId: "57321773-013d-4531-98ce-6521b01a7fe6",
            fields: {
              Description: {
                value: "Phone Number",
              },
              Parameters: {
                value: `{"referenceId":"{2420A361-FF7F-48F4-AEA8-860BCBD6431EC}"}`,
              },
              "Submit Action": {
                id: "3f3e2093-9dea-4199-86ca-44fc69ef624d",
                fieldType: "Droptree",
                fieldName: "Submit Action",
                url: "/sitecore/system/settings/forms/submit-actions/redirect-to-page",
                fields: {
                  Editor: null,
                  "Error Message": {
                    value: "Redirect to page failed!",
                  },
                  "Model Type": {
                    value:
                      "Sitecore.ExperienceForms.Processing.Actions.RedirectToPage",
                  },
                },
              },
            },
            items: [],
          },
        ],
      };
      
      const referralCodeYesNextButton = {
        id: "24d892a6-ba06-4c0f-8389-6bb5ba0fc781",
        name: "referralCodeYesNextButton",
        displayName: "referralCodeYesNextButton",
        parentId: "3fc9e2df-ed81-4104-909d-ecf0ffad216f",
        fields: {
          "Navigation Step": {
            value: "1",
          },
          emailTemplateLink: {
            value: "",
          },
          "Css Class": {
            value: "next-button",
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: {
            id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
            fieldType: "Droplink",
            fieldName: "apiName",
            url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
            fields: {
              value: {
                value: "",
              },
              key: {
                value: "",
              },
            },
          },
          "Label Css Class": {
            value: "",
          },
          Title: {
            value: subStr || enf?.nextButtonText,
          },
          "Field Type": {
            id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/button.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/button",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Button",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: "DFA430F198F44C0DA331CD16E1DB2233D",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [
          {
            id: "e4a2a602-5e65-4597-8c0e-d6c2a59f8e76",
            name: "Redirect to Page",
            displayName: "Redirect to Page",
            parentId: "57321773-013d-4531-98ce-6521b01a7fe6",
            fields: {
              Description: {
                value: "Phone Number",
              },
              Parameters: {
                value: `{"referenceId":"{${nextStepInfos?.id?.toUpperCase()}}"}`,
              },
              "Submit Action": {
                id: "3f3e2093-9dea-4199-86ca-44fc69ef624d",
                fieldType: "Droptree",
                fieldName: "Submit Action",
                url: "/sitecore/system/settings/forms/submit-actions/redirect-to-page",
                fields: {
                  Editor: null,
                  "Error Message": {
                    value: "Redirect to page failed!",
                  },
                  "Model Type": {
                    value:
                      "Sitecore.ExperienceForms.Processing.Actions.RedirectToPage",
                  },
                },
              },
            },
            items: [],
          },
        ],
      };
      nextSteps.push(referralCodeNoNextButton, referralCodeYesNextButton);
      const step = {
        id: constants.radios_group_referralCode.id,
        name: "referralCodePage",
        displayName: "referralCodePage",
        parentId: "57ec00df-f75a-4645-a4f1-a830be98de45",
        fields: {
          gaLabel: {
            value: "",
          },
          backgroundColor: {
            id: "cbdc6265-d09f-4982-981a-bcc8f118f36f",
            fieldType: "Droplink",
            fieldName: "backgroundColor",
            url: "/sitecore/content/fwd/global/settings/list/colors/whiteorange",
            fields: {
              value: {
                value: "white-orange",
              },
              key: {
                value: "WhiteOrange",
              },
            },
          },
          "Css Class": {
            value: `referralCodeMorePage`,
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: null,
          "Field Type": {
            id: "d819b43e-c136-4392-9393-8be7fce32f5e",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/page",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/window_split_ver.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "cfee7b51-8505-45ce-b843-9358f827df87",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/page",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomPageViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Page",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: "4DF8F5E941DF48CC846122E6426D5147",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [
          {
            id: "2aaa89b7-a326-46a0-b73f-d028383ef493",
            name: "Text",
            displayName: "Text",
            parentId: "3fc9e2df-ed81-4104-909d-ecf0ffad216f",
            fields: {
              "Html Tag": {
                value: "p",
              },
              Text: {
                value: strWithoutTag,
              },
              "Css Class": {
                value: "block",
              },
              isHidden: {
                value: false,
              },
              HelpText: {
                value: "",
              },
              GeneralError: {
                value: "",
              },
              SpecificError: {
                value: "",
              },
              apiName: {
                id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
                fieldType: "Droplink",
                fieldName: "apiName",
                url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
                fields: {
                  value: {
                    value: "",
                  },
                  key: {
                    value: "",
                  },
                },
              },
              "Field Type": {
                id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
                fieldType: "Droptree",
                fieldName: "Field Type",
                url: "/sitecore/system/settings/forms/field-types/basic/text",
                fields: {
                  BackgroundColor: {
                    value: "Sky",
                  },
                  Icon: {
                    value: "OfficeWhite/32x32/document_text.png",
                  },
                  IsDisabled: {
                    value: false,
                  },
                  SpritePosition: {
                    value: "",
                  },
                  "Field Template": {
                    id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                    fieldType: "Droptree",
                    fieldName: "Field Template",
                    url: "/sitecore/templates/system/forms/fields/text",
                    fields: {},
                  },
                  "Allowed Validations": [],
                  "Model Type": {
                    value:
                      "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
                  },
                  "Property Editor": null,
                  "View Path": {
                    value: "FieldTemplates/Text",
                  },
                },
              },
              Conditions: {
                value: JSON.stringify(conditions),
              },
              "Field Key": {
                value: "2CEFA97A507747A183E4DB2D202E0B11",
              },
              "Collapsed by Default": {
                value: false,
              },
            },
            items: [],
          },
          {
            id: "6947b485-f82f-456e-af03-d8779ef44eba",
            name: "Existing Mode",
            displayName: "Existing Mode",
            parentId: "3fc9e2df-ed81-4104-909d-ecf0ffad216f",
            fields: {
              Datasource: null,
              "Default Selection": {
                value: "",
              },
              "Display Field Name": {
                value: "__Display name",
              },
              "Is Dynamic": {
                value: false,
              },
              "Value Field Name": {
                value: "Value",
              },
              "Css Class": {
                value: "block",
              },
              isHidden: {
                value: false,
              },
              HelpText: {
                value: "",
              },
              GeneralError: {
                value: "",
              },
              SpecificError: {
                value: "",
              },
              apiName: {
                id: "50d505ca-38f7-420f-8f73-f221ef889e74",
                fieldType: "Droplink",
                fieldName: "apiName",
                url: "/sitecore/content/fwd/global/settings/list/form-api-fields/referralCode",
                fields: {
                  value: {
                    value: "referralCode",
                  },
                  key: {
                    value: "referralCode",
                  },
                },
              },
              "Label Css Class": {
                value: "",
              },
              Title: {
                value: "",
              },
              Required: {
                value: true,
              },
              Validations: [],
              "Is Tracking Enabled": {
                value: true,
              },
              "Allow Save": {
                value: true,
              },
              "Value Provider Parameters": {
                value: "",
              },
              "Value Provider": null,
              "Field Type": {
                id: "edbd38a8-1ae9-42ec-8ccd-f5b0e2998b4f",
                fieldType: "Droptree",
                fieldName: "Field Type",
                url: "/sitecore/system/settings/forms/field-types/lists/radio-button-list",
                fields: {
                  BackgroundColor: {
                    value: "Grass",
                  },
                  Icon: {
                    value: "OfficeWhite/32x32/radio_button_group.png",
                  },
                  IsDisabled: {
                    value: false,
                  },
                  SpritePosition: {
                    value: "",
                  },
                  "Field Template": {
                    id: "5b672865-55d2-413e-b699-fdfc7e732ccf",
                    fieldType: "Droptree",
                    fieldName: "Field Template",
                    url: "/sitecore/templates/system/forms/fields/list",
                    fields: {},
                  },
                  "Allowed Validations": [],
                  "Model Type": {
                    value:
                      "FWD.Foundation.Forms.CustomRadioButtonListViewModel,FWD.Foundation.Forms",
                  },
                  "Property Editor": null,
                  "View Path": {
                    value: "FieldTemplates/RadioButtonList",
                  },
                },
              },
              Conditions: {
                value: JSON.stringify(conditions),
              },
              "Field Key": {
                value: "049474A552CA48A58B82690D122142C93",
              },
              "Collapsed by Default": {
                value: false,
              },
            },
            items: nameValues || [],
          },
          ...nextSteps,
        ],
      };
      enquirySteps.push(step);
    }

    if (block?.thank_you_screen) {
      enf.darkMode = !!!block?.thank_you_screen?.white_mode;
      enf.loadingDarkMode = !!!block?.thank_you_screen?.loading_white_mode;
      const textForTime: any = {
        id: "cfdcb351-999b-4fb1-840a-0da5b30edb80",
        name: "TextForMeetAnAgent",
        displayName: "TextForMeetAnAgent",
        parentId: "3627d278-2067-4aa3-831e-496e0af1bd30",
        fields: {
          "Html Tag": {
            value: "p",
          },
          Text: {
            value:
              block.thank_you_screen?.description_with_time ||
              block.thank_you_screen?.description,
          },
          "Css Class": {
            value: "block",
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: {
            id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
            fieldType: "Droplink",
            fieldName: "apiName",
            url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
            fields: {
              value: {
                value: "",
              },
              key: {
                value: "",
              },
            },
          },
          "Field Type": {
            id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/basic/text",
            fields: {
              BackgroundColor: {
                value: "Sky",
              },
              Icon: {
                value: "OfficeWhite/32x32/document_text.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/text",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Text",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: "915D7E632E374A2AA094ABE3D95214DF",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [],
      };
      const textForEmail: any = {
        id: "3ed175f0-eac3-4f9d-ade4-effaf7c66da8",
        name: "TextForEmail",
        displayName: "TextForEmail",
        parentId: "3627d278-2067-4aa3-831e-496e0af1bd30",
        fields: {
          "Html Tag": {
            value: "p",
          },
          Text: {
            value: block.thank_you_screen?.description || "",
          },
          "Css Class": {
            value: "",
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: null,
          "Field Type": {
            id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/basic/text",
            fields: {
              BackgroundColor: {
                value: "Sky",
              },
              Icon: {
                value: "OfficeWhite/32x32/document_text.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/text",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Text",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: "98FB8E4051374455BD89F04F5AAA2D77",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [],
      };
      const textForPhone: any = {
        id: "cfdcb351-999b-4fb1-840a-0da5b30edb79",
        name: "TextForMeetAnAgent",
        displayName: "TextForMeetAnAgent",
        parentId: "3627d278-2067-4aa3-831e-496e0af1bd30",
        fields: {
          "Html Tag": {
            value: "p",
          },
          Text: {
            value:
              block.thank_you_screen?.description_with_time ||
              block.thank_you_screen?.description,
          },
          "Css Class": {
            value: "block",
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: {
            id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
            fieldType: "Droplink",
            fieldName: "apiName",
            url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
            fields: {
              value: {
                value: "",
              },
              key: {
                value: "",
              },
            },
          },
          "Field Type": {
            id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/basic/text",
            fields: {
              BackgroundColor: {
                value: "Sky",
              },
              Icon: {
                value: "OfficeWhite/32x32/document_text.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/text",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Text",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: "915D7E632E374A2AA094ABE3D95213BE",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [],
      };

      let textForThanks: any = [];
      let conditions: any = { fieldConditions: [] };

      if (block.thank_you_screen?.description) {
        textForThanks.push(textForEmail);
      }
      if (block.thank_you_screen?.description_with_time) {
        textForThanks.push(textForPhone);
        if (
          ["th"].indexOf(countryCode) >= 0 &&
          categoryNeqConditions?.length > 0
        ) {
          textForThanks.push(textForTime);
        }
      }
      let thankYouBlock: any = {
        id: "3627d278-2067-4aa3-831e-496e0af1bd30",
        name: "phoneBookAnAgentThankYouPage",
        displayName: "Thank You - Phone",
        parentId: "57ec00df-f75a-4645-a4f1-a830be98de45",
        fields: {
          gaLabel: {
            value: "",
          },
          backgroundColor: {
            id: "b47928d6-98fc-4b29-8d0f-586ab477286e",
            fieldType: "Droplink",
            fieldName: "backgroundColor",
            url: "/sitecore/content/fwd/global/settings/list/colors/orangewhite",
            fields: {
              value: {
                value: "orange-white",
              },
              key: {
                value: "OrangeWhite",
              },
            },
          },
          "Css Class": {
            value: "phoneSubmitPage darkMode conditionPage",
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          apiName: null,
          "Field Type": {
            id: "d819b43e-c136-4392-9393-8be7fce32f5e",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/structure/page",
            fields: {
              BackgroundColor: {
                value: "Eggplant",
              },
              Icon: {
                value: "OfficeWhite/32x32/window_split_ver.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "cfee7b51-8505-45ce-b843-9358f827df87",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/page",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomPageViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Page",
              },
            },
          },
          Conditions: {
            value: JSON.stringify(conditions),
          },
          "Field Key": {
            value: "F880144362CB4E118C0EE581A4C3D11C",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [
          {
            id: "589eab8a-8dbc-43a4-a827-b99be1adbf11",
            name: "Text",
            displayName: "Text",
            parentId: "3627d278-2067-4aa3-831e-496e0af1bd30",
            fields: {
              "Html Tag": {
                value: "p",
              },
              Text: {
                value: block.thank_you_screen?.title,
              },
              "Css Class": {
                value: "",
              },
              isHidden: {
                value: false,
              },
              HelpText: {
                value: "",
              },
              GeneralError: {
                value: "",
              },
              SpecificError: {
                value: "",
              },
              apiName: null,
              "Field Type": {
                id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
                fieldType: "Droptree",
                fieldName: "Field Type",
                url: "/sitecore/system/settings/forms/field-types/basic/text",
                fields: {
                  BackgroundColor: {
                    value: "Sky",
                  },
                  Icon: {
                    value: "OfficeWhite/32x32/document_text.png",
                  },
                  IsDisabled: {
                    value: false,
                  },
                  SpritePosition: {
                    value: "",
                  },
                  "Field Template": {
                    id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                    fieldType: "Droptree",
                    fieldName: "Field Template",
                    url: "/sitecore/templates/system/forms/fields/text",
                    fields: {},
                  },
                  "Allowed Validations": [],
                  "Model Type": {
                    value:
                      "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
                  },
                  "Property Editor": null,
                  "View Path": {
                    value: "FieldTemplates/Text",
                  },
                },
              },
              Conditions: {
                value: '{"fieldConditions":[]}',
              },
              "Field Key": {
                value: "753703DC08564AD893AFA873A6215A42",
              },
              "Collapsed by Default": {
                value: false,
              },
            },
            items: [],
          },
          ...textForThanks,
          {
            id: "bb11b9bd-73fa-4b7e-aa16-4de14f99c8b3",
            name: "backToHome",
            displayName: "Back to Home",
            parentId: "3627d278-2067-4aa3-831e-496e0af1bd30",
            fields: {
              "Navigation Step": {
                value: "1",
              },
              emailTemplateLink: {
                value: "",
              },
              "Css Class": {
                value: "back-to-home",
              },
              isHidden: {
                value: false,
              },
              HiddenInfos: {
                activeUrl: block?.thank_you_screen?.active_url,
              },
              HelpText: {
                value: "",
              },
              GeneralError: {
                value: "",
              },
              SpecificError: {
                value: "",
              },
              apiName: null,
              "Label Css Class": {
                value: "",
              },
              Title: {
                value: block.thank_you_screen?.button_text,
              },
              "Field Type": {
                id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
                fieldType: "Droptree",
                fieldName: "Field Type",
                url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
                fields: {
                  BackgroundColor: {
                    value: "Eggplant",
                  },
                  Icon: {
                    value: "OfficeWhite/32x32/button.png",
                  },
                  IsDisabled: {
                    value: false,
                  },
                  SpritePosition: {
                    value: "",
                  },
                  "Field Template": {
                    id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
                    fieldType: "Droptree",
                    fieldName: "Field Template",
                    url: "/sitecore/templates/system/forms/fields/button",
                    fields: {},
                  },
                  "Allowed Validations": [],
                  "Model Type": {
                    value:
                      "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
                  },
                  "Property Editor": null,
                  "View Path": {
                    value: "FieldTemplates/Button",
                  },
                },
              },
              Conditions: {
                value: '{"fieldConditions":[]}',
              },
              "Field Key": {
                value: "1B48EED990F64B8DBA5887B6BCFC9DC5",
              },
              "Collapsed by Default": {
                value: false,
              },
            },
            items: [],
          },
          {
            id: "e245005a-db37-403d-9a32-63ddd8d0b2f4",
            name: "MediaSource",
            displayName: "MediaSource",
            parentId: "3627d278-2067-4aa3-831e-496e0af1bd30",
            fields: {
              "Html Tag": {
                value: "p",
              },
              Text: {
                value: "",
              },
              "Css Class": {
                value: "",
              },
              isHidden: {
                value: true,
              },
              HelpText: {
                value: "",
              },
              GeneralError: {
                value: "",
              },
              SpecificError: {
                value: "",
              },
              apiName: {
                id: "9966e4e3-f61b-48f4-9958-8eedf4aa3665",
                fieldType: "Droplink",
                fieldName: "apiName",
                url: "/sitecore/content/fwd/global/settings/list/form-api-fields/mediasource",
                fields: {
                  value: {
                    value: "mediaSource",
                  },
                  key: {
                    value: "mediaSource",
                  },
                },
              },
              "Field Type": {
                id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
                fieldType: "Droptree",
                fieldName: "Field Type",
                url: "/sitecore/system/settings/forms/field-types/basic/text",
                fields: {
                  BackgroundColor: {
                    value: "Sky",
                  },
                  Icon: {
                    value: "OfficeWhite/32x32/document_text.png",
                  },
                  IsDisabled: {
                    value: false,
                  },
                  SpritePosition: {
                    value: "",
                  },
                  "Field Template": {
                    id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                    fieldType: "Droptree",
                    fieldName: "Field Template",
                    url: "/sitecore/templates/system/forms/fields/text",
                    fields: {},
                  },
                  "Allowed Validations": [],
                  "Model Type": {
                    value:
                      "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
                  },
                  "Property Editor": null,
                  "View Path": {
                    value: "FieldTemplates/Text",
                  },
                },
              },
              Conditions: {
                value: '{"fieldConditions":[]}',
              },
              "Field Key": {
                value: "3DA1A3628894402E9BE4E5BC3B57C06A",
              },
              "Collapsed by Default": {
                value: false,
              },
            },
            items: [],
          },
          {
            id: "b43fde27-c1bc-45f8-8a0d-4257e9810134",
            name: "consentMsg",
            displayName: "consentMsg",
            parentId: "3627d278-2067-4aa3-831e-496e0af1bd30",
            fields: {
              "Html Tag": {
                value: "p",
              },
              Text: {
                value: "",
              },
              "Css Class": {
                value: "",
              },
              isHidden: {
                value: true,
              },
              HelpText: {
                value: "",
              },
              GeneralError: {
                value: "",
              },
              SpecificError: {
                value: "",
              },
              apiName: {
                id: "742c941c-44ec-4e36-878f-6dc28f99baff",
                fieldType: "Droplink",
                fieldName: "apiName",
                url: "/sitecore/content/fwd/global/settings/list/form-api-fields/consentmsg",
                fields: {
                  value: {
                    value: "consentMsg",
                  },
                  key: {
                    value: "consentMsg",
                  },
                },
              },
              "Field Type": {
                id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
                fieldType: "Droptree",
                fieldName: "Field Type",
                url: "/sitecore/system/settings/forms/field-types/basic/text",
                fields: {
                  BackgroundColor: {
                    value: "Sky",
                  },
                  Icon: {
                    value: "OfficeWhite/32x32/document_text.png",
                  },
                  IsDisabled: {
                    value: false,
                  },
                  SpritePosition: {
                    value: "",
                  },
                  "Field Template": {
                    id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                    fieldType: "Droptree",
                    fieldName: "Field Template",
                    url: "/sitecore/templates/system/forms/fields/text",
                    fields: {},
                  },
                  "Allowed Validations": [],
                  "Model Type": {
                    value:
                      "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
                  },
                  "Property Editor": null,
                  "View Path": {
                    value: "FieldTemplates/Text",
                  },
                },
              },
              Conditions: {
                value: '{"fieldConditions":[]}',
              },
              "Field Key": {
                value: "060F06DE197645569C2D4DFF110C6F4A",
              },
              "Collapsed by Default": {
                value: false,
              },
            },
            items: [],
          },
          {
            id: "8afdb6aa-d62e-4521-bf22-2726f4236b1b",
            name: "consentVersionCd",
            displayName: "consentVersionCd",
            parentId: "3627d278-2067-4aa3-831e-496e0af1bd30",
            fields: {
              "Html Tag": {
                value: "p",
              },
              Text: {
                value: consentBlock?.version_cd,
              },
              "Css Class": {
                value: "",
              },
              isHidden: {
                value: true,
              },
              HelpText: {
                value: "",
              },
              GeneralError: {
                value: "",
              },
              SpecificError: {
                value: "",
              },
              apiName: {
                id: "10fc0d1b-f2f0-4007-bcd7-9dc3675741bb",
                fieldType: "Droplink",
                fieldName: "apiName",
                url: "/sitecore/content/fwd/global/settings/list/form-api-fields/consentversioncd",
                fields: {
                  value: {
                    value: "consentVersionCd",
                  },
                  key: {
                    value: "ConsentVersionCd",
                  },
                },
              },
              "Field Type": {
                id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
                fieldType: "Droptree",
                fieldName: "Field Type",
                url: "/sitecore/system/settings/forms/field-types/basic/text",
                fields: {
                  BackgroundColor: {
                    value: "Sky",
                  },
                  Icon: {
                    value: "OfficeWhite/32x32/document_text.png",
                  },
                  IsDisabled: {
                    value: false,
                  },
                  SpritePosition: {
                    value: "",
                  },
                  "Field Template": {
                    id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                    fieldType: "Droptree",
                    fieldName: "Field Template",
                    url: "/sitecore/templates/system/forms/fields/text",
                    fields: {},
                  },
                  "Allowed Validations": [],
                  "Model Type": {
                    value:
                      "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
                  },
                  "Property Editor": null,
                  "View Path": {
                    value: "FieldTemplates/Text",
                  },
                },
              },
              Conditions: {
                value: '{"fieldConditions":[]}',
              },
              "Field Key": {
                value: "8DEBE8307E4048FD97CEBBCC69003314",
              },
              "Collapsed by Default": {
                value: false,
              },
            },
            items: [],
          },
          {
            id: "2192b1e6-9f17-43e8-a25b-19481afde423",
            name: "dtcCampaignId",
            displayName: "dtcCampaignId",
            parentId: "3627d278-2067-4aa3-831e-496e0af1bd30",
            fields: {
              "Html Tag": {
                value: "p",
              },
              Text: {
                value: "NextGen",
              },
              "Css Class": {
                value: "",
              },
              isHidden: {
                value: true,
              },
              HelpText: {
                value: "",
              },
              GeneralError: {
                value: "",
              },
              SpecificError: {
                value: "",
              },
              apiName: {
                id: "cd19cd7e-4435-4ee7-8e7b-522d7384522b",
                fieldType: "Droplink",
                fieldName: "apiName",
                url: "/sitecore/content/fwd/global/settings/list/form-api-fields/dtccampaignid",
                fields: {
                  value: {
                    value: "dTCCampaignId",
                  },
                  key: {
                    value: "dTCCampaignId",
                  },
                },
              },
              "Field Type": {
                id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
                fieldType: "Droptree",
                fieldName: "Field Type",
                url: "/sitecore/system/settings/forms/field-types/basic/text",
                fields: {
                  BackgroundColor: {
                    value: "Sky",
                  },
                  Icon: {
                    value: "OfficeWhite/32x32/document_text.png",
                  },
                  IsDisabled: {
                    value: false,
                  },
                  SpritePosition: {
                    value: "",
                  },
                  "Field Template": {
                    id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                    fieldType: "Droptree",
                    fieldName: "Field Template",
                    url: "/sitecore/templates/system/forms/fields/text",
                    fields: {},
                  },
                  "Allowed Validations": [],
                  "Model Type": {
                    value:
                      "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
                  },
                  "Property Editor": null,
                  "View Path": {
                    value: "FieldTemplates/Text",
                  },
                },
              },
              Conditions: {
                value: '{"fieldConditions":[]}',
              },
              "Field Key": {
                value: "94CF8FCDD9E2408DB9B317F83F0CC039",
              },
              "Collapsed by Default": {
                value: false,
              },
            },
            items: [],
          },
          {
            id: "23e5c856-0710-4668-87b6-4c68b6b44998",
            name: "partnerName",
            displayName: "partnerName",
            parentId: "3627d278-2067-4aa3-831e-496e0af1bd30",
            fields: {
              "Html Tag": {
                value: "p",
              },
              Text: {
                value: "NexGen",
              },
              "Css Class": {
                value: "",
              },
              isHidden: {
                value: true,
              },
              HelpText: {
                value: "",
              },
              GeneralError: {
                value: "",
              },
              SpecificError: {
                value: "",
              },
              apiName: {
                id: "865d0d1e-9b62-4e35-af48-f3912b20dd0c",
                fieldType: "Droplink",
                fieldName: "apiName",
                url: "/sitecore/content/fwd/global/settings/list/form-api-fields/partnername",
                fields: {
                  value: {
                    value: "partnerName",
                  },
                  key: {
                    value: "PartnerName",
                  },
                },
              },
              "Field Type": {
                id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
                fieldType: "Droptree",
                fieldName: "Field Type",
                url: "/sitecore/system/settings/forms/field-types/basic/text",
                fields: {
                  BackgroundColor: {
                    value: "Sky",
                  },
                  Icon: {
                    value: "OfficeWhite/32x32/document_text.png",
                  },
                  IsDisabled: {
                    value: false,
                  },
                  SpritePosition: {
                    value: "",
                  },
                  "Field Template": {
                    id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                    fieldType: "Droptree",
                    fieldName: "Field Template",
                    url: "/sitecore/templates/system/forms/fields/text",
                    fields: {},
                  },
                  "Allowed Validations": [],
                  "Model Type": {
                    value:
                      "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
                  },
                  "Property Editor": null,
                  "View Path": {
                    value: "FieldTemplates/Text",
                  },
                },
              },
              Conditions: {
                value: '{"fieldConditions":[]}',
              },
              "Field Key": {
                value: "49685C5ED7494EF9A73C8BEB81B082B7",
              },
              "Collapsed by Default": {
                value: false,
              },
            },
            items: [],
          },
          {
            id: "63325b5b-c209-4ad6-8f40-67bf5d4986f0",
            name: "source",
            displayName: "source",
            parentId: "30844f80-3099-4c1d-a684-1f92721ac3e3",
            fields: {
              "Html Tag": {
                value: "p",
              },
              Text: {
                value: "4",
              },
              "Css Class": {
                value: "",
              },
              HelpText: {
                value: "",
              },
              GeneralError: {
                value: "",
              },
              SpecificError: {
                value: "",
              },
              isHidden: {
                value: true,
              },
              apiName: {
                id: "0d6d0e98-3398-447a-90a9-94636f297d18",
                fieldType: "Droplink",
                fieldName: "apiName",
                url: "/sitecore/content/fwd/global/settings/list/form-api-fields/source",
                fields: {
                  value: {
                    value: "source",
                  },
                  key: {
                    value: "source",
                  },
                },
              },
              serverValidationErrorText: {
                value: "",
              },
              serverValidationLoadingText: {
                value: "",
              },
              enableServerValidation: {
                value: false,
              },
              serverValidationAPI: null,
              "Field Type": {
                id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
                fieldType: "Droptree",
                fieldName: "Field Type",
                url: "/sitecore/system/settings/forms/field-types/basic/text",
                fields: {
                  BackgroundColor: {
                    value: "Sky",
                  },
                  Icon: {
                    value: "OfficeWhite/32x32/document_text.png",
                  },
                  IsDisabled: {
                    value: false,
                  },
                  SpritePosition: {
                    value: "",
                  },
                  "Field Template": {
                    id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                    fieldType: "Droptree",
                    fieldName: "Field Template",
                    url: "/sitecore/templates/system/forms/fields/text",
                    fields: {},
                  },
                  "Allowed Validations": [],
                  "Model Type": {
                    value:
                      "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
                  },
                  "Property Editor": null,
                  "View Path": {
                    value: "FieldTemplates/Text",
                  },
                },
              },
              Conditions: {
                value: "",
              },
              "Field Key": {
                value: "",
              },
              "Collapsed by Default": {
                value: false,
              },
            },
            items: [],
          },
          {
            id: "23e5c856-0710-4668-87b6-4c68b6b44001",
            name: "requireULLicense",
            displayName: "requireULLicense",
            parentId: "3627d278-2067-4aa3-831e-496e0af1bd30",
            fields: {
              "Html Tag": {
                value: "p",
              },
              Text: {
                value: "N",
              },
              "Css Class": {
                value: "",
              },
              isHidden: {
                value: true,
              },
              HelpText: {
                value: "",
              },
              GeneralError: {
                value: "",
              },
              SpecificError: {
                value: "",
              },
              apiName: {
                id: "865d0d1e-9b62-4e35-af48-f3912b20dd0c",
                fieldType: "Droplink",
                fieldName: "apiName",
                url: "/sitecore/content/fwd/global/settings/list/form-api-fields/requireullicense",
                fields: {
                  value: {
                    value: "requireULLicense",
                  },
                  key: {
                    value: "requireULLicense",
                  },
                },
              },
              "Field Type": {
                id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
                fieldType: "Droptree",
                fieldName: "Field Type",
                url: "/sitecore/system/settings/forms/field-types/basic/text",
                fields: {
                  BackgroundColor: {
                    value: "Sky",
                  },
                  Icon: {
                    value: "OfficeWhite/32x32/document_text.png",
                  },
                  IsDisabled: {
                    value: false,
                  },
                  SpritePosition: {
                    value: "",
                  },
                  "Field Template": {
                    id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                    fieldType: "Droptree",
                    fieldName: "Field Template",
                    url: "/sitecore/templates/system/forms/fields/text",
                    fields: {},
                  },
                  "Allowed Validations": [],
                  "Model Type": {
                    value:
                      "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
                  },
                  "Property Editor": null,
                  "View Path": {
                    value: "FieldTemplates/Text",
                  },
                },
              },
              Conditions: {
                value: '{"fieldConditions":[]}',
              },
              "Field Key": {
                value: "49685C5ED7494EF9A73C8BEB81B0867D",
              },
              "Collapsed by Default": {
                value: false,
              },
            },
            items: [],
          },
        ],
      };
      enquirySteps.push(thankYouBlock);
    }
  });

  const enquiryBlock = {
    ...enf,
    ...{
      enquirySteps: enquirySteps,
    },
  };

  return enquiryBlock;
}
