import I18nContext from "i18n/context/LanguageContext";
import { useContext } from "react";
import React from "react";
import { getGroupEnquiryFormProps } from "./getCallbackGroupEnquiryFormProps";
import axios from "axios";
import { resolveAbsoluteUrlInParagraph } from "utils/route";
import { getEnquiryServices } from "./getEnquiryServices";
import { useRouter } from "next/router";
import { getCountryCode } from "utils/string";

import dynamic from "next/dynamic";
const NextGenGroupEnquiryForm = dynamic<any>(() => import("@fwd-dep/nextgen-ui-lib").then(mod => mod.GroupEnquiryForm))

interface ApiStatus {
  loading: boolean;
  apiStatus: string | null;
  error: boolean | null;
  errorCode: number | null;
  failCounter: number;
  message: string;
}

export const CSEnquiryFormBlock = (csData: any) => {
  const data = csData?.data?.single_page_reference?.[0];
  const additionalInfos = csData?.data?.additional_infos?.[0];
  const i18nContext = useContext(I18nContext);
  const language = i18nContext.language;
  const router = useRouter();
  const dictionary = {
    "global-error-validation-msg": data?.global_error_validation_msg,
    enquiry: data?.form_type,
    apiErrorMessageForm: resolveAbsoluteUrlInParagraph(
      data?.api_error_message_form,
      i18nContext
    ),
    sending: data?.sending,
    enterKeyInfo: data?.next_button_hint,
    genericRequiredErrorMessage: data?.generic_required_error_message,
    minLengthErrorMessage: data?.min_length_error_message || "",
    maxLengthErrorMessage: data?.max_length_error_message || "",
  };
  const props = getGroupEnquiryFormProps(
    data,
    csData?.data?.product_details_reference?.[0]?.global_field?.page_title
    || additionalInfos?.product?.expected_product_name
    || "",
    i18nContext,
    csData?.data?.publicConfigurations
  );
  const defaultAPIStatus: ApiStatus = {
    loading: false,
    apiStatus: null,
    error: null,
    errorCode: null,
    failCounter: 0,
    message: "",
  };
  const [apiStatus, setApiStatus] = React.useState(defaultAPIStatus);

  const onSubmit = async (payload: any) => {
    setApiStatus({
      loading: true,
      apiStatus: null,
      error: null,
      errorCode: null,
      failCounter: 0,
      message: "",
    });
    const configurationInfo = {
      country: getCountryCode(
        csData?.data?.publicConfigurations?.publicCountryCode
      ),
      emailTemplates:
        Object.fromEntries(
          data?.email_template?.map((e: any) => {
            return [e.template, e.uid];
          })
        ) || {},
      services: getEnquiryServices(data?.services),
    };
    payload = mapAdditionalInfos(payload);
    try {
      const response = await axios({
        method: "POST",
        url: payload?.apiUrl,
        data: {
          ...payload?.data,
          language: language.languageCode,
          locale: language.locale,
          ...configurationInfo,
        },
        headers: {
          entity: payload?.entity,
          Authorization: payload?.authorizationKey,
          ...(payload?.factorType
            ? { "factor-type": payload?.factorType }
            : null),
        },
      });

      if ([200, 201].indexOf(response.status) >= 0) {
        if (response.data !== "undefined") {
          if (response.data.success) {
            setApiStatus({
              loading: false,
              apiStatus: "success",
              error: false,
              errorCode: 200,
              failCounter: 0,
              message: "",
            });
          } else {
            setApiStatus({
              loading: false,
              apiStatus: "fail",
              error: true,
              errorCode: response.status,
              failCounter: 0,
              message: "-Request returned 0",
            });
          }
        } else {
          setApiStatus({
            loading: false,
            apiStatus: "fail",
            error: true,
            errorCode: response.status,
            failCounter: 0,
            message: "-Request failed",
          });
        }
      } else {
        setApiStatus({
          loading: false,
          apiStatus: "fail",
          error: true,
          errorCode: response.status,
          failCounter: 0,
          message: response.data,
        });
      }
    } catch (error: any) {
      setApiStatus({
        loading: false,
        apiStatus: "fail",
        error: true,
        errorCode: 400,
        failCounter: 0,
        message: error?.message,
      });
    }
  };


  const mapAdditionalInfos = (payload: any) => {
    delete payload?.data?.user?.profile?.contact.email.templateUrl;
    if (payload && payload?.data?.user?.profile?.contact?.email?.parameters) {
      payload.data.user.profile.contact.email.parameters.dTCCampaignId = additionalInfos?.product?.dtc_campaign_id || "";
      payload.data.user.profile.contact.email.parameters.requireULLicense = additionalInfos?.product?.required_ul_license || "";
      payload.data.user.profile.contact.email.parameters.expectedInsuranceType = payload.data.user.profile.contact.email.parameters.expectedInsuranceType || 
                        additionalInfos?.product?.expected_insurance_type || "";
      payload.data.user.profile.contact.email.parameters.expectedProductName = additionalInfos?.product?.expected_product_name || "";

      if (!!payload?.data?.user?.lead && additionalInfos) {
        payload.data.user.lead.dTCCampaignId = payload.data.user.lead.dTCCampaignId || additionalInfos?.product?.dtc_campaign_id || "";
        payload.data.user.lead.requireULLicense = payload.data.user.lead.requireULLicense || additionalInfos?.product?.required_ul_license || "";
        payload.data.user.lead.expectedInsuranceType = payload.data.user.lead.expectedInsuranceType || additionalInfos?.product?.expected_insurance_type || "";
        payload.data.user.lead.expectedProductName = payload.data.user.lead.expectedProductName || additionalInfos?.product?.expected_product_name || "";
      }
    }
    if (!!router.query && csData?.data?.publicConfigurations?.publicCountryCode === "ph"
      && payload?.data?.user?.lead && payload?.data?.user?.profile?.contact?.email?.parameters) {
      let keyMaps = ["utm_source", "utm_medium", "utm_campaign", "utm_content", "utm_term", "utm_id"];
      Object.keys(router.query).forEach(key => {
        if (keyMaps.indexOf(key) >= 0) {
          payload.data.user.profile.contact.email.parameters[key] = router.query[key];
          payload.data.user.lead[key] = router.query[key];
        }
      })
    }
    return payload;
  };

  return (
    <>
      <NextGenGroupEnquiryForm
        saveLeadAPI={onSubmit}
        resetAPIStatus={() => setApiStatus(defaultAPIStatus)}
        apiStatus={apiStatus}
        dictionary={dictionary}
        {...props}
      />
    </>
  );
};
