import { findObjectByKeys } from "utils/object";
import { resolveAbsoluteUrlInParagraph } from "utils/route";
import { I18nContextData } from "i18n/context/LanguageContext";
import { generateUniqSerial } from "utils/uid";

export function getGroupEnquiryFormProps(
  data: any,
  productName: string,
  i18nContext: I18nContextData,
  publicConfigurations: any
) {
  let submissionUrl = data?.submission_endpoint;
  if (!/^(http:\/\/|https:\/\/|mailto:|tel:).*/.test(submissionUrl)) {
    submissionUrl = `${publicConfigurations?.publicRootUrl}${submissionUrl}`;
  }
  const modularBlocks = data?.modular_blocks || [];
  let consentBlock: any = findObjectByKeys(modularBlocks, "consent_block");
  let enquirySteps: any = [];
  let enquiryMapData: any = {
    title: data?.display_title,
    consent: {
      content: resolveAbsoluteUrlInParagraph(
        consentBlock?.content,
        i18nContext
      ),
      validationMessage: consentBlock?.validation_message,
      submitButton: consentBlock?.submit_button_text,
      submitUrl: consentBlock?.submit_url,
      redirectUrl: consentBlock?.redirect_url,
      versionCd: consentBlock?.version_cd,
      dtcCampaignId: consentBlock?.dtc_campaign_id,
      requiredUlLicense: consentBlock?.required_ul_license,
    },
    thankYouBlock: {
      title: findObjectByKeys(modularBlocks, "thank_you_screen")?.title,
      description: resolveAbsoluteUrlInParagraph(
        findObjectByKeys(modularBlocks, "thank_you_screen")?.description,
        i18nContext
      ),
      descriptionWithTime: resolveAbsoluteUrlInParagraph(
        findObjectByKeys(modularBlocks, "thank_you_screen")
          ?.description_with_time,
        i18nContext
      ),
      buttonText: findObjectByKeys(modularBlocks, "thank_you_screen")
        ?.button_text,
      activeUrl: findObjectByKeys(modularBlocks, "thank_you_screen")
        ?.active_url,
      darkMode: !!!findObjectByKeys(modularBlocks, "thank_you_screen")
        ?.white_mode,
      loadingDarkMode: !!!findObjectByKeys(modularBlocks, "thank_you_screen")
        ?.loading_white_mode,
    },
    authenticationKey: data?.authentication_key,
    submissionEndpoint: submissionUrl,
    sendEmailEndpoint: submissionUrl,
  };
  let hasLookingFor: boolean = false;
  let hasSBCustomer: boolean = false;
  let hasLeaveAMessage: boolean = false;
  let fieldTypeOfBusiness = "";
  let lastValueOfTypeOfBusiness = "";
  const titleStep = {
    id: "398a42ed-ed9c-4747-a8ee-38ded6c37df2",
    name: "title",
    displayName: "title",
    parentId: "30844f80-3099-4c1d-a684-1f92721ac3e3",
    fields: {
      "Html Tag": {
        value: "p",
      },
      Text: {
        value: enquiryMapData?.title,
      },
      "Css Class": {
        value: "block formHeading",
      },
      HelpText: {
        value: "",
      },
      GeneralError: {
        value: "",
      },
      SpecificError: {
        value: "",
      },
      isHidden: {
        value: false,
      },
      apiName: {
        id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
        fieldType: "Droplink",
        fieldName: "apiName",
        url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
        fields: {
          value: {
            value: "",
          },
          key: {
            value: "",
          },
        },
      },
      serverValidationErrorText: {
        value: "",
      },
      serverValidationLoadingText: {
        value: "",
      },
      enableServerValidation: {
        value: false,
      },
      serverValidationAPI: null,
      "Field Type": {
        id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
        fieldType: "Droptree",
        fieldName: "Field Type",
        url: "/sitecore/system/settings/forms/field-types/basic/text",
        fields: {
          BackgroundColor: {
            value: "Sky",
          },
          Icon: {
            value: "OfficeWhite/32x32/document_text.png",
          },
          IsDisabled: {
            value: false,
          },
          SpritePosition: {
            value: "",
          },
          "Field Template": {
            id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
            fieldType: "Droptree",
            fieldName: "Field Template",
            url: "/sitecore/templates/system/forms/fields/text",
            fields: {},
          },
          "Allowed Validations": [],
          "Model Type": {
            value:
              "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
          },
          "Property Editor": null,
          "View Path": {
            value: "FieldTemplates/Text",
          },
        },
      },
      Conditions: {
        value: '{"fieldConditions":[]}',
      },
      "Field Key": {
        value: "5AFBE94591DE4982B4D5427C14F0A7B0",
      },
      "Collapsed by Default": {
        value: false,
      },
    },
    items: [],
  };

  enquirySteps.push(titleStep);

  modularBlocks.forEach((block: any) => {
    if (block?.label_group) {
      const labelGroup = {
        id: "6661677d-66cf-471b-ba84-050c78f4aa15",
        name: "labelGroup",
        displayName: "labelGroup",
        parentId: "30844f80-3099-4c1d-a684-1f92721ac3e3",
        fields: {
          Datasource: {
            id: "8f559418-9052-427e-9470-808e3f757ee1",
            fieldType: "Droptree",
            fieldName: "Datasource",
            url: "/content/forms/labelGroup",
            fields: {},
          },
          "Default Selection": {
            value: "",
          },
          "Display Field Name": {
            value: "value",
          },
          "Is Dynamic": {
            value: true,
          },
          "Value Field Name": {
            value: "key",
          },
          "Css Class": {
            value: "block labelGroup label-enquiry",
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          isHidden: {
            value: false,
          },
          apiName: {
            id: "861a9ba1-7ff7-44a5-9dfa-4a1f68a5f96c",
            fieldType: "Droplink",
            fieldName: "apiName",
            url: "/sitecore/content/fwd/global/settings/list/form-api-fields/labelGroup",
            fields: {
              value: {
                value: "labelGroup",
              },
              key: {
                value: "labelGroup",
              },
            },
          },
          serverValidationErrorText: {
            value: "",
          },
          serverValidationLoadingText: {
            value: "",
          },
          enableServerValidation: {
            value: false,
          },
          serverValidationAPI: null,
          "Label Css Class": {
            value: "",
          },
          Title: {
            value: block?.label_group?.label,
          },
          Required: {
            value: false,
          },
          Validations: [],
          "Is Tracking Enabled": {
            value: false,
          },
          "Allow Save": {
            value: false,
          },
          "Value Provider Parameters": {
            value: "",
          },
          "Value Provider": null,
          "Field Type": {
            id: "edbd38a8-1ae9-42ec-8ccd-f5b0e2998b4f",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/lists/radio-button-list",
            fields: {},
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: "F322E8FAE6BF4277B710C84F66E7EDA7",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [],
      };
      enquirySteps.push(labelGroup);
    }

    if (
      !!block?.text_field?.field_name &&
      ["email", "typeOfBusinessOther"].indexOf(block?.text_field?.field_name) === -1
    ) {
      let stepClass = "";
      enquiryMapData[block?.text_field?.field_name] = {
        label: block?.text_field?.label,
        validationMessage: block?.text_field?.validation_message,
      };
      if (block?.text_field?.field_name === "lastName") {
        let middleNameIdx = modularBlocks.findIndex(
          (block: any) => block?.text_field?.field_name === "middleName"
        );
        stepClass = `${middleNameIdx >= 0 ? "lastName" : "inline lastName"}`;
      } else if (["middleName"].indexOf(block?.text_field?.field_name) >= 0) {
        stepClass = `inline ${block?.text_field?.field_name}`;
      } else if (["firstName"].indexOf(block?.text_field?.field_name) >= 0) {
        stepClass = `inline ${block?.text_field?.field_name}`;
      } else {
        stepClass = `block ${block?.text_field?.field_name}`;
      }

      const step = {
        id: generateUniqSerial(),
        name: `${block?.text_field?.field_name}Name`,
        displayName: enquiryMapData[block?.text_field?.field_name].label || "",
        parentId: "30844f80-3099-4c1d-a684-1f92721ac3e3",
        fields: {
          "Default Value": {
            value: "",
          },
          "Max Length": {
            value: `${block?.text_field?.max_length || 255}`,
          },
          "Min Length": {
            value: "0",
          },
          "Placeholder Text": {
            value: "",
          },
          dialInCodeLength: null,
          "Css Class": {
            value: `${stepClass}`,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value:
              enquiryMapData[block?.text_field?.field_name].validationMessage,
          },
          SpecificError: {
            value: "",
          },
          isHidden: {
            value: false,
          },
          apiName: {
            id: "8245a7c2-9615-4e69-ab60-10a1a6912786",
            fieldType: "Droplink",
            fieldName: "apiName",
            url: `/sitecore/content/fwd/global/settings/list/form-api-fields/${block?.text_field?.field_name}`,
            fields: {
              value: {
                value: block?.text_field?.field_name,
              },
              key: {
                value: block?.text_field?.field_name,
              },
            },
          },
          serverValidationErrorText: {
            value: "",
          },
          serverValidationLoadingText: {
            value: "",
          },
          enableServerValidation: {
            value: false,
          },
          serverValidationAPI: null,
          "Label Css Class": {
            value: "",
          },
          Title: {
            value: enquiryMapData[block?.text_field?.field_name].label,
          },
          Required: {
            value: block?.text_field?.field_name !== "middleName",
          },
          Validations: [
            {
              id: "83e96d09-11c8-4132-8a8a-76d8db8208d6",
              fields: {
                Message: {
                  value:
                    "{0} must have at least {1} and no more than {2} characters.",
                },
                Parameters: {
                  value: "",
                },
                Type: {
                  value:
                    "Sitecore.ExperienceForms.Mvc.Models.Validation.StringLengthValidation,Sitecore.ExperienceForms.Mvc",
                },
              },
            },
          ],
          "Is Tracking Enabled": {
            value: true,
          },
          "Allow Save": {
            value: true,
          },
          "Value Provider Parameters": {
            value: "",
          },
          "Value Provider": null,
          "Field Type": {
            id: "4ee89ea7-cefe-4c8e-8532-467ef64591fc",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/basic/single-line-text",
            fields: {
              BackgroundColor: {
                value: "Sky",
              },
              Icon: {
                value: "OfficeWhite/32x32/text_field.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "0908030b-4564-42ea-a6fa-c7a5a2d921a8",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/input",
                fields: {},
              },
              "Allowed Validations": [
                {
                  id: "83e96d09-11c8-4132-8a8a-76d8db8208d6",
                  fields: {
                    Message: {
                      value:
                        "{0} must have at least {1} and no more than {2} characters.",
                    },
                    Parameters: {
                      value: "",
                    },
                    Type: {
                      value:
                        "Sitecore.ExperienceForms.Mvc.Models.Validation.StringLengthValidation,Sitecore.ExperienceForms.Mvc",
                    },
                  },
                },
              ],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomSingleLineViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/SingleLineText",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: generateUniqSerial().split("-").join(""),
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [],
      };
      enquirySteps.push(step);
    }

    if (
      !!block?.dropdown?.field_name &&
      ["region", "homeProvince", "lookingFor", "sbcArea"].indexOf(
        block?.dropdown?.field_name
      ) === -1
    ) {
      const stepID = generateUniqSerial();
      enquiryMapData[block?.dropdown?.field_name] = {
        label: block?.dropdown?.label,
        items: block?.dropdown?.items?.map(({ item_key, item_value }: any) => {
          return {
            id: generateUniqSerial(),
            name: item_value,
            displayName: item_value,
            parentId: stepID,
            fields: {
              value: {
                value: item_value,
              },
              key: {
                value: item_key,
              },
            },
          };
        }),
        subLabel: block?.dropdown?.sub_label,
      };
      const step = {
        id: stepID,
        name: block?.dropdown?.field_name,
        displayName: block?.dropdown?.field_name,
        parentId: "30844f80-3099-4c1d-a684-1f92721ac3e3",
        fields: {
          "Show Empty Item": {
            value: true,
          },
          isAutoSuggest: {
            value: false,
          },
          isDynamicDropdown: {
            value: false,
          },
          node: {
            value: "",
          },
          Datasource: {
            id: "e829a0df-4294-44ce-aef1-7996d5170c6e",
            fieldType: "Droptree",
            fieldName: "Datasource",
            url: "/content/forms/business-types",
            fields: {},
          },
          "Default Selection": {
            value: "",
          },
          "Display Field Name": {
            value: "value",
          },
          "Is Dynamic": {
            value: true,
          },
          "Value Field Name": {
            value: "key",
          },
          "Css Class": {
            value: "block typeOfBusiness",
          },
          HelpText: {
            value: enquiryMapData[block?.dropdown?.field_name].subLabel,
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          isHidden: {
            value: false,
          },
          apiName: {
            id: "87cc12b9-dd79-4dbe-86ea-96597e36682d",
            fieldType: "Droplink",
            fieldName: "apiName",
            url: `/sitecore/content/fwd/global/settings/list/form-api-fields/${block?.dropdown?.field_name}`,
            fields: {
              value: {
                value: block?.dropdown?.field_name,
              },
              key: {
                value: block?.dropdown?.field_name,
              },
            },
          },
          serverValidationErrorText: {
            value: "",
          },
          serverValidationLoadingText: {
            value: "",
          },
          enableServerValidation: {
            value: false,
          },
          serverValidationAPI: null,
          "Label Css Class": {
            value: "",
          },
          Title: {
            value: enquiryMapData[block?.dropdown?.field_name]?.label,
          },
          Required: {
            value: true,
          },
          Validations: [],
          "Is Tracking Enabled": {
            value: true,
          },
          "Allow Save": {
            value: true,
          },
          "Value Provider Parameters": {
            value: "",
          },
          "Value Provider": null,
          "Collapsed by Default": {
            value: false,
          },
          "Field Type": {
            id: "e0cfadee-1ac0-471d-a820-2e70d1547b4b",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/lists/dropdown-list",
            fields: {
              BackgroundColor: {
                value: "Grass",
              },
              Icon: {
                value: "OfficeWhite/32x32/drop_down_list.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "9121d435-48b8-4649-9d13-03d680474fad",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/dropdownlist",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomDropDownListViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/DropdownList",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: generateUniqSerial().split("-").join(""),
          },
        },
        items: enquiryMapData[block?.dropdown?.field_name]?.items || [],
      };
      enquirySteps.push(step);
      // Map Leave a Message 
      if (["applyingFor"].indexOf(block?.dropdown?.field_name) >= 0) {
        hasLeaveAMessage = true;
      }
    }

    if (
      !!block?.radios_group?.field_name &&
      ["subject", "date", "time", "sbcCustomer"].indexOf(
        block?.radios_group?.field_name
      ) === -1
    ) {
      const stepID = generateUniqSerial();
      const fieldID = generateUniqSerial().split("-").join("");
      if (block?.radios_group?.field_name === "typeOfBusiness") {
        fieldTypeOfBusiness = fieldID;
        const length = block?.radios_group?.items?.length || 0;
        lastValueOfTypeOfBusiness = length > 0 ? block?.radios_group?.items?.[length-1]?.item_value : "";
      }

      enquiryMapData[block?.radios_group?.field_name] = {
        label: block?.radios_group?.label,
        items:
          block?.radios_group?.items && block?.radios_group?.items.length
            ? block?.radios_group?.items?.map(
                ({ item_key, item_value }: any) => {
                  return {
                    id: generateUniqSerial(),
                    name: item_value,
                    displayName: item_value,
                    parentId: stepID,
                    fields: {
                      value: {
                        value: item_value,
                      },
                      key: {
                        value: item_key,
                      },
                    },
                  };
                }
              )
            : [],
      };
      const labelStep = {
        id: generateUniqSerial(),
        name: `label${block?.radios_group?.field_name}`,
        displayName: `label${block?.radios_group?.field_name}`,
        parentId: "30844f80-3099-4c1d-a684-1f92721ac3e3",
        fields: {
          "Html Tag": {
            value: "p",
          },
          Text: {
            value: "",
          },
          "Css Class": {
            value: "block fieldHeading",
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          isHidden: {
            value: false,
          },
          apiName: {
            id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
            fieldType: "Droplink",
            fieldName: "apiName",
            url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
            fields: {
              value: {
                value: "",
              },
              key: {
                value: "",
              },
            },
          },
          serverValidationErrorText: {
            value: "",
          },
          serverValidationLoadingText: {
            value: "",
          },
          enableServerValidation: {
            value: false,
          },
          serverValidationAPI: null,
          "Field Type": {
            id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/basic/text",
            fields: {
              BackgroundColor: {
                value: "Sky",
              },
              Icon: {
                value: "OfficeWhite/32x32/document_text.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/text",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Text",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: generateUniqSerial().split("-").join(""),
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [],
      };
      const step = {
        id: stepID,
        name: block?.radios_group?.field_name,
        displayName: block?.radios_group?.label,
        parentId: "30844f80-3099-4c1d-a684-1f92721ac3e3",
        fields: {
          Datasource: {
            id: "8f559418-9052-427e-9470-808e3f757ee1",
            fieldType: "Droptree",
            fieldName: "Datasource",
            url: `/content/forms/${block?.radios_group?.field_name}`,
            fields: {},
          },
          "Default Selection": {
            value: "",
          },
          "Display Field Name": {
            value: "value",
          },
          "Is Dynamic": {
            value: true,
          },
          "Value Field Name": {
            value: "key",
          },
          "Css Class": {
            value: `block ${block?.radios_group?.field_name}`,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          isHidden: {
            value: false,
          },
          apiName: {
            id: "861a9ba1-7ff7-44a5-9dfa-4a1f68a5f96c",
            fieldType: "Droplink",
            fieldName: "apiName",
            url: `/sitecore/content/fwd/global/settings/list/form-api-fields/${block?.radios_group?.field_name}`,
            fields: {
              value: {
                value: block?.radios_group?.field_name,
              },
              key: {
                value: block?.radios_group?.field_name,
              },
            },
          },
          serverValidationErrorText: {
            value: "",
          },
          serverValidationLoadingText: {
            value: "",
          },
          enableServerValidation: {
            value: false,
          },
          serverValidationAPI: null,
          "Label Css Class": {
            value: "",
          },
          Title: {
            value: block?.radios_group?.label,
          },
          Required: {
            value: true,
          },
          Validations: [],
          "Is Tracking Enabled": {
            value: true,
          },
          "Allow Save": {
            value: true,
          },
          "Value Provider Parameters": {
            value: "",
          },
          "Value Provider": null,
          "Field Type": {
            id: "edbd38a8-1ae9-42ec-8ccd-f5b0e2998b4f",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/lists/radio-button-list",
            fields: {
              BackgroundColor: {
                value: "Grass",
              },
              Icon: {
                value: "OfficeWhite/32x32/radio_button_group.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "5b672865-55d2-413e-b699-fdfc7e732ccf",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/list",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomRadioButtonListViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/RadioButtonList",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: fieldID,
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: enquiryMapData[block?.radios_group?.field_name]?.items || [],
      };
      enquirySteps.push(labelStep, step);
    }

    if (!!block?.checkboxes?.field_name) {
      const stepID = generateUniqSerial();
      enquiryMapData[block?.checkboxes?.field_name] = {
        label: block?.checkboxes?.label,
        items: block?.checkboxes?.items?.map(
          ({ item_key, item_value }: any) => {
            return {
              id: generateUniqSerial(),
              name: item_value,
              displayName: item_value,
              parentId: stepID,
              fields: {
                value: {
                  value: item_value,
                },
                key: {
                  value: item_key,
                },
              },
            };
          }
        ),
        itemPerRows: block?.checkboxes?.items_on_row,
      };

      const labelStep = {
        id: generateUniqSerial(),
        name: block?.checkboxes?.label,
        displayName: block?.checkboxes?.label,
        parentId: "30844f80-3099-4c1d-a684-1f92721ac3e3",
        fields: {
          "Html Tag": {
            value: "p",
          },
          Text: {
            value: "",
          },
          "Css Class": {
            value: "block fieldHeading",
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          isHidden: {
            value: false,
          },
          apiName: {
            id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
            fieldType: "Droplink",
            fieldName: "apiName",
            url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
            fields: {
              value: {
                value: "",
              },
              key: {
                value: "",
              },
            },
          },
          serverValidationErrorText: {
            value: "",
          },
          serverValidationLoadingText: {
            value: "",
          },
          enableServerValidation: {
            value: false,
          },
          serverValidationAPI: null,
          "Field Type": {
            id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/basic/text",
            fields: {
              BackgroundColor: {
                value: "Sky",
              },
              Icon: {
                value: "OfficeWhite/32x32/document_text.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/text",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Text",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: generateUniqSerial().split("-").join(""),
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [],
      };
      const step = {
        id: stepID,
        name: block?.checkboxes?.field_name,
        displayName: block?.checkboxes?.field_name,
        parentId: "30844f80-3099-4c1d-a684-1f92721ac3e3",
        fields: {
          Datasource: {
            id: "503a96c5-c7ce-42de-8bc0-57906d93273e",
            fieldType: "Droptree",
            fieldName: "Datasource",
            url: `/content/forms/${block?.checkboxes?.field_name}`,
            fields: {},
          },
          "Default Selection": {
            value: "",
          },
          "Display Field Name": {
            value: "value",
          },
          "Is Dynamic": {
            value: true,
          },
          "Value Field Name": {
            value: "key",
          },
          "Css Class": {
            value: `block coverage itemOnRows-${
              enquiryMapData[block?.checkboxes?.field_name]?.itemPerRows || 2
            }`,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          isHidden: {
            value: false,
          },
          apiName: {
            id: "9391050f-eb85-43d5-b946-b13e2dacb0b3",
            fieldType: "Droplink",
            fieldName: "apiName",
            url: `/sitecore/content/fwd/global/settings/list/form-api-fields/${block?.checkboxes?.field_name}`,
            fields: {
              value: {
                value: block?.checkboxes?.field_name,
              },
              key: {
                value: block?.checkboxes?.field_name,
              },
            },
          },
          serverValidationErrorText: {
            value: "",
          },
          serverValidationLoadingText: {
            value: "",
          },
          enableServerValidation: {
            value: false,
          },
          serverValidationAPI: null,
          "Label Css Class": {
            value: "",
          },
          Title: {
            value: enquiryMapData[block?.checkboxes?.field_name]?.label,
          },
          Required: {
            value: true,
          },
          Validations: [],
          "Is Tracking Enabled": {
            value: true,
          },
          "Allow Save": {
            value: true,
          },
          "Value Provider Parameters": {
            value: "",
          },
          "Value Provider": null,
          "Field Type": {
            id: "d86a361a-d4ff-46b2-9e97-a37fc5b1fe1a",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/lists/checkbox-list",
            fields: {
              BackgroundColor: {
                value: "Grass",
              },
              Icon: {
                value: "OfficeWhite/32x32/checkbox_group.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "5b672865-55d2-413e-b699-fdfc7e732ccf",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/list",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomCheckBoxListViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/CheckBoxList",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: generateUniqSerial().split("-").join(""),
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: enquiryMapData[block?.checkboxes?.field_name]?.items || [],
      };
      enquirySteps.push(labelStep, step);
    }

    if (block?.text_field?.field_name === "email") {
      enquiryMapData[block?.text_field?.field_name] = {
        label: block?.text_field?.label,
        validationMessage: block?.text_field?.validation_message,
        validationRegexMessage: block?.text_field?.validation_rules?.message,
        validationRegex: block?.text_field?.validation_rules?.regex,
      };

      const email = {
        id: "ca053dbc-e596-4da8-b0b9-04c2a55b1043",
        name: "email",
        displayName: enquiryMapData?.email?.label,
        parentId: "30844f80-3099-4c1d-a684-1f92721ac3e3",
        fields: {
          "Default Value": {
            value: "",
          },
          "Max Length": {
            value: "255",
          },
          "Min Length": {
            value: "0",
          },
          "Placeholder Text": {
            value: "",
          },
          dialInCodeLength: null,
          "Css Class": {
            value: "block email",
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: enquiryMapData?.email?.validationMessage,
          },
          SpecificError: {
            value: "",
          },
          isHidden: {
            value: false,
          },
          apiName: {
            id: "fa36b97a-b013-41b6-b33a-b8c3e2843137",
            fieldType: "Droplink",
            fieldName: "apiName",
            url: "/sitecore/content/fwd/global/settings/list/form-api-fields/email",
            fields: {
              value: {
                value: "email",
              },
              key: {
                value: "email",
              },
            },
          },
          serverValidationErrorText: {
            value: "Email is invalid",
          },
          serverValidationLoadingText: {
            value: "",
          },
          enableServerValidation: {
            value: !!(data?.server_side_validation),
          },
          serverValidationAPI:
            !!(data?.server_side_validation)
              ? {
                  fields: {
                    value: {
                      value:
                        `${publicConfigurations.publicApiUrl}/api/enquiry/validate`,
                    },
                    key: {
                      value:
                        "",
                    },
                  },
                }
              : null,
          "Label Css Class": {
            value: "",
          },
          Title: {
            value: enquiryMapData?.email?.label,
          },
          Required: {
            value: true,
          },
          Validations: [
            {
              id: "9bae3e9a-d89f-4f93-9577-68b5e9d44a38",
              fields: {
                Message: {
                  value: enquiryMapData?.email?.validationRegexMessage,
                },
                Parameters: {
                  value: enquiryMapData?.email?.validationRegex,
                },
                Type: {
                  value:
                    "FWD.Foundation.Forms.Validators.CustomRegularExpressionValidation,FWD.Foundation.Forms",
                },
              },
            },
          ],
          "Is Tracking Enabled": {
            value: true,
          },
          "Allow Save": {
            value: true,
          },
          "Value Provider Parameters": {
            value: "",
          },
          "Value Provider": null,
          "Field Type": {
            id: "04c39cac-8976-4910-be0d-879ed3368429",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/basic/email",
            fields: {
              BackgroundColor: {
                value: "Sky",
              },
              Icon: {
                value: "OfficeWhite/32x32/mail.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "886adec1-abf8-40e1-9926-d9189c4e8e1b",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/email",
                fields: {},
              },
              "Allowed Validations": [
                {
                  id: "9bae3e9a-d89f-4f93-9577-68b5e9d44a38",
                  fields: {
                    Message: {
                      value: enquiryMapData?.email?.validationRegexMessage,
                    },
                    Parameters: {
                      value: enquiryMapData?.email?.validationRegex,
                    },
                    Type: {
                      value:
                        "FWD.Foundation.Forms.Validators.CustomRegularExpressionValidation,FWD.Foundation.Forms",
                    },
                  },
                },
                {
                  id: "83e96d09-11c8-4132-8a8a-76d8db8208d6",
                  fields: {
                    Message: {
                      value:
                        "{0} must have at least {1} and no more than {2} characters.",
                    },
                    Parameters: {
                      value: "",
                    },
                    Type: {
                      value:
                        "Sitecore.ExperienceForms.Mvc.Models.Validation.StringLengthValidation,Sitecore.ExperienceForms.Mvc",
                    },
                  },
                },
              ],
              "Model Type": {
                value:
                  " FWD.Foundation.Forms.CustomSingleLineViewModel, FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Email",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: "5E6F5F3982364938B0EF5969FDA65B1F",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [],
      };
      enquirySteps.push(email);
    }

    if (block?.phone_number_block) {
      enquiryMapData["phoneNumber"] = {
        label: block?.phone_number_block?.placeholder,
        prefix: block?.phone_number_block?.prefix,
        code: block?.phone_number_block?.country_code,
        validationMessage: block?.phone_number_block?.validation_message,
        validationRegexMessage: findObjectByKeys(
          modularBlocks,
          "phone_number_block"
        )?.validation_rules?.message,
        validationRegex: block?.phone_number_block?.validation_rules?.regex,
        minLength: block?.phone_number_block?.min_length,
        maxLength: block?.phone_number_block?.max_length,
      };
      const countryCode = {
        id: "f00a016a-68b8-4e88-aeaa-5b74d8e4269d",
        name: "countryCode",
        displayName: "countryCode",
        parentId: "30844f80-3099-4c1d-a684-1f92721ac3e3",
        fields: {
          "Default Value": {
            value: enquiryMapData?.phoneNumber?.code,
          },
          "Max Length": {
            value: "255",
          },
          "Min Length": {
            value: "0",
          },
          "Placeholder Text": {
            value: "",
          },
          dialInCodeLength: null,
          "Css Class": {
            value: "inline disabled countryCodeInput",
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          isHidden: {
            value: false,
          },
          apiName: {
            id: "01b8756b-4bc7-4534-b990-516943821261",
            fieldType: "Droplink",
            fieldName: "apiName",
            url: "/sitecore/content/fwd/global/settings/list/form-api-fields/countrycode",
            fields: {
              value: {
                value: "countryCode",
              },
              key: {
                value: "countryCode",
              },
            },
          },
          serverValidationErrorText: {
            value: "",
          },
          serverValidationLoadingText: {
            value: "",
          },
          enableServerValidation: {
            value: false,
          },
          serverValidationAPI: null,
          "Label Css Class": {
            value: "",
          },
          Title: {
            value: enquiryMapData?.phoneNumber?.prefix,
          },
          Required: {
            value: true,
          },
          Validations: [],
          "Is Tracking Enabled": {
            value: true,
          },
          "Allow Save": {
            value: true,
          },
          "Value Provider Parameters": {
            value: "",
          },
          "Value Provider": null,
          "Field Type": {
            id: "4ee89ea7-cefe-4c8e-8532-467ef64591fc",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/basic/single-line-text",
            fields: {
              BackgroundColor: {
                value: "Sky",
              },
              Icon: {
                value: "OfficeWhite/32x32/text_field.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "0908030b-4564-42ea-a6fa-c7a5a2d921a8",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/input",
                fields: {},
              },
              "Allowed Validations": [
                {
                  id: "83e96d09-11c8-4132-8a8a-76d8db8208d6",
                  fields: {
                    Message: {
                      value:
                        "{0} must have at least {1} and no more than {2} characters.",
                    },
                    Parameters: {
                      value: "",
                    },
                    Type: {
                      value:
                        "Sitecore.ExperienceForms.Mvc.Models.Validation.StringLengthValidation,Sitecore.ExperienceForms.Mvc",
                    },
                  },
                },
              ],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomSingleLineViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/SingleLineText",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: "7B939E0F1DAB43619E74A87C2381C32C",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [],
      };

      const phoneNumber = {
        id: "b510d847-adf7-435c-9ffa-2ac63000884b",
        name: "telephone",
        displayName: "Phone number",
        parentId: "30844f80-3099-4c1d-a684-1f92721ac3e3",
        fields: {
          "Default Value": {
            value: "000000000",
          },
          "Max Length": {
            value: enquiryMapData?.phoneNumber?.maxLength,
          },
          "Min Length": {
            value: enquiryMapData?.phoneNumber?.minLength,
          },
          "Placeholder Text": {
            value: "",
          },
          dialInCodeLength: 0,
          "Css Class": {
            value: "inline telephoneInput",
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: enquiryMapData?.phoneNumber?.validationMessage,
          },
          SpecificError: {
            value: "",
          },
          isHidden: {
            value: false,
          },
          apiName: {
            id: "22259656-b6e9-4f74-993e-4578dbcf0741",
            fieldType: "Droplink",
            fieldName: "apiName",
            url: "/sitecore/content/fwd/global/settings/list/form-api-fields/cellphone",
            fields: {
              value: {
                value: "cellPhone",
              },
              key: {
                value: "cellPhone",
              },
            },
          },
          serverValidationErrorText: {
            value: "Phone is invalid",
          },
          serverValidationLoadingText: {
            value: "",
          },
          enableServerValidation: {
            value: !!(data?.server_side_validation),
          },
          serverValidationAPI:
            !!(data?.server_side_validation)
              ? {
                  fields: {
                    value: {
                      value:
                        `${publicConfigurations.publicApiUrl}/api/enquiry/validate`,
                    },
                    key: {
                      value:
                        "",
                    },
                  },
                }
              : null,
          "Label Css Class": {
            value: "",
          },
          Title: {
            value: enquiryMapData?.phoneNumber?.label,
          },
          Required: {
            value: true,
          },
          Validations: [
            {
              id: "4a662f4f-977d-452c-ac86-edb8eb1c7f7d",
              fields: {
                Message: {
                  value: enquiryMapData?.phoneNumber?.validationRegexMessage,
                },
                Parameters: {
                  value: enquiryMapData?.phoneNumber?.validationRegex,
                },
                Type: {
                  value:
                    "FWD.Foundation.Forms.Validators.CustomRegularExpressionValidation,FWD.Foundation.Forms",
                },
              },
            },
          ],
          "Is Tracking Enabled": {
            value: true,
          },
          "Allow Save": {
            value: true,
          },
          "Value Provider Parameters": {
            value: "",
          },
          "Value Provider": null,
          "Field Type": {
            id: "df74f55b-47e6-4d1c-92f8-b0d46a7b2704",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/basic/telephone",
            fields: {
              BackgroundColor: {
                value: "Sky",
              },
              Icon: {
                value: "OfficeWhite/32x32/mobile_phone.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "0908030b-4564-42ea-a6fa-c7a5a2d921a8",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/input",
                fields: {},
              },
              "Allowed Validations": [
                {
                  id: "1aa74652-2177-48c9-88f5-1214445fea34",
                  fields: {
                    Message: {
                      value: "Please input 9 to 10 digits phone number.",
                    },
                    Parameters: {
                      value: "//^d +$//",
                    },
                    Type: {
                      value:
                        "FWD.Foundation.Forms.Validators.CustomRegularExpressionValidation,FWD.Foundation.Forms",
                    },
                  },
                },
                {
                  id: "83e96d09-11c8-4132-8a8a-76d8db8208d6",
                  fields: {
                    Message: {
                      value:
                        "{0} must have at least {1} and no more than {2} characters.",
                    },
                    Parameters: {
                      value: "",
                    },
                    Type: {
                      value:
                        "Sitecore.ExperienceForms.Mvc.Models.Validation.StringLengthValidation,Sitecore.ExperienceForms.Mvc",
                    },
                  },
                },
              ],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomSingleLineViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Telephone",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: "FA943FCD68AF4246A0AC171EDC55B0CB",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [],
      };
      if (!!enquiryMapData?.phoneNumber?.code) {
        enquirySteps.push(countryCode);
      }
      enquirySteps.push(phoneNumber);
    }

    if (block?.dob_block) {
      const dob = {
        id: "c7a9a0a1-9f24-47aa-b654-277e8f72debf",
        name: "dob",
        displayName: {
          day: block?.dob_block?.day?.default_text,
          month: block?.dob_block?.month?.default_text,
          year: block?.dob_block?.year?.default_text,
        },
        parentId: "3cb1e880-b628-4ef4-89f8-80e7bcbddc7d",
        fields: {
          "Show Empty Item": {
            value: true,
          },
          isAutoSuggest: {
            value: false,
          },
          Datasource: {
            id: "18de3e80-78b9-48b3-a1f1-aedf03565306",
            fieldType: "Droptree",
            fieldName: "Datasource",
            url: "/content/forms/contactdaylist-general-enquiry",
            fields: {},
          },
          Text: {
            value: "",
          },
          "Default Selection": {
            value: "1",
          },
          "Display Field Name": {
            value: "value",
          },
          "Is Dynamic": {
            value: true,
          },
          "Value Field Name": {
            value: "key",
          },
          "Css Class": {
            value: "inline",
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: block?.dob_block?.validationMessage,
          },
          SpecificError: {
            value: "",
          },
          apiName: {
            id: "58b136a7-764d-48c7-a721-a53d8fa01074",
            fieldType: "Droplink",
            fieldName: "apiName",
            url: "/sitecore/content/fwd/global/settings/list/form-api-fields/dob",
            fields: {
              value: {
                value: "dob",
              },
              key: {
                value: "dob",
              },
            },
          },
          minimumYear: {
            value: block?.dob_block?.year?.minimum_year,
          },
          maximumYear: {
            value: block?.dob_block?.year?.maximum_year,
          },
          "Label Css Class": {
            value: "",
          },
          Title: {
            value: "",
          },
          Required: {
            value: true,
          },
          Validations: [],
          "Is Tracking Enabled": {
            value: true,
          },
          "Allow Save": {
            value: true,
          },
          "Value Provider Parameters": {
            value: "",
          },
          "Value Provider": null,
          "Collapsed by Default": {
            value: false,
          },
          "Field Type": {
            id: "e0cfadee-1ac0-471d-a820-2e70d1547b4b",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/dob",
            fields: {
              BackgroundColor: {
                value: "Grass",
              },
              Icon: {
                value: "OfficeWhite/32x32/drop_down_list.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "9121d435-48b8-4649-9d13-03d680474fad",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/dropdownlist",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomDropDownListViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/DropdownList",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: "dob",
          },
        },
        items: [],
      };
      enquirySteps.push(dob);
    }

    if (["homeProvince", "region"].indexOf(block?.dropdown?.field_name) >= 0) {
      enquiryMapData[block?.dropdown?.field_name] = {
        label: block?.dropdown?.label,
        items: block?.dropdown?.items?.map(({ item_key, item_value }: any) => {
          return {
            id: `276d2cc4-8bde-4e8d-9b88-55da1298bb15-${item_key}`,
            name: item_value,
            displayName: item_value,
            parentId: "bb339335-5ea1-4dac-a9da-35a04b0a0c46",
            fields: {
              value: {
                value: item_value,
              },
              key: {
                value: item_key,
              },
            },
          };
        }),
      };

      const region = {
        id: "21e73a25-09fa-4e74-afc0-394e726fe79f",
        name: "region",
        displayName: "Region",
        parentId: "30844f80-3099-4c1d-a684-1f92721ac3e3",
        fields: {
          "Show Empty Item": {
            value: true,
          },
          isAutoSuggest: {
            value: false,
          },
          isDynamicDropdown: {
            value: false,
          },
          node: {
            value: "",
          },
          Datasource: {
            id: "bb339335-5ea1-4dac-a9da-35a04b0a0c46",
            fieldType: "Droptree",
            fieldName: "Datasource",
            url: "/content/forms/contactdaylist",
            fields: {},
          },
          "Default Selection": {
            value: "",
          },
          "Display Field Name": {
            value: "value",
          },
          "Is Dynamic": {
            value: true,
          },
          "Value Field Name": {
            value: "key",
          },
          "Css Class": {
            value: "block lookingFor",
          },
          HelpText: {
            value: block?.dropdown?.sub_label,
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          isHidden: {
            value: false,
          },
          apiName: {
            id: "58b136a7-764d-48c7-a721-a53d8fa01074",
            fieldType: "Droplink",
            fieldName: "apiName",
            url: "/sitecore/content/fwd/global/settings/list/form-api-fields/homeProvince",
            fields: {
              value: {
                value: "homeProvince",
              },
              key: {
                value: "homeProvince",
              },
            },
          },
          serverValidationErrorText: {
            value: "",
          },
          serverValidationLoadingText: {
            value: "",
          },
          enableServerValidation: {
            value: false,
          },
          serverValidationAPI: null,
          "Label Css Class": {
            value: "",
          },
          Title: {
            value: enquiryMapData[block?.dropdown?.field_name]?.label,
          },
          Required: {
            value: true,
          },
          Validations: [],
          "Is Tracking Enabled": {
            value: true,
          },
          "Allow Save": {
            value: true,
          },
          "Value Provider Parameters": {
            value: "",
          },
          "Value Provider": null,
          "Collapsed by Default": {
            value: false,
          },
          "Field Type": {
            id: "e0cfadee-1ac0-471d-a820-2e70d1547b4b",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/lists/dropdown-list",
            fields: {
              BackgroundColor: {
                value: "Grass",
              },
              Icon: {
                value: "OfficeWhite/32x32/drop_down_list.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "9121d435-48b8-4649-9d13-03d680474fad",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/dropdownlist",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomDropDownListViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/DropdownList",
              },
            },
          },
          Conditions: {
            value: `{"fieldConditions":[]}`,
          },
          "Field Key": {
            value: "756763D51D4A42F08156F8E1F86A3EC7",
          },
        },
        items: enquiryMapData[block?.dropdown?.field_name]?.items || [],
      };
      enquirySteps.push(region);

      block?.dropdown?.items.forEach((item: any) => {
        const fieldId = generateUniqSerial().split("-").join("").toUpperCase();
        const conditions = {
          fieldConditions: [
            {
              matchTypeId: "{365C94DA-C1CD-4783-A91D-0D17A16C7118}",
              conditions: [
                {
                  fieldId: "756763D51D4A42F08156F8E1F86A3EC7",
                  operatorId: "{1D38B217-A2EE-4E7B-B6ED-13E751462FEB}",
                  value: item?.item_key,
                },
              ],
              actions: [
                {
                  fieldId: fieldId,
                  actionTypeId: "{AAE07A52-46A4-49EF-98B0-C2595BAC2382}",
                  value: null,
                },
              ],
            },
          ],
        };
        const childItems = item?.sub_items?.map(
          ({ item_key, item_value }: any) => {
            return {
              id: `276d2cc4-8bde-4e8d-9b88-55da1298bb14-${item_key}`,
              name: item_value,
              displayName: item_value,
              parentId: "bb339335-5ea1-4dac-a9da-35a04b0a0c46",
              fields: {
                value: {
                  value: item_value,
                },
                key: {
                  value: item_key,
                },
              },
            };
          }
        );
        const childDropdown = {
          id: generateUniqSerial(),
          name: `REGION${item?.item_key.replace(/\s/g, "")}`,
          displayName: `REGION${item?.item_key.replace(/\s/g, "")}`,
          parentId: "30844f80-3099-4c1d-a684-1f92721ac3e3",
          fields: {
            "Show Empty Item": {
              value: true,
            },
            isAutoSuggest: {
              value: false,
            },
            isDynamicDropdown: {
              value: false,
            },
            node: {
              value: "",
            },
            Datasource: {
              id: "bb339335-5ea1-4dac-a9da-35a04b0a0c46",
              fieldType: "Droptree",
              fieldName: "Datasource",
              url: "/content/forms/contactdaylist",
              fields: {},
            },
            "Default Selection": {
              value: "",
            },
            "Display Field Name": {
              value: "value",
            },
            "Is Dynamic": {
              value: true,
            },
            "Value Field Name": {
              value: "key",
            },
            "Css Class": {
              value: "block lookingFor",
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: "",
            },
            SpecificError: {
              value: "",
            },
            isHidden: {
              value: false,
            },
            apiName: {
              id: "58b136a7-764d-48c7-a721-a53d8fa01074",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/municipality",
              fields: {
                value: {
                  value: "municipality",
                },
                key: {
                  value: "municipality",
                },
              },
            },
            serverValidationErrorText: {
              value: "",
            },
            serverValidationLoadingText: {
              value: "",
            },
            enableServerValidation: {
              value: false,
            },
            serverValidationAPI: null,
            "Label Css Class": {
              value: "",
            },
            Title: {
              value: item?.sub_label_items,
            },
            Required: {
              value: true,
            },
            Validations: [],
            "Is Tracking Enabled": {
              value: true,
            },
            "Allow Save": {
              value: true,
            },
            "Value Provider Parameters": {
              value: "",
            },
            "Value Provider": null,
            "Collapsed by Default": {
              value: false,
            },
            "Field Type": {
              id: "e0cfadee-1ac0-471d-a820-2e70d1547b4b",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/lists/dropdown-list",
              fields: {
                BackgroundColor: {
                  value: "Grass",
                },
                Icon: {
                  value: "OfficeWhite/32x32/drop_down_list.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "9121d435-48b8-4649-9d13-03d680474fad",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/dropdownlist",
                  fields: {},
                },
                "Allowed Validations": [],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomDropDownListViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/DropdownList",
                },
              },
            },
            Conditions: {
              value: `${JSON.stringify(conditions)}`,
            },
            "Field Key": {
              value: fieldId,
            },
          },
          items: childItems || [],
        };
        if (childItems && childItems.length > 0) {
          enquirySteps.push(childDropdown);
        }
      });
    }

    if (block?.dropdown?.field_name === "lookingFor") {
      enquiryMapData[block?.dropdown?.field_name] = {
        label: block?.dropdown?.label,
        items: block?.dropdown?.items?.map(({ item_key, item_value }: any) => {
          return {
            id: `276d2cc4-8bde-4e8d-9b88-55da1298aa15-${item_key}`,
            name: item_value,
            displayName: item_value,
            parentId: "bb339335-5ea1-4dac-a9da-35a04b0a0c46",
            fields: {
              value: {
                value: item_value,
              },
              key: {
                value: item_key,
              },
            },
          };
        }),
      };

      const conditions = {
        fieldConditions: [
          {
            matchTypeId: "{365C94DA-C1CD-4783-A91D-0D17A16C7117}",
            conditions: [
              {
                fieldId: "756763D51D4A42F08156F8E1F86B3ED8",
                operatorId: "{1D38B217-A2EE-4E7B-B6ED-13E751462FEB}",
                value: "Book time with an advisor",
              },
            ],
            actions: [
              {
                fieldId: "F322E8FAE6BF4277B710C84F66E7EDB8",
                actionTypeId: "{AAE07A52-46A4-49EF-98B0-C2595BAC2382}",
                value: null,
              },
              {
                fieldId: "F322E8FAE6BF4277B710C84F66E7ECC8",
                actionTypeId: "{AAE07A52-46A4-49EF-98B0-C2595BAC2382}",
                value: null,
              },
              {
                fieldId: "F322E8FAE6BF4277B710C84F66E7EDC9",
                actionTypeId: "{AAE07A52-46A4-49EF-98B0-C2595BAC2382}",
                value: null,
              },
            ],
          },
          {
            matchTypeId: "{365C94DA-C1CD-4783-A91D-0D17A16C7118}",
            conditions: [
              {
                fieldId: "756763D51D4A42F08156F8E1F86B3ED8",
                operatorId: "{49F47E77-E8C5-46F9-BF39-78D6B0D40B48}",
                value: "Book time with an advisor",
              },
            ],
            actions: [
              {
                fieldId: "C6739D2F83B749A3A7F79BB681CC6DDA",
                actionTypeId: "{AAE07A52-46A4-49EF-98B0-C2595BAC2382}",
                value: null,
              },
            ],
          },
        ],
      };

      const lookingFor = {
        id: "21e73a25-09fa-4e74-afc0-394e726fe89f",
        name: "lookingFor",
        displayName: "Looking For",
        parentId: "30844f80-3099-4c1d-a684-1f92721ac3e3",
        fields: {
          "Show Empty Item": {
            value: true,
          },
          isAutoSuggest: {
            value: false,
          },
          isDynamicDropdown: {
            value: false,
          },
          node: {
            value: "",
          },
          Datasource: {
            id: "bb339335-5ea1-4dac-a9da-35a04b0a0c46",
            fieldType: "Droptree",
            fieldName: "Datasource",
            url: "/content/forms/contactdaylist",
            fields: {},
          },
          "Default Selection": {
            value: "",
          },
          "Display Field Name": {
            value: "value",
          },
          "Is Dynamic": {
            value: true,
          },
          "Value Field Name": {
            value: "key",
          },
          "Css Class": {
            value: "block lookingFor",
          },
          HelpText: {
            value: block?.dropdown?.sub_label,
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          isHidden: {
            value: false,
          },
          apiName: {
            id: "58b136a7-764d-48c7-a721-a53d8fa01074",
            fieldType: "Droplink",
            fieldName: "apiName",
            url: "/sitecore/content/fwd/global/settings/list/form-api-fields/queryReason",
            fields: {
              value: {
                value: "queryReason",
              },
              key: {
                value: "queryReason",
              },
            },
          },
          serverValidationErrorText: {
            value: "",
          },
          serverValidationLoadingText: {
            value: "",
          },
          enableServerValidation: {
            value: false,
          },
          serverValidationAPI: null,
          "Label Css Class": {
            value: "",
          },
          Title: {
            value: enquiryMapData[block?.dropdown?.field_name]?.label,
          },
          Required: {
            value: true,
          },
          Validations: [],
          "Is Tracking Enabled": {
            value: true,
          },
          "Allow Save": {
            value: true,
          },
          "Value Provider Parameters": {
            value: "",
          },
          "Value Provider": null,
          "Collapsed by Default": {
            value: false,
          },
          "Field Type": {
            id: "e0cfadee-1ac0-471d-a820-2e70d1547b4b",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/lists/dropdown-list",
            fields: {
              BackgroundColor: {
                value: "Grass",
              },
              Icon: {
                value: "OfficeWhite/32x32/drop_down_list.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "9121d435-48b8-4649-9d13-03d680474fad",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/dropdownlist",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomDropDownListViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/DropdownList",
              },
            },
          },
          Conditions: {
            value: `${JSON.stringify(conditions)}`,
          },
          "Field Key": {
            value: "756763D51D4A42F08156F8E1F86B3ED8",
          },
        },
        items: enquiryMapData[block?.dropdown?.field_name]?.items || [],
      };
      enquirySteps.push(lookingFor);
      hasLookingFor = true;
      hasLeaveAMessage = true;
    }

    if (block?.radios_group?.field_name === "subject" && hasLookingFor) {
      enquiryMapData[block?.radios_group?.field_name] = {
        label: block?.radios_group?.label,
        items: block?.radios_group?.items?.map(
          ({ item_key, item_value }: any) => {
            return {
              id: `3ea90c82-98ff-43ab-b3a7-e61750997fbf-${item_key}`,
              name: item_value,
              displayName: item_value,
              parentId: "8f559418-9052-427e-9470-808e3f757ee1",
              fields: {
                value: {
                  value: item_value,
                },
                key: {
                  value: item_key,
                },
              },
            };
          }
        ),
      };
      const groupTalkAbout = {
        id: "6661677d-66cf-471b-ba84-050c78f4aa26",
        name: "groupTalkAbout",
        displayName: "Group Talk About",
        parentId: "30844f80-3099-4c1d-a684-1f92721ac3e3",
        fields: {
          Datasource: {
            id: "8f559418-9052-427e-9470-808e3f757ee1",
            fieldType: "Droptree",
            fieldName: "Datasource",
            url: "/content/forms/grouptalkabout",
            fields: {},
          },
          "Default Selection": {
            value: "",
          },
          "Display Field Name": {
            value: "value",
          },
          "Is Dynamic": {
            value: true,
          },
          "Value Field Name": {
            value: "key",
          },
          "Css Class": {
            value: "block groupTalkAbout",
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          isHidden: {
            value: false,
          },
          apiName: {
            id: "861a9ba1-7ff7-44a5-9dfa-4a1f68a5f96c",
            fieldType: "Droplink",
            fieldName: "apiName",
            url: "/sitecore/content/fwd/global/settings/list/form-api-fields/productName",
            fields: {
              value: {
                value: "productName",
              },
              key: {
                value: "productName",
              },
            },
          },
          serverValidationErrorText: {
            value: "",
          },
          serverValidationLoadingText: {
            value: "",
          },
          enableServerValidation: {
            value: false,
          },
          serverValidationAPI: null,
          "Label Css Class": {
            value: "",
          },
          Title: {
            value: block?.radios_group?.label,
          },
          Required: {
            value: true,
          },
          Validations: [],
          "Is Tracking Enabled": {
            value: true,
          },
          "Allow Save": {
            value: true,
          },
          "Value Provider Parameters": {
            value: "",
          },
          "Value Provider": null,
          "Field Type": {
            id: "edbd38a8-1ae9-42ec-8ccd-f5b0e2998b4f",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/lists/radio-button-list",
            fields: {
              BackgroundColor: {
                value: "Grass",
              },
              Icon: {
                value: "OfficeWhite/32x32/radio_button_group.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "5b672865-55d2-413e-b699-fdfc7e732ccf",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/list",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomRadioButtonListViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/RadioButtonList",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: "F322E8FAE6BF4277B710C84F66E7EDB8",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: enquiryMapData[block?.radios_group?.field_name]?.items,
      };

      enquirySteps.push(groupTalkAbout);
    }

    if (block?.radios_group?.field_name === "date" && hasLookingFor) {
      enquiryMapData[block?.radios_group?.field_name] = {
        label: block?.radios_group?.label,
        items: block?.radios_group?.items?.map(
          ({ item_key, item_value }: any) => {
            return {
              id: `3ea90c82-98ff-43ab-b3a7-e61750997fbf-${item_key}`,
              name: item_value,
              displayName: item_value,
              parentId: "8f559418-9052-427e-9470-808e3f757ee1",
              fields: {
                value: {
                  value: item_value,
                },
                key: {
                  value: item_key,
                },
              },
            };
          }
        ),
      };
      const freeToTalk = {
        id: "6661677d-66cf-471b-ba84-050c78f4aa26",
        name: "freeToTalk",
        displayName: "Free To Talk",
        parentId: "30844f80-3099-4c1d-a684-1f92721ac3e3",
        fields: {
          Datasource: {
            id: "8f559418-9052-427e-9470-808e3f757ee1",
            fieldType: "Droptree",
            fieldName: "Datasource",
            url: "/content/forms/date",
            fields: {},
          },
          "Default Selection": {
            value: "",
          },
          "Display Field Name": {
            value: "value",
          },
          "Is Dynamic": {
            value: true,
          },
          "Value Field Name": {
            value: "key",
          },
          "Css Class": {
            value: "block groupTalkAbout",
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          isHidden: {
            value: false,
          },
          apiName: {
            id: "861a9ba1-7ff7-44a5-9dfa-4a1f68a5f96c",
            fieldType: "Droplink",
            fieldName: "apiName",
            url: "/sitecore/content/fwd/global/settings/list/form-api-fields/preferredDay",
            fields: {
              value: {
                value: "preferredDay",
              },
              key: {
                value: "preferredDay",
              },
            },
          },
          serverValidationErrorText: {
            value: "",
          },
          serverValidationLoadingText: {
            value: "",
          },
          enableServerValidation: {
            value: false,
          },
          serverValidationAPI: null,
          "Label Css Class": {
            value: "",
          },
          Title: {
            value: block?.radios_group?.label,
          },
          Required: {
            value: true,
          },
          Validations: [],
          "Is Tracking Enabled": {
            value: true,
          },
          "Allow Save": {
            value: true,
          },
          "Value Provider Parameters": {
            value: "",
          },
          "Value Provider": null,
          "Field Type": {
            id: "edbd38a8-1ae9-42ec-8ccd-f5b0e2998b4f",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/lists/radio-button-list",
            fields: {
              BackgroundColor: {
                value: "Grass",
              },
              Icon: {
                value: "OfficeWhite/32x32/radio_button_group.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "5b672865-55d2-413e-b699-fdfc7e732ccf",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/list",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomRadioButtonListViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/RadioButtonList",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: "F322E8FAE6BF4277B710C84F66E7ECC8",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: enquiryMapData[block?.radios_group?.field_name]?.items,
      };
      enquirySteps.push(freeToTalk);
    }

    if (block?.radios_group?.field_name === "time" && hasLookingFor) {
      enquiryMapData[block?.radios_group?.field_name] = {
        label: block?.radios_group?.label,
        items: block?.radios_group?.items?.map(
          ({ item_key, item_value }: any) => {
            return {
              id: `3ea90c82-98ff-43ab-b3a7-e61750997fbf-${item_key}`,
              name: item_value,
              displayName: item_value,
              parentId: "8f559418-9052-427e-9470-808e3f757ee1",
              fields: {
                value: {
                  value: item_value,
                },
                key: {
                  value: item_key,
                },
              },
            };
          }
        ),
      };
      const timeAvailable = {
        id: "6661677d-66cf-471b-ba84-050c78f4aa26",
        name: "timeAvailable",
        displayName: "Time Available",
        parentId: "30844f80-3099-4c1d-a684-1f92721ac3e3",
        fields: {
          Datasource: {
            id: "8f559418-9052-427e-9470-808e3f757ee1",
            fieldType: "Droptree",
            fieldName: "Datasource",
            url: "/content/forms/time",
            fields: {},
          },
          "Default Selection": {
            value: "",
          },
          "Display Field Name": {
            value: "value",
          },
          "Is Dynamic": {
            value: true,
          },
          "Value Field Name": {
            value: "key",
          },
          "Css Class": {
            value: "block groupTalkAbout",
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          isHidden: {
            value: false,
          },
          apiName: {
            id: "861a9ba1-7ff7-44a5-9dfa-4a1f68a5f96c",
            fieldType: "Droplink",
            fieldName: "apiName",
            url: "/sitecore/content/fwd/global/settings/list/form-api-fields/preferredTime",
            fields: {
              value: {
                value: "preferredTime",
              },
              key: {
                value: "preferredTime",
              },
            },
          },
          serverValidationErrorText: {
            value: "",
          },
          serverValidationLoadingText: {
            value: "",
          },
          enableServerValidation: {
            value: false,
          },
          serverValidationAPI: null,
          "Label Css Class": {
            value: "",
          },
          Title: {
            value: block?.radios_group?.label,
          },
          Required: {
            value: true,
          },
          Validations: [],
          "Is Tracking Enabled": {
            value: true,
          },
          "Allow Save": {
            value: true,
          },
          "Value Provider Parameters": {
            value: "",
          },
          "Value Provider": null,
          "Field Type": {
            id: "edbd38a8-1ae9-42ec-8ccd-f5b0e2998b4f",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/lists/radio-button-list",
            fields: {
              BackgroundColor: {
                value: "Grass",
              },
              Icon: {
                value: "OfficeWhite/32x32/radio_button_group.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "5b672865-55d2-413e-b699-fdfc7e732ccf",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/list",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomRadioButtonListViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/RadioButtonList",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: "F322E8FAE6BF4277B710C84F66E7EDC9",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: enquiryMapData[block?.radios_group?.field_name]?.items,
      };
      enquirySteps.push(timeAvailable);
    }

    if (block?.leave_us_a_message && hasLeaveAMessage) {
      const addAComment = {
        id: "43fe4bed-cefd-43ee-91d2-4c02bf5d83ae",
        name: "AddAComment",
        displayName: "AddAComment",
        parentId: "2344a106-ff60-4cbf-ac9c-c3f0994b0e83",
        fields: {
          Rows: {
            value: "4",
          },
          "Default Value": {
            value: "",
          },
          "Max Length": {
            value: "0",
          },
          "Min Length": {
            value: "0",
          },
          "Placeholder Text": {
            value:
              "Please use this space to ask any questions or make a comment",
          },
          dialInCodeLength: null,
          "Css Class": {
            value: "block",
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: block?.leave_us_a_message?.validation_message,
          },
          SpecificError: {
            value:
              "To submit your question(s) or to comment, please leave a message.",
          },
          apiName: {
            id: "87827a1f-bbbd-42b2-b966-5fa2a695f102",
            fieldType: "Droplink",
            fieldName: "apiName",
            url: "/sitecore/content/fwd/global/settings/list/form-api-fields/comment",
            fields: {
              value: {
                value: "comment",
              },
              key: {
                value: "comment",
              },
            },
          },
          "Label Css Class": {
            value: "",
          },
          Title: {
            value: block?.leave_us_a_message?.placeholder,
          },
          Required: {
            value: true,
          },
          Validations: [],
          "Is Tracking Enabled": {
            value: true,
          },
          "Allow Save": {
            value: true,
          },
          "Value Provider Parameters": {
            value: "",
          },
          "Value Provider": null,
          "Field Type": {
            id: "a296a1c1-0da0-4493-a92e-b8191f43aec6",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/basic/multiple-line-text",
            fields: {
              BackgroundColor: {
                value: "Sky",
              },
              Icon: {
                value: "OfficeWhite/32x32/text.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "d8386d04-c1e3-4cd3-9227-9e9f86ef3c88",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/multiple-line-text",
                fields: {},
              },
              "Allowed Validations": [
                {
                  id: "83e96d09-11c8-4132-8a8a-76d8db8208d6",
                  fields: {
                    Message: {
                      value:
                        "{0} must have at least {1} and no more than {2} characters.",
                    },
                    Parameters: {
                      value: "",
                    },
                    Type: {
                      value:
                        "Sitecore.ExperienceForms.Mvc.Models.Validation.StringLengthValidation,Sitecore.ExperienceForms.Mvc",
                    },
                  },
                },
              ],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomMultipleLineTextViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/MultipleLineText",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: "C6739D2F83B749A3A7F79BB681CC6DDA",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [],
      };
      enquirySteps.push(addAComment);
    }

    if (["sbcCustomer"].indexOf(block?.radios_group?.field_name) >= 0) {
      const stepID = generateUniqSerial();
      enquiryMapData[block?.radios_group?.field_name] = {
        label: block?.radios_group?.label,
        items:
          block?.radios_group?.items && block?.radios_group?.items.length
            ? block?.radios_group?.items?.map(
                ({ item_key, item_value }: any) => {
                  return {
                    id: generateUniqSerial(),
                    name: item_value,
                    displayName: item_key,
                    parentId: stepID,
                    fields: {
                      value: {
                        value: item_value,
                      },
                      key: {
                        value: item_key,
                      },
                    },
                  };
                }
              )
            : [],
      };

      const labelStep = {
        id: generateUniqSerial(),
        name: `label${block?.radios_group?.field_name}`,
        displayName: `label${block?.radios_group?.field_name}`,
        parentId: "30844f80-3099-4c1d-a684-1f92721ac3e3",
        fields: {
          "Html Tag": {
            value: "p",
          },
          Text: {
            value: "",
          },
          "Css Class": {
            value: "block fieldHeading",
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          isHidden: {
            value: false,
          },
          apiName: {
            id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
            fieldType: "Droplink",
            fieldName: "apiName",
            url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
            fields: {
              value: {
                value: "",
              },
              key: {
                value: "",
              },
            },
          },
          serverValidationErrorText: {
            value: "",
          },
          serverValidationLoadingText: {
            value: "",
          },
          enableServerValidation: {
            value: false,
          },
          serverValidationAPI: null,
          "Field Type": {
            id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/basic/text",
            fields: {
              BackgroundColor: {
                value: "Sky",
              },
              Icon: {
                value: "OfficeWhite/32x32/document_text.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/text",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/Text",
              },
            },
          },
          Conditions: {
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: generateUniqSerial().split("-").join(""),
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [],
      };
      const conditions = {
        fieldConditions: [
          {
            matchTypeId: "{365C94DA-C1CD-4783-A91D-0D17A16C7123}",
            conditions: [
              {
                fieldId: "756763D51D4A42F08156F8E1F86A92BD",
                operatorId: "{1D38B217-A2EE-4E7B-B6ED-13E751462FEB}",
                value: block?.radios_group?.items?.[0]?.item_key?.toLowerCase(),
              },
            ],
            actions: [
              {
                fieldId: `756763D51D4A42F08156F8E1F86A68C7`,
                actionTypeId: "{AAE07A52-46A4-49EF-98B0-C2595BAC2382}",
                value: null,
              },
            ],
          },
        ],
      };

      const step = {
        id: stepID,
        name: block?.radios_group?.field_name,
        displayName: block?.radios_group?.label,
        parentId: "30844f80-3099-4c1d-a684-1f92721ac3e3",
        fields: {
          Datasource: {
            id: "8f559418-9052-427e-9470-808e3f757ee1",
            fieldType: "Droptree",
            fieldName: "Datasource",
            url: `/content/forms/${block?.radios_group?.field_name}`,
            fields: {},
          },
          "Default Selection": {
            value: "",
          },
          "Display Field Name": {
            value: "value",
          },
          "Is Dynamic": {
            value: true,
          },
          "Value Field Name": {
            value: "key",
          },
          "Css Class": {
            value: `block ${block?.radios_group?.field_name}`,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          isHidden: {
            value: false,
          },
          apiName: {
            id: "861a9ba1-7ff7-44a5-9dfa-4a1f68a5f96c",
            fieldType: "Droplink",
            fieldName: "apiName",
            url: `/sitecore/content/fwd/global/settings/list/form-api-fields/${block?.radios_group?.field_name}`,
            fields: {
              value: {
                value: block?.radios_group?.field_name,
              },
              key: {
                value: block?.radios_group?.field_name,
              },
            },
          },
          serverValidationErrorText: {
            value: "",
          },
          serverValidationLoadingText: {
            value: "",
          },
          enableServerValidation: {
            value: false,
          },
          serverValidationAPI: null,
          "Label Css Class": {
            value: "",
          },
          Title: {
            value: block?.radios_group?.label,
          },
          Required: {
            value: true,
          },
          Validations: [],
          "Is Tracking Enabled": {
            value: true,
          },
          "Allow Save": {
            value: true,
          },
          "Value Provider Parameters": {
            value: "",
          },
          "Value Provider": null,
          "Field Type": {
            id: "edbd38a8-1ae9-42ec-8ccd-f5b0e2998b4f",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/lists/radio-button-list",
            fields: {
              BackgroundColor: {
                value: "Grass",
              },
              Icon: {
                value: "OfficeWhite/32x32/radio_button_group.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "5b672865-55d2-413e-b699-fdfc7e732ccf",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/list",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomRadioButtonListViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/RadioButtonList",
              },
            },
          },
          Conditions: {
            // value: '{"fieldConditions":[]}',
            value: `${JSON.stringify(conditions)}`,
          },
          "Field Key": {
            value: "756763D51D4A42F08156F8E1F86A92BD",
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: enquiryMapData[block?.radios_group?.field_name]?.items || [],
      };
      enquirySteps.push(labelStep, step);
      hasSBCustomer = true;
    }

    if (
      ["sbcArea"].indexOf(block?.dropdown?.field_name) >= 0 &&
      !!hasSBCustomer
    ) {
      const stepID = generateUniqSerial();
      enquiryMapData[block?.dropdown?.field_name] = {
        label: block?.dropdown?.label,
        items: block?.dropdown?.items?.map(({ item_key, item_value }: any) => {
          return {
            id: `276d2cc4-8bde-4e8d-9b88-55da1298bb15-${item_key}`,
            name: item_value,
            displayName: item_value,
            parentId: "bb339335-5ea1-4dac-a9da-35a04b0a0c46",
            fields: {
              value: {
                value: item_value,
              },
              key: {
                value: item_key,
              },
            },
          };
        }),
      };
      const step = {
        id: stepID,
        name: `step${block?.dropdown?.field_name}`,
        displayName: `step${block?.dropdown?.field_name}`,
        parentId: "30844f80-3099-4c1d-a684-1f92721ac3e3",
        fields: {
          "Show Empty Item": {
            value: true,
          },
          isAutoSuggest: {
            value: false,
          },
          isDynamicDropdown: {
            value: false,
          },
          node: {
            value: "",
          },
          Datasource: {
            id: "bb339335-5ea1-4dac-a9da-35a04b0a0c46",
            fieldType: "Droptree",
            fieldName: "Datasource",
            url: "/content/forms/contactdaylist",
            fields: {},
          },
          "Default Selection": {
            value: "",
          },
          "Display Field Name": {
            value: "value",
          },
          "Is Dynamic": {
            value: true,
          },
          "Value Field Name": {
            value: "key",
          },
          "Css Class": {
            value: "block lookingFor",
          },
          HelpText: {
            value: block?.dropdown?.sub_label,
          },
          GeneralError: {
            value: "",
          },
          SpecificError: {
            value: "",
          },
          isHidden: {
            value: false,
          },
          apiName: {
            id: "58b136a7-764d-48c7-a721-a53d8fa01074",
            fieldType: "Droplink",
            fieldName: "apiName",
            url: "/sitecore/content/fwd/global/settings/list/form-api-fields/sbcArea",
            fields: {
              value: {
                value: "sbcArea",
              },
              key: {
                value: "sbcArea",
              },
            },
          },
          serverValidationErrorText: {
            value: "",
          },
          serverValidationLoadingText: {
            value: "",
          },
          enableServerValidation: {
            value: false,
          },
          serverValidationAPI: null,
          "Label Css Class": {
            value: "",
          },
          Title: {
            value: enquiryMapData[block?.dropdown?.field_name]?.label,
          },
          Required: {
            value: true,
          },
          Validations: [],
          "Is Tracking Enabled": {
            value: true,
          },
          "Allow Save": {
            value: true,
          },
          "Value Provider Parameters": {
            value: "",
          },
          "Value Provider": null,
          "Collapsed by Default": {
            value: false,
          },
          "Field Type": {
            id: "e0cfadee-1ac0-471d-a820-2e70d1547b4b",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/lists/dropdown-list",
            fields: {
              BackgroundColor: {
                value: "Grass",
              },
              Icon: {
                value: "OfficeWhite/32x32/drop_down_list.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "9121d435-48b8-4649-9d13-03d680474fad",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/dropdownlist",
                fields: {},
              },
              "Allowed Validations": [],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomDropDownListViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/DropdownList",
              },
            },
          },
          Conditions: {
            // value: `${JSON.stringify(conditions)}`,
            value: '{"fieldConditions":[]}',
          },
          "Field Key": {
            value: "756763D51D4A42F08156F8E1F86A68C7",
          },
        },
        items: enquiryMapData[block?.dropdown?.field_name]?.items || [],
      };
      enquirySteps.push(step);

      block?.dropdown?.items.forEach((item: any) => {
        const conditions = {
          fieldConditions: [
            {
              matchTypeId: "{365C94DA-C1CD-4783-A91D-0D17A16C7119}",
              conditions: [
                {
                  fieldId: "756763D51D4A42F08156F8E1F86A68C7",
                  operatorId: "{1D38B217-A2EE-4E7B-B6ED-13E751462FEB}",
                  value: item?.item_key,
                },
              ],
              actions: [
                {
                  fieldId: `sbcArea${item?.item_key.replace(/\s/g, "")}`,
                  actionTypeId: "{AAE07A52-46A4-49EF-98B0-C2595BAC2382}",
                  value: null,
                },
              ],
            },
          ],
        };
        const childItems = item?.sub_items?.map(
          ({ item_key, item_value }: any) => {
            return {
              id: `276d2cc4-8bde-4e8d-9b88-55da1298bb14-${item_key}`,
              name: item_value,
              displayName: item_value,
              parentId: "bb339335-5ea1-4dac-a9da-35a04b0a0c46",
              fields: {
                value: {
                  value: item_value,
                },
                key: {
                  value: item_key,
                },
              },
            };
          }
        );
        const childDropdown = {
          id: "21e73a25-09fa-4e74-afc0-394e726fe79f",
          name: "city",
          displayName: "City",
          parentId: "30844f80-3099-4c1d-a684-1f92721ac3e3",
          fields: {
            "Show Empty Item": {
              value: true,
            },
            isAutoSuggest: {
              value: false,
            },
            isDynamicDropdown: {
              value: false,
            },
            node: {
              value: "",
            },
            Datasource: {
              id: "bb339335-5ea1-4dac-a9da-35a04b0a0c46",
              fieldType: "Droptree",
              fieldName: "Datasource",
              url: "/content/forms/contactdaylist",
              fields: {},
            },
            "Default Selection": {
              value: "",
            },
            "Display Field Name": {
              value: "value",
            },
            "Is Dynamic": {
              value: true,
            },
            "Value Field Name": {
              value: "key",
            },
            "Css Class": {
              value: "block lookingFor",
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: "",
            },
            SpecificError: {
              value: "",
            },
            isHidden: {
              value: false,
            },
            apiName: {
              id: "58b136a7-764d-48c7-a721-a53d8fa01074",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/sbcBranch",
              fields: {
                value: {
                  value: "sbcBranch",
                },
                key: {
                  value: "sbcBranch",
                },
              },
            },
            serverValidationErrorText: {
              value: "",
            },
            serverValidationLoadingText: {
              value: "",
            },
            enableServerValidation: {
              value: false,
            },
            serverValidationAPI: null,
            "Label Css Class": {
              value: "",
            },
            Title: {
              value: item?.sub_label_items,
            },
            Required: {
              value: true,
            },
            Validations: [],
            "Is Tracking Enabled": {
              value: true,
            },
            "Allow Save": {
              value: true,
            },
            "Value Provider Parameters": {
              value: "",
            },
            "Value Provider": null,
            "Collapsed by Default": {
              value: false,
            },
            "Field Type": {
              id: "e0cfadee-1ac0-471d-a820-2e70d1547b4b",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/lists/dropdown-list",
              fields: {
                BackgroundColor: {
                  value: "Grass",
                },
                Icon: {
                  value: "OfficeWhite/32x32/drop_down_list.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "9121d435-48b8-4649-9d13-03d680474fad",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/dropdownlist",
                  fields: {},
                },
                "Allowed Validations": [],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomDropDownListViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/DropdownList",
                },
              },
            },
            Conditions: {
              value: `${JSON.stringify(conditions)}`,
            },
            "Field Key": {
              value: `sbcArea${item?.item_key.replace(/\s/g, "")}`,
            },
          },
          items: childItems || [],
        };
        enquirySteps.push(childDropdown);
      });
    }

    if (
      !!block?.text_field?.field_name &&
      ["typeOfBusinessOther"].indexOf(block?.text_field?.field_name) >= 0
    ) {
      const stepClass = `block conditional ${block?.text_field?.field_name}`;
      const typeOfBusinessOtherID = generateUniqSerial().split("-").join("");
      const conditions = {
        fieldConditions: [
          {
            matchTypeId: "{365C94DA-C1CD-4783-A91D-0D17A16C7119}",
            conditions: [
              {
                fieldId: fieldTypeOfBusiness,
                operatorId: "{1D38B217-A2EE-4E7B-B6ED-13E751462FEB}",
                value: lastValueOfTypeOfBusiness,
              },
            ],
            actions: [
              {
                fieldId: typeOfBusinessOtherID,
                actionTypeId: "{AAE07A52-46A4-49EF-98B0-C2595BAC2382}",
                value: null,
              },
            ],
          },
        ],
      };
      enquiryMapData[block?.text_field?.field_name] = {
        label: block?.text_field?.label,
        validationMessage: block?.text_field?.validation_message,
      };
      
      const step = {
        id: "43fe4bed-cefd-43ee-91d2-4c02bf5d13ae",
        name: `${block?.text_field?.field_name}Name`,
        displayName: enquiryMapData[block?.text_field?.field_name].label || "",
        parentId: "2344a106-ff60-4cbf-ac9c-c3f0994b0e83",
        fields: {
          Rows: {
            value: "4",
          },
          "Default Value": {
            value: "",
          },
          "Max Length": {
            value: `${block?.text_field?.max_length || 0}`,
          },
          "Min Length": {
            value: "0",
          },
          "Placeholder Text": {
            value:
              "Please use this space to ask any questions or make a comment",
          },
          dialInCodeLength: null,
          "Css Class": {
            value: stepClass,
          },
          isHidden: {
            value: false,
          },
          HelpText: {
            value: "",
          },
          GeneralError: {
            value: block?.text_field?.validation_message,
          },
          SpecificError: {
            value:
              "To submit your question(s) or to comment, please leave a message.",
          },
          apiName: {
            id: "8245a7c2-9615-4e69-ab60-10a1a6912786",
            fieldType: "Droplink",
            fieldName: "apiName",
            url: `/sitecore/content/fwd/global/settings/list/form-api-fields/${block?.text_field?.field_name}`,
            fields: {
              value: {
                value: block?.text_field?.field_name,
              },
              key: {
                value: block?.text_field?.field_name,
              },
            },
          },
          "Label Css Class": {
            value: "",
          },
          Title: {
            value: block?.text_field?.placeholder,
          },
          Required: {
            value: true,
          },
          Validations: [],
          "Is Tracking Enabled": {
            value: true,
          },
          "Allow Save": {
            value: true,
          },
          "Value Provider Parameters": {
            value: "",
          },
          "Value Provider": null,
          "Field Type": {
            id: "a296a1c1-0da0-4493-a92e-b8191f43aec6",
            fieldType: "Droptree",
            fieldName: "Field Type",
            url: "/sitecore/system/settings/forms/field-types/basic/multiple-line-text",
            fields: {
              BackgroundColor: {
                value: "Sky",
              },
              Icon: {
                value: "OfficeWhite/32x32/text.png",
              },
              IsDisabled: {
                value: false,
              },
              SpritePosition: {
                value: "",
              },
              "Field Template": {
                id: "d8386d04-c1e3-4cd3-9227-9e9f86ef3c88",
                fieldType: "Droptree",
                fieldName: "Field Template",
                url: "/sitecore/templates/system/forms/fields/multiple-line-text",
                fields: {},
              },
              "Allowed Validations": [
                {
                  id: "83e96d09-11c8-4132-8a8a-76d8db8208d6",
                  fields: {
                    Message: {
                      value:
                        "{0} must have at least {1} and no more than {2} characters.",
                    },
                    Parameters: {
                      value: "",
                    },
                    Type: {
                      value:
                        "Sitecore.ExperienceForms.Mvc.Models.Validation.StringLengthValidation,Sitecore.ExperienceForms.Mvc",
                    },
                  },
                },
              ],
              "Model Type": {
                value:
                  "FWD.Foundation.Forms.CustomMultipleLineTextViewModel,FWD.Foundation.Forms",
              },
              "Property Editor": null,
              "View Path": {
                value: "FieldTemplates/MultipleLineText",
              },
            },
          },
          Conditions: {
            value: `${JSON.stringify(conditions)}`,
          },
          "Field Key": {
            value: typeOfBusinessOtherID,
          },
          "Collapsed by Default": {
            value: false,
          },
        },
        items: [],
      };
      enquirySteps.push(step);
    }
  });

  let lastSteps: any = [
    {
      id: "1afc8b29-d790-495d-8cfb-8a8943636d34",
      name: "submitButton",
      displayName: "Submit button",
      parentId: "30844f80-3099-4c1d-a684-1f92721ac3e3",
      fields: {
        "Navigation Step": {
          value: "0",
        },
        emailTemplateLink: {
          value: "",
        },
        "Css Class": {
          value: "submitButton",
        },
        HelpText: {
          value: "",
        },
        GeneralError: {
          value: "",
        },
        SpecificError: {
          value: "",
        },
        isHidden: {
          value: false,
        },
        apiName: {
          id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
          fieldType: "Droplink",
          fieldName: "apiName",
          url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
          fields: {
            value: {
              value: "",
            },
            key: {
              value: "",
            },
          },
        },
        serverValidationErrorText: {
          value: "",
        },
        serverValidationLoadingText: {
          value: "",
        },
        enableServerValidation: {
          value: false,
        },
        serverValidationAPI: null,
        "Label Css Class": {
          value: "",
        },
        Title: {
          value: enquiryMapData?.consent?.submitButton,
        },
        "Field Type": {
          id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
          fieldType: "Droptree",
          fieldName: "Field Type",
          url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
          fields: {
            BackgroundColor: {
              value: "Eggplant",
            },
            Icon: {
              value: "OfficeWhite/32x32/button.png",
            },
            IsDisabled: {
              value: false,
            },
            SpritePosition: {
              value: "",
            },
            "Field Template": {
              id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
              fieldType: "Droptree",
              fieldName: "Field Template",
              url: "/sitecore/templates/system/forms/fields/button",
              fields: {},
            },
            "Allowed Validations": [],
            "Model Type": {
              value:
                "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
            },
            "Property Editor": null,
            "View Path": {
              value: "FieldTemplates/Button",
            },
          },
        },
        Conditions: {
          value: '{"fieldConditions":[]}',
        },
        "Field Key": {
          value: "D27EE3F0C3F445BD945F957A0AFBD626",
        },
        "Collapsed by Default": {
          value: false,
        },
      },
      items: [
        {
          id: "62ca7733-4673-4d76-8237-f9a76d52fc4d",
          name: "Save Data",
          displayName: "Save Data",
          parentId: "1afc8b29-d790-495d-8cfb-8a8943636d34",
          fields: {
            Description: {
              value: "",
            },
            Parameters: {
              value: "",
            },
            "Submit Action": {
              id: "0c61eab3-a61e-47b8-ae0b-b6eba0d6eb1b",
              fieldType: "Droptree",
              fieldName: "Submit Action",
              url: "/sitecore/system/settings/forms/submit-actions/save-data",
              fields: {
                Editor: null,
                "Error Message": {
                  value: "Saving form data failed!",
                },
                "Model Type": {
                  value: "Sitecore.ExperienceForms.Processing.Actions.SaveData",
                },
              },
            },
          },
          items: [],
        },
      ],
    },
    {
      id: "63325b5b-c209-4ad6-8f40-67bf5d4986f0",
      name: "source",
      displayName: "source",
      parentId: "30844f80-3099-4c1d-a684-1f92721ac3e3",
      fields: {
        "Html Tag": {
          value: "p",
        },
        Text: {
          value: "4",
        },
        "Css Class": {
          value: "",
        },
        HelpText: {
          value: "",
        },
        GeneralError: {
          value: "",
        },
        SpecificError: {
          value: "",
        },
        isHidden: {
          value: true,
        },
        apiName: {
          id: "0d6d0e98-3398-447a-90a9-94636f297d18",
          fieldType: "Droplink",
          fieldName: "apiName",
          url: "/sitecore/content/fwd/global/settings/list/form-api-fields/source",
          fields: {
            value: {
              value: "source",
            },
            key: {
              value: "source",
            },
          },
        },
        serverValidationErrorText: {
          value: "",
        },
        serverValidationLoadingText: {
          value: "",
        },
        enableServerValidation: {
          value: false,
        },
        serverValidationAPI: null,
        "Field Type": {
          id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
          fieldType: "Droptree",
          fieldName: "Field Type",
          url: "/sitecore/system/settings/forms/field-types/basic/text",
          fields: {
            BackgroundColor: {
              value: "Sky",
            },
            Icon: {
              value: "OfficeWhite/32x32/document_text.png",
            },
            IsDisabled: {
              value: false,
            },
            SpritePosition: {
              value: "",
            },
            "Field Template": {
              id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
              fieldType: "Droptree",
              fieldName: "Field Template",
              url: "/sitecore/templates/system/forms/fields/text",
              fields: {},
            },
            "Allowed Validations": [],
            "Model Type": {
              value:
                "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
            },
            "Property Editor": null,
            "View Path": {
              value: "FieldTemplates/Text",
            },
          },
        },
        Conditions: {
          value: "",
        },
        "Field Key": {
          value: "",
        },
        "Collapsed by Default": {
          value: false,
        },
      },
      items: [],
    },
    {
      id: "61f5d804-49c1-4749-aa5b-b877a3cf404e",
      name: "consentMsg",
      displayName: "consentMsg",
      parentId: "30844f80-3099-4c1d-a684-1f92721ac3e3",
      fields: {
        "Html Tag": {
          value: "p",
        },
        Text: {
          value: "",
        },
        "Css Class": {
          value: "",
        },
        HelpText: {
          value: "",
        },
        GeneralError: {
          value: "",
        },
        SpecificError: {
          value: "",
        },
        isHidden: {
          value: true,
        },
        apiName: {
          id: "742c941c-44ec-4e36-878f-6dc28f99baff",
          fieldType: "Droplink",
          fieldName: "apiName",
          url: "/sitecore/content/fwd/global/settings/list/form-api-fields/consentmsg",
          fields: {
            value: {
              value: "consentMsg",
            },
            key: {
              value: "consentMsg",
            },
          },
        },
        serverValidationErrorText: {
          value: "",
        },
        serverValidationLoadingText: {
          value: "",
        },
        enableServerValidation: {
          value: false,
        },
        serverValidationAPI: null,
        "Field Type": {
          id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
          fieldType: "Droptree",
          fieldName: "Field Type",
          url: "/sitecore/system/settings/forms/field-types/basic/text",
          fields: {
            BackgroundColor: {
              value: "Sky",
            },
            Icon: {
              value: "OfficeWhite/32x32/document_text.png",
            },
            IsDisabled: {
              value: false,
            },
            SpritePosition: {
              value: "",
            },
            "Field Template": {
              id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
              fieldType: "Droptree",
              fieldName: "Field Template",
              url: "/sitecore/templates/system/forms/fields/text",
              fields: {},
            },
            "Allowed Validations": [],
            "Model Type": {
              value:
                "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
            },
            "Property Editor": null,
            "View Path": {
              value: "FieldTemplates/Text",
            },
          },
        },
        Conditions: {
          value: "",
        },
        "Field Key": {
          value: "",
        },
        "Collapsed by Default": {
          value: false,
        },
      },
      items: [],
    },
    {
      id: "2bcd5b19-1b41-4fb3-b01b-ef2f809d7963",
      name: "consentVersionCd",
      displayName: "consentVersionCd",
      parentId: "30844f80-3099-4c1d-a684-1f92721ac3e3",
      fields: {
        "Html Tag": {
          value: "p",
        },
        Text: {
          value: enquiryMapData?.consent?.versionCd || "",
        },
        "Css Class": {
          value: "",
        },
        HelpText: {
          value: "",
        },
        GeneralError: {
          value: "",
        },
        SpecificError: {
          value: "",
        },
        isHidden: {
          value: true,
        },
        apiName: {
          id: "10fc0d1b-f2f0-4007-bcd7-9dc3675741bb",
          fieldType: "Droplink",
          fieldName: "apiName",
          url: "/sitecore/content/fwd/global/settings/list/form-api-fields/consentversioncd",
          fields: {
            value: {
              value: "consentVersionCd",
            },
            key: {
              value: "ConsentVersionCd",
            },
          },
        },
        serverValidationErrorText: {
          value: "",
        },
        serverValidationLoadingText: {
          value: "",
        },
        enableServerValidation: {
          value: false,
        },
        serverValidationAPI: null,
        "Field Type": {
          id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
          fieldType: "Droptree",
          fieldName: "Field Type",
          url: "/sitecore/system/settings/forms/field-types/basic/text",
          fields: {
            BackgroundColor: {
              value: "Sky",
            },
            Icon: {
              value: "OfficeWhite/32x32/document_text.png",
            },
            IsDisabled: {
              value: false,
            },
            SpritePosition: {
              value: "",
            },
            "Field Template": {
              id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
              fieldType: "Droptree",
              fieldName: "Field Template",
              url: "/sitecore/templates/system/forms/fields/text",
              fields: {},
            },
            "Allowed Validations": [],
            "Model Type": {
              value:
                "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
            },
            "Property Editor": null,
            "View Path": {
              value: "FieldTemplates/Text",
            },
          },
        },
        Conditions: {
          value: "",
        },
        "Field Key": {
          value: "",
        },
        "Collapsed by Default": {
          value: false,
        },
      },
      items: [],
    },
    {
      id: "291417cb-da03-44c1-8053-cab3adace975",
      name: "partnerName",
      displayName: "partnerName",
      parentId: "30844f80-3099-4c1d-a684-1f92721ac3e3",
      fields: {
        "Html Tag": {
          value: "p",
        },
        Text: {
          value: "NexGen",
        },
        "Css Class": {
          value: "",
        },
        HelpText: {
          value: "",
        },
        GeneralError: {
          value: "",
        },
        SpecificError: {
          value: "",
        },
        isHidden: {
          value: true,
        },
        apiName: {
          id: "865d0d1e-9b62-4e35-af48-f3912b20dd0c",
          fieldType: "Droplink",
          fieldName: "apiName",
          url: "/sitecore/content/fwd/global/settings/list/form-api-fields/partnername",
          fields: {
            value: {
              value: "partnerName",
            },
            key: {
              value: "PartnerName",
            },
          },
        },
        serverValidationErrorText: {
          value: "",
        },
        serverValidationLoadingText: {
          value: "",
        },
        enableServerValidation: {
          value: false,
        },
        serverValidationAPI: null,
        "Field Type": {
          id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
          fieldType: "Droptree",
          fieldName: "Field Type",
          url: "/sitecore/system/settings/forms/field-types/basic/text",
          fields: {
            BackgroundColor: {
              value: "Sky",
            },
            Icon: {
              value: "OfficeWhite/32x32/document_text.png",
            },
            IsDisabled: {
              value: false,
            },
            SpritePosition: {
              value: "",
            },
            "Field Template": {
              id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
              fieldType: "Droptree",
              fieldName: "Field Template",
              url: "/sitecore/templates/system/forms/fields/text",
              fields: {},
            },
            "Allowed Validations": [],
            "Model Type": {
              value:
                "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
            },
            "Property Editor": null,
            "View Path": {
              value: "FieldTemplates/Text",
            },
          },
        },
        Conditions: {
          value: "",
        },
        "Field Key": {
          value: "",
        },
        "Collapsed by Default": {
          value: false,
        },
      },
      items: [],
    },
    {
      id: "7a52c948-2874-4886-b126-4691b1083d58",
      name: "dtcCampaingId",
      displayName: "dtcCampaingId",
      parentId: "30844f80-3099-4c1d-a684-1f92721ac3e3",
      fields: {
        "Html Tag": {
          value: "p",
        },
        Text: {
          value: enquiryMapData?.consent?.dtcCampaignId || "",
        },
        "Css Class": {
          value: "",
        },
        HelpText: {
          value: "",
        },
        GeneralError: {
          value: "",
        },
        SpecificError: {
          value: "",
        },
        isHidden: {
          value: true,
        },
        apiName: {
          id: "cd19cd7e-4435-4ee7-8e7b-522d7384522b",
          fieldType: "Droplink",
          fieldName: "apiName",
          url: "/sitecore/content/fwd/global/settings/list/form-api-fields/dtccampaignid",
          fields: {
            value: {
              value: "dTCCampaignId",
            },
            key: {
              value: "dTCCampaignId",
            },
          },
        },
        serverValidationErrorText: {
          value: "",
        },
        serverValidationLoadingText: {
          value: "",
        },
        enableServerValidation: {
          value: false,
        },
        serverValidationAPI: null,
        "Field Type": {
          id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
          fieldType: "Droptree",
          fieldName: "Field Type",
          url: "/sitecore/system/settings/forms/field-types/basic/text",
          fields: {
            BackgroundColor: {
              value: "Sky",
            },
            Icon: {
              value: "OfficeWhite/32x32/document_text.png",
            },
            IsDisabled: {
              value: false,
            },
            SpritePosition: {
              value: "",
            },
            "Field Template": {
              id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
              fieldType: "Droptree",
              fieldName: "Field Template",
              url: "/sitecore/templates/system/forms/fields/text",
              fields: {},
            },
            "Allowed Validations": [],
            "Model Type": {
              value:
                "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
            },
            "Property Editor": null,
            "View Path": {
              value: "FieldTemplates/Text",
            },
          },
        },
        Conditions: {
          value: "",
        },
        "Field Key": {
          value: "",
        },
        "Collapsed by Default": {
          value: false,
        },
      },
      items: [],
    },
    {
      id: "03f206b4-46ec-4e4b-93c0-43096b738b06",
      name: "MediaSource",
      displayName: "MediaSource",
      parentId: "30844f80-3099-4c1d-a684-1f92721ac3e3",
      fields: {
        "Html Tag": {
          value: "p",
        },
        Text: {
          value: "",
        },
        "Css Class": {
          value: "",
        },
        HelpText: {
          value: "",
        },
        GeneralError: {
          value: "",
        },
        SpecificError: {
          value: "",
        },
        isHidden: {
          value: true,
        },
        apiName: {
          id: "9966e4e3-f61b-48f4-9958-8eedf4aa3665",
          fieldType: "Droplink",
          fieldName: "apiName",
          url: "/sitecore/content/fwd/global/settings/list/form-api-fields/mediasource",
          fields: {
            value: {
              value: "mediaSource",
            },
            key: {
              value: "mediaSource",
            },
          },
        },
        serverValidationErrorText: {
          value: "",
        },
        serverValidationLoadingText: {
          value: "",
        },
        enableServerValidation: {
          value: false,
        },
        serverValidationAPI: null,
        "Field Type": {
          id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
          fieldType: "Droptree",
          fieldName: "Field Type",
          url: "/sitecore/system/settings/forms/field-types/basic/text",
          fields: {
            BackgroundColor: {
              value: "Sky",
            },
            Icon: {
              value: "OfficeWhite/32x32/document_text.png",
            },
            IsDisabled: {
              value: false,
            },
            SpritePosition: {
              value: "",
            },
            "Field Template": {
              id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
              fieldType: "Droptree",
              fieldName: "Field Template",
              url: "/sitecore/templates/system/forms/fields/text",
              fields: {},
            },
            "Allowed Validations": [],
            "Model Type": {
              value:
                "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
            },
            "Property Editor": null,
            "View Path": {
              value: "FieldTemplates/Text",
            },
          },
        },
        Conditions: {
          value: '{"fieldConditions":[]}',
        },
        "Field Key": {
          value: "3DA1A3628894402E9BE4E5BC3B57C06A",
        },
        "Collapsed by Default": {
          value: false,
        },
      },
      items: [],
    },
    {
      id: "23e5c856-0710-4668-87b6-4c68b6b44001",
      name: "requireULLicense",
      displayName: "requireULLicense",
      parentId: "3627d278-2067-4aa3-831e-496e0af1bd30",
      fields: {
        "Html Tag": {
          value: "p",
        },
        Text: {
          value: enquiryMapData?.consent?.requiredUlLicense || "",
        },
        "Css Class": {
          value: "",
        },
        isHidden: {
          value: true,
        },
        HelpText: {
          value: "",
        },
        GeneralError: {
          value: "",
        },
        SpecificError: {
          value: "",
        },
        apiName: {
          id: "865d0d1e-9b62-4e35-af48-f3912b20dd0c",
          fieldType: "Droplink",
          fieldName: "apiName",
          url: "/sitecore/content/fwd/global/settings/list/form-api-fields/requireullicense",
          fields: {
            value: {
              value: "requireULLicense",
            },
            key: {
              value: "requireULLicense",
            },
          },
        },
        "Field Type": {
          id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
          fieldType: "Droptree",
          fieldName: "Field Type",
          url: "/sitecore/system/settings/forms/field-types/basic/text",
          fields: {
            BackgroundColor: {
              value: "Sky",
            },
            Icon: {
              value: "OfficeWhite/32x32/document_text.png",
            },
            IsDisabled: {
              value: false,
            },
            SpritePosition: {
              value: "",
            },
            "Field Template": {
              id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
              fieldType: "Droptree",
              fieldName: "Field Template",
              url: "/sitecore/templates/system/forms/fields/text",
              fields: {},
            },
            "Allowed Validations": [],
            "Model Type": {
              value:
                "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
            },
            "Property Editor": null,
            "View Path": {
              value: "FieldTemplates/Text",
            },
          },
        },
        Conditions: {
          value: '{"fieldConditions":[]}',
        },
        "Field Key": {
          value: "49685C5ED7494EF9A73C8BEB81B0867D",
        },
        "Collapsed by Default": {
          value: false,
        },
      },
      items: [],
    },
  ];

  if (enquiryMapData.consent?.content) {
    const consentCheckbox: any = [{
      id: "af91d620-6ceb-44b5-a97f-78dbfb2d2206",
      name: "CheckList Section",
      displayName: "CheckList Section",
      parentId: "30844f80-3099-4c1d-a684-1f92721ac3e3",
      fields: {
        backgroundColor: {
          id: "921097f6-7bcb-4385-897e-b4adbf4a99de",
          fieldType: "Droplink",
          fieldName: "backgroundColor",
          url: "/sitecore/content/fwd/global/settings/list/colors/grey",
          fields: {
            value: {
              value: "grey",
            },
            key: {
              value: "grey",
            },
          },
        },
        "Css Class": {
          value: "consentSection",
        },
        HelpText: {
          value: "",
        },
        GeneralError: {
          value: "",
        },
        SpecificError: {
          value: "",
        },
        isHidden: {
          value: false,
        },
        apiName: null,
        serverValidationErrorText: {
          value: "",
        },
        serverValidationLoadingText: {
          value: "",
        },
        enableServerValidation: {
          value: false,
        },
        serverValidationAPI: null,
        "Field Type": {
          id: "447aa745-6d29-4b65-a5a3-8173aa8af548",
          fieldType: "Droptree",
          fieldName: "Field Type",
          url: "/sitecore/system/settings/forms/field-types/structure/section",
          fields: {
            BackgroundColor: {
              value: "Eggplant",
            },
            Icon: {
              value: "OfficeWhite/32x32/document_header.png",
            },
            IsDisabled: {
              value: false,
            },
            SpritePosition: {
              value: "",
            },
            "Field Template": {
              id: "8cddb194-f456-4a75-89b7-346f8f39f95c",
              fieldType: "Droptree",
              fieldName: "Field Template",
              url: "/sitecore/templates/system/forms/fields/section",
              fields: {},
            },
            "Allowed Validations": [],
            "Model Type": {
              value:
                "Sitecore.ExperienceForms.Mvc.Models.Fields.FieldViewModel,Sitecore.ExperienceForms.Mvc",
            },
            "Property Editor": null,
            "View Path": {
              value: "FieldTemplates/Section",
            },
          },
        },
        Conditions: {
          value: '{"fieldConditions":[]}',
        },
        "Field Key": {
          value: "E2E49B32D9E247A4980F0D4E4CFFC50E",
        },
        "Collapsed by Default": {
          value: false,
        },
      },
      items: [
        {
          id: "34f4f2b6-3b65-4a7e-8d33-72f23967234d",
          name: "privacyPolicy",
          displayName: "Privacy policy",
          parentId: "af91d620-6ceb-44b5-a97f-78dbfb2d2206",
          fields: {
            Datasource: {
              id: "e8c592ad-cf7f-465a-b669-94d1f2744ced",
              fieldType: "Droptree",
              fieldName: "Datasource",
              url: "/content/forms/privacypolicy/general-consent-002",
              fields: {},
            },
            "Default Selection": {
              value: "",
            },
            "Display Field Name": {
              value: "value",
            },
            "Is Dynamic": {
              value: true,
            },
            "Value Field Name": {
              value: "key",
            },
            "Css Class": {
              value: "block privacyPolicy",
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: enquiryMapData?.consent?.validationMessage,
            },
            SpecificError: {
              value: "",
            },
            isHidden: {
              value: false,
            },
            apiName: {
              id: "2b066ac3-4cbd-41c7-8e19-c26ab07eccc1",
              fieldType: "Droplink",
              fieldName: "apiName",
              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/consentcheckbox",
              fields: {
                value: {
                  value: "consentCheckBox",
                },
                key: {
                  value: "consentCheckBox",
                },
              },
            },
            serverValidationErrorText: {
              value: "",
            },
            serverValidationLoadingText: {
              value: "",
            },
            enableServerValidation: {
              value: false,
            },
            serverValidationAPI: null,
            "Label Css Class": {
              value: "",
            },
            Title: {
              value: "",
            },
            Required: {
              value: true,
            },
            Validations: [],
            "Is Tracking Enabled": {
              value: true,
            },
            "Allow Save": {
              value: true,
            },
            "Value Provider Parameters": {
              value: "",
            },
            "Value Provider": null,
            "Field Type": {
              id: "d86a361a-d4ff-46b2-9e97-a37fc5b1fe1a",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/lists/checkbox-list",
              fields: {
                BackgroundColor: {
                  value: "Grass",
                },
                Icon: {
                  value: "OfficeWhite/32x32/checkbox_group.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "5b672865-55d2-413e-b699-fdfc7e732ccf",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/list",
                  fields: {},
                },
                "Allowed Validations": [],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomCheckBoxListViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/CheckBoxList",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: "22FDF3EF931946358A3C4022846A03E4",
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: [
            {
              id: "804cdfce-0538-4b4a-8a2a-8ec157a56712",
              name: "privacy-policy",
              displayName: "Privacy Policy",
              parentId: "e8c592ad-cf7f-465a-b669-94d1f2744ced",
              fields: {
                value: {
                  value: enquiryMapData?.consent?.content,
                },
              },
            },
          ],
        },
      ],
    }];
    lastSteps = [...consentCheckbox, ...lastSteps]
  }

  enquirySteps = [...enquirySteps, ...lastSteps];

  return {
    sitecoreContext: {
      language: i18nContext?.language?.languageCode || "en",
      languageCode: i18nContext?.language?.languageCode || "en",
      site: {
        name: `fwd-${i18nContext?.countryCode}`,
      },
      countryCode: i18nContext?.countryCode,
      availableLanguages: ["th", "en"],
      route: {
        fields: {
          apiList: [
            {
              id: "d58e3660-0ab7-4811-ad6f-d007e52af349",
              fields: {
                apiName: {
                  value: "SaveLead",
                },
                value: {
                  value: enquiryMapData?.submissionEndpoint,
                },
                key: {
                  value: enquiryMapData?.authenticationKey,
                },
              },
            },
            {
              id: "2334a4bf-d431-461f-b313-1dd10100c27e",
              fields: {
                apiName: {
                  value: "Email",
                },
                value: {
                  value: enquiryMapData?.sendEmailEndpoint,
                },
                key: {
                  value: enquiryMapData?.authenticationKey,
                },
              },
            },
          ],
          formEmailTemplate: {
            value: {
              text: "",
              linktype: "form",
              querystring: "",
              target: "",
              id: "{604FEEB8-7B79-4966-A3D8-FEA5BCD7D98D}",
              href: "/forms-list/product-enquiry-form/product-enquiry-form-email-template",
            },
          },
          enableProductName: {
            value: true,
          },
          expectedProductName: {
            value: productName,
          },
          contentType: null,
          pagedescription: {
            value: "",
          },
          pagetitle: {
            value: "",
          },
          disableHTMLCache: {
            value: true,
          },
          globalMetaTextSetting: null,
          pageType: {
            id: "4fded742-177c-4850-9853-fa31cc47d63e",
            fieldType: "Droplink",
            fieldName: "pageType",
            url: "/components/pagetype/individual",
            fields: {
              value: {
                value: "Individual",
              },
              key: {
                value: "individual",
              },
            },
          },
          localizedPages: {
            value: {},
          },
          metadataTitle: {
            value: "FWD Insurance Products",
          },
          metadataDescription: {
            value:
              "At FWD, we design insurance products that’s inspired by our customers. Explore our range of insurance products to find what best suits you, your lifestyle and your needs.",
          },
          metadataKeywords: {
            value: "",
          },
          accrediationValue: null,
          accreditationMode: null,
          accreditationDescription: {
            value: "",
          },
          disableCompliance: {
            value: false,
          },
          openGraphType: {
            value: "",
          },
          openGraphDescription: {
            value: "",
          },
          openGraphURL: {
            value: {},
          },
          openGraphImage: {
            value: {},
          },
          openGraphTitle: {
            value: "",
          },
          canonicalUrl: {
            value: "",
          },
          isPageSearchable: {
            value: true,
          },
          hideInSitemap: {
            value: false,
          },
          hideLocalizedVersion: {
            value: false,
          },
        },
      },
    },
    params: {
      formType: "regular",
      loaderBackgroundColor: enquiryMapData?.thankYouBlock?.loadingDarkMode
        ? "orange"
        : "white",
    },
    fields: {
      items: [
        {
          id: "30844f80-3099-4c1d-a684-1f92721ac3e3",
          name: "groupEnquiryFormPage",
          displayName: "groupEnquiryFormPage",
          parentId: "f0bd8ca1-8714-4e69-9262-cec4362a0c44",
          fields: {
            backgroundColor: {
              id: "cbdc6265-d09f-4982-981a-bcc8f118f36f",
              fieldType: "Droplink",
              fieldName: "backgroundColor",
              url: "/sitecore/content/fwd/global/settings/list/colors/whiteorange",
              fields: {
                value: {
                  value: "white-orange",
                },
                key: {
                  value: "WhiteOrange",
                },
              },
            },
            gaLabel: {
              value: "",
            },
            "Css Class": {
              value: "",
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: "",
            },
            SpecificError: {
              value: "",
            },
            isHidden: {
              value: false,
            },
            apiName: {
              value: "SaveLead",
            },
            serverValidationErrorText: {
              value: "",
            },
            serverValidationLoadingText: {
              value: "",
            },
            enableServerValidation: {
              value: false,
            },
            serverValidationAPI: null,
            "Field Type": {
              id: "d819b43e-c136-4392-9393-8be7fce32f5e",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/structure/page",
              fields: {
                BackgroundColor: {
                  value: "Eggplant",
                },
                Icon: {
                  value: "OfficeWhite/32x32/window_split_ver.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "cfee7b51-8505-45ce-b843-9358f827df87",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/page",
                  fields: {},
                },
                "Allowed Validations": [],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomPageViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/Page",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: "5CBA38EE63B849BFB118BF3902C30F18",
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: enquirySteps,
        },
        {
          id: "3627d278-2067-4aa3-831e-496e0af1bd30",
          name: "thankYouPage",
          displayName: "Thank You",
          parentId: "57ec00df-f75a-4645-a4f1-a830be98de45",
          fields: {
            gaLabel: {
              value: "",
            },
            backgroundColor: {
              id: "b47928d6-98fc-4b29-8d0f-586ab477286e",
              fieldType: "Droplink",
              fieldName: "backgroundColor",
              url: "/sitecore/content/fwd/global/settings/list/colors/orangewhite",
              fields: {
                value: {
                  value: "orange-white",
                },
                key: {
                  value: "OrangeWhite",
                },
              },
            },
            "Css Class": {
              value: "phoneSubmitPage darkMode conditionPage",
            },
            isHidden: {
              value: false,
            },
            HiddenInfos: {
              darkMode: enquiryMapData?.thankYouBlock?.darkMode,
            },
            HelpText: {
              value: "",
            },
            GeneralError: {
              value: "",
            },
            SpecificError: {
              value: "",
            },
            apiName: null,
            "Field Type": {
              id: "d819b43e-c136-4392-9393-8be7fce32f5e",
              fieldType: "Droptree",
              fieldName: "Field Type",
              url: "/sitecore/system/settings/forms/field-types/structure/page",
              fields: {
                BackgroundColor: {
                  value: "Eggplant",
                },
                Icon: {
                  value: "OfficeWhite/32x32/window_split_ver.png",
                },
                IsDisabled: {
                  value: false,
                },
                SpritePosition: {
                  value: "",
                },
                "Field Template": {
                  id: "cfee7b51-8505-45ce-b843-9358f827df87",
                  fieldType: "Droptree",
                  fieldName: "Field Template",
                  url: "/sitecore/templates/system/forms/fields/page",
                  fields: {},
                },
                "Allowed Validations": [],
                "Model Type": {
                  value:
                    "FWD.Foundation.Forms.CustomPageViewModel,FWD.Foundation.Forms",
                },
                "Property Editor": null,
                "View Path": {
                  value: "FieldTemplates/Page",
                },
              },
            },
            Conditions: {
              value: '{"fieldConditions":[]}',
            },
            "Field Key": {
              value: "F880144362CB4E118C0EE581A4C3D11C",
            },
            "Collapsed by Default": {
              value: false,
            },
          },
          items: [
            {
              id: "589eab8a-8dbc-43a4-a827-b99be1adbf11",
              name: "Text",
              displayName: "Text",
              parentId: "3627d278-2067-4aa3-831e-496e0af1bd30",
              fields: {
                "Html Tag": {
                  value: "p",
                },
                Text: {
                  value: enquiryMapData?.thankYouBlock?.title,
                },
                "Css Class": {
                  value: "",
                },
                isHidden: {
                  value: false,
                },
                HelpText: {
                  value: "",
                },
                GeneralError: {
                  value: "",
                },
                SpecificError: {
                  value: "",
                },
                apiName: null,
                "Field Type": {
                  id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
                  fieldType: "Droptree",
                  fieldName: "Field Type",
                  url: "/sitecore/system/settings/forms/field-types/basic/text",
                  fields: {
                    BackgroundColor: {
                      value: "Sky",
                    },
                    Icon: {
                      value: "OfficeWhite/32x32/document_text.png",
                    },
                    IsDisabled: {
                      value: false,
                    },
                    SpritePosition: {
                      value: "",
                    },
                    "Field Template": {
                      id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                      fieldType: "Droptree",
                      fieldName: "Field Template",
                      url: "/sitecore/templates/system/forms/fields/text",
                      fields: {},
                    },
                    "Allowed Validations": [],
                    "Model Type": {
                      value:
                        "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
                    },
                    "Property Editor": null,
                    "View Path": {
                      value: "FieldTemplates/Text",
                    },
                  },
                },
                Conditions: {
                  value: '{"fieldConditions":[]}',
                },
                "Field Key": {
                  value: "753703DC08564AD893AFA873A6215A42",
                },
                "Collapsed by Default": {
                  value: false,
                },
              },
              items: [],
            },
            {
              id: "cfdcb351-999b-4fb1-840a-0da5b30edb79",
              name: "TextForMeetAnAgent",
              displayName: "TextForMeetAnAgent",
              parentId: "3627d278-2067-4aa3-831e-496e0af1bd30",
              fields: {
                "Html Tag": {
                  value: "p",
                },
                Text: {
                  value: enquiryMapData?.thankYouBlock?.descriptionWithTime,
                },
                "Css Class": {
                  value: "block",
                },
                isHidden: {
                  value: false,
                },
                HelpText: {
                  value: "",
                },
                GeneralError: {
                  value: "",
                },
                SpecificError: {
                  value: "",
                },
                apiName: {
                  id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
                  fieldType: "Droplink",
                  fieldName: "apiName",
                  url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
                  fields: {
                    value: {
                      value: "",
                    },
                    key: {
                      value: "",
                    },
                  },
                },
                "Field Type": {
                  id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
                  fieldType: "Droptree",
                  fieldName: "Field Type",
                  url: "/sitecore/system/settings/forms/field-types/basic/text",
                  fields: {
                    BackgroundColor: {
                      value: "Sky",
                    },
                    Icon: {
                      value: "OfficeWhite/32x32/document_text.png",
                    },
                    IsDisabled: {
                      value: false,
                    },
                    SpritePosition: {
                      value: "",
                    },
                    "Field Template": {
                      id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                      fieldType: "Droptree",
                      fieldName: "Field Template",
                      url: "/sitecore/templates/system/forms/fields/text",
                      fields: {},
                    },
                    "Allowed Validations": [],
                    "Model Type": {
                      value:
                        "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
                    },
                    "Property Editor": null,
                    "View Path": {
                      value: "FieldTemplates/Text",
                    },
                  },
                },
                Conditions: {
                  value: '{"fieldConditions":[]}',
                },
                "Field Key": {
                  value: "915D7E632E374A2AA094ABE3D95213BE",
                },
                "Collapsed by Default": {
                  value: false,
                },
              },
              items: [],
            },
            {
              id: "bb11b9bd-73fa-4b7e-aa16-4de14f99c8b3",
              name: "backToHome",
              displayName: "Back to Home",
              parentId: "3627d278-2067-4aa3-831e-496e0af1bd30",
              fields: {
                "Navigation Step": {
                  value: "1",
                },
                emailTemplateLink: {
                  value: "",
                },
                "Css Class": {
                  value: "back-to-home",
                },
                isHidden: {
                  value: false,
                },
                HiddenInfos: {
                  activeUrl: enquiryMapData?.thankYouBlock?.activeUrl,
                },
                HelpText: {
                  value: "",
                },
                GeneralError: {
                  value: "",
                },
                SpecificError: {
                  value: "",
                },
                apiName: null,
                "Label Css Class": {
                  value: "",
                },
                Title: {
                  value: enquiryMapData?.thankYouBlock?.buttonText,
                },
                "Field Type": {
                  id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
                  fieldType: "Droptree",
                  fieldName: "Field Type",
                  url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
                  fields: {
                    BackgroundColor: {
                      value: "Eggplant",
                    },
                    Icon: {
                      value: "OfficeWhite/32x32/button.png",
                    },
                    IsDisabled: {
                      value: false,
                    },
                    SpritePosition: {
                      value: "",
                    },
                    "Field Template": {
                      id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
                      fieldType: "Droptree",
                      fieldName: "Field Template",
                      url: "/sitecore/templates/system/forms/fields/button",
                      fields: {},
                    },
                    "Allowed Validations": [],
                    "Model Type": {
                      value:
                        "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
                    },
                    "Property Editor": null,
                    "View Path": {
                      value: "FieldTemplates/Button",
                    },
                  },
                },
                Conditions: {
                  value: '{"fieldConditions":[]}',
                },
                "Field Key": {
                  value: "1B48EED990F64B8DBA5887B6BCFC9DC5",
                },
                "Collapsed by Default": {
                  value: false,
                },
              },
              items: [],
            },
          ],
        },
      ],
    },
  };
}
